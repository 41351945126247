
// import styles from './Users.module.css'
import useAuth from "../../hooks/useAuth";
import { Toast } from "primereact/toast";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { BiChevronLeft } from "react-icons/bi";
import CustomTable from "../../Components/CustomTable/CustomTable";

import MainButton from "../../Components/MainButton/MainButton";
// import SecondaryButton from "../../Components/SecondaryButton/SecondaryButton";
import CustomModal from "../../Components/CustomModal/CustomModal";
import DetailCoWorkerForm from "../../Components/DetailCoWorkerForm/DetailCoWorkerForm";
import { useEffect, useState } from "react";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import { db } from "../../firebase/firebase";
import { collection,  onSnapshot, query, where } from "firebase/firestore";
import AuxButton from "../../Components/AuxButton/AuxButton";
import StatusLight from "../../Components/StatusLight/StatusLight";
import moment from "moment";


// Este Componente de pagina muestra y gestiona una tabla de usuarios colaboradores (CoWorkers) creados por el Seller
export default function MyUsers() {
  const { userData } = useAuthContext();
  const { toast,
    // setCustomClaimsMasterUser
  } = useAuth();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { t } = useTranslation("Users");
  const [coworkers, setCoworkers] = useState([]);
  const [visibleCoworkerForm, setVisibleCoworkerForm] = useState(false);
  const [reRender, setReRender] = useState(false);
  const [selectedCoWorkerUser, setSelectedCoWorkerUser] = useState({});

  const CoWorkersTableColumns = [
    { 
      field: "role", header: t("table-headers.role"), 
      filterable: false,
      sortable: false, 
    },
    { 
      header: t("table-headers.user"), 
      filterable: false,
      sortable: false, 
      body: (rowData) => {
        return <span>
          {rowData.first_names + " " + rowData.last_names }
        </span>
      }
    },
    { field: "email", header: t("table-headers.email") },
    {
      field: 'created_at',
      header: t("createdAt"),
      // Columna para el campo 'created_at' con encabezado traducido y cuerpo personalizado para formatear la fecha
      body: (rowData) => {
        const createdAt = rowData.created_at
          ? moment.unix(rowData.created_at.seconds).format(t("table-headers.dateFormat"))
          : t("table-headers.invalidDateFormat");
        return <span>{createdAt}</span>;
      },
    },
    {
      field: 'last_session',
      header: t('table-headers.lastSession'),
        // Columna para mostrar el estado usando un componente personalizado 'StatusLight'
      body: (rowData) => {
        const lastSession = rowData.last_session
          ? moment.unix(rowData.last_session.seconds).format(t("table-headers.dateFormat"))
          : t("table-headers.invalidDateFormat");
        return <span>{lastSession}</span>;
      },
    },
    {
      field: "i",
      header: t("table-headers.state"),
      filterable: false, // no se puede filtrar
      sortable: false, // no se puede ordenar
        // Columna para mostrar el estado usando el componente StatusLight
      body: (rowData) => (
        <StatusLight
          color={rowData?.state?.color}
          tooltip={rowData?.state?.name}
        />
      ),
    },
    {
      header: "Editar",
      filterable: false, // no se puede filtrar
      sortable: false, // no se puede ordenar
        // Columna para botón de edición con un componente personalizado 'AuxButton'
      body: (rowData) => (
        <AuxButton
          data={rowData}
          disable={false}
          label={t("edit")}
          style={{
            backgroundColor: "var(--main-color)",
            color: "black",
            fontSize: "var(--font-size-h5)",
          }}
        onClick={openCoworkerFormModal}
        />
      ),
    },
  ];
  const openCoworkerFormModal = (account) => {
    setVisibleCoworkerForm(true);
    if (account.uid) { setSelectedCoWorkerUser(account);
    } else { setSelectedCoWorkerUser({}) }
  };
  // Funcion que controla el cierre del modal del formulario de coWorker
  const closeCoWorkerModal = () => { setVisibleCoworkerForm(false) };

  // const getUserCustomClaim = async () => {
  //   try {
  //     const uid = "QQEOHVzJViaOcn4fgAFK55KUXER2";
  //     const response = await fetch("https://us-central1-shoppex-aa709.cloudfunctions.net/getCustomClaims", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ uid }),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log(data.customClaims);
  //       console.log("Custom Claims:", data.customClaims);
  //     } else {
  //       console.error("Error en la solicitud:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  // Efecto secundario para cargar los datos de los coworkers
  useEffect(() => {
    setGlobalLoading(true);
    if (!userData?.business_id) {
      setGlobalLoading(false); // No hay business_id definido, entonces detener la carga global
      return;
    }
    const collectionName = "seller_users";
    const coworkerCollection = collection(db, collectionName);
    const q = query( coworkerCollection, where("business_id", "==", userData?.business_id) );
    let changeDetected = false; // Bandera para verificar si hubo cambios en la colección
      // Snapshot listener para detectar cambios en la colección
    const coworkerDocsUnsubscribe = onSnapshot(q, (querySnapshot) => {
      const coworkerData = [];
      querySnapshot.forEach((doc) => {
        coworkerData.push({...doc.data() });
      });
      if (!querySnapshot.empty && !changeDetected) {
        // Hubo cambios y no se ha detectado aún
        changeDetected = true; // Actualizar la bandera
      }
      if (changeDetected) {
        // Se detectaron cambios, actualiza los datos
        const filteredCoworkersWithoutActuallyUser = coworkerData.filter((coworker) => coworker.uid !== userData.uid);
        setCoworkers(filteredCoworkersWithoutActuallyUser);
        setGlobalLoading(false);
      } else {
        setGlobalLoading(false); // No se detectaron cambios, detener la carga global
      }
    });
    return () => {
      coworkerDocsUnsubscribe(); // Detiene el snapshot listener al desmontar el componente o actualizar el efecto.
    };
  }, [userData, reRender]);


  return (
    <div style={{ color: "white" }}>
      <div style={{ color: "white", marginBottom:"40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtnMyUsers")} />
      </div>
        <CustomTable
          className="custom-table"
          columns={CoWorkersTableColumns}
          data={coworkers}
          action={() => {}}
        />
      <CustomModal
        visible={visibleCoworkerForm}
        setVisible={setVisibleCoworkerForm}
        object={selectedCoWorkerUser}
        editMode={t("updateUser")}
        createMode={t("createUser")}
        content={
          <DetailCoWorkerForm
            onClose={setVisibleCoworkerForm}
            selectedCoWorkerUser={selectedCoWorkerUser}
            setReRender={setReRender}
            userData={userData}
            labelDelete={t("deleteUser")}
            labelSuccess={t("createUser")}
            toast={toast}
          />
        }
        onClose={closeCoWorkerModal}
      />
      <div style={{ marginTop:"100px" }}>
        <MainButton
          loading={globalLoading}
          // label={t("addBank")}
          label={t("createUser")}
          onClick={openCoworkerFormModal}
        />
      {/* <SecondaryButton
        onClick={
          // () =>
        // //   setCustomClaimsMasterUser()
          getUserCustomClaim
        }
        label="See CUSTOM CLAIMS"
      /> */}
      </div>
      <Toast ref={toast} />
    </div>
  );
}
