// Importando los módulos de los píxeles de Facebook y TikTok
import { fbq } from "../Meta/pixelFB";
import { TiktokPixel } from "../Tiktok/pixelTT";

// Evento PageView
const pageView = () => {
    fbq('track', 'PageView');
    TiktokPixel.pageView();
}

// Evento ViewContent
const viewContent = (description = "any") => {
    fbq('track', 'ViewContent',{
        description,
    });
    TiktokPixel.track('ViewContent', {
        content_id: description,
        description,
    });
}

// Evento InitiateCheckout
const initiateCheckout = (description = "any") => {
    fbq('track', 'InitiateCheckout');
    TiktokPixel.track('InitiateCheckout', {
        content_id: description,
    });
}

// Evento AddPaymentInfo
const addPaymentInfo = (description = "any") => {
    fbq('track', 'AddPaymentInfo');
    TiktokPixel.track('AddPaymentInfo', {
        content_id: description,
    });
}

// Evento lead
const lead = (description = "any") => {
    fbq('track', 'Lead');
    TiktokPixel.track('SubmitForm', {
        content_id: description,
    });
}

// Evento Register
const register = (description = "any") => {
    fbq('track', 'CompleteRegistration');
    TiktokPixel.track('CompleteRegistration', {
        content_id: description,
    });
}

// Evento AddToCart
const addToCart = (value, currency = 'COP', description = "any") => {
    fbq('track', 'AddToCart');
    TiktokPixel.track('AddToCart', {
        content_id: description,
        value: value,
        currency: currency,
    });
}

// Evento Purchase
const purchase = (value, currency = 'COP', description = "any") => {
    fbq('track', 'Purchase', {
        value: value,
        currency: currency,
    });
    TiktokPixel.track('CompletePayment', {
        content_id: description,
        value: value,
        currency: currency,
    });
}

// Exportando todos los eventos para usar en otros archivos
export { pageView, viewContent, addToCart, initiateCheckout, addPaymentInfo, lead, register, purchase };
