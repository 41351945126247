const copyValue = async (value, toastRef) => {
  console.log(value, toastRef)
    try {
      await navigator.clipboard.writeText(value);
      toastRef.current.show({ severity: 'success', summary: 'Codigo copiado ', detail: value, life: 3000 });
    } catch (err) {
      toastRef.current.show({ severity: 'error', summary: 'Error', detail: 'No se pudo copiar', life: 3000 });
    }
  };

export default copyValue;