import styles from './CustomSelect.module.css';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';

export default function CustomSelect({id, floatLabel, optionLabel, options, value, valueTemplate, itemTemplate, placeholder, onChange, emptyFilterMessage, emptyMessage, required, disabled, inColumTable}) {
  const {t} = useTranslation("CustomSelect")
  if(floatLabel){
    return (
      <div className={styles.floatLabelContainer}>
        <span className="p-float-label">
        <Dropdown
          required={required}
          value={value}
          onChange={onChange}
          optionLabel={optionLabel}
          options={options}
          placeholder={placeholder}
          filterInputAutoFocus={false}
          // filter Presenta un problema si se elige con filtro y con label desplazable
          valueTemplate={valueTemplate}
          itemTemplate={itemTemplate}
          className={inColumTable ? styles.customTableComponent: styles.customFormComponent}
          emptyFilterMessage={t("emptyFilterMessage")}
          emptyMessage={t("emptyMessage")}
          disabled={disabled}
        />
        <label htmlFor={id}>{placeholder}</label>
        </span>
      </div>
    )
  }else{
    return (
      <Dropdown
        required={required}
        value={value}
        onChange={onChange}
        options={options}
        optionLabel={optionLabel}
        placeholder={placeholder}
        filter
        filterInputAutoFocus={false}
        valueTemplate={valueTemplate}
        itemTemplate={itemTemplate}
        className={inColumTable ? styles.customTableComponent: styles.customFormComponent}

        emptyFilterMessage={t("emptyFilterMessage")}
        emptyMessage={t("emptyMessage")}
        disabled={disabled}
        onKeyDown={(e) => {
          if (e.key === "Tab") {
            // Aquí puedes manejar el evento onBlur según tus necesidades
            console.log("Dropdown onBlur");
          }
        }}
      />
    )
  }
}
