import React from 'react';
import numeral from 'numeral';
import styles from './ShoppingCartBox.module.css';

export default function ShoppingCartBox({ item, remove_from_cart, select_item_from_cart }) {
    return (
        <div className=''> 
            <div className={styles.shoppingCartBox} style={{ backgroundImage: `url(${item.sku_img})` }}>
                <div onClick={() => remove_from_cart(item)} className={styles.shoppingCartBoxRemove}>
                    X
                </div>
                <div className={styles.shoppingCartBoxLabel} onClick={select_item_from_cart}>
                    <div className={styles.sku_title}> 
                        {item.sku_title}
                    </div>
                    <div className={styles.price}>
                        {numeral(item.unit_price).format("$ 0,0a")} <span className={styles.x}>x</span> <span className={styles.quantity}>{item.quantity}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
