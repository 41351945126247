import React from 'react';
import styles from './Benefits.module.css';
import Paragraph from '../Paragraph/Paragraph';
import OptionWithTitleAndText from '../../OptionWithTitleAndText/OptionWithImgAndText';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import CTA from '../CTA/CTA';
import Safe from './../../../assets/LOTTIE/Safe.json';
import Shipping from './../../../assets/LOTTIE/Shipping.json';
import COD from './../../../assets/LOTTIE/COD.json';
import TransportadoraInterr from './../../../assets/Transportadora-Interr.png';
import TransportadoraCoordi from './../../../assets/Transportadora-Coordi.png';
// import WIN from './../../../assets/WIN.png';
import WINN from './../../../assets/WINN.png';
import EASY from './../../../assets/EASY.png';
import FREE from './../../../assets/FREE.png';

export default function Benefits() {
  const { isDesktop } = useGlobalContext();

  return (
    <div id='pros' style={{marginTop:"150px"}}>
        <Paragraph
            title={"Beneficios clave para ti"}
            subtitle={""}
            subtitle_style={{backgroundColor:"var(--orange-background)", color:"var(--orange-text)"}}
            bold_text={"🤑 Enfocate en vender "}
            regular_text={"nosotros hacemos el resto"} 
        />
        <div style={{marginLeft:!isDesktop?"-20px":"", marginTop:"80px"}} className={"gridCardContainer"}>
            <OptionWithTitleAndText
                optionData={{
                    id: "customized",
                    title: "Sin riesgo de inventario",
                    text: "",
                    isSelected: true,
                    selectFunction: null,
                    disabled: false,
                    disabled_message: "",
                    animationData: Safe
                }}
            />
            <OptionWithTitleAndText
                optionData={{
                    id: "native-experience",
                    title: "Encuentra productos ganadores",
                    text: "",
                    isSelected: true,
                    selectFunction: null,
                    disabled: false,
                    disabled_message: "",
                    img: WINN
                }}
            />
            <OptionWithTitleAndText
                optionData={{
                    id: "one-on-one",
                    title: "Despacha a todo el pais con Interrapidisimo",
                    text: "",
                    isSelected: true,
                    selectFunction: null,
                    disabled: false,
                    disabled_message: "",
                    // animationData: Shipping,
                    img: TransportadoraInterr
                }}
            />
            <OptionWithTitleAndText
                optionData={{
                    id: "one-on-one",
                    title: "Despacha a todo el pais con Coordinadora",
                    text: "",
                    isSelected: true,
                    selectFunction: null,
                    disabled: false,
                    disabled_message: "",
                    // animationData: Shipping,
                    img: TransportadoraCoordi
                }}
            />
            <OptionWithTitleAndText
                optionData={{
                    id: "always-available",
                    title: "Ofrece a tus clientes pagos contraentrega",
                    text: "",
                    isSelected: true,
                    selectFunction: null,
                    disabled: false,
                    disabled_message: "",
                    animationData: COD
                }}
            />
            <OptionWithTitleAndText
                optionData={{
                    id: "natural-learning",
                    title: "Recibe tus ganancias facil",
                    text: "",
                    isSelected: true,
                    selectFunction: null,
                    disabled: false,
                    img: EASY
                }}
            />
            <OptionWithTitleAndText
                optionData={{
                    id: "natural-learning",
                    title: "Sin costo para ti",
                    text: "",
                    isSelected: true,
                    selectFunction: null,
                    disabled: false,
                    disabled_message: "",
                    img: FREE
                }}
            />
        </div>
        <CTA label={"CONOCE NUESTRO CATALOGO"} navigateTo={"/signup"} style={{marginTop:"40px"}}/>
    </div>
  );
}

