import React from 'react'
import styles from './AuxStatusBadget.module.css';

export default function AuxStatusBadget({statusLabel, style, onClick}) {
  return (
    <div
      onClick={onClick}
        className={styles.AuxStatusBadget}
        style={style}
    >
        {statusLabel}
    </div>
  )
}
