import React from 'react'
import styles from './HorizontalUserWidget.module.css'
import CircleCard from '../CircleCard/CircleCard'
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { Link, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/Authcontext/Authcontext';
import userAvatar from '../../assets/userAvatar.jpg'
import userAvatar2 from '../../assets/userAvatar2.jpg' // Sunglasses
import userAvatar3 from '../../assets/userAvatar3.jpg'
import userAvatar5 from '../../assets/userAvatar5.png'
// import userAvatar5 from '../../assets/userAvatar5.png'
import userAvatar6 from '../../assets/userAvatar6.png'
import profileImg from '../../assets/profileImg.jpg'

export default function HorizontalUserWidget({toggleSidebar, user_data}) {
  const { t } = useTranslation('HorizontalUserWidget');
  const { userSignedIn } = useAuthContext();

  return (
    <div onClick={null} className={`clickable ${styles.HorizontalUserWidget}`}>
        <Link to={userSignedIn? "/account" : "/login"}>
          <div onClick={()=>toggleSidebar(false)}>
            <CircleCard
              out_circle_color={"--main-semilight-color"}
              img_src={user_data?.user_img_profile}
              img_alt={user_data?.user_full_name}
              user_data={user_data}
              isEditable={false}
              img_placeholder={userSignedIn?userAvatar6:userAvatar6} 
            />
          </div>
        </Link>
       <div style={{marginLeft:"10px", fontWeight:"var(--font-weight-semi-bold)", lineHeight:"1"}}>
        <Link to={userSignedIn? "/account" : "/signup"}>
          <h5 onClick={()=>toggleSidebar(false)} style={{fontWeight:"var(--font-weight-bolder)"}}> {userSignedIn? t("hi") : t("register")} </h5>
        </Link>
        <Link to={userSignedIn? "/account" : "/login"}>
          <h5 onClick={()=>toggleSidebar(false)} style={{color:"var(--main-color)", fontWeight:"var(--font-weight-bolder)"}}>{userSignedIn? user_data?.user_full_name : t('login')}</h5>
        </Link>
       </div>
    </div>
  )
}
