import PropTypes from "prop-types";
import styles from './RequestWithdrawalNewsForm.module.css';
import { useTranslation } from "react-i18next";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx"
import { useState, useEffect } from "react";
import toastFunction from "../../utils/toastFunction.js";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import MainButton from "../MainButton/MainButton.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import { generateRandomId } from '../../utils/generateRandomId.js';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase/firebase.js";
import generateValidObjectName from "../../utils/generateValidObjectName.js";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
        

export default function RequestWithdrawalNewsForm({userData, toast, onClose, selectedWithdrawalRequest}) {
    const { t } = useTranslation("Withdrawals");
    const { globalLoading, setGlobalLoading } = useLoaderContext();
    const [selectedReason, setSelectedReason] = useState(null);
    const { withdrawalRequestNews } = useGlobalContext();
    const { addToArray, updateDocument } = useFirebaseCRUD();
    
    const initialWithdrawalNewUpdate = {
        id: generateRandomId(),
        code: 0, // id de la solicitud
        created_at: new Date(), // fecha creacion de la solicitud
        text: "", // texto de la novedad
    }
    const [withdrawalRequestUpdateNewsValues, setWithdrawalRequestUpdateNewsValues] = useState(initialWithdrawalNewUpdate);
    
    // Función para subir un archivo al almacenamiento de Firebase
    const uploadFileToFirebaseStorage = async (file, withdrawalRequestId) => {
        try {
            const storageRef = ref(storage);
            const validFileName = generateValidObjectName(file.name);
            const filePath = `approvedWithdrawals/${withdrawalRequestId}/${validFileName}`;
            const fileRef = ref(storageRef, filePath);

            // Borrar toda la carpeta antes de subir el nuevo archivo
            const folderRef = ref(storageRef, `approvedWithdrawals/${withdrawalRequestId}`);
            const folderContent = await listAll(folderRef);
            await Promise.all(folderContent.items.map(item => deleteObject(item)));

            // Subir el nuevo archivo
            await uploadBytes(fileRef, file);
            const downloadURL = await getDownloadURL(fileRef);
            return downloadURL;
        } catch (error) {
            console.error('Error al subir el archivo:', error);
            return null;
        }
    };

    const handleNewUpdateSelect = (newest) => {
        setSelectedReason(newest);
        setWithdrawalRequestUpdateNewsValues((prevValues) => ({
            ...prevValues,
            code: newest.code,
        }));
    };
    const processNewnessOfRequest = async (e) => {
        console.log("Antes de ejcutar", withdrawalRequestUpdateNewsValues.code)
        const collectionWithdrawal = "funds_withdraw_requests";
        const documentWithdrawalId = selectedWithdrawalRequest.id;
        e.preventDefault();
        setGlobalLoading(true); 
        if (withdrawalRequestUpdateNewsValues.code === 0) {  // Marcar la solicitud como pendiente
            const newWithDrawalNews = {...withdrawalRequestUpdateNewsValues, text : `Solicitud de retiro pendiente, novedad:  ${withdrawalRequestUpdateNewsValues.text}`};
            const resAddNewestToRequest = addToArray(collectionWithdrawal, documentWithdrawalId, "news", newWithDrawalNews);
            const resUpdateWithdrawalDocument = await updateDocument(collectionWithdrawal, documentWithdrawalId, {state: 0})  // Marcar la orden como pendiente
            if(resUpdateWithdrawalDocument && resAddNewestToRequest) {
                onClose();
            }
        } else if (withdrawalRequestUpdateNewsValues.code === 1) {   // Marcar la solicitud como aprobada
            const downloadUrl = await uploadFileToFirebaseStorage( withdrawalRequestUpdateNewsValues.file, selectedWithdrawalRequest.id);
            if( downloadUrl) {
                setWithdrawalRequestUpdateNewsValues((prevValues) => ({...prevValues, file: downloadUrl}))
                const withDrawalNewsWithUrl = {...withdrawalRequestUpdateNewsValues, file : downloadUrl };
                const resAddNewestToRequest = addToArray(collectionWithdrawal, documentWithdrawalId, "news", withDrawalNewsWithUrl);
                if (resAddNewestToRequest) {
                    const resUpdateWithdrawalDocument = await updateDocument(collectionWithdrawal, documentWithdrawalId, {state: 1, is_processed: true})                
                    if(resUpdateWithdrawalDocument) {
                        onClose();
                    }
                }
            }
        } else if (withdrawalRequestUpdateNewsValues.code === 2) { // Marca la solicitud como rechazada
            const newWithDrawalNews = {...withdrawalRequestUpdateNewsValues, text : `Solicitud de retiro rechazada, novedad:  ${withdrawalRequestUpdateNewsValues.text}`};
        
            addToArray(collectionWithdrawal, documentWithdrawalId, "news", newWithDrawalNews);
            const resUpdateWithdrawalDocument = await updateDocument(collectionWithdrawal, documentWithdrawalId, {state: 2, is_processed:true})  // Marcar la solicitud como rechazada
            if(resUpdateWithdrawalDocument && newWithDrawalNews) {
                onClose();
            }
        }
        setGlobalLoading(false);
    };
    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Solo se toma el primer archivo seleccionado
        if (file) {
            setWithdrawalRequestUpdateNewsValues((prevValues) => ({
                ...prevValues,
                file: file,
            }));
        }
    };
    const handleFileDelete = () => {
        setWithdrawalRequestUpdateNewsValues((prevValues) => ({
            ...prevValues,
            file: null,
        }));
    };    

    useEffect(() => {
        setGlobalLoading(true);
        if(userData?.bank_accounts.length === 0) {
            toastFunction(toast, "info", "Porfavor Agrega cuentas bancarias para poder solcitar un retiro", "", 3000)
        }
        setGlobalLoading(false);
    },[userData]);

    return (
        <div >
          <CustomSelect
            required={true}
            floatLabel={true}
            optionLabel={"name"}
            options={withdrawalRequestNews}
            valueTemplate={""}
            itemTemplate={""}
            value={selectedReason}
            placeholder={t("input-placeholders.stateOfWithdrawalRequest")}
            onChange={(e) => handleNewUpdateSelect(e.target.value)}
            disabled={globalLoading}
          />
          <CustomInputText
            floatLabel={true}
            type={"text"}
            value={withdrawalRequestUpdateNewsValues.text}
            placeholder={t("input-placeholders.reason")}
            onChange={(e) => setWithdrawalRequestUpdateNewsValues((prevValues) =>  ({ ...prevValues, text: e.target.value}))}
            disabled={!selectedReason || globalLoading}
            required={true}
          />

        {withdrawalRequestUpdateNewsValues.code === 1  && !globalLoading && (
            <div className="flex justify-center mb-8 mt-12">
                <label htmlFor="file-upload" className={styles.custom_file_upload}>
                    Seleccionar archivo
                </label>
                <input
                    id="file-upload"
                    type="file"
                    accept="image/*,.pdf"
                    onChange={(e) => handleFileChange(e)}
                    required={true}
                    style={{ display: 'none' }} // Oculta el input de tipo "file" original
                    disabled={globalLoading}
                />
                {withdrawalRequestUpdateNewsValues.file && !globalLoading && (
                    <div className="ml-8 mt-1 flex items-center">
                        <p className="mr-2">Archivo seleccionado: {withdrawalRequestUpdateNewsValues.file.name}</p>
                        <button onClick={() => handleFileDelete()} className="text-red-600" disabled={globalLoading}>
                            X
                        </button>
                    </div>
                )}
            </div>
        )}

          <MainButton
            style={{ marginTop: "20px" }}
            type={"button"}
            label={t("processNewnessOfRequest")}
            loading={globalLoading}
            onClick={processNewnessOfRequest}
            disabled={globalLoading}
            autoFocus
        />
        </div>
    )
}

RequestWithdrawalNewsForm.propTypes = {
    userData: PropTypes.object, // Datos del usuario
    walletUser: PropTypes.object, // Datos de la billetera del usuario
    toast: PropTypes.object, // Función para mostrar mensajes de notificación
    onClose: PropTypes.func,
    selectedWithdrawalRequest: PropTypes.object,
};