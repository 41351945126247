import { useEffect, useState } from "react";
import ProductGridCard from "../../Components/ProductGridCard/ProductGridCard";
import CustomModal from "../../Components/CustomModal/CustomModal";
import DetailProduct from "../../Components/DetailProduct/DetailProduct";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { Toast } from "primereact/toast";
import ProductForm from "../../Components/ProductForm/ProductForm";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { BiChevronLeft } from "react-icons/bi";
import { getPaginatedProducts } from "../../utils/getPaginatedProducts";
import { collection } from "firebase/firestore";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import { db } from "../../firebase/firebase";
import toastFunction from "../../utils/toastFunction.js";
// Componente de pagina que muestra los productos vinculados en base a los ids de los productos vinculados con las tiendas de shopify
const MyVinculatedProducts = () => {
  const { setGlobalLoading } = useLoaderContext();
  const { userData, userSellerOwnerData } = useAuthContext();
  const {getDocumentsByQuery} = useFirebaseCRUD();
  const { toast } = useShopifyRequest();
  const [products, setProducts] = useState([]);
  const [visibleDetailProduct, setVisibleDetailProduct] = useState(false);
  const [selectedProduct, setselectedProduct] = useState({});
  const { t } = useTranslation("Products");
  const [storeSelectedName, setStoreSelectedName] = useState([]);

  const isCoworker = !userData.isAdmin  && userData.related_seller_id && userData.is_coworker

  let userOwnerStores = (isCoworker && userSellerOwnerData) ? userSellerOwnerData.seller_stores : userData.seller_stores
  const sellerOptionsWithGenericOption = [
    ...userOwnerStores,
    { id: 1, name: "Todas las tiendas" }
  ];
  const [visibleProductForm, setVisibleProductForm] = useState(false);

  const [categoryProducts, setCategoryProducts] = useState([]);
  const quantityDocuments = 30;


  // Trae los productos de la coleccion "products" en base a su Id
  const getProductosFromIds = async (productsIds) => {
    try {
      if ( productsIds.length === 0 ) {
        return []
      }
      const body = {
        productsIds: productsIds,
        quantityDocuments,
        type: "onlyProductsSnapshot",
      };
      const response = await getPaginatedProducts(body)
      if (!response) {
        throw new Error("Error al obtener los productos");
      }
      return response;
    } catch (error) {
      console.error("Error al obtener los productos:", error);
      throw error;
    }
  };
  const getProductCategories = async () => {
    try {
      const q = collection(db, "category_products");
      const categoryProducts = await getDocumentsByQuery(q);
      setCategoryProducts(categoryProducts);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
      setGlobalLoading(false);
      throw error;
      
    }
  };
  // Función para abrir el modal de tiendas y establecer el producto seleccionado
  const openDetailProductModal = (product) => {
    setVisibleDetailProduct(true);
    if (product.id) {
      setselectedProduct(product);
    } else {
      setselectedProduct({});
    }
  };
  // Función para cerrar el modal de tiendas
  const closeDetailProductModal = () => {
    setVisibleDetailProduct(false);
  };
  // Función para cerrar el modal de tiendas
  const closeProductFormModal = () => {
    setVisibleProductForm(false);
  };
  
  /**
 * Agregar la clave 'related_synced_id' y 'related_synced_name' a cada producto para "marcarlos" y renderizarlos.
 * 
 * @param {Array} products - Array de productos.
 * @param {Array} syncedProductsIds - Array de objetos con información de productos sincronizados.
 * @return {Array|null} - Array de productos con valores sincronizados añadidos o null si el array está vacío.
 */
const addSyncedValues = (products, syncedProductsIds) => {
  const allProductsWithSyncedValues = products.map(product => {
    const syncedInfo = syncedProductsIds.find(syncedData => syncedData.product10XId === product.id);

    if (syncedInfo) {
      product.related_synced_id = syncedInfo.syncedId || null;
      product.related_synced_name = syncedInfo.syncedName || null;
    } else {
      product.related_synced_id = null;
      product.related_synced_name = null;
    }

    return product;
  });

  if (allProductsWithSyncedValues.length > 0 ) {
    return allProductsWithSyncedValues
  }
};

  // Manejador de eventos cuando se selecciona el nombre de la tienda
  const handleStoreSelectedName = async (selectedStore) => {
    setGlobalLoading(true);
    setStoreSelectedName(selectedStore);
    // La tienda con el id 1 es la opcion "todas las opciones" del select
    try {
      if (selectedStore.id === 1) {
        const productsWithSyncedValues = [];
        for (const store of sellerOptionsWithGenericOption) {
          // Obtenemos todos los ids de los productos vinculados 
          const { productsIds, syncedProductsIds } = amountProductSellerAllStoresIdS([store]);
          const products = await getProductosFromIds(productsIds);
          const syncedProductsByStore = addSyncedValues(products, syncedProductsIds)
          productsWithSyncedValues.push(syncedProductsByStore)
        }
        const allProducts = productsWithSyncedValues.filter((product) => product !== undefined)  
        const allProductsFlatMap = allProducts.flatMap((arrayProducts) => arrayProducts.length > 0 ? arrayProducts : null )
        // Actualizar el estado con los nuevos productos

        setProducts(allProductsFlatMap);
      } else {
    
          // Obtener los Ids de la tienda seleccionada
          const { productsIds, syncedProductsIds } = amountProductSellerAllStoresIdS([selectedStore]);
          // Procesar y actualizar productos según la tienda seleccionada
          if (productsIds.length > 0) {
            const products = await getProductosFromIds(productsIds);
            // Agregar la clave 'related_synced_id' a cada producto
            const allProducts = addSyncedValues(products, syncedProductsIds)
            // Asignar directamente los nuevos productos
            setProducts(allProducts); // Asignar directamente los nuevos productos
          } else {
            // Caso donde no hay productos
            setProducts([]);
            toastFunction(toast, "warn", t("noProductsWithTheseCategory"), "", 3000)
          }
      }
    }  catch (error) {
      console.error("Error al obtener productos:", error);
      // Manejar el error según sea necesario
    }
    setGlobalLoading(false);
  };
  
  // Función para calcular la cantidad de productos y productos sincronizados con Shopify de todas las tiendas filtradas
  const amountProductSellerAllStoresIdS = (filteredSellerStores) => {
    if (!userData || !filteredSellerStores ) return [];
    const productsIds = [];
    const syncedProductsIds = [];
    filteredSellerStores.forEach((store) => {
      if (store.synced_products) {
        store.synced_products.forEach((item) => {
          if (item?.product_id) {
            productsIds.push(item.product_id);
            syncedProductsIds.push({
              syncedId: item.id,
              syncedName: item.synced_product_name,
              product10XId: item.product_id,
            });
          }
        });
      }
    });
    // Devolver objetos con IDs de productos y productos sincronizados
    return { productsIds, syncedProductsIds };
  };
 

  useEffect(() => {
    // Inicializar el estado y cargar los productos al cargar la página o cambiar el usuario
    setGlobalLoading(true);

    const fetchData = async () => {
      await getProductCategories();
      // Crear opciones de vendedor con la opción genérica "Todas las tiendas"
  
      if (userOwnerStores && userOwnerStores.length > 0) {
        try {
          await handleStoreSelectedName({id: 1, name: "Todas las tiendas"});
        } catch (error) {
          console.error("Error al obtener productos:", error);
        }finally {
          setGlobalLoading(false);
        }
      }
    };
    // Llamar a la función fetchData al montar el componente o cuando cambia el usuario
    fetchData();
    setGlobalLoading(false);

  }, [userData, userSellerOwnerData]);

  return (
    <>
    <div style={{ marginBottom: "40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtnMyVinculatedProducts")} />
    </div>
      <div className="mb-12">
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={sellerOptionsWithGenericOption}
          value={storeSelectedName}
          placeholder={t("input-placeholders.platform")}
          onChange={(e) => handleStoreSelectedName(e.value)}
          required={true}
        />
      </div>
      <div className="mb-12">
        {products.length > 0 ? (
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
            {products.map((product, index) => (
              <ProductGridCard
                key={index}
                index={index}
                user_data={userSellerOwnerData || userData}
                product_data={product}
                onClick={() => openDetailProductModal(product)}
              />
            ))}
          </div>
        ) : (
          <marquee>No se encontraron productos</marquee>
        )}
      </div>
      <div>
        <CustomModal
          visible={visibleDetailProduct}
          setVisible={setVisibleDetailProduct}
          object={selectedProduct}
          editMode={t("detailProduct")}
          createMode={t("vinculateProduct")}
          content={
            <DetailProduct
              productData={selectedProduct}
              toast={toast}
              onClose={closeDetailProductModal}
              categoryProductsData={categoryProducts}
              userData={userSellerOwnerData || userData}
            />
          }
          onClose={closeDetailProductModal}
        />
      </div>
      <div>
        <CustomModal
          visible={visibleProductForm}
          setVisible={setVisibleProductForm}
          object={selectedProduct}
          content={
            <ProductForm
              mode="CREATE"
              categoryProductsData={categoryProducts}
              toast={toast}
              onClose={closeProductFormModal}
            />
          }
          onClose={closeProductFormModal}
        />
      </div>
      <Toast ref={toast} />
    </>
  );
};
export default MyVinculatedProducts;
