import { fetchFromCloudFunction } from '../../cloudFunctinons/fetchFromCloudFunction';
import { emailSignin } from '../emailSignin/emailSignin';

export const signup = async (endpoint, userData) => {
    let successSignup = false;
    try {
        const signupRes = await fetchFromCloudFunction(endpoint, {userData}, "POST");
        if (signupRes.status === 200){
            try {
                if(endpoint==="auth/signupWithEmail"){
                    await emailSignin( userData.email, userData.password);
                }
                successSignup = true
                return {successSignup, error: null}
            } catch (error) {
                console.error('Error iniciando sesion despues de crear usuario:', error);
                return {successSignup, error: "signin/error"}
            }
        }
        return {successSignup, error: signupRes.data.error.code} //signupRes.data.error.code -> Codigos de errores de auth de firebase, Ej: auth/email-already-exists
    } catch (error) {
        console.error('Error creando usuario:', error);
        return {successSignup, error: "local/error"}
    }
}
