import { useState, useEffect} from "react";
// import styles from "./Wallet.module.css";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { BiChevronLeft } from "react-icons/bi";
import { Calendar } from "primereact/calendar";
import DetailOptionCard from "../../Components/DetailOptionCard/DetailOptionCard";
import MainButton from "../../Components/MainButton/MainButton";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { Chart } from "primereact/chart";
import numeral from "numeral";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { Toast } from "primereact/toast";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";

// Este Componente de pagina muestra una grafica con los ingresos por hora de las en base a los totales de las ordenes
export default function Home() {
  const { toast } = useAuth();
  const { userData, userSellerOwnerData } = useAuthContext();
  const initialDate = new Date();
  initialDate.setHours(0, 0, 0, 0);
  const finishDate = new Date();
  finishDate.setHours(23, 59, 59, 999);
  const [dates, setDates] = useState([initialDate, finishDate]);
  const { t } = useTranslation("Home");
  const [chartData, setChartData] = useState({});
  const [loading, setLoading] = useState(true); // Estado de carga

  const [chartOptions, setChartOptions] = useState({});
  const [totalIncomes, setTotalIncomes] = useState(0);
  const [ongoingIncomes, setOngoingIncomes] = useState(0);
  const [completedIncomes, setCompletedIncomes] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const isInsider = userData?.user_custom_claims?.type === "insider" || userData?.user_custom_claims?.type === "master"  ? true : false;
  const userOwnerData = userSellerOwnerData || userData;
  
  // Obtener el estilo del documento para acceder a las variables de color definidas en CSS
  const documentStyle = getComputedStyle(document.documentElement);
  // Objeto que almacena las variables de color del documento
  const colorVariables = {
    mainGradientReversed: documentStyle.getPropertyValue(
      "--main-gradient-reversed"
    ),
    mainColor: documentStyle.getPropertyValue("--main-color"),
    mainHardColor: documentStyle.getPropertyValue("--main-hard-color"),
    mainSemilightColor: documentStyle.getPropertyValue(
      "--main-semilight-color"
    ),
    mainLightColor: documentStyle.getPropertyValue("--main-light-color"),
    mainLightColorRGB: documentStyle.getPropertyValue("--main-light-color-rgb"),
  };

  // Función para realizar una consulta de pedidos basada en fechas seleccionadas
  const handleQueryOrders = async () => {
    if (dates && dates.length === 2) {
      try {
        setLoading(true); // Iniciar el estado de carga

        // Extraer fechas de inicio y fin del rango seleccionado
        const startDate = dates[0];
        const endDate = dates[1];
    
        // Crear referencias y consultas para acceder a los documentos de pedidos en Firebase

        const startDateFirebase = new Date(startDate.getTime());
        const endDateFirebase = new Date(endDate.getTime());
        startDateFirebase.setHours(0, 0, 0, 0);
        endDateFirebase.setHours(23, 59, 59, 999);
        const ordersRef = collection(db, "orders");
        let q = [];
        if (isInsider) {
          q = [...q, where("created_at", ">=", startDateFirebase), where("created_at", "<=", endDateFirebase)]
        } else {
          q = [...q, where("created_at", ">=", startDateFirebase), where("created_at", "<=", endDateFirebase),
            where("related_seller_business_id", "==", userOwnerData?.business_id)]
        }
        const queryOrders = query(
          ordersRef, ...q
        );
        // Obtener un conjunto de documentos que cumplen con la consulta
        const unsubscribe = onSnapshot(queryOrders, async (snapshot) => {
        // Inicializar variables para calcular estadísticas de pedidos
          const ordersByHour = {}; // Objeto para almacenar totales por cada hora
          let totalOrders = 0;
          let totalIncome = 0;
          let ongoingIncome = 0;
          let completedIncome = 0;

        // Iterar sobre cada documento de pedido en el conjunto
          snapshot.forEach((doc) => {
            const orderData = doc.data();
            const orderDate = orderData.created_at.toDate();
            const hour = orderDate.getHours();
            let orderTotal = orderData.total_order_price;
            // console.log("Orden, itemTotal y hora: ", orderData.order_number, " - ", orderTotal, hour, " - ", orderDate);
            // const orderTotal = orderData?.total_order_price || 0; // Cambiar a la clave correcta que almacena el total del pedido
            // Verificar si hay un total para esta hora y acumularlo
            if (!ordersByHour[hour]) {
              // Actualizar estadísticas por hora y totales generales
              ordersByHour[hour] = orderTotal;
            } else {
              ordersByHour[hour] += orderTotal;
            }
            totalOrders++;
            totalIncome += orderTotal;
            // Clasificar ingresos según el estado del pedido
  
            const orderState = orderData.last_state;
            if (orderState === 5 || orderState === 6) {
              completedIncome += orderTotal;
            } else {
              ongoingIncome += orderTotal;
            }
          });
        // Crear los datos para la gráfica basados en los totales por hora
          const labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
          const data = {
            labels,
            datasets: [
              {
                label: t("labelDataset"),
                backgroundColor: colorVariables.mainHardColor,
                borderColor: colorVariables.mainHardColor,
                borderWidth: 1,
                hoverBackgroundColor: colorVariables.mainSemilightColor,
                hoverBorderColor: colorVariables.mainHardColor,
                data: labels.map(
                  (label) => ordersByHour[parseInt(label.split(":")[0])] || 0
                ),
              },
            ],
          };
          // Opciones de configuración para la gráfica
          const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            scales: {
              y: {
                ticks: {
                  stepSize: 1000,
                  precision: 0,
                },
              },
            },
          };
           // Mostrar un mensaje si no hay datos de pedidos

          Object.keys(chartData).length;
          if (Object.keys(ordersByHour).length === 0) {
            toast.current.show({
              severity: "warn",
              summary: t("toast-summary.noFoundData"),
              detail: "",
            });
          }

          // Actualizar estados con los resultados de la consulta
          totalIncome = completedIncome + ongoingIncome;
          setChartData(data);
          setChartOptions(options);
          setTotalOrders(totalOrders);
          setTotalIncomes(totalIncome);
          setOngoingIncomes(ongoingIncome);
          setCompletedIncomes(completedIncome);
          setLoading(false); // Terminar el estado de carga

        });      
       return unsubscribe;
      } catch (error) {
        setLoading(false); // Terminar el estado de carga en caso de error

        toast.current.show({
          severity: "error",
          summary: t("toast-summary.consultError"),
          detail: "",
        });
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: t("toast-summary.invalidDateRange"),
        detail: "",
      });
    }
  };
  
  // Efecto secundario que se ejecuta una vez al montar el componente para inicializar datos y configuraciones
  useEffect(() => {
    // Obtener el estilo del documento para acceder a las variables de color definidas en CSS
    const fetchInitialData = async () => {
      await handleQueryOrders();
    };
    fetchInitialData();

    const documentStyle = getComputedStyle(document.documentElement);
    // Definir un objeto que almacena las variables de color del documento

    const colorVariables = {
      mainGradientReversed: documentStyle.getPropertyValue(
        "--main-gradient-reversed"
      ),
      mainColor: documentStyle.getPropertyValue("--main-color"),
      mainHardColor: documentStyle.getPropertyValue("--main-hard-color"),
      mainSemilightColor: documentStyle.getPropertyValue(
        "--main-semilight-color"
      ),
      mainLightColor: documentStyle.getPropertyValue("--main-light-color"),
      mainLightColorRGB: documentStyle.getPropertyValue(
        "--main-light-color-rgb"
      ),
    };
    // Configuración inicial de la gráfica y datos simulados para el efecto de carga
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      scales: {
        y: {
          ticks: {
            stepSize: 10000,
            precision: 0,
          },
        },
      },
    };
    const ordersByHour = {
      3: 0,
      10: 1000,
    };
    // concatenar signo pesos
    const labels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
    const data = {
      labels,
      datasets: [
        {
          label: t("labelDataset"),
          backgroundColor: colorVariables.mainHardColor,
          borderColor: colorVariables.mainHardColor,
          borderWidth: 1,
          hoverBackgroundColor: colorVariables.mainSemilightColor,
          hoverBorderColor: colorVariables.mainHardColor,
          data: labels.map(
            (label) =>
              ordersByHour[parseInt(label.split(":")[0])] || ordersByHour
          ),
        },
      ],
    };
    setChartData(data);
    setChartOptions(options);
  }, []);
 
  return (
    <div className="mb-20">
      <div style={{ marginBottom: "40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtn")} />
      </div>
      <div className="mb-12">
        <Calendar
          value={dates}
          onChange={(e) => setDates(e.value)}
          selectionMode="range"
          readOnlyInput
          placeholder={t("calendarPlaceholder")}
          style={{ width: "100%", marginBottom: "40px" }}
        />
        <span className="">
          <MainButton
            type="button"
            onClick={handleQueryOrders}
            label={t("consult")}
          />
        </span>
      </div>
      {Object.keys(chartData).length > 0 && (
        <div className="mb-14">
          <Chart type="bar" data={chartData} options={chartOptions} />
        </div>
      )}
      {!loading ? (
        <>
          <DetailOptionCard
            bold_text={t("totalOrders")}
            regular_text={totalOrders}
            key={1}
            hidde_btn={true}
          />
          <DetailOptionCard
            bold_text={t("totalIncomes")}
            regular_text={numeral(totalIncomes).format(t("cashFormat"))}
            key={2}
            hidde_btn={true}
          />
          <DetailOptionCard
            bold_text={t("ongoingIncomes")}
            regular_text={numeral(ongoingIncomes).format(t("cashFormat"))}
            key={3}
            hidde_btn={true}
          />
          <DetailOptionCard
            bold_text={t("completedIncomes")}
            regular_text={numeral(completedIncomes).format(t("cashFormat"))}
            key={4}
            hidde_btn={true}
          />
        </>
      ) : (
        <div className="flex justify-center"> <LoaderSpinner /> </div> // Mostrar un mensaje o spinner mientras se cargan los datos
      )}
      <Toast ref={toast} />
    </div>
  );
}


