
// import styles from './Users.module.css'
import useAuth from "../../hooks/useAuth";
import { Toast } from "primereact/toast";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { BiChevronLeft } from "react-icons/bi";
import CustomTable from "../../Components/CustomTable/CustomTable";

import MainButton from "../../Components/MainButton/MainButton";
import CustomModal from "../../Components/CustomModal/CustomModal";
import DetailCoWorkerForm from "../../Components/DetailCoWorkerForm/DetailCoWorkerForm";
import { useEffect, useRef, useState } from "react";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import { db } from "../../firebase/firebase";
import { collection,  onSnapshot, orderBy, query } from "firebase/firestore";
import AuxButton from "../../Components/AuxButton/AuxButton";
import StatusLight from "../../Components/StatusLight/StatusLight";
import moment from "moment";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

// Este Componente de pagina muestra y gestiona una tabla de usuarios colaboradores (CoWorkers) creados por el Seller
export default function Users() {
  const { userData } = useAuthContext();
  const { toast,
    // setCustomClaimsMasterUser
  } = useAuth();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { t } = useTranslation("Users");
  const [coworkers, setCoworkers] = useState([]);
  const [visibleCoworkerForm, setVisibleCoworkerForm] = useState(false);
  const [selectedCoWorkerUser, setSelectedCoWorkerUser] = useState({});
  const opStores = useRef(null);

  const CoWorkersTableColumns = [   
    {
      field: "user_custom_claims.isAdmin",
      header: t("table-headers.isAdmin"),      
      filterable: false,
      
      body : (rowData) => {
        return (
           <div className="flex gap-2">
            { rowData?.user_custom_claims?.type && 
              rowData?.user_custom_claims?.isAdmin? `Admin` : `Colaborador`}
          </div>
        )
      }
    },
    { 
      field: "business_id",
      header: t("businessId"),
      filterable: false,
      sortable: false, 
      body : (rowData) => {
        return <span>
          {rowData.business_id}
        </span>
      }
    },
    {
      field: "user_custom_claims.type",
      header: t("table-headers.coworkerType"),      
    },
    { 
      header: t("stores"),
      filterable: false,
      sortable: false, 
      body : (rowData) => {
        return (
           <div className="flex gap-2">
            { rowData.type==="seller" && rowData?.user_custom_claims?.isAdmin  && (<Button
              type="button"
              icon="pi pi-search"
              label={`Tiendas ${rowData?.seller_stores?.length}`}
              onClick={(e) => openStoresOverlay(rowData, e)}
            />)}
          </div>
        )
      }
    },
    
    { 
      field: "role",
      header: t("role"),
      filterable: false,
      sortable: false, 
      body : (rowData) => {
        return <span>
          {rowData.role ? rowData.role  :"No tiene Rol asignado"}
        </span>
      }
    },
    { 
      header: t("table-headers.user"), 
      filterable: false,
      sortable: false, 
      body: (rowData) => {
        return <span>
          {rowData.first_names + " " + rowData.last_names }
        </span>
      }
    },
    { 
      field: "email",
      header: t("table-headers.email"),
      filterable: false,
      sortable: false, 
    },
    {
      field: 'created_at',
      header: t("createdAt"),
      // Columna para el campo 'created_at' con encabezado traducido y cuerpo personalizado para formatear la fecha
      body: (rowData) => {
        const createdAt = rowData?.created_at
          ? moment.unix(rowData?.created_at.seconds).format(t("table-headers.dateFormat"))
          : t("table-headers.invalidDateFormat");
        return <span>{createdAt}</span>;
      },
    },
    {
      field: 'last_session',
      header: t('table-headers.lastSession'),
        // Columna para mostrar el estado usando un componente personalizado 'StatusLight'
      body: (rowData) => {
        const lastSession = rowData.last_session
          ? moment.unix(rowData?.last_session?.seconds).format(t("table-headers.dateFormat"))
          : t("table-headers.invalidDateFormat");
        return <span>{lastSession}</span>;
      },
    },
    
    {
      field: "i1",
      header: t("table-headers.state"),

      filterable: false,
      sortable: false, 
        // Columna para mostrar el estado usando el componente StatusLight
      body: (rowData) => (
        <StatusLight
          color={rowData?.state?.color}
          tooltip={rowData?.state?.name}
        />
      ),
    },
    {
      header: "Editar",
      filterable: false,
      sortable: false, 
        // Columna para botón de edición con un componente personalizado 'AuxButton'
      body: (rowData) => (
        <AuxButton
          data={rowData}
          disable={false}
          label={t("edit")}
          style={{
            backgroundColor: "var(--main-color)",
            color: "black",
            fontSize: "var(--font-size-h5)",
          }}
        onClick={openCoworkerFormModal}
        />
      ),
    },
  ];
  const openStoresOverlay = (rowData, e) => {
    console.log(rowData,e)
    setSelectedCoWorkerUser(rowData)
    opStores.current.toggle(e); // // Abre el overlay
  };
  const openCoworkerFormModal = (account) => {
    setVisibleCoworkerForm(true);
    if (account.uid) {
      setSelectedCoWorkerUser(account);
    } else {
      setSelectedCoWorkerUser({});
    }
  };
  // Funcion que controla el cierre del modal del formulario de coWorker
  const closeCoWorkerModal = () => {
    setVisibleCoworkerForm(false);
  };

  // const getUserCustomClaim = async () => {
  //   try {
  //     const uid = "QQEOHVzJViaOcn4fgAFK55KUXER2";
  //     const response = await fetch("https://us-central1-shoppex-aa709.cloudfunctions.net/getCustomClaims", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ uid }),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log(data.customClaims);
  //       console.log("Custom Claims:", data.customClaims);
  //     } else {
  //       console.error("Error en la solicitud:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  // Efecto secundario para cargar los datos de los coworkers
  
  useEffect(() => {
    if (!userData?.business_id ) {
      setGlobalLoading(false);
      return;
    }

    const coworkerInsiderCollection = collection(db, "insider_users");
    const coworkerSellerCollection = collection(db, "seller_users");
    const qInsiders = query(
      coworkerInsiderCollection,
      orderBy("created_at", "asc")
    );
    const qSellers = query(
      coworkerSellerCollection,
      orderBy("created_at", "asc")
    );

    let unsubscribeInsiders;
    let unsubscribeSellers;

    try {
      unsubscribeInsiders = onSnapshot(qInsiders, (resultInsiders) => {
        const insiderDocuments = resultInsiders.docs.map(doc => doc.data());
        const documentsFilteredWithoutActuallyUser  = insiderDocuments.filter((insider) => insider.uid !== userData.uid)
        unsubscribeSellers = onSnapshot(qSellers, (resultSellers) => {
          const sellerDocuments = resultSellers.docs.map(doc => doc.data());
          const allDocuments = [...documentsFilteredWithoutActuallyUser, ...sellerDocuments]
          setCoworkers(allDocuments);
          setGlobalLoading(false);
        });
    });

    } catch (error) {
      console.error("Hubo un error", error);
    }
  
    return () => {
      unsubscribeInsiders && unsubscribeInsiders();
      unsubscribeSellers && unsubscribeSellers();
    };
  }, [userData]);

  // console.log("selectedCoWorkerUser", selectedCoWorkerUser)
  
  return (
    <div style={{ color: "white" }}>
      <div style={{ color: "white", marginBottom:"40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtnUsers")} />
      </div>
        <CustomTable
          className="custom-table"
          columns={CoWorkersTableColumns}
          data={coworkers}
          action={() => {}}
        />
      <CustomModal
        visible={visibleCoworkerForm}
        setVisible={setVisibleCoworkerForm}
        object={selectedCoWorkerUser}
        // menu_option={{label:t('menu-options.home')}}
        editMode={t("updateUser")}
        createMode={t("createUser")}
        content={
          <DetailCoWorkerForm
            onClose={setVisibleCoworkerForm}
            selectedCoWorkerUser={selectedCoWorkerUser}
            userData={userData}
            labelDelete={t("deleteUser")}
            labelSuccess={t("createUser")}
            toast={toast}
          />
        }
        onClose={closeCoWorkerModal}
      />
      <div className="card flex flex-column align-items-center gap-3">
        <OverlayPanel ref={opStores} showCloseIcon closeOnEscape dismissable={false}>
          <DataTable
            value={selectedCoWorkerUser.seller_stores}
            selectionMode="single"
            paginator
            rows={2}
          >
            <Column
              field="id"
              header={t("dataTable-headers.sellerStoreId")}
              style={{ minWidth: "12rem" }}
            />
            <Column
              field="name"
              header={t("dataTable-headers.sellerStoreName")}
              style={{ minWidth: "8rem" }}
            />           
          </DataTable>
        </OverlayPanel>
      </div>
      <div style={{ marginTop:"100px" }}>
        <MainButton
          loading={globalLoading}
          // label={t("addBank")}
          label={t("createUser")}
          onClick={openCoworkerFormModal}
        />
      {/* <SecondaryButton
        onClick={
          // () =>
        // //   setCustomClaimsMasterUser()
          getUserCustomClaim
        }
        label="See CUSTOM CLAIMS"
      /> */}
      </div>
      <Toast ref={toast} />
    </div>
  );
}                           