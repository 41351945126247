import React from 'react'
import styles from './CustomChips.module.css';
import { Chips } from 'primereact/chips';
import { useTranslation } from 'react-i18next';

export default function CustomChips({id, floatLabel, value, onChange, separator, itemTemplate, keyfilter, variant, invalid, disabled, placeholder, onFocus, onBlur}) {
    const {t} = useTranslation("CustomSelect")

    if(floatLabel){
      return (
          <div className={styles.CustomChips}>
            <span className="p-float-label">
            <Chips
                className="card p-fluid"
                id={id}
                value={value}
                onChange={onChange}
                separator={separator}
                itemTemplate={itemTemplate??undefined}
                keyfilter={keyfilter}
                variant={variant}
                invalid={invalid}
                disabled={disabled}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <label htmlFor={id}>{placeholder}</label>
            </span>
          </div>
        )
      }else{
        return (
            <Chips
                id={id}
                className="card p-fluid"
                value={value} 
                onChange={onChange}
                separator={separator}
                itemTemplate={itemTemplate??undefined}
                keyfilter={keyfilter}
                variant={variant}
                invalid={invalid}
                disabled={disabled}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
            />
        )
      }
}
