export const userFieldsValidation = (toast, password, confirmPassword, country, acceptTerms) => {
    // Validación de confirmación de contraseña
    if (password !== confirmPassword) {
        toast.current.show({
            severity: 'error',
            summary: 'Error en Contraseña',
            detail: 'Las contraseñas no coinciden. Por favor, verifica y vuelve a intentarlo.'
        });
        return false;
    }
    // Validación de pais
    if (!country) {
        toast.current.show({
            severity: 'error',
            summary: 'Selecciona tu pais',
            detail: ''
        });
        return false;
    }
    // Validación de aceptación de términos y condiciones
    if (!acceptTerms) {
        toast.current.show({
            severity: 'warn',
            summary: 'Términos y Condiciones',
            detail: 'Debes aceptar los términos y condiciones para continuar.'
        });
        return false;
    }
    // Validacion de longitud de contraseña
    if (password.length <= 6) {
        toast.current.show({
            severity: 'warn',
            summary: 'Contraseña muy corta',
            detail: 'La contraseña debe contener al menos 6 caracteres'
        });
        return false;
    }
    return true
}