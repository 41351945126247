import PropTypes from 'prop-types';
import DeleteButton from '../../Components/DeleteButton/DeleteButton';
import SecondaryButton from '../../Components/SecondaryButton/SecondaryButton';
import MainButton from '../../Components/MainButton/MainButton';


const AccordionMyOrdersContent = ({
  rowCheckBox,
  cleanRowsSelection,
  globalLoading,
  handleConfirmBatchOrders,
  handleGenerateBatchGuides,
  handleDownloadBatchPdfsGuides,
  handleFullfillmentBatchOrders,
  t,
  styles
}) => {
  return (
    <div
      style={{
        height: "80vh",
        overflowY: "auto",
      }}
    >
      <h4 className="flex justify-center">Ordenes seleccionadas</h4>
      <h5 className="flex justify-center mb-4">{rowCheckBox?.length}</h5>
      <div className="grid gap-4 p-4 mb-18">
        <DeleteButton
          className={styles.secondaryButton}
          type={"button"}
          onClick={cleanRowsSelection}
          label={t("clearOrderSelection")}
          loading={globalLoading}
        />
        <SecondaryButton
          className={styles.secondaryButton}
          type={"button"}
          onClick={handleConfirmBatchOrders}
          label={t("confirmOrdersBatch")}
          loading={globalLoading}
        />
        <MainButton
          className={styles.secondaryButton}
          type={"button"}
          onClick={handleGenerateBatchGuides}
          label={t("generateGuidesBatch")}
          loading={globalLoading}
        />
        <SecondaryButton
          className={styles.secondaryButton}
          type={"button"}
          onClick={handleDownloadBatchPdfsGuides}
          label={t("downloadPdfsBatch")}
          loading={globalLoading}
        />
        <SecondaryButton
          className={styles.secondaryButton}
          type={"button"}
          onClick={handleFullfillmentBatchOrders}
          label={t("orderFullfillment")}
          loading={globalLoading}
        />
      </div>
    </div>
  );
};
AccordionMyOrdersContent.propTypes = {
    rowCheckBox: PropTypes.arrayOf(PropTypes.object).isRequired,
    cleanRowsSelection: PropTypes.func.isRequired,
    globalLoading: PropTypes.bool.isRequired,
    handleConfirmBatchOrders: PropTypes.func.isRequired,
    handleGenerateBatchGuides: PropTypes.func.isRequired,
    handleDownloadBatchPdfsGuides: PropTypes.func.isRequired,
    handleFullfillmentBatchOrders: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    styles: PropTypes.object.isRequired,
  };
export default AccordionMyOrdersContent;
