import { BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn.jsx";
import { useTranslation } from "react-i18next";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { Toast } from "primereact/toast";
import useAuth from "../../hooks/useAuth.js";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import { useEffect, useRef, useState } from "react";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import CustomModal from "../../Components/CustomModal/CustomModal.jsx";
import RequestWithdrawalNewsForm from "../../Components/RequestWithdrawalNewsForm/RequestWithdrawalNewsForm.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import InsiderWithdrawalsTableColumns from "./InsiderWithdrawalsTableColumns.jsx";
import SellerWithdrawalsTableColumns from "./sellerWithdrawalsTableColumns.jsx";

export default function Withdrawals() {
  const { t } = useTranslation("Withdrawals");
  const { toast } = useAuth();
  const { userData, userSellerOwnerData, isCoworker, isInsider } = useAuthContext();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { stateColors } = useGlobalContext();
  const [withdrawalsRequests, setWithdrawalsRequests] = useState([]);
  const [visibleWithdrawalRequestUpdateNewsForm, setVisibleWithdrawalRequestUpdateNewsForm] = useState(false);
  const [selectedWithdrawalRequest, setSelectedWithdrawalRequest] = useState();
  const [userBankAccounts, setUserBankAccounts] = useState([]);

  const menu = useRef(null);
  const menuItems = [
    {
      label: t("Actualizaciones "),
      icon: "pi pi-fw pi-file",
      command: () => setVisibleWithdrawalRequestUpdateNewsForm(true),
    },
    // {
    //   label: t("Foo"),
    //   icon: "pi pi-fw pi-file",
    //   //   command: () => handleCancelAnOrderModal(selectedWithdrawalRequest),
    //   },
  ];
  const transactionStates = {
    "0": t("transactionStates.pending"),
    "1": t("transactionStates.paid"),
    "2": t("transactionStates.reject"),
  };
  const optionMenu = (e, rowData) => {
    // Abre el menú de opciones y establece la fila de cliente seleccionada
    menu.current.toggle(e);
    setSelectedWithdrawalRequest(rowData);
  };

  const withdrawalsTableColumns = isInsider ? InsiderWithdrawalsTableColumns(t, userBankAccounts, stateColors, transactionStates, menuItems, menu, isInsider, optionMenu, globalLoading )
   : 
    SellerWithdrawalsTableColumns( t, userBankAccounts, stateColors, transactionStates, menuItems, menu, isInsider, optionMenu, globalLoading);
  
    
  useEffect(() => {
    let userOwnerData;
    if ((isCoworker && userSellerOwnerData) || (isInsider && userSellerOwnerData)) {
      userOwnerData = userSellerOwnerData;
    } else {
      userOwnerData = userData;
    }
    if (!userOwnerData ) {
      setGlobalLoading(false);
      return;
    }
    const withdrawalsRequestsCollection = collection(db, "funds_withdraw_requests");
    const sellerUsersCollection = collection(db, "seller_users");
    
    let  qWithdrawalRequest = query( withdrawalsRequestsCollection, where("related_wallet_id", "==", userOwnerData.related_wallet_id),
        orderBy("created_at", "asc")); 
    if ( isInsider ) {
        qWithdrawalRequest =  query(withdrawalsRequestsCollection, orderBy("created_at", "asc"))                
    } else {
      setUserBankAccounts(userOwnerData?.bank_accounts);
    }

    let unsubscribeWithdrawalsRequests;
    let unsubscribeWallet;
    let unsubscribeSellerUsersOwners;
    let userSellersOwnersBankAccounts
    try {
      unsubscribeWithdrawalsRequests = onSnapshot(qWithdrawalRequest, (resultWithdrawRequest) => {
        const fundsWithdrawRequestsDocuments = resultWithdrawRequest.docs.map(doc => doc.data());

        setWithdrawalsRequests(fundsWithdrawRequestsDocuments);

        if (isInsider) {
            const relatedWalletIdsSet = new Set(fundsWithdrawRequestsDocuments.map((request) => request.related_wallet_id));
            const relatedWalletIdsArray = [...relatedWalletIdsSet]; // Convertir de vuelta a un array si es necesario
            
            const qSellerUsersOwners = query(sellerUsersCollection, where("related_wallet_id", "in", relatedWalletIdsArray), orderBy("created_at", "asc"));
            
            unsubscribeSellerUsersOwners = onSnapshot(qSellerUsersOwners, (resultQuerySellerOwnersQuery) => {
                const userSellersOwnersDocuments = resultQuerySellerOwnersQuery.docs.map(doc => doc.data());
                userSellersOwnersBankAccounts = userSellersOwnersDocuments.flatMap(doc => doc.bank_accounts);
                setUserBankAccounts(userSellersOwnersBankAccounts);
                setGlobalLoading(false);
            });
        }
        setGlobalLoading(false);
      });

    } catch (error) {
      console.error("Hubo un error", error);
    }
  
    return () => {
      unsubscribeWithdrawalsRequests && unsubscribeWithdrawalsRequests() &&
      unsubscribeWallet && unsubscribeWallet() &&
      unsubscribeSellerUsersOwners && unsubscribeSellerUsersOwners()
    };

  }, [userData, userSellerOwnerData]);

  return (
    <div className="mb-20 ">
      <div style={{ marginBottom: "50px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtn")} />
      </div>
      <CustomTable
        className="custom-table"
        columns={withdrawalsTableColumns}
        data={withdrawalsRequests}
        action={() => {}}
      />

      <Toast ref={toast} />
       <CustomModal
        visible={visibleWithdrawalRequestUpdateNewsForm}
        setVisible={setVisibleWithdrawalRequestUpdateNewsForm}
        // menu_option={{label:t('menu-options.home')}}
        createMode={t("Actualizar el estado de la solicitud")}
        content={
          <RequestWithdrawalNewsForm 
            userData={userSellerOwnerData || userData }
            toast={toast} onClose = {() => setVisibleWithdrawalRequestUpdateNewsForm(false)}
            selectedWithdrawalRequest={selectedWithdrawalRequest}
          />
        }
        onClose={() => setVisibleWithdrawalRequestUpdateNewsForm(false)}
      />
    </div>
  );
}
