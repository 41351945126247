import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase/firebase';

export const emailSignin = async (email, password) => {
    let successSignIn = false
    try {
        // console.log("entre a emaisigin")
        await signInWithEmailAndPassword(auth, email, password);
        successSignIn = true
        return {successSignIn, error: null}
    } catch (error) {
        // console.error("Ocurrio un error al iniciar sesion con email y contraseña", error)
        successSignIn = false
        return {successSignIn, error: error.code}
    }
}