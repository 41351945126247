import { BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { useTranslation } from "react-i18next";
import CustomTable from "../../Components/CustomTable/CustomTable";
import AuxButton from "../../Components/AuxButton/AuxButton";
import { useEffect, useState } from "react";
import MainButton from "../../Components/MainButton/MainButton";
import { Toast } from "primereact/toast";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import CustomModal from "../../Components/CustomModal/CustomModal";
import DetailPromotionForm from "../../Components/DetailPromotionForm/DetailPromotionForm";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import StatusLight from "../../Components/StatusLight/StatusLight";
import moment from "moment";
import CustomInputText from "../../Components/FormComponents/CustomInputText/CustomInputText";

// Componente de pagina que muestra las promociones asociadas a las tiendas de Shoppex
export default function Promotions() {
  const { userData, userSellerOwnerData } = useAuthContext();
  const { t } = useTranslation("Promotions");
  const { toast } = useShopifyRequest();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const [promotions, setPromotions] = useState([]);
  const [zones, setZones] = useState([]);

  const [visible, setVisible] = useState(false);
  const [selectedSellerPromotion, setselectedSellerPromotion] = useState();

  // Definición de columnas personalizadas para una tabla
  const CustomPromotionsTableColumns = [
    {
      field: "created_at",
      header: t("table-headers.createdAt"),
      // Función para formatear la fecha de creación
      body: (rowData) => {
        const createdAt = rowData.created_at
          ? moment.unix(rowData.created_at.seconds).format(t("table-headers.dateFormat"))
          : t("table-headers.invalidDateFormat");
        return <span>{createdAt}</span>;
      },
    },
    {
      field: "promotion_name",
      header: t("table-headers.promotionName"),
      filterable: false,
      sortable: false,
    },
    {
      field: "related_stores_ids",
      header: t("table-headers.relatedStoresIds"),
      filterable: false,
      sortable: false,
      // Función para renderizar una lista de tiendas relacionadas
      body: (rowData) => (
        <div className="grid justify-center">
          {rowData.related_stores_ids.map((store) => {
            return <div key={store.id}>{store.name}</div>;
          })}
        </div>
      ),
    },
    {
      field: "promotion_type.name",
      header: t("table-headers.promotionType"),
      filterable: false,
      sortable: false,
    },
    {
      field: "discount_unit.name",
      header: t("table-headers.discountUnitName"),
      filterable: false,
      sortable: false,
    },
    {
      field: "discount_value",
      header: t("table-headers.discountValue"),
      body: (rowData) => (
        <CustomInputText
        inColumTable={true}
          floatLabel={true}
          value={
            rowData.promotion_type?.code === 0
              ? rowData?.discount_value
              : rowData?.promotion_type?.code === 2
              ? rowData?.discount_upsell_value
              : rowData?.promotion_type?.code === 3
              ? rowData?.discount_downsell_value
              : rowData?.promotion_type?.code === 4
              ? rowData?.discount_crossell_value
              : ""
          }
          prefix={rowData?.discount_unit?.code === 0 ? "% " : "$"}
          disabled={true}
        />
      ),
    },
    {
      field: "is_active",
      header: t("table-headers.promotionalState"),
      // Función para renderizar un indicador de estado basado en la propiedad 'is_active'
      body: (rowData) => (
        <StatusLight
          color={rowData.is_active ? "green" : "red"}
          // tooltip={rowData.state.name}
        />
      ),
    },
    {
      // Columna especial para editar

      header: "Editar",
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <AuxButton
          data={rowData}
          disable={false}
          label={t("edit")}
          style={{
            backgroundColor: "var(--main-color)",
            color: "white",
            fontSize: "var(--font-size-h5)",
          }}
          onClick={openPromotionModal}
        />
      ),
    },
  ];
  // Función para abrir el modal de promocion y configurar el estado según la tienda seleccionada
  const openPromotionModal = (store) => {
    setVisible(true);
    if (store.id) {
      setselectedSellerPromotion(store);
    } else {
      setselectedSellerPromotion({});
    }
  };
  // Función para cerrar el modal de tiendas
  const closeStoresModal = () => {
    setVisible(false);
  };
  // Efecto secundario para cargar datos de promociones y zonas al montar el componente o cuando userData cambia
  useEffect(() => {
    if (!userData?.business_id) {
      setGlobalLoading(false); // No hay business_id definido, entonces detener la carga global
      return;
    }

    const promotionsCollection = collection(db, "promotions");
    const configZonesCollection = collection(db, "config");
    const zonesDocRef = doc(configZonesCollection, "zones");
    // Consulta para obtener promociones relacionadas con el vendedor actual
    const q = query(promotionsCollection, where("related_business_id", "==", userData?.business_id));
    let changeDetected = false;
    // Suscripción a cambios en la colección de promociones
    const promotionsDocsUnsubscribe = onSnapshot(q, (querySnapshot) => {
      const promotionsData = [];
      querySnapshot.forEach((doc) => {
        promotionsData.push({ ...doc.data() });
      });
      if (!querySnapshot.empty && !changeDetected) {
        changeDetected = true;
      }
      if (changeDetected) {
        setPromotions(promotionsData);
        setGlobalLoading(false);
      } else {
        setGlobalLoading(false);
      }
    });
    // Función para obtener el documento 'Zones'

    const getZonezDocument = async () => {
      try {
        const docSnapshot = await getDoc(zonesDocRef);
        if (docSnapshot.exists()) {
          const zonezData = docSnapshot.data();
          // console.log("Documento zones' encontrado:", zonezData);
          setZones(zonezData.zones);
        } else {
          console.log("El documento 'Zones' no existe.");
        }
      } catch (error) {
        console.error("Error al obtener el documento 'Zones':", error);
      }
    };

    getZonezDocument(); // Llamada para obtener el documento 'Zones'

    return () => {
      promotionsDocsUnsubscribe();
    };
  }, [userData, userSellerOwnerData]);
  return (
    <div className="mb-20">
      <div style={{ marginBottom: "40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtn")} />
      </div>
      <CustomTable className="custom-table" columns={CustomPromotionsTableColumns} data={promotions} action={() => ({})} />
      <div>
        <CustomModal
          visible={visible}
          setVisible={setVisible}
          object={selectedSellerPromotion}
          // menu_option={{label:t('menu-options.home')}}
          editMode={t("updatePromotion")}
          createMode={t("createPromotion")}
          content={
            <DetailPromotionForm
              onClose={setVisible}
              selectedSellerPromotion={selectedSellerPromotion}
              userData={userSellerOwnerData || userData}
              labelDelete={t("deletePromotion")}
              labelSuccess={t("createPromotion")}
              toast={toast}
              zones={zones}
            />
          }
          onClose={closeStoresModal}
        />
      </div>

      <div style={{ marginTop: "100px" }}>
        <MainButton
          loading={globalLoading}
          // label={t("addBank")}
          label={t("createPromotion")}
          onClick={openPromotionModal}
        />
      </div>
      <Toast ref={toast} />
    </div>
  );
}
