import React, { useState } from 'react'
import styles from './UserMoneyDataCard.module.css';
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import numeral from 'numeral';
import MoneyDetailsTable from '../MoneyDetailsTable/MoneyDetailsTable';

export default function UserMoneyDataCard({moneyData, labelTableTitle, valueTableTitle, without_arrow,}) {
    const [isCollapse, setIsCollapse] = useState(true)
    return (
        <div className='clickable'>
            <div onClick={()=>setIsCollapse(!isCollapse)} className={styles.UserMoneyDataCard}>
                <div className={styles.content}>
                    <div className={styles.total}>{numeral(moneyData.total).format('$ 0,0[.]')}</div>
                    <div className={styles.title}>{moneyData.title}</div>
                </div>
                <div className={styles.collapseBtn}>
                    {
                        isCollapse && !without_arrow?
                        <IoIosArrowDown className='icon' />
                        :
                        !without_arrow &&
                        <IoIosArrowForward className='icon' />
                    }
                </div>
            </div>
            {
                isCollapse && !without_arrow && (
                    <MoneyDetailsTable className="custom-table" transactions={moneyData?.itemsTable} />
                )
            }
        </div>
  )
}