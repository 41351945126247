import React from 'react'
import styles from './Reviews.module.css'
import Paragraph from '../Paragraph/Paragraph'
// import VideoDemo from '../VideoDemo/VideoDemo'
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import CTA from '../CTA/CTA';

export default function Reviews() {
  const { isDesktop } = useGlobalContext();
//   const reviewData = [
//     { userName: "Name", reviewText: '"Siento que hablo con un amigo con Mybroo"', videoSrc: "" },
//     { userName: "Name", reviewText: '"Mybroo es diferente a otras plataformas"', videoSrc: "" },
//     { userName: "Name", reviewText: '"Mybroo tiene un plan para todos"', videoSrc: "" },
//     { userName: "Name", reviewText: '"Mi inglés ha mejorado gracias a Mybroo"', videoSrc: "" },
//     { userName: "Name", reviewText: '"Mybroo puede ser nuevo, pero es increíble"', videoSrc: "" },
//     { userName: "Name", reviewText: '"Me mantiene motivado y en progreso constante"', videoSrc: "" }
//   ];
  const reviewData = [
    { userName: "Alejandra - Bogotá", reviewText: '"Supplaii ha revolucionado mi negocio. No necesito preocuparme por inventarios y la logística es increíblemente eficiente."', videoSrc: "", profile_img: "https://firebasestorage.googleapis.com/v0/b/mybroo-users.appspot.com/o/PEOPLE%2F240_F_362718396_bcioDlg0zcEzRA7s4lnNngOT68NlNPDo.jpg?alt=media&token=33dd1c0b-57ed-4733-beed-38f39a0ea470" },
    { userName: "Carlos - Medellín", reviewText: '"Gracias a Supplaii, he podido enfocarme en vender y crecer mi negocio sin los dolores de cabeza de la logística. ¡Altamente recomendado!"', videoSrc: "", profile_img: "https://firebasestorage.googleapis.com/v0/b/mybroo-users.appspot.com/o/PEOPLE%2F240_F_443122841_t7TN0bnXpGPcBBgW46Jm8U7plZ0KeoKV.jpg?alt=media&token=bb7c1af9-4ebe-4131-aec8-ef1652cd53ab" },
    { userName: "Valeria - Cali", reviewText: '"La integración con pagos contra entrega ha incrementado mis ventas significativamente. Supplaii realmente entiende las necesidades de los emprendedores."', videoSrc: "", profile_img: "https://firebasestorage.googleapis.com/v0/b/mybroo-users.appspot.com/o/PEOPLE%2F240_F_418142160_UYB1DBHsbkunm0rEav16lkbMZVg5bF8w.jpg?alt=media&token=9c4e5568-b2df-4bf5-ae18-22181969d114" },
    { userName: "Miguel - Barranquilla", reviewText: '"La facilidad de encontrar productos ganadores en Supplaii es insuperable. Mis ganancias han aumentado y el proceso es simple y directo."', videoSrc: "", profile_img: "https://firebasestorage.googleapis.com/v0/b/mybroo-users.appspot.com/o/PEOPLE%2F240_F_702080810_oCHXrHbVYI3eQODrG38nh8A2L5ozGBOa.jpg?alt=media&token=0512d2fb-4f72-4463-a9c6-bf38a2888bad" },
    { userName: "Laura - Cartagena", reviewText: '"Supplaii ha superado todas mis expectativas. Su plataforma es fácil de usar y su soporte al cliente es excepcional."', videoSrc: "", profile_img: "https://firebasestorage.googleapis.com/v0/b/mybroo-users.appspot.com/o/PEOPLE%2Fcol.JPG?alt=media&token=f2a8e783-dbac-4ef1-a0e2-b588edb26a5f" },
    { userName: "Jorge - Pasto", reviewText: '"Desde que empecé a usar Supplaii, he visto un aumento constante en mis ingresos. La rapidez en recibir mis ganancias es un gran plus."', videoSrc: "", profile_img: "https://firebasestorage.googleapis.com/v0/b/mybroo-users.appspot.com/o/PEOPLE%2F240_F_506788036_oCyqoRgoYnsrJTTxcF2k8j6aMyKq1KoC.jpg?alt=media&token=b11d8512-871b-49cb-b417-3a096acc1d80" }
  ];

  return (
    <div id='reviews_sect' style={{marginTop:"150px"}}>
        <Paragraph
            title={"Miles de emprendedores"}
            subtitle={""}
            subtitle_style={{backgroundColor:"var(--green-background)", color:"var(--green-text)"}}
            bold_text={"Ya estan generando sus ingresos soñados"}
            regular_text={""}
        />
        <div className={isDesktop && styles.video_carrusel}>
            {reviewData.map((item, index) => (
                <ReviewItem 
                    key={index}
                    userName={item.userName} 
                    reviewText={item.reviewText} 
                    videoSrc={item.videoSrc} 
                    profile_img={item.profile_img}
                />
            ))}
        </div>
        <CTA label={"REGISTRARME GRATIS"} navigateTo={"/signup"} style={{marginTop:"40px"}}/>

    </div>
  )
}

const ReviewItem = ({ userName, reviewText, videoSrc, profile_img }) => {
    return (
        <div className={styles.video_carrusel_item}>
            <div className={styles.user_review}>
                <img className={styles.review_summary_user_img} src={profile_img}/>
                <div className={styles.review_summary_user}>{userName}</div>
                <div className={styles.review_summary}>{reviewText}</div>
            </div>
            {/* <VideoDemo autoPlay={false} src={videoSrc}/> */}
        </div>
    );
};