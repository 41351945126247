import  { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../../firebase/firebase';
import { onSnapshot, doc, collection, query, where } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { useLoaderContext } from '../LoaderContext/LoaderContext';
import useAuth from '../../hooks/useAuth';
import { useLocation, useSearchParams } from 'react-router-dom';

const AuthContext = createContext();

function AuthProvider({ children }) {
    const {seeCustomClaims} = useAuth();
    const {setGlobalLoading} = useLoaderContext();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState(null);
    const [catalogData, setCatalogData] = useState(null);

    const [userSellerOwnerData, setUserSellerOwnerData] = useState(null);

    const [isCoworker, setIsCoworker] = useState(false);
    const [isInsider, setIsInsider] = useState(false);

    const [userClaimsData, setUserClaimsData] = useState(null);
    const [userAuth, setUserAuth] = useState(null);
    const [userSignedIn, setUserSignedIn] = useState(false);

   const [zonesData, setZonesData] = useState([]);
   const [searchParams] = useSearchParams();
   const catalogId = searchParams.get('catalogId');
  
    useEffect(() => {
        const getCustomClaims = async (user) => {
          try {
            const userCustomClaims = await seeCustomClaims(user);
            // // console.log(userCustomClaims, "En usefect");
            // setLoading(false);
            // setGlobalLoading(false);
            return userCustomClaims
          } catch (error) {
            console.error("Error al obtener custom claims:", error);
            setLoading(false);
            setGlobalLoading(false);
          }
        };

        setGlobalLoading(true);
        let userDocUnsubscribe = null;
        let authNameCollection = null;
        let unsuscribeSellerOwner = null;

        const authUnsubscribe = auth.onAuthStateChanged(async (user) => {
          if (user) {
            try {
              const userCustomClaims = await getCustomClaims(user);
              // // console.log(userCustomClaims, "Después de la función asincrónica");
              setUserClaimsData(userCustomClaims);
              if (userCustomClaims?.is_master && userCustomClaims?.is_insider && userCustomClaims?.type === "master") {
                // // console.log("Es el usuario master");
                authNameCollection = "master_user";
              } else if (!userCustomClaims?.is_master && userCustomClaims?.is_insider && userCustomClaims?.type === "insider") {
                // // console.log("Es insider");
                authNameCollection = "insider_users";
              } else if (!userCustomClaims?.is_master && !userCustomClaims?.is_insider && userCustomClaims?.type === "seller") {
                // // console.log("Es Seller");
                authNameCollection = "seller_users";
              }else {
                // // console.log("Es Seller por descarte");
                authNameCollection = "seller_users";
              }
              
              let userDocRef = doc(db, authNameCollection, user.uid);
              userDocUnsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                  const userDataAuth = docSnapshot.data()
                  // // console.log("Encontré el documento del usuario", userDataAuth);
                  setUserData(userDataAuth);
                  const sellerColl = collection(db, "seller_users");
                  const masterColl = collection(db, "master_user");
                  const userIsCoworker = !userDataAuth?.isAdmin && userDataAuth?.related_seller_id && userDataAuth?.is_coworker
                  const userIsInsider = userDataAuth?.is_insider
                  // // console.log(userDataAuth, userDataAuth?.is_insider);
                  setIsCoworker(userIsCoworker);
                  setIsInsider(userIsInsider);

                  if(userIsCoworker) {
                    const sellerOwnerRef = doc(sellerColl, userDataAuth?.related_seller_id);
                    // console.log("userIsCoworker en auth", userIsCoworker,  userDataAuth?.related_seller_id, sellerOwnerRef)
  
                    unsuscribeSellerOwner = onSnapshot(sellerOwnerRef, (sellerOwnerDocSnap) => {
                      // console.log(sellerOwnerDocSnap.exists(), "jakldjaskjdklsajklj");
                      if (sellerOwnerDocSnap.exists()) {
                        const sellerOwnerData = sellerOwnerDocSnap?.data();
                    // console.log("sellerOwnerData", sellerOwnerData)

                        setUserSellerOwnerData(sellerOwnerData)
                      } else {
                        setUserSellerOwnerData(null);
                        // console.log("El documento del vendedor propietario no existe");
                      }
                    });
                    // return unsubscribe;
                  } else if(userIsInsider) {
                    const masterDocRef = doc(masterColl, "56z5lV7Nl4QTzqaHjWO2NvufWnI3");
                    // // console.log("userIsInsider en auth", userIsInsider)
  
                    unsuscribeSellerOwner = onSnapshot(masterDocRef, (sellerOwnerDocSnap) => {
                      if (sellerOwnerDocSnap.exists()) {
                        const masterData = sellerOwnerDocSnap.data();
                        setUserSellerOwnerData(masterData)
                      } else {
                        setUserSellerOwnerData(null);
                        // console.log("El documento del master no existe");
                      }
                    });
                    // return unsubscribe;
                  }
                  setUserSignedIn(true);
                } else {
                  setUserData(null);
                  setUserSellerOwnerData(null);
                }
                setLoading(false);
                setGlobalLoading(false);
              });

            
            } catch (error) {
              console.error("Error al obtener los Claims personalizados del usuario:", error);
              setLoading(false);
              setGlobalLoading(false);
            }
          } else {
            setLoading(false);
            setGlobalLoading(false);

            setUserAuth(null);
            setUserData(null);
            setUserSignedIn(false);
            
            if (userDocUnsubscribe) {
              userDocUnsubscribe();
            }
            if(unsuscribeSellerOwner) {
              unsuscribeSellerOwner();
            }      
           
          }
        });
        
        return () => {
          authUnsubscribe();
          if (userDocUnsubscribe) {
            userDocUnsubscribe();
          }
          if(unsuscribeSellerOwner) {
            unsuscribeSellerOwner();
          }
        };
      }, []);

    useEffect(() => {
    }, [userData]);

    const location = useLocation();

    useEffect(() => {
      let userCatalogDoc = null;
      if (location.pathname.startsWith('/catalog') ) {
        // console.log("Estoy en la ruta catalog");
        setGlobalLoading(true);
  
        const qMyCatalog = query(collection(db, 'catalogs'), where('id', '==', catalogId));
  
        userCatalogDoc = onSnapshot(qMyCatalog, (querySnapshot) => {
          if (!querySnapshot.empty) {
            setCatalogData(querySnapshot.docs[0].data());
          } else {
            // console.log("No se encontró el catálogo");
            // navigate('/login');
            
          }
          setGlobalLoading(false);
        }, (error) => {
          console.error("Error al obtener el catálogo:", error);
          setGlobalLoading(false);
        });
      } else {
        // console.log("No estoy en la ruta catalog");
      }
  
      // Limpieza cuando el componente se desmonta o la ubicación cambia
      return () => {
        if (userCatalogDoc) {
          userCatalogDoc();
        }
      };
    },[location]);

    useEffect(() => {
      const configCol = collection(db, "config");
      const configDoc = doc(configCol, "zones");
    
      const unsubscribeZonesData = onSnapshot(configDoc, (docSnapshot) => {
    
        if (docSnapshot.exists()) {
          const configData = docSnapshot.data();
          setZonesData(configData?.zones);
        }
      }, (error) => {
        console.error("Error al suscribirse a configDoc:", error);
      });
    
      return () => {
        if (unsubscribeZonesData) {
          unsubscribeZonesData();
        }
      };
    }, [location]);

    function logout() {
        signOut(auth)
            .then(() => {
                // console.log("Usuario ha cerrado sesión exitosamente");
                setUserData(null);
                setUserSellerOwnerData(null);
            })
            .catch((error) => {
                console.error("Error al cerrar sesión: ", error);
            });
    }

    return (
        <AuthContext.Provider value={{
            loading,
            userSignedIn,
            userData,
            setUserData,
            userAuth,
            setUserAuth,
            logout,
            userClaimsData,
            userSellerOwnerData,
            isCoworker,
            isInsider,
            zonesData,
            catalogData
        }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuthContext() {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext debe ser usado dentro de un AuthProvider');
    }
    return context;
}

export { AuthProvider, useAuthContext };