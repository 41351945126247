import { useRef } from "react";
import { useLoaderContext } from "../contexts/LoaderContext/LoaderContext";
import { useTranslation } from "react-i18next";
import { fetchFromCloudFunction } from "../services/cloudFunctinons/fetchFromCloudFunction";

function useShopifyRequest() {
  const toast = useRef(null);
  const { setGlobalLoading } = useLoaderContext();
  const { t } = useTranslation("");

  const checkShopifyProductConnection = async (userShopifyData) => {
    try {
      setGlobalLoading(true);
      const result = await fetchFromCloudFunction(
        "shopify/checkShopifyProductsConnection",
        userShopifyData,
        "POST"
      );
      console.log("resultado", result);
      if (result.status === 200) {
        setGlobalLoading(false);
        return result;
      }
    } catch (error) {
      console.error("Error al checkear la conexion a productos :", error);
    }
    setGlobalLoading(false);
  };
  const fetchShopifyProductConnection = async (userShopifyData) => {
    try {
      setGlobalLoading(true);
      const result = await fetchFromCloudFunction(
        "shopify/fetchShopifyProductsData",
        userShopifyData,
        "POST"
      );
      console.log("resultado", result);
      if (result.status === 200) {
        setGlobalLoading(false);
        return result;
      }
    } catch (error) {
      console.error("Error al checkear la conexion a productos :", error);
    }
    setGlobalLoading(false);
  };
  return { toast, checkShopifyProductConnection, fetchShopifyProductConnection };
}
export default useShopifyRequest;
