import { initializeApp } from 'firebase/app';
import { getFirestore, 
  // connectFirestoreEmulator 
} from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getFunctions, 
  // connectFunctionsEmulator 
} from 'firebase/functions';
import { getAuth, 
  // connectAuthEmulator 
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// const baseUrlEmulators = "http://127.0.0.1:4000";
const firebaseConfig = {
  apiKey: "AIzaSyCazk7CBvbho_aIIwJR1Gp-OAFN1bTfF2g",
  authDomain: "shoppex-aa709.firebaseapp.com",
  projectId: "shoppex-aa709",
  storageBucket: "shoppex-aa709.appspot.com",
  messagingSenderId: "383841609507",
  appId: "1:383841609507:web:edffc575e5aba0f24d4e96",
  measurementId: "G-XQN0HXNN60"
};

const app = initializeApp(firebaseConfig, "SHOPPEX");
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
