 const formatDate = (created_at) => {
    if (created_at.nanoseconds !== undefined && created_at.seconds !== undefined) {
      // Formato con segundos y nanosegundos
      const date = new Date(created_at.seconds * 1000 + created_at.nanoseconds / 1000000);
      return date.toLocaleString();
    } else if (created_at.toDate !== undefined) {
      // Formato de Firebase Timestamp
      return created_at.toDate().toLocaleString();
    } else {
      // Otro formato no manejado
      return 'Fecha no válida';
    }
  };
  export default formatDate;
  