import PropTypes from "prop-types"
import IconProvider from '../IconProvider/IconProvider.jsx';
import styles from './BadgetCard.module.css';

export default function BadgetCard({Icon, color, bold_text, regular_text, onClick, data, isErasable, disabled}) {
  const colorShadow = {
    boxShadow: `0px 0px 15px -3px #${color}`
  }
  return (
    <div  className={ `clickable ${styles.BadgetCard}`} style={disabled ? {opacity: "50%", boxShadow: colorShadow.boxShadow}: colorShadow}>
        <span 
            className="mr-4 md:ml-5 md:mr-12"
            style={{ color:`var(${color})`, fontSize:"var(--font-size-h5)"}}
        >
          <IconProvider 
            key={data.id} 
            name={Icon}
            className="md:w-1/10 w-1/10"
            colorIcon={color}
            sizeIcon={"2em"}

          />
        </span>
        <div 
          className="flex md:gap-4 gap-2 items-center " 
          style={{ fontSize:"var(--font-size-h4)", }}> 
            <span 
            className="font-bold md:text-2xl text-sm"
            style={{color:`#${color}`}}>
                {bold_text}
            </span>
              <span
              className="md:text-xl text-sm"
               style={{color: "black"}}>
                {regular_text}
              </span>
            {isErasable && (
              <button disabled={disabled} type="button" onClick={() => onClick(data, "badget")} className={styles.buttonOverlay}>Editar
            </button> )}
        </div>
    </div>
  )
  
}

BadgetCard.propTypes = {
  Icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  bold_text: PropTypes.string,
  regular_text: PropTypes.string,
  isErasable: PropTypes.bool,
  data: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,

}
