import styles from './MenuOption.module.css'
import CartWidget from '../CartWidget/CartWidget'

export default function MenuOption({ref, onClick, Icon, menu_option, selected_option}) {
  let isSelect = menu_option.value===selected_option
  return (
    <div ref={ref} onClick={onClick} className={`clickable ${styles.MenuOption}`}>

        {Icon==="CartWidget"?
          <CartWidget
            iconSize={"var(--font-size-h3)"}
            style={{marginRight:"15px"}}
            items={[1,2,3]}
          />
        :
          <Icon
              className={"icon"}
              style={{marginRight:"15px", fontSize:"var(--font-size-h3)", color: isSelect? "var(--main-color)" : "var(--font-head-color)", opacity: isSelect? "1" : "0.85"}} 
          />}
        <h5
            style={{fontWeight:"var(--font-weight-bold)", color: isSelect? "var(--main-color)" : "var(--font-head-color)", opacity: isSelect? "1" : "0.85"}}
        >
            {menu_option.label}
        </h5>
    </div>
  )
}
