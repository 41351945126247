import { useEffect, useState } from "react";
import ProductGridCard from "../../Components/ProductGridCard/ProductGridCard";
import CustomModal from "../../Components/CustomModal/CustomModal";
import DetailProduct from "../../Components/DetailProduct/DetailProduct";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { Toast } from "primereact/toast";
import ProductForm from "../../Components/ProductForm/ProductForm";
import MainButton from "../../Components/MainButton/MainButton";
import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { BiChevronLeft } from "react-icons/bi";
import { CREATE } from "../../Components/router/paths";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
// Componente de pagina que muestra los productos vinculados en base a los ids de los productos vinculados con las tiendas de shopify
const MyCreatedProducts = () => {

  const { setGlobalLoading } = useLoaderContext();
  const { userData, userSellerOwnerData } = useAuthContext();
  const {getDocumentsByQuery} = useFirebaseCRUD();
  const { toast } = useShopifyRequest();
  const [visibleDetailProduct, setVisibleDetailProduct] = useState(false);
  const [selectedProduct, setselectedProduct] = useState({});
  const { t } = useTranslation("Products");
  const [visibleProductForm, setVisibleProductForm] = useState(false);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [productos, setProductos] = useState([]);
  const [unsubscribe, setUnsubscribe] = useState(null);

  const getProductCategories = async () => {
    try {
      const q = collection(db, "category_products");
      const categoryProducts = await getDocumentsByQuery(q);
      setCategoryProducts(categoryProducts)
      return categoryProducts;
    } catch (error) {
      console.error("Error al obtener los productos:", error);
      throw error;
    }
  };

  // Función para abrir el modal de tiendas y establecer el producto seleccionado
  const openDetailProductModal = (product) => {
    setVisibleDetailProduct(true);
    if (product.id) {
      setselectedProduct(product);
    } else {
      setselectedProduct({});
    }
  };

  const updateProductNamesForSearch = async () => {
    try {
      // Referencia a la colección "products"
      const productsCollection = collection(db, "products");
  
      // Obtener todos los documentos en la colección "products"
      const querySnapshot = await getDocs(productsCollection);
  
      // Iterar sobre cada documento
      querySnapshot.forEach(async (documentSnapshot) => {
        const productData = documentSnapshot.data();
        const productId = documentSnapshot.id;
  
        // Tomar el valor de "product_name" y convertirlo a minúsculas
        const productName = productData.product_name;
        if (productName) {
          const productNameForSearch = productName.toLowerCase();
  
          // Actualizar el documento con la nueva clave "product_name_for_search"
          const productDocRef = doc(db, "products", productId);
          await updateDoc(productDocRef, {
            product_name_for_search: productNameForSearch,
          });
  
          console.log(`Updated product ${productId} with product_name_for_search`);
        }
      });
    } catch (error) {
      console.error("Error updating product_name_for_search: ", error);
    }
  };



  // Función para cerrar el modal de tiendas
  const closeDetailProductModal = () => {
    setVisibleDetailProduct(false);
  };
  // Función para abrir el modal de tiendas y establecer el producto seleccionado
  const openProductModal = (product) => {
    setVisibleProductForm(true);
    if (product.id) {
      setselectedProduct(product);
    } else {
      setselectedProduct({});
    }
  };

  // Función para cerrar el modal de tiendas
  const closeProductFormModal = () => {
    setVisibleProductForm(false);
  };

  useEffect(() => {
    const getProductCategoriesData = async () => {
      try {
        setGlobalLoading(true);
        await getProductCategories();

        const productsCollection = collection(db, 'products');
        const productsQuery = query(productsCollection, where('commercial_partner_id', '==', userData.business_id));
        const unsubscribe = onSnapshot(productsQuery, (snapshot) => {
          const products = [];
          snapshot.forEach((doc) => {
            const producto = doc.data();
            products.push(producto);
          });
          setProductos(products);
        });
        // Guardar la función de desactivación del listener
        setUnsubscribe(() => unsubscribe);
        setGlobalLoading(false);
      } catch (error) {
        console.error('Error al obtener productos:', error);
        setGlobalLoading(false);
      }
    };

    // Llamar a la función fetchData al montar el componente o cuando cambia el usuario
    getProductCategoriesData();

    // Desmontar el componente
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Desactivar el listener onSnapshot al desmontar el componente
      }
    };
  }, [userData]);

  return (
    <>
     <div style={{ marginBottom: "40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtnMyCreatedProducts")} />
      </div>
      <div className="mb-12">
        <MainButton
          type="button"
          label={t("createProduct")}
          onClick={openProductModal}
        />
      </div>
      {productos.length > 0 ? (
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {productos.map((product, index) => (
            <ProductGridCard
              key={index}
              index={index}
              user_data={userData}
              product_data={product}
              onClick={() => openDetailProductModal(product)}
            />
          ))}
        </div>
      ) : (
        <marquee>No se encontraron productos</marquee>
      )}
      <div>
        <CustomModal
          visible={visibleDetailProduct}
          setVisible={setVisibleDetailProduct}
          object={selectedProduct}
          editMode={t("detailProduct")}
          createMode={t("vinculateProduct")}
          content={
            <DetailProduct
            //   onLastProductIndex={setLastProductIndex}
              productData={selectedProduct}
              categoryProductsData={categoryProducts}
              toast={toast}
              onClose={closeDetailProductModal}
              module={CREATE}
              userData={userSellerOwnerData || userData}
            />
          }
          onClose={closeDetailProductModal}
        />
      </div>
        <CustomModal
          visible={visibleProductForm}
          setVisible={setVisibleProductForm}
          object={selectedProduct}
          content={
            <ProductForm
              mode={CREATE}
              selectedProduct={selectedProduct}
              categoryProductsData={categoryProducts}
              toast={toast}
              onClose={closeProductFormModal}
            />
          }
          onClose={() => setVisibleProductForm(false)}
        />
          
      <Toast ref={toast} />
    </>
  );
};
export default MyCreatedProducts;
