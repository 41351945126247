import { Button } from "primereact/button";
import AuxStatusBadget from "../../Components/AuxStatusBadget/AuxStatusBadget.jsx";
import CustomInputText from "../../Components/FormComponents/CustomInputText/CustomInputText.jsx";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect.jsx";
import formatCurrency from "../../utils/formatCurrency.js";
import formatDate from "../../utils/formatDate.js";
import { Checkbox } from "primereact/checkbox";

export const ConsolidatedOrderTableColumns = (t, orderStates, stateColors, orderPaymentStatus, shippingCompanies, openItemOverlayPanel, zones, openOrderStatusOverlayPanel, openConsolidateItemsDialog) => [
  {
    field: "states",
    header: t("table-headers.state"),
    filterable: false,
    sortable: false,

    body: (rowData) => (
      <>
        <div className={"grid"}>
          <span>{formatDate(rowData?.created_at)}</span>
          <span>
            <AuxStatusBadget
            onClick={(e) => openOrderStatusOverlayPanel(rowData, e)}
              statusLabel={orderStates
                .filter((state) => state?.code === rowData?.last_state)
                .map((matchingState) => (
                  <span
                    key={matchingState?.code}
                    style={{
                      backgroundColor: stateColors[matchingState.code],
                      color: "white",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      margin: "4px 0",
                      display: "flex",
                    }}
                  >
                    {matchingState?.name}
                  </span>
                ))}
            />
          </span>
        </div>
      </>
    ),
  },
  {
    header: t("table-headers.order"),
    filterable: false,
    sortable: false,

    body: (rowData) => (
      <>
        <div className={""}>
          <span>{rowData?.store_name}</span>
          <span
            style={{
              margin: "4px 0",
              display: "flex",
            }}
          >
            {rowData?.order_number}
          </span>
        </div>
      </>
    ),
  },
  {
    field: "customer_full_name",
    header: t("table-headers.customer"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <div className="">
        <CustomInputText
          value={rowData.customer_full_name}
          disabled={true}
          placeholder={t("input-placeholders.customerFullName")}
          inColumTable={true}
        />
        <CustomInputText
          value={rowData.customer_phone_number || ""}
          disabled={true}
          placeholder={t("input-placeholders.customerPhone")}
          inColumTable={true}
          
        />
      </div>
    ),
  },
  {
    field: "shipping_destination",
    header: t("table-headers.destination"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <>
        <CustomSelect
          optionLabel={"city_name"}
          options={zones}
          value={rowData?.shipping_destination}
          placeholder={t("input-placeholders.destinyCity")}
          disabled={true}

        />
        <CustomInputText
          value={rowData.customer_shipping_address}
          // onChange={(e) =>handleRowChange(rowData,"customer_shipping_address",e.target.value) }
          disabled={true}
          placeholder={t("input-placeholders.destinyShippingAddress")}
          inColumTable={true}

        />
      </>
    ),
  },
  {
    field: "line_items",
    header: t("table-headers.items"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <>
        <Button
          type="button"
          icon="pi pi-search"
          label={rowData?.line_items?.length}
          onClick={(e) => openItemOverlayPanel({rowData, e})}
        />
      </>
    ),
  },
  {
    field: "total_order_price",
    header: t("table-headers.totalOrder"),
    filterable: false,
    sortable: false,

    body: (rowData) => (
      // <span>{numeral(rowData.total_order_price).format('$0,0')}</span>
      <span>{formatCurrency(rowData?.total_order_price)}</span>
    ),
  },
  {
    header: t("table-headers.dispatch"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <>
        <CustomSelect
          optionLabel={"name"}
          options={shippingCompanies}
          value={rowData.shipping_company_details}
          placeholder={t("input-placeholders.shippingCompany")}
          disabled={true}

        />
        <CustomInputText
          value={rowData?.shipping_guide_number || ""}
          placeholder={t("input-placeholders.shippingGuideNumber")}
          disabled={true}

        />
      </>
    ),
  },
  {
    field: "payment_method",
    header: t("table-headers.paymentMethod"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <div className="card flex justify-content-center">
        <AuxStatusBadget
          statusLabel={rowData.payment_method === 1 ? "COD" : "ONLINE"}
          style={{
            display: "flex",
            backgroundColor: "green",
            color: "white",
          }}
        />
          { rowData?.payment_method === 2 && (<span>
          <AuxStatusBadget
              statusLabel={orderPaymentStatus
                ?.filter((state) => state.code === rowData?.online_payment_status)
                ?.map((matchingState) => (
                  <span
                    key={matchingState?.code}
                    style={{
                      backgroundColor: stateColors[matchingState.code],
                      color: "white",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      margin: "4px 0",
                      display: "flex",
                    }}
                  >
                    {matchingState?.name}
                  </span>
                ))}
            />
          </span>)}
      </div>
    ),
  },
  {
    field: "order_note",
    header: t("table-headers.note"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <div 
      >
        <CustomInputText
          value={rowData.note || ""}
          placeholder={t("input-placeholders.orderNote")}
          disabled={true}
          inColumTable={true}
        />
      </div>
    ),
  },
   {
    field: "consolidateItemsBtn",
    header: t("table-headers.consolidateItemsBtn"),
    
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <div 
      >
       <Button onClick={() => openConsolidateItemsDialog(rowData)} label={t("consolidateItemsBtn")}/>
      </div>
    ),
   }
];

export const OrderLineItemsTableColumns = (t, selectedItems, handleItemsSelection) => [
  
   {
      field: "product_title",
      header: t("table-headers.product_title"),
      filterable: false,
      sortable: false,
    },
    {
      field: "sku_title",
      header: t("table-headers.variation"),
      filterable: false,
      sortable: false,
    },
    {
      filterable: false,

      field: "quantity",
      header: t("table-headers.qty"),
    },
    {
      filterable: false,
      field:"unit_price",
      header:t("table-headers.unitProductPrice"),
      body: (rowData) => 
        {return formatCurrency(rowData.unit_price)}
      ,
    },
    {
      field:"total_item_price",
      header:t("table-headers.totalProductPrice"),
      filterable: false,
      body: (rowData) => 

        {return formatCurrency(rowData.total_item_price)}
      ,
    },
    // {
    //   field: "checkbox",
    //   filterable: false,
    //   sortable: false,
    //   selectionMode: "multiple",
      
    // },
    {
      filterable: false,
      sortable: false,
      body: (rowItemData) => (
          <Checkbox
            inputId={`selectOneRow-${rowItemData.id}`}
            onChange={(e) => handleItemsSelection(e, rowItemData)}
            checked={selectedItems.some((itemData) => itemData.id === rowItemData.id)}
            style={{ display: "flex", width: "30px", alignItems: "center" }}
          />
      ),
    },
    {
      field: "consolidate",
       body: () => 
        {
        return (
          <span 
            style={{display: "flex", alignItems: "center", alignContent:"center"}}>
                {t("table-headers.consolidate")}
          </span>
        )
      }
      ,
      filterable: false,
      sortable: false,
    },
    
];
export const OrderStatesTableColumns = (orderStates, stateColors) => [
  {
    field: "checkbox",
    filterable: false,
    sortable: false,
    body : ( orderState ) => {
       return (
            <span >{formatDate(orderState?.created_at)}</span>
        );
    }
  },
   {
    field: "checkbox",
    filterable: false,
    sortable: false,
    body : ( orderState ) => {
       return (
            <AuxStatusBadget
                  statusLabel={orderStates
                    .filter((state) => state.code === orderState?.state)
                    .map((matchingState) => (
                      <span
                        key={matchingState?.code}
                        style={{
                          backgroundColor: stateColors[matchingState.code],
                          color: "white",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          margin: "4px 0",
                          display: "flex",
                        }}
                      >
                        {matchingState?.name}
                      </span>
                    ))}
            />
        );
    }
  },
];


