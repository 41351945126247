
export const verifySmsCode = async (smsConfirmationResult, smsCode) => {
    try {
        const userCredential = await smsConfirmationResult.confirm(smsCode);
        const uid = userCredential.user.uid;
        return {success: true, uid, error: null}
    } catch (error) {
        console.error(error);
        return {success: false, error}
    }
};