import styles from './Hero.module.css'
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext'
import CTA from '../CTA/CTA'
import LottieAnimation from '../../LottieAnimation/LottieAnimation';
import HeroAnimation from '../../../assets/LOTTIE/Hero.json'


export default function Hero() {
  const { isDesktop } = useGlobalContext();

  return (
    <div id='hero_sect' className={styles.hero}>
        <div className={styles.value_proposal}>
            <div className={styles.main_title}>Emprende desde casa <span className={styles.logo}>vendiendo y ganando por internet</span> </div>
            <div className={styles.sub_title}><span>Empieza hoy y construye el negocio de tus sueños ❤️</span></div>
            {
                isDesktop && (
                    <CTA label={"CREAR CUENTA"} navigateTo={"/signup"} style={{marginTop:"40px"}}/>
                )
            }
        </div>
        <div className={styles.mvp}>
            <LottieAnimation animationData={HeroAnimation} style={{minWidth:isDesktop?"500px":""}} />
        </div>
        {
            !isDesktop && (
                <CTA label={"CREAR CUENTA"} navigateTo={"/signup"}/>
            )
        }
    </div>
  )
}
