import { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AuxButton from "../../Components/AuxButton/AuxButton";
import StatusLight from "../../Components/StatusLight/StatusLight";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import { useAuthContext } from "../Authcontext/Authcontext";
import useAuth from "../../hooks/useAuth";
const GlobalContext = createContext();

const moneyData = {
  title: "Reintegros",
  total: 69900,
  tableColumns: [
    {
      field: "date",
      header: "Fecha",
      sortable: false,
      filterable: false,
    },
    {
      field: "amount",
      header: "Monto pagado",
      sortable: false,
      filterable: false,
      hasTotal: true,
    },
    {
      field: "state",
      header: "Estado",
      sortable: false,
      filterable: false,
      body: (rowData) => (
        <StatusLight
          color={rowData.stateColor}
          tooltip={rowData.stateTooltip}
        />
      ),
    },
  ],
  tableData: [
    {
      date: "00/00/2023",
      amount: "$ 19.900",
      stateColor: "green",
      stateTooltip: "Pago aprobado",
    },
    {
      date: "00/00/2023",
      amount: "$ 19.900",
      stateColor: "red",
      stateTooltip: "Pago rechazado",
    },
  ],
};
const CustomTableColumns = [
  { field: "rol", header: "Rol" },
  { field: "usuario", header: "Usuario" },
  { field: "fechaCreacion", header: "Fecha de creacion" },
  { field: "ultimoIngreso", header: "Ultimo Ingreso" },
  {
    field: "estado",
    header: "Estado",
    filterable: false, // no se puede filtrar
    sortable: false, // no se puede ordenar
    body: (rowData) => (
      <StatusLight
        color={rowData.estadoColor}
        tooltip={rowData.estadoTooltip}
      />
    ),
    // body: (rowData) => <CustomInputSwitch id={} checked={} onChange={}/>
  },
  {
    header: "Editar",
    filterable: false, // no se puede filtrar
    sortable: false, // no se puede ordenar
    body: (rowData) => (
      <AuxButton
        data={rowData}
        disable={false}
        label={"Eliminar"}
        style={{
          backgroundColor: "var(--main-color)",
          color: "black",
          fontSize: "var(--font-size-h5)",
        }}
      />
    ),
  },
];
const CustomTableData = [
  {
    rol: "Editor",
    usuario: "samuel coworker",
    fechaCreacion: "01/02/2023",
    ultimoIngreso: "02/02/2023",
    estadoColor: "green", // Esto determinará el color del "bombillo"
  },
  {
    rol: "Visualizador",
    usuario: "samuel coworker2",
    fechaCreacion: "03/02/2023",
    ultimoIngreso: "04/02/2023",
    estadoColor: "red", // Esto determinará el color del "bombillo"
  },
  {
    fechaInicio: "15/01/2023",
    nombre: "PlayStation 5",
    fechaLimite: "15/02/2023",
    comprasSuperioresA: "$29.900",
    gananciaAfiliado: "$6.000",
    cuposTotales: "5.000",
    resolucionColjuegos: "SAHDJBAS78QW",
    estadoColor: "orange",
    estadoTooltip: "Pago pendiente",
  },
  {
    fechaInicio: "20/01/2023",
    nombre: "Xbox Series X",
    fechaLimite: "20/02/2023",
    comprasSuperioresA: "$24.900",
    gananciaAfiliado: "$4.500",
    cuposTotales: "7.000",
    resolucionColjuegos: "PKDJSHD87DWE",
    estadoColor: "red",
    estadoTooltip: "Pago rechazado",
  },
  {
    fechaInicio: "20/01/2023",
    nombre: "Xbox Series X",
    fechaLimite: "20/02/2023",
    comprasSuperioresA: "$24.900",
    gananciaAfiliado: "$4.500",
    cuposTotales: "7.000",
    resolucionColjuegos: "PKDJSHD87DWE",
    estadoColor: "",
    estadoTooltip: "",
  },
];
const typeAccountOptions = [
  { id: 0, name: "CORRIENTE" },
  { id: 1, name: "AHORROS" },
];
const bankOptions = [
  { id: 1, name: "BANCO DE BOGOTÁ" },
  { id: 2, name: "BANCO POPULAR" },
  { id: 6, name: "ITAÚ CORPBANCA COLOMBIA S.A." },
  { id: 7, name: "BANCOLOMBIA S.A." },
  { id: 8, name: "NEQUI" },
  { id: 9, name: "CITIBANK COLOMBIA" },
  { id: 12, name: "GNB SUDAMERIS S.A." },
  { id: 13, name: "BBVA COLOMBIA" },
  { id: 42, name: "COLPATRIA" },
  { id: 23, name: "BANCO DE OCCIDENTE" },
  { id: 9, name: "BANCO CAJA SOCIAL - BCSC S.A." },
  { id: 43, name: "BANCO AGRARIO DE COLOMBIA S.A." },
  { id: 39, name: "BANCO DAVIVIENDA S.A." },
  { id: 49, name: "BANCO AV VILLAS" },
  { id: 53, name: "BANCO W S.A." },
  { id: 14, name: "BANCO CREDIFINANCIERA S.A.C.F" },
  { id: 57, name: "BANCO PICHINCHA S.A." },
  { id: 54, name: "BANCOOMEVA" },
  { id: 56, name: "CMR FALABELLA S.A." },
  { id: 55, name: "BANCO FINANDINA S.A." },
  { id: 59, name: "BANCO SANTANDER DE NEGOCIOS COLOMBIA S.A." },
  { id: 58, name: "BANCO COOPERATIVO COOPCENTRAL" },

];
const typeIdUserOptions = [
  { id: 0, name: "CEDULA" },
  { id: 1, name: "TARJETA DE IDENTIDAD" },
  { id: 2, name: "DOCUMENTO EXTRANJERO" },
  { id: 3, name: "NIT" },
];
const typeEntitys = [
  { id: 1, name: "PERSONA NATURAL" },
  { id: 2, name: "PERSONA JURIDICA" },
];
const shippingAddresTypes = [
  { code: 0, name: "EN DIRECCION" },
  { code: 1, name: "EN OFICINA TRANSPORTADORA" },
  { code: 2, name: "EN GUARNICION MILITAR O BATALLON" },
  { code: 3, name: "EN KILOMETRO" },
];

const coworkerStates = [
  { id: 0, name: "ACTIVO", color: "green" },
  { id: 1, name: "INACTIVO", color: "red" },
];
const VIDEO_SHOPIFY_PRODUCT_TOKEN_TUTORIAL = ""
const VIDEO_SHOPIFY_ORDERS_ENDPOINT_TUTORIAL = ""
const VIDEO_SHOPIFY_CHECK_ORDERS__TUTORIAL = ""
const defaultCoverImage = 'https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/imagenes_predeterminadas%2Ffondo-gris-claro-liso-liso_8087-1190.avif?alt=media&token=3b786009-3806-43bc-bbbb-f33b28cd7df1'; // Imagen predeterminada



function GlobalProvider({ children }) {

  // Constante 
  const isDesktop = window.innerWidth > 992;

  
  const desktopSidebarWidth = "400px";
  const currentPage = useLocation().pathname.split("/")[1];
  const countries = [
    { name: "Colombia", code: "+57" },
    // { name: "Estados Unidos", code: "+1" },
    // { name: "Brasil", code: "+55" },
    // { name: "México", code: "+52" },
    // { name: "Perú", code: "+51" },
    // { name: "Ecuador", code: "+593" },
    // { name: "Chile", code: "+56" },
  ];
  const [shoppingCart, setShoppingCart] = useState([]);
  
    const addProductToShoppingCart = (hasEdit, productItem) => {
    if ( hasEdit ){
      const copyCartValues = [...shoppingCart];
      const filteredValues = copyCartValues.filter((cartItem) => cartItem.id !== productItem.id);
      setShoppingCart([...filteredValues, productItem]);
    }else {
      setShoppingCart((prevData) => [...prevData, productItem]);
    }
  }
  // console.log("Productos del carrito", shoppingCart)
  const deleteProductToShoppingCart = (productItem) => {
    const copyCartValues = [...shoppingCart];
    const filteredValues = copyCartValues.filter((cartItem) => cartItem.id !== productItem.id);
    setShoppingCart(filteredValues)
  }
  const platforms = [
    {name: "Shopify", code : 2}
  ]
  const unityTypes = [
    {name: "Porcentage", code: 0},
    {name: "Monto neto", code: 1}
  ]
  const coverTypes = [
    {name: "Imagen", code: 0, string: "img"},
    {name: "Video", code: 1, string: "video"}
  ]
  const collectionTypes = [
    {name: "Por categoria", code: 0, string: "byCategorie" },
    {name: "Por tag", code: 1, string: "byTag"}
  ]
  // Codigos de divisa ISO 4217
  const currencyTypes = [
    {name: "Peso Colombiano", code: "170", string: "COP"},
    {name: "Real Brasileño", code: "070", string: "BRL"},
    {name: "Dolar Estadounidense", code: "840", string: "USD" },
    {name: "Euro", code: "978", string: "EUR"},
  ]
  const languages = [
    {name: "Español", code: 0},
  ]
  const promotionTypes = [
    { code: 0, name: "Codigo" },
    { code: 1, name: "Volumen"},
    { code: 2, name: "Upsell"},
    { code: 3, name: "Downsell"},
    { code: 4, name: "Crosell"},
    { code: 5, name: "Shipping"},
  ];

  const orderAttributes = [
    { code: 1, name: "Numero de Guia"},
    { code: 2, name: "Numero de pedido"},
  ];
  const eligibleLevels = [
    {code: 0, name: "Orden"},
    {code: 1, name: "Item"}
  ];
  const orderStates = [
    { code: 1, name: "Recibido"},
    { code: 2, name: "En preparación"},
    { code: 3, name: "Empacado"},
    { code: 4, name: "Despachado"},
    { code: 5, name: "Entregado"},
    { code: 6, name: "Desembolsado"},
    { code: 7, name: "Devuelto"},
    { code: 8, name: "Cancelado"},
    { code: 9, name: "En devolucion"}
  ];

  const subscriptionStates = [
    { code: 1, name: "Creado"},
    { code: 2, name: "Activo"},
    { code: 3, name: "Expirado"},
    { code: 4, name: "Pausado"},
  ];

  
  const stateColors = {
    0: "white",
    1: "#2874A6",
    2: "#e5be01",
    3: "purple",
    4: "blue",
    5: "green",
    6: "grey",
    7: "#013220",
    8: "red",
    9: "#804000",
  };

  const stateLabelColors = {
    0: "white",
    1: "white",
    2: "black",
    3: "white",
    4: "white",
    5: "white",
    6: "white",
    7: "black",
    8: "white",
    9: "white",
  };


  const shippingCompanies = [
    { code: 1, name: "Coordinadora"},
    // { code: 2, name: "Envia"},
    { code: 3, name: "Interrapidisimo"},
  ];
  const orderPaymentStatus = [
    { code: 1, name: "Pendiente"},
    { code: 2, name: "Aprobado"},
    { code: 3, name: "Rechazado"},
  ];
  const reasonsToCancelAnOrder = [
    { code: 1, name: "Cliente baneado"},
    { code: 2, name: "Pedido duplicada"},
    { code: 3, name: "Cancelado por el cliente"},
    { code: 4, name: "Compra de productos sin stock"},
    { code: 5, name: "Cliente problematico"},
    { code: 6, name: "Destino sin cobertura"},
  ];
  const withdrawalRequestNews = [
    {code: 0, name: "Solicitud pendiente"},
    {code: 1, name: "Solicitud aprobada"},
    {code: 2, name: "Solicitud rechazada"},
  ];
  const coordinadoraShippingNews = [
    { code: 100, name: "Faltante de la unidad(s) en el transporte" },
    { code: 200, name: "Deterioro en transporte" },
    { code: 300, name: "Faltante interno en transporte" },
    { code: 500, name: "Novedad en Carretera" },
    { code: 501, name: "Envio aprehendido por Autoridad Competente en transporte" },
    { code: 507, name: "Vehiculo Varado / Accidentado / Falla apertura puerta seguridad" },
    { code: 510, name: "Revision por autoridad competente" },
    { code: 601, name: "Instrucción del remitente de detener el envio" },
    { code: 602, name: "Novedad para entregar en Terminal CM" },
    { code: 605, name: "Poblacion de destino que no se cubre" },
    { code: 610, name: "Sobrante de la unidad(es)" },
    { code: 701, name: "Se Visita, no se logra entrega" },
    { code: 702, name: "No se localiza direccion del destinatario o esta incompleta" },
    { code: 703, name: "En direccion de entrega no conocen destinatario o se traslado" },
    { code: 705, name: "En direccion de entrega no conocen al remitente" },
    { code: 706, name: "No se entrega, destinatario solicita otra direccion" },
    { code: 713, name: "No se entrega, destinatario solicita inventario, unidades selladas" },
    { code: 717, name: "Destinatario persona juridica confirma no recibe sabados" },
    { code: 720, name: "Se entrega el despacho, guia en poder del destinatario" },
    { code: 722, name: "Se entrega en otra direccion, se recupera" },
    { code: 728, name: "Cita previa con destinatario para entregar el envio (sujeto a Malla)" },
    { code: 733, name: "Falta de Tiempo" },
    { code: 736, name: "No se entrega dest no devuelve unidad cambio mano a mano" },
    { code: 750, name: "Se Visita, no reciben por Cuarentena" },
    { code: 800, name: "Informacion soporte del envio con novedad (OC / fact / rem)" },
    { code: 803, name: "No recibe pedidos parciales" },
    { code: 821, name: "Dest no recibe hasta remitente no recoja devolucion" },
    { code: 823, name: "Devolucion Rte no autorizada por el destinatario" },
    { code: 824, name: "No se entrega, el destinatario no cancela el Flete. FCE" },
    { code: 827, name: "No se asigna para entrega, fletes previos no cancelados" },
    { code: 828, name: "No se entrega, el destinatario no cancela el valor a recaudar. RCE" },
    { code: 830, name: "Valor a Recaudar diferente Facturado por Cliente" },
    { code: 831, name: "En adecuacion unidad, por deterioro de empaque externo (Inventario en CM)" },
    { code: 832, name: "El Destinatario no suministra el codigo de seguridad" },
    { code: 999, name: "Caso cerrado Direccion General" },
    { code: 1001, name: "Cambio de valor propuesta por DIAN" },
    { code: 1002, name: "Cambios de modalidad de importacion" },
    { code: 1101, name: "Pendiente pago de impuestos" },
    { code: 1102, name: "Retorno autorizado" },
    { code: 1103, name: "Retorno automatico" },
    { code: 1104, name: "Nacionalización con agente aduanero (Broker)" },
    { code: 1105, name: "Pendiente por documentos proceso de nacionalizacion" },
    { code: 1106, name: "Revision Aduana Pais Destino" }
  ];
  const enviaShippingNews = [
    { code: 21, name: "Mercancia debe ser entregada en otra direccion"},
    { code: 31, name: "No conocen destinatario en direccion destino" },
    { code: 34, name: "Direccion destinatario no existe" },
    { code: 35, name: "Destinatario se traslado" },
    { code: 36, name: "Direccion de destino corresponde a otra ciudad" },
    { code: 40, name: "Guia sin direccion destinatario " },
    { code: 44, name: "Destinatario no conoce al remitente" },
  ];
  const stateNews = [
    {code: 1, name: "Sin resolver"},
    {code: 2, name: "Solucion rechazada"},
    {code: 3, name: "Solucion aprobada"},
  ]
  const shippingSolutions = [
    { code: 1, name: "Cambio de Direccion"},
    { code: 2, name: "Cambio de fecha de Entrega"},
    { code: 3, name: "Cancelar la Entrega"},
    { code: 4, name: "Cambio de Direccion a otra sede (cambio de Direccion y Destinatario"},
  ];

  const whatsappPhone = "+573054074246";
  const { userData, catalogData} = useAuthContext();
  const messageWhatsappText = userData?.uid ? `*ID USUARIO :* ${userData?.uid} *NOMBRE USUARIO :* ${userData?.user_full_name}` : "*USUARIO NO REGISTRADO*";
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${whatsappPhone}&text=${encodeURIComponent(messageWhatsappText)}%20`;
  const { getLastSession } = useAuth();
  const { updateDocument } = useFirebaseCRUD();

  const waUrlHook = (type, phone, message) => {
    let waUrl = null;
    if (type === "withPhone") {
      waUrl = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(message)}%20`;
    } else if (type === "withoutPhone"){
      waUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}%20`;
    }
    if (waUrl) {
      window.open(waUrl, '_blank');

    }
  } 

  useEffect(() => {
    if (userData && userData.user_custom_claims) {
      const { updateSession, timestamp } = getLastSession(userData);
      try {
        if ( updateSession) {
          if (userData.user_custom_claims?.is_master && userData.user_custom_claims?.is_insider && userData.user_custom_claims?.type === "master") {
            updateDocument("master_user", userData?.uid, {
              last_session: timestamp,
            });
          } else if (!userData.user_custom_claims?.is_master && userData.user_custom_claims?.is_insider && userData.user_custom_claims?.type === "insider") {
            updateDocument("insider_users", userData?.uid, {
              last_session: timestamp,
            });

          } else if (!userData.user_custom_claims?.is_master && !userData.user_custom_claims?.is_insider && userData.user_custom_claims?.type === "seller") {
            updateDocument("seller_users", userData?.uid, {
              last_session: timestamp,
            });
          }
        }
      } catch (error) {
        console.error("Error al actualizar la ultima sesion", error);
      }
    }
  }, [userData]);

  
  return (
    <GlobalContext.Provider
      value={{
        isDesktop,
        desktopSidebarWidth,
        currentPage,
        countries,
        typeAccountOptions,
        bankOptions,
        typeIdUserOptions,

        moneyData,
        CustomTableColumns,
        CustomTableData,
        coworkerStates,
        platforms,
        promotionTypes,
        unityTypes,
        VIDEO_SHOPIFY_PRODUCT_TOKEN_TUTORIAL,
        VIDEO_SHOPIFY_ORDERS_ENDPOINT_TUTORIAL,VIDEO_SHOPIFY_CHECK_ORDERS__TUTORIAL,
        orderStates,
        shippingCompanies,
        orderAttributes,
        stateColors,
        typeEntitys,
        shippingAddresTypes,
        reasonsToCancelAnOrder,
        eligibleLevels,
        withdrawalRequestNews,
        currencyTypes,
        languages,
        coverTypes,
        collectionTypes,
        defaultCoverImage,

        shoppingCart,
        setShoppingCart,
        addProductToShoppingCart,
        deleteProductToShoppingCart,
        whatsappUrl,
        coordinadoraShippingNews,
        stateNews,
        shippingSolutions,
        enviaShippingNews,
        orderPaymentStatus,
        subscriptionStates,
        waUrlHook,
        catalogData,
        whatsappPhone,
        stateLabelColors,
        
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

function useGlobalContext() {
  const context = useContext(GlobalContext);

  if (!context) {
    throw new Error(
      "useGlobalContext debe ser usado dentro de un GlobalProvider"
    );
  }

  return context;
}

export { GlobalProvider, useGlobalContext };