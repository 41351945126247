import { BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn.jsx";
import { useTranslation } from "react-i18next";
import CustomTable from "../../Components/CustomTable/CustomTable.jsx";
import AuxButton from "../../Components/AuxButton/AuxButton.jsx";
import { useEffect, useState } from "react";
// import MainButton from "../../Components/MainButton/MainButton.jsx";
import { Toast } from "primereact/toast";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import CustomModal from "../../Components/CustomModal/CustomModal.jsx";
import DetailSuscriptionForm from "../../Components/DetailSuscriptionForm/DetailSuscriptionForm.jsx";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import useShopifyRequest from "../../hooks/useShopifyRequest.js";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import moment from "moment";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect.jsx";
import toastFunction from "../../utils/toastFunction.js";
import getOnsnapshotByQuery from "../../utils/getOnsnapshotByQuery.js";
import CustomInputText from "../../Components/FormComponents/CustomInputText/CustomInputText.jsx";
import SecondaryButton from "../../Components/SecondaryButton/SecondaryButton.jsx";

// Componente de pagina que muestra las promociones asociadas a las tiendas de Shoppex
export default function Subscriptions() {
  const subscriptionsColl = collection(db, 'subscriptions');
  const { userData, userSellerOwnerData, isInsider } = useAuthContext();
  const { t } = useTranslation("Subscriptions");
  const { toast } = useShopifyRequest();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const [promotions, setSubscripcions] = useState([]);

  const [visible, setVisible] = useState(false);
  const [selectedSubscription, setselectedSubscription] = useState();
  
  const userOwnerStores = (
    userSellerOwnerData && !isInsider ? userSellerOwnerData.seller_stores :
    !isInsider ? userData.seller_stores :
    []
  )?.filter(store => store?.platform?.code === 1) || [];
  
  const [sellerStoresWithGenericOptions, setSellerStoresWithGenericOptions] = useState([
    ...userOwnerStores,
    { id: 1, name: "Todas las tiendas" }
  ]);
  const storesIds = sellerStoresWithGenericOptions?.filter((store) => store?.platform?.code === 1).map((store) => store.id);
  // console.log(storesIds, sellerStoresWithGenericOptions)

  const [storeSelectedName, setStoreSelectedName] = useState( { id: 1, name: "Todas las tiendas" } );

  const [unsubscribeSubscriptionsSnapshot, setUnsubscribeProductsSnapshot] = useState(null);
  const [ sellerBusinessId, setSellerBusinessId ] = useState("");
  
  // Definición de columnas personalizadas para una tabla
  const CustomSuscriptionsTableColumns = [
    {
      field: "related_customer_id",
      header: t("table-headers.relatedCustomerId"),
      filterable: false,
      sortable: false,
    },
    {
      field: "related_plan_id",
      header: t("table-headers.relatedPlanId"),
      filterable: false,
      sortable: false,
    },
    {
      field: "month_price",
      header: t("table-headers.monthPrice"),
      filterable: false,
      sortable: false,
    },
    {
      field: "start_date",
      header: t("table-headers.startDate"),
      filterable: false,
      body: (rowData) => {
        const createdAt = rowData.start_date
          ? moment.unix(rowData.start_date.seconds).format(t("table-headers.dateFormat"))
          : t("table-headers.invalidDateFormat");
        return <span>{createdAt}</span>;
      },
    },
    {
      field: "end_date",
      header: t("table-headers.endDate"),
      filterable: false,
      body: (rowData) => {
        const createdAt = rowData.end_date
          ? moment.unix(rowData.end_date.seconds).format(t("table-headers.dateFormat"))
          : t("table-headers.invalidDateFormat");
        return <span>{createdAt}</span>;
      },
    },
    {
      field: "last_state_date",
      header: t("table-headers.lastStateDate"),
      filterable: false,

      // Función para formatear la fecha de creación
      body: (rowData) => {
        const createdAt = rowData.last_state_date
          ? moment.unix(rowData.last_state_date.seconds).format(t("table-headers.dateFormat"))
          : t("table-headers.invalidDateFormat");
        return <span>{createdAt}</span>;
      },
    },
    {
      // Columna especial para editar
      header: "Editar",
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <AuxButton
          data={rowData}
          disable={false}
          label={t("edit")}
          style={{
            backgroundColor: "var(--main-color)",
            color: "white",
            fontSize: "var(--font-size-h5)",
          }}
          onClick={openPromotionModal}
        />
      ),
    },
  ];
  // Función para abrir el modal de promocion y configurar el estado según la tienda seleccionada
  const openPromotionModal = (store) => {
    setVisible(true);
    if (store.id) {
      setselectedSubscription(store);
    } else {
      setselectedSubscription({});
    }
  };

  // Manejador de eventos cuando se selecciona una tienda
  const handleStoreSelect = async (selectedStore) => {
    setGlobalLoading(true);
    setStoreSelectedName(selectedStore);
    try {
      if (unsubscribeSubscriptionsSnapshot && typeof unsubscribeSubscriptionsSnapshot === 'function') {
        unsubscribeSubscriptionsSnapshot();
        setUnsubscribeProductsSnapshot(null);
      }
      let storesQuery;
      if (selectedStore.id === 1) {
        // console.log("Selecciono todas las tiendas");
        storesQuery = query(subscriptionsColl, where('related_store_id', 'in', storesIds));
      } else {
        // console.log("Selecciono una tienda", selectedStore);
        storesQuery = query(subscriptionsColl, where('related_store_id', '==', selectedStore.id));
      }

      const unsubscribe = getOnsnapshotByQuery(storesQuery, (promotionsData) => {
        setSubscripcions(promotionsData);
      }, toast);

      setUnsubscribeProductsSnapshot(() => {
        // Desuscribirse de la suscripción actual si existe
        if (unsubscribeSubscriptionsSnapshot && typeof unsubscribeSubscriptionsSnapshot === 'function') {
          unsubscribeSubscriptionsSnapshot();
        }
        return unsubscribe;
      });
    } catch (error) {
      toastFunction(toast, 'error', t('errorQuery'), error.message, 2000);
      return false;
    } finally {
      setGlobalLoading(false);
    }
  };

  const loadInitialSubscriptions = async () => {
    if (storesIds?.length > 0) {
      try {
        if (unsubscribeSubscriptionsSnapshot && typeof unsubscribeSubscriptionsSnapshot === 'function') {
          unsubscribeSubscriptionsSnapshot();
          setUnsubscribeProductsSnapshot(null);
        }
  
        // Definir la consulta en función de si es insider o no
        let subscriptionQuery;
        if (isInsider) {
          subscriptionQuery = subscriptionsColl;
        } else {
          subscriptionQuery = query(subscriptionsColl, where('related_store_id', 'in', storesIds));
        }
  
        // Obtener los datos usando la función getOnsnapshotByQuery
        const unsubscribe = getOnsnapshotByQuery(subscriptionQuery, (promotionsData) => {
          setSubscripcions(promotionsData);
        }, toast);
  
        // Guardar la función de desuscripción
        setUnsubscribeProductsSnapshot(() => {
          // Desuscribirse de la suscripción actual si existe
          if (unsubscribeSubscriptionsSnapshot && typeof unsubscribeSubscriptionsSnapshot === 'function') {
            unsubscribeSubscriptionsSnapshot();
          }
          return unsubscribe;
        });
  
      } catch (error) {
        console.error('Error al obtener productos:', error);
      }
      setGlobalLoading(false);
    }
  };
  
  const getSellerByBusinessId = async () => {
    try {
      const sellerColl = collection(db, "seller_users");
      const querySellerByBusinessId = query(sellerColl, where("business_id", "==", sellerBusinessId), where("isAdmin", "==", true));
      const querySnapshot = await getDocs(querySellerByBusinessId);
  
      if (!querySnapshot.empty) {
        const sellerDocData = querySnapshot.docs[0].data(); // Suponiendo que sólo necesitas el primer documento
        const sellerStores = sellerDocData?.seller_stores?.filter(store => store?.platform?.code === 1)
        if(sellerStores.lenght === 0) {
          toastFunction(toast, "warn", "El negocio no tiene tiendas shoppex asociadas", "", 5000)
          setSellerStoresWithGenericOptions([]);
        } else {
          toastFunction(toast, "success", "Tiendas encontradas con exito ", "", 3000)
          setSellerStoresWithGenericOptions([
            ...sellerStores,
            { id: 1, name: "Todas las tiendas" }
          ]);
        }
        
        // console.log("Encontré el documento:", sellerDocData, sellerStoresWithGenericOptions);

      } else {
        toastFunction(toast, "warn", "No se encontró ningún documento con el identificador del negocio proporcionado", "", 3000)

        // console.log("No se encontró ningún documento con el business_id proporcionado.");
      }
    } catch (error) {
      toastFunction(toast, "warn", "Error obteniendo el documento", "", 3000)
    }
  };
 

  useEffect(() => {
    loadInitialSubscriptions();
  }, [userData, userSellerOwnerData]);

  return (
    <div className="mb-20">
      <div style={{ marginBottom: "40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtn")} />
      </div>
      <div className="mb-12">
        {isInsider && (
         <div className="grid gap-4 mb-12">
         <CustomInputText
          floatLabel={true}
          type={"text"}
          value={sellerBusinessId}
          placeholder={t("input-placeholders.sellerBusinessId")}
          onChange={(e) =>  setSellerBusinessId(e.target.value)}
          disabled={globalLoading}        
          required={true}
        />
          <SecondaryButton
            onClick={getSellerByBusinessId}
            type={"button"}
            label={t("findStores")}
            disabled={ isInsider && sellerBusinessId === ""}
            autoFocus={true}
          />
         </div> 
      )
        }
        <CustomSelect
            floatLabel={true}
            optionLabel={"name"}
            options={sellerStoresWithGenericOptions}
            value={storeSelectedName}
            placeholder={t("input-placeholders.platform")}
            onChange={(e) => handleStoreSelect(e.value)}
            required={true}
            disabled={ isInsider && sellerBusinessId === ""}
          />
      </div>
        <CustomTable className="custom-table" columns={CustomSuscriptionsTableColumns} data={promotions} action={() => ({})} />
      <div>
        <CustomModal
          visible={visible}
          setVisible={setVisible}
          object={selectedSubscription}
          editMode={t("updateSubscription")}
          createMode={t("createSubscription")}
          content={
            <DetailSuscriptionForm
              onClose={setVisible}
              selectedSubscription={selectedSubscription}
              userData={userSellerOwnerData || userData}
              labelDelete={t("deleteSubscription")}
              labelSuccess={t("createSubscription")}
              toast={toast}
            />
          }
          onClose={() => setVisible(false)}
        />
      </div>

      {/* <div style={{ marginTop: "100px" }}>
        <MainButton
          loading={globalLoading}
          label={t("createSubscription")}
          onClick={openPromotionModal}
        />
      </div> */}
      <Toast ref={toast} />
    </div>
  );
}
