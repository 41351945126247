import { useEffect } from 'react';

const useResize = (
    setIsMobile
) => {
    
    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth < 767);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
};

export default useResize;
