import PropTypes from 'prop-types';
import styles from './DetailOptionCard.module.css';
import AuxStatusBadget from '../AuxStatusBadget/AuxStatusBadget';
import { IoIosArrowForward } from 'react-icons/io';

export default function DetailOptionCard({
  bold_text,
  regular_text,
  statusLabel,
  onClick,
  hidde_btn,
}) {
  return (
    <div className="clickable" onClick={onClick}>
      <div className={styles.DetailOptionCard}>
        <div className={styles.textContent}>
          <div className={styles.bold_text}>{bold_text}</div>
          <div className={styles.regular_text}>{regular_text}</div>
        </div>
        {!hidde_btn && (
          <div className={styles.statusBadget}>
            <AuxStatusBadget
              statusLabel={statusLabel}
              style={{
                backgroundColor: 'var(--main-light-color)',
                color: 'var(--main-color)',
              }}
            />
            <IoIosArrowForward style={{ marginLeft: '10px' }} className="icon" />
          </div>
        )}
      </div>
    </div>
  );
}

DetailOptionCard.propTypes = {
  bold_text: PropTypes.string.isRequired,
  regular_text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  statusLabel: PropTypes.string,
  onClick: PropTypes.func,
  hidde_btn: PropTypes.bool,
};
