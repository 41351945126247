import { BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn.jsx";
import { useTranslation } from "react-i18next";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect.jsx";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import MainButton from "../../Components/MainButton/MainButton.jsx";
import { useEffect, useRef, useState } from "react";
import CustomTable from "../../Components/CustomTable/CustomTable.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import { ToggleButton } from "primereact/togglebutton";
import useShopifyRequest from "../../hooks/useShopifyRequest.js";
import amountStoresIds from "../../utils/amountStoresIds.js";
import { Toast } from "primereact/toast";
import { getConsolidatedOrders, getConsolidatedOrdersAdmin, getOrdersToConsolidate, getOrdersToConsolidateAdmin, getSellerUsersOwners } from "../MyOrders/scripts/getOrders.js";
import { OverlayPanel } from "primereact/overlaypanel";
import sortArrayByCreatedAt from "../../utils/sortArrayByCreatedAt.js";
import { OrderLineItemsTableColumns, OrderStatesTableColumns, ConsolidatedOrderTableColumns } from "./ConsolidatedOrderTableColumns.jsx";
import { getPaginatedProducts } from "../../utils/getPaginatedProducts.js";
import { generateRandomId } from "../../utils/generateRandomId.js";
import toastFunction from "../../utils/toastFunction.js";
import { fetchFromCloudFunction } from "../../services/cloudFunctinons/fetchFromCloudFunction.js";
import CustomConfirmDialog from "../../Components/OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import ConsolidatedOrderCard from "./ConsolidatedOrderCard.jsx";

const ConsolidatedOrders = () => {
  const { t } = useTranslation("ConsolidatedOrders");
  const { userData, userSellerOwnerData, isInsider } = useAuthContext();

  const { toast } = useShopifyRequest();
  const userOwnerData = userSellerOwnerData || userData;
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { orderStates, stateColors, orderPaymentStatus, shippingCompanies } = useGlobalContext();
  const userStores = userOwnerData?.seller_stores ? [...userOwnerData.seller_stores, { id: 1, name: "Todas las tiendas" }] : [...userData.seller_stores, { id: 1, name: "Todas las tiendas" }];

  const [ amountSellerStoresIds, setAmountSellerStoresIds ] = useState();
  const [ selectedStore, setSelectedStore] = useState({ id: 1, name: "Todas las tiendas" });
  const [ selectedSeller, setSelectedSeller ] = useState(null);

  const [ selectedOrders, setSelectedOrders ] = useState([]);


  const [ checkRelatedSuplierIds, setCheckRelatedSupplierIds ] = useState(false);

  const [ unsubscribeOrderStateSnapshot, setUnsubscribeOrderStateSnapshot ] = useState();
  const [ unsubscribeConsolidatedOrderStateSnapshot, setUnsubscribeConsolidatedOrderStateSnapshot ] = useState();

  const [ zonesData, setZonesData ] = useState([]);
  const [ ordersData, setOrdersData ] = useState([]);
  const [ sellerUsersData, setSellerUsersData ] = useState([]);
  const [ productsData, setProductsData ] = useState([]);
  const [ consolidatedOrdersData, setConsolidatedOrdersData ] = useState([]);
  const [ selectedOrderRowData, setSelectedOrderRowData ] = useState();
  
  const [selectedItems, setSelectedItems] = useState([]);

  const consolidatedOrderInitialValues = {
    class: 0,
    related_orders_ids: [],
    commercial_partner_id: !isInsider ? userOwnerData?.business_id : selectedSeller?.business_id, // "Bussines id del dueño del pedido"
    created_at: new Date(),
    disbursed_is_done: false, 
    id: generateRandomId(),
    related_disbursement_id: null,
    related_individual_orders: null,
    states: [
        {
          date_state: new Date(),
          state: "Entregado parcialmente", 
        }
    ],
    type: 0,
    update_wallets: false,
    products : []
  };
  
  const [consolidatedOrderValues, setConsolidatedOrderValues] = useState(consolidatedOrderInitialValues);

  const [visibleConfirmConsolidateAllItemsDialog, setVisibleConfirmConsolidateAllItemsDialog] = useState(false);

  
  // Referencias del panel de superposicion
  const opItems = useRef(null);
  const opOrderStatus = useRef(null);


  // Maneja la selección de una tienda basado en el id y actualiza los estados correspondientes
  const handleStoreSelection = (store) => {
    setSelectedStore(store);
    if (store.id !== 1) {
      const filteredStore = userStores.find((stor) => stor.id === store.id);
      const filteredStorArray = [filteredStore];
      const arrayStoresIds = amountStoresIds(filteredStorArray);
      setAmountSellerStoresIds(arrayStoresIds.storesIds);
    } else {
      const arrayStoresIds = amountStoresIds(userStores);
      const arrayWithoutDefaultOption = arrayStoresIds.storesIds.filter((id) => {
        return id !== 1;
      });
      setAmountSellerStoresIds(arrayWithoutDefaultOption);
    }
  };
  // Maneja la selección de una tienda basado en el id y actualiza los estados correspondientes
  const handleSellerSelection = (userSeller) => {
    setSelectedSeller(userSeller);
    setConsolidatedOrderValues((prevValues) => {
        return {
          ...prevValues,
          commercial_partner_id: userSeller.business_id,
        }
    })
  };
  // Maneja la selección de los items consolidalos en la orden
  const updateSelectedOrdersIds = (products, rowData) => {
    // console.log("VALIDACION updateSelectedOrdersIds", products, rowData.id)
    // Verificar si algún producto en la lista tiene el related_order_id correspondiente
    const hasRelatedOrder = products.some((item) => item.related_order_id === rowData.id);
  
    setSelectedOrders(prevSelectedOrders => {
      // Si no hay productos con related_order_id igual al rowData.id
      if (!hasRelatedOrder) {
        // console.log("La orden no esta")
        // Filtrar las órdenes para eliminar la que ya no tiene productos relacionados
        const updatedSelectedOrders = prevSelectedOrders.filter(order => order.id !== rowData.id);
        setConsolidatedOrderValues(prevValues => ({
          ...prevValues,
          related_orders_ids: updatedSelectedOrders.map(order => order.id) // Actualizar related_orders_ids con las IDs actuales
        }));
        return updatedSelectedOrders;
      } else {
        // console.log("La orden si esta")
        // Si hay productos con related_order_id igual al rowData.id
        // Asegurarse de que la orden esté en la lista de órdenes seleccionadas
        const updatedSelectedOrders = [...prevSelectedOrders];
        if (!updatedSelectedOrders.find(order => order.id === rowData.id)) {
          updatedSelectedOrders.push(rowData);
        }
        setConsolidatedOrderValues(prevValues => ({
          ...prevValues,
          related_orders_ids: updatedSelectedOrders.map(order => order.id) // Actualizar related_orders_ids con las IDs actuales
        }));
        return updatedSelectedOrders;
      }
    });
  };
  

  // Abre el panel de superposición de elementos con los Items de la orden seleccionada
  const openItemsOverlayPanel = ({rowData, e}) => {
    // Filtrar los items de la fila para que solo muestre items relacionados con el usuario
    let event = e;
    // updateSelectedOrdersIds(consolidatedOrderValues.products, rowData)

    // Guardar los datos de la orden seleccionada en el estado
    setSelectedOrderRowData(rowData);
    // Mostrar el OverlayPanel
    if (opItems.current) {
        opItems.current.toggle(event);
    }
};
  // Abre el panel de superposición de elementos con los estados de la orden seleccionada
  const openOrderStatusOverlayPanel = async (rowData, e) => {
    setSelectedOrderRowData(rowData); // Guardar los datos de la orden seleccionada en el estado
    opOrderStatus.current.toggle(e); // // Abre el overlay
  };

  const openConsolidateItemsDialog = async (rowData) => {
    setSelectedOrderRowData(rowData); // Guardar los datos de la orden seleccionada en el estado
    setVisibleConfirmConsolidateAllItemsDialog(true);
  };
  
  const handleItemsSelectionBulk = (e, itemsArray, rowData) => {
    const relatedOrderData = rowData || selectedOrderRowData;
    // console.log("validacion bulk", e, itemsArray, relatedOrderData);
  
    let _selectedItems = [...selectedItems];
    let updatedItems = [...consolidatedOrderValues.products];
  
    // Función para procesar cada elemento
    const processItem = (item) => {
      if (item.id && item.related_order_id && item.quantity) {
        const { id, sku, sku_title, variation_id, product_id, product_title, product_label, quantity, related_order_id } = item;
        const variationValues = {
          id: id,
          sku: sku,
          sku_label: sku_title,
          sku_req_qty: quantity,
          variation_id: variation_id,
        };
  
        // Buscar si ya existe un producto con el mismo product_id y related_order_id
        const existingProductIndex = updatedItems.findIndex(
          (product) => product.product_id === product_id && product.related_order_id === related_order_id
        );
        if (existingProductIndex !== -1) {
          updatedItems[existingProductIndex].variations.push(variationValues);
        } else {
          const newItem = {
            id: generateRandomId(),
            product_id: product_id,
            related_order_id: related_order_id,
            product_label: product_title || product_label,
            variations: [variationValues],
          };
          updatedItems.push(newItem);
        }
      } else {
        return;
      }
    };
  
    // Procesar itemsArray
    itemsArray.forEach(processItem);
  
    // Actualizar los valores consolidados del pedido
    setConsolidatedOrderValues((prevValues) => ({
      ...prevValues,
      products: updatedItems,
    }));
  
    // Actualizar los elementos seleccionados
    if (e.checked) {
      _selectedItems = _selectedItems.concat(itemsArray);
    } else {
      _selectedItems = _selectedItems.filter((item) => !itemsArray.some((arrayItem) => arrayItem.id === item.id));
    }
  
    updateSelectedOrdersIds(_selectedItems, relatedOrderData);
  
    // console.log("itemsArray", itemsArray);
    // console.log("_selectedItems", _selectedItems);
  
    setSelectedItems(_selectedItems);
  };
  

  // Función para manejar la consulta de pedidos
 const handleConsultOrders = async () => {
  // Desuscribirse de la suscripción actual si existe
  if (unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function") {
    unsubscribeOrderStateSnapshot();
    setUnsubscribeOrderStateSnapshot(null);
  }

  // Desuscribirse de la suscripción consolidada actual si existe
  if (unsubscribeConsolidatedOrderStateSnapshot && typeof unsubscribeConsolidatedOrderStateSnapshot === "function") {
    unsubscribeConsolidatedOrderStateSnapshot();
    setUnsubscribeConsolidatedOrderStateSnapshot(null);
  }

  // Iniciar la nueva suscripción
  let unsubscribeOrders;
  let unsubscribeConsolidatedOrders;

  if (isInsider) {
    unsubscribeOrders = await getOrdersToConsolidateAdmin(selectedSeller?.business_id, setZonesData, setOrdersData, toast, t);
    unsubscribeConsolidatedOrders = await getConsolidatedOrdersAdmin(selectedSeller?.business_id, setConsolidatedOrdersData);
  } else {
    unsubscribeOrders = await getOrdersToConsolidate(checkRelatedSuplierIds ? amountSellerStoresIds : null, setZonesData, setOrdersData, userOwnerData.business_id, toast, t);
  }
   
  // Establecer la nueva función de desuscripción
  setUnsubscribeOrderStateSnapshot(() => {
    // Desuscribirse de la suscripción actual si existe
    if (unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function") {
      unsubscribeOrderStateSnapshot();
    }
    return unsubscribeOrders;
  });

  if (isInsider) {
    setUnsubscribeConsolidatedOrderStateSnapshot(() => {
      // Desuscribirse de la suscripción consolidada si existe
      if (unsubscribeConsolidatedOrderStateSnapshot && typeof unsubscribeConsolidatedOrderStateSnapshot === "function") {
        unsubscribeConsolidatedOrderStateSnapshot();
      }
      return unsubscribeConsolidatedOrders;
    });
  }
  };
  // Funcion que ejecuta la carga inicial de datos
  const fetchInitialData = async (handleUnsubscribe) => {
    setGlobalLoading(true);
    try {
      // Obtener todos los productos
      const productsDocuments = await getPaginatedProducts({ type: "allProducts" });
      setProductsData(productsDocuments);

      // Desuscribirse de la suscripción actual si existe
      handleUnsubscribe(unsubscribeOrderStateSnapshot);

      // Variables para almacenar las funciones de desuscripción de las consultas a la base de datos
      let unsubscribeOrders = null;
      let unsubscribeConsolidatedOrders = null;
      let unsubscribeSellerUsers = null;

      // Obtener todos los pedidos si es Insider o solo los relacionados si es Seller
      if (isInsider) {
        unsubscribeSellerUsers = await getSellerUsersOwners(setSellerUsersData);
      } else {
        const { storesIds } = amountStoresIds(userOwnerData.seller_stores);
        setAmountSellerStoresIds(storesIds);
        
        unsubscribeOrders = await getOrdersToConsolidate(null, setZonesData, setOrdersData,userOwnerData.business_id, toast, t);
        unsubscribeConsolidatedOrders = await getConsolidatedOrders(setConsolidatedOrdersData, userOwnerData.business_id,);
      }

      // Establecer la nueva función de desuscripción
      setUnsubscribeOrderStateSnapshot(() => unsubscribeOrders);
      return {
        unsubscribeOrders,
        unsubscribeConsolidatedOrders,
        unsubscribeSellerUsers
      };
    } catch (error) {
      console.error("Error al obtener los pedidos:", error);
    } finally {
      setGlobalLoading(false);
    }
  };

  // Funcion para manejar la confirmacion de todos los items a nivel de orden 
  const handleConfirmConsolidateAllItems = async () => {
    // console.log("validacion BULK", selectedOrderRowData.id)
    handleItemsSelectionBulk({ checked: true }, selectedOrderRowData?.line_items, selectedOrderRowData);
    setConsolidatedOrderValues((prevValues) => ({
      ...prevValues,
      related_orders_ids: [...prevValues.related_orders_ids, selectedOrderRowData.id]
    }));
    if (isInsider) {
      setConsolidatedOrderValues((prevValues) => ({
        ...prevValues,
        commercial_partner_id: selectedSeller.business_id,
      }));
    }
  };

  const createdConsolidateOrder = async () => {
    setGlobalLoading(true);        
        const body = consolidatedOrderValues;
        const resCreateConsolidatedOrder = await fetchFromCloudFunction("createConsolidatedOrder", body, "POST");
        setGlobalLoading(false);
        if (resCreateConsolidatedOrder.status === 200) {
          toastFunction(toast, "success", "Pedido consolidado con exito ", "", 3000);        
          setConsolidatedOrderValues(consolidatedOrderInitialValues);
          setSelectedItems([]);
          setSelectedOrders([]);
        } else {
          toastFunction(toast, "error", "El pedido no pudo ser consolidado ", "", 3000);
        }
        if (userOwnerData) {
          fetchInitialData(handleUnsubscribe)
            .then(({ unsubscribeOrders, unsubscribeConsolidatedOrders }) => {
              // Efecto de limpieza para desuscribirse de las consultas al desmontar el componente    
              return () => {
                handleUnsubscribe(unsubscribeOrders);
                handleUnsubscribe(unsubscribeConsolidatedOrders);
              };
          });
        }
    setGlobalLoading(false);
  };

  const handleItemsSelection = (e, itemData, rowData) => {
    const relatedOrderData = rowData || selectedOrderRowData
    let _selectedItems = [...selectedItems];
    const updatedItems = [];
  
    const processItem = (item) => {
      if (item.id && item.related_order_id && item.quantity) {
      // console.log("Item procesado", item.quantity)
        const { id, sku, sku_title, variation_id, product_id, product_title, product_label, quantity, related_order_id } = item;
        const variationValues = {
          id: id, // agregamos el id del item a la variacion para poder emaprejarlo
          sku: sku,
          sku_label: sku_title,
          sku_req_qty: quantity,
          variation_id: variation_id,
        };
    
        const existingProductIndex = updatedItems.findIndex(
          (product) => product.product_id === product_id
        );
        // console.log("EL PRODUCTO EXISTE?", existingProductIndex)
        if (existingProductIndex !== -1) {
          updatedItems[existingProductIndex].variations.push(variationValues);
        } else {
          const newItem = {
            id: generateRandomId(),
            product_id: product_id,
            related_order_id: related_order_id,
            product_label: product_title || product_label,
            variations: [variationValues],
          };
          updatedItems.push(newItem);
        }
      } else {
        return
      }
    };
    if (itemData.length > 0) {
      itemData.forEach(processItem);
    } else {
      processItem(itemData);
      const reorderedItems = [...selectedItems, ...updatedItems ]
      reorderedItems.forEach(processItem);
    }
    // console.log("updatedItems", updatedItems)
    setConsolidatedOrderValues((prevValues) => ({
      ...prevValues,
      products: updatedItems,
    }));
  
    if (e.checked) {
      _selectedItems.push(itemData);
    } else {
      _selectedItems = _selectedItems.filter((item) => item.id !== itemData.id);
    }

    // console.log("VALIDACION EN ITEM ", !itemData.length > 0)
    !itemData.length > 0  ? updateSelectedOrdersIds(_selectedItems, relatedOrderData) : null;
    setSelectedItems(_selectedItems);
  };
  // console.log(" consolidateOrderValues", consolidatedOrderValues.related_orders_ids)

  // Columnas de las tablas 
  const consolidatedOrderTableColumns =  ConsolidatedOrderTableColumns(t, orderStates, stateColors, orderPaymentStatus, shippingCompanies, openItemsOverlayPanel, zonesData?.zones, openOrderStatusOverlayPanel, openConsolidateItemsDialog);
  const orderLineItemsTableColumns = OrderLineItemsTableColumns(t, selectedItems, handleItemsSelection);
  const orderStatesTableColumns = OrderStatesTableColumns(orderStates, stateColors);
 
  const handleUnsubscribe = (unsubscribeFn) => {
    if (unsubscribeFn && typeof unsubscribeFn === "function") {
      unsubscribeFn();
    }
  };

  // Efecto para la carga inicial de datos y suscripción 
  useEffect(() => {
    // Verificar si userData está disponible y ejecutar la carga inicial de datos
    if (userOwnerData) {
      fetchInitialData(handleUnsubscribe)
        .then(({ unsubscribeOrders, unsubscribeConsolidatedOrders }) => {
          // Efecto de limpieza para desuscribirse de las consultas al desmontar el componente

          return () => {
            handleUnsubscribe(unsubscribeOrders);
            handleUnsubscribe(unsubscribeConsolidatedOrders);
          };
      });
    }
  }, [userOwnerData]); // Asegúrate de incluir las dependencias relevantes

  const handleCheckRelatedSupplierIds = (value) => {
    setCheckRelatedSupplierIds(value);
  }

    return (
      <>
        <main
          className="w-full grid grid-cols-1 mb-12">
            <div style={{marginBottom:"40px",  }}>
              <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtnConsolidatedOrders")} />
            </div>
            <ToggleButton checked={checkRelatedSuplierIds} onChange={(e) => handleCheckRelatedSupplierIds(e.value)} onLabel="Ver pedidos con productos mios " offLabel="Ver pedidos de mis tiendas" />
            <h5 className="mt-3"> { checkRelatedSuplierIds ? "Viendo pedidos de mis tiendas" : "Viendo pedidos con productos mios "}</h5>
            <div className="mt-4 custom-table">
              <CustomSelect
                floatLabel={true}
                optionLabel={"name"}
                options={userStores}
                value={selectedStore}
                placeholder={t("input-placeholders.selectStore")}
                onChange={ (e) => handleStoreSelection(e.target.value)}
                disabled={globalLoading || isInsider || !checkRelatedSuplierIds}
              />
              {isInsider && (
                <CustomSelect
                  floatLabel={true}
                  optionLabel={"user_full_name"}
                  options={sellerUsersData}
                  value={selectedSeller}
                  placeholder={t("input-placeholders.selectSeller")}
                  onChange={ (e) => handleSellerSelection(e.target.value)}
                  disabled={globalLoading}
                />

              )}
            </div>
            <div className="gap-4 mt-4 mb-8">
              <MainButton 
                type={"button"} label={t("consult")} loading={globalLoading} autoFocus onClick={handleConsultOrders} 
                disabled={isInsider ? !selectedSeller : false}
              />
            </div>
            <article className="mb-8 ">
              <CustomTable
                selection={selectedOrders}
                selectionMode={'multiple'}
                dataKey="id" tableStyle={{ minWidth: '50rem' }}
                className="custom-table" columns={consolidatedOrderTableColumns} data={ordersData} 
                action={
                  // (e) => openItemsOverlayPanel({rowData: e, e: null})
                () => {}
                }
              />
             
            </article>

            <div className="grid grid-cols-1"> 
             <MainButton label={ selectedItems?.length === 0 ? t("createConsolidateOrder")  :`Crear pedido consolidado con ${selectedItems?.length} Items` }
              onClick={createdConsolidateOrder}
              type={"button"}
              loading={globalLoading}
              disabled={ isInsider ? !selectedSeller || selectedItems?.length === 0  : false || selectedItems?.length === 0 }
             /> 
            </div>
          <h3 className="mt-8 mb-6"> Pedidos Consolidados</h3>
          <div className="grid grid-cols-2 gap-4">
          {consolidatedOrdersData?.map((consolidatedOrder, index) => {
            return (
              <ConsolidatedOrderCard
                key={`${consolidatedOrder.id}-${index}`}
                consolidatedOrder={consolidatedOrder}
                productsData={productsData}
              />
            );
          })}
        </div>
        </main>
        <div>                                                                                                                                                                                         
          <OverlayPanel  ref={opItems} showCloseIcon closeOnEscape dismissable={true}>
            <CustomTable
              selectionMode={'multiple'}
              selection={selectedItems}
              dataKey="id" tableStyle={{ minWidth: '50rem' }}
              className="custom-table" 
              columns={orderLineItemsTableColumns} 
              data={selectedOrderRowData?.line_items} action={() => {}}
            />
          </OverlayPanel>
          <OverlayPanel ref={opOrderStatus} showCloseIcon closeOnEscape dismissable={true}>
            <CustomTable
              data={sortArrayByCreatedAt(selectedOrderRowData?.states)}
              action={() => {}}
              columns={orderStatesTableColumns}
              className="custom-table"
            />
          </OverlayPanel>
          <CustomConfirmDialog
            visible={visibleConfirmConsolidateAllItemsDialog}
            onHide={() => setVisibleConfirmConsolidateAllItemsDialog(false)}
            header={t("confirmDialog-confirmConsolidateItems.header")}
            message={t("confirmDialog-confirmConsolidateItems.message")}
            icon="pi pi-question-circle"
            accept={handleConfirmConsolidateAllItems}
            reject={() => setVisibleConfirmConsolidateAllItemsDialog(false)}
            acceptLabel={t("confirmDialog-confirmConsolidateItems.acceptLabel")}
            rejectLabel={t("confirmDialog-confirmConsolidateItems.rejectLabel")}
          />
          <Toast ref={toast} />
          
        </div>
        
      </>
    )
}

export default ConsolidatedOrders;
