import PropTypes from "prop-types"
const VideoModal = ({ videoUrl, videoLabel, videoContent }) => {
  // Extraer el ID del video de la URL de YouTube
  const videoId = videoUrl && videoUrl.split('v=')[1];

  return (
    <div>
      <h2>{videoLabel}</h2>
      <p>{videoContent}</p>
      <div>
        {videoId ? (
          <iframe
            width="100%"
            height="500"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="Reproductor de Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <p>No se pudo encontrar el video</p>
        )}
      </div>
    </div>
  );
};

export default VideoModal;

VideoModal.propTypes = {
    videoUrl: PropTypes.string,
    videoLabel: PropTypes.string,
    videoContent: PropTypes.string,
};
