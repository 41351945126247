// Definimos la función para verificar si es base64
 const isBase64 = (str) => {
    // Expresión regular para validar base64
    const base64Regex = /^(data:)([\w/+]+);(charset=[\w-]+|base64),([^\n\r]+)$/;
    // Verificar si la cadena coincide con el patrón base64
    return base64Regex.test(str);
  }
const base64ToBlob = (base64Data, contentType) => {
  const byteCharacters = atob(base64Data.split(',')[1]);
  const arrayBuffer = new ArrayBuffer(byteCharacters.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteCharacters.length; i++) {
      uint8Array[i] = byteCharacters.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: contentType });
}
export { isBase64, base64ToBlob}