import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import MainButton from "../MainButton/MainButton.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import { generateRandomId } from '../../utils/generateRandomId.js';
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import BannerCard from "../BannerCard/BannerCard.jsx";
import { SECTION } from "../router/paths.js";
import toastFunction from "../../utils/toastFunction.js";
import { base64ToBlob, isBase64 } from "../../utils/utilsFunctions.js";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../../firebase/firebase.js";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber.jsx";
import CustomTextArea from "../FormComponents/CustomTextArea/CustomTextArea.jsx";
        

export default function SectionShoppexStoreForm({toast, onClose, selectedSection, shoppexStore, productData}) {
    const { t } = useTranslation("Products");
    let hasEdit;
    if (selectedSection) {
        hasEdit = !!Object.keys(selectedSection)?.length;
    }
    const groupType = "banner"
    const { coverTypes, defaultCoverImage } = useGlobalContext();
    const { globalLoading, setGlobalLoading } = useLoaderContext();
    const { updateArray } = useFirebaseCRUD();
    const [mediaCoverType, setMediaCoverType ] = useState(null);
    const [noresize, setNoresize] = useState(false);


    const initialSectionValues =  {
        id: selectedSection?.id || generateRandomId(),
        position: selectedSection?.position || 0, // Posicion de la seccion
        title: selectedSection?.title || null, // Texto principal de la seccion
        subtitle: selectedSection?.subtitle || null, // Texto secundario de la seccion
        description: selectedSection?.description ||null, // Parrafo de la seccion
        media_type: selectedSection?.media_type ||null, // Tipo del contenido multimedia de la sección. “img” || “video”
        media_src: selectedSection?.media_src || defaultCoverImage, // URL de la seccion de multimedia
        media_path: selectedSection?.media_path ||null,
    };

    const [sectionValues, setSectionValues] = useState(initialSectionValues);

    
    const validateFields = (sectionValuesData) => {
        // Excluir los campos específicos
        const sectionValuesObject = {
            title: sectionValuesData.title,
            subtitle: sectionValuesData.subtitle,
            media_path: sectionValuesData.media_path,
            description: sectionValues.description,
        } 
        // Obtener un array de los valores restantes
        const valuesArray = Object.values(sectionValuesObject);
        // Verificar si al menos un campo es diferente de null
        const hasNonNullValue = valuesArray.some(value => value !== null);
        return hasNonNullValue;
    };
    const processMediaArray = async (array) => {
    // Función para procesar cada objeto del array
        const processObject = async (obj) => {
            // Procesar media_src
            if (isBase64(obj.media_src)) {
                obj.media_src = await processBase64Media(obj.media_path, obj.media_src, obj.media_type);
            }
        };
      // Mapear y procesar cada objeto en el array
    await Promise.all(array.map(processObject));
    return array;
    };
    // Función para procesar la base64 según el tipo de medio y devolver la URL
    const processBase64Media = async (mediaPath, mediaSrc, mediaType) => {
        let downloadURL = null;
        if (mediaType === "img") {
        const blob = await fetch(mediaSrc).then((res) => res.blob());
        const storageRef = ref(storage, mediaPath);
        await uploadBytes(storageRef, blob);

        // Obtener la URL de descarga
        downloadURL = await getDownloadURL(storageRef);
        } else if (mediaType === "video") {
        const videoBlob = base64ToBlob(mediaSrc, 'video/mp4');
        const storageRefOriginal = ref(storage, mediaPath);
        await uploadBytes(storageRefOriginal, videoBlob);
        downloadURL = await getDownloadURL(storageRefOriginal);
        }
        return downloadURL;
    };
    const saveSection = async (e) => {
        e.preventDefault();
        setGlobalLoading(true);
       if (validateFields(sectionValues)) {
        let result;
        await processMediaArray([sectionValues])
        if (hasEdit) {
            const relatedLandingContent = shoppexStore.related_products_landing_content;
            const findedProductLandingContent = relatedLandingContent.find((relatedProductLanding) => relatedProductLanding?.id === productData?.related_synced_id);
            const sectionsWhitoutSectionToDelete = findedProductLandingContent.sections.filter((landingSection) => landingSection.id !== selectedSection.id);
            const newProductLandingContent = {...findedProductLandingContent, sections: [...sectionsWhitoutSectionToDelete, sectionValues]}
            // console.log("antes de actualizar originales",  relatedLandingContent);
            // console.log("antes de actualizar viejos",  findedProductLandingContent);
            // console.log("antes de actualizar nuevos", newProductLandingContent);
            // console.log("antes de actualizar a eliminar", sectionsWhitoutSectionToDelete);
            result =  await updateArray("stores", shoppexStore.id, "related_products_landing_content", findedProductLandingContent, newProductLandingContent );
        } else { // Esta creando 
            const findedRelatedLandingContent = shoppexStore.related_products_landing_content.find((relatedLandingContent) => relatedLandingContent.id === productData?.related_synced_id)
            const relatedLandingSections = [...findedRelatedLandingContent.sections, sectionValues];
            const newRelatedLandingContent = {...findedRelatedLandingContent, sections: relatedLandingSections}
            result = await updateArray("stores", shoppexStore?.id, "related_products_landing_content", findedRelatedLandingContent, newRelatedLandingContent);
        }
        if(result) {
            toastFunction(toast, "success", "Seccion agregada con exito");
            onClose()
        }

       } else {
        toastFunction(toast, "warn", "Porfavor agrega un Titulo un subtitulo, una descripcion, una imagen o video", "", 3000);
       }
        setGlobalLoading(false);
    };
    const handleDeleteMedia = () => {
          setSectionValues((prevData) => ({
            ...prevData,
            media_src:  defaultCoverImage,
            media_path:  null,
          }));
           setNoresize(false);
    };
    const handleInputChange = (fieldName, e) => {
        let value = e;
        if ( fieldName === "media_type") {
          setSectionValues((prevData) => ({
            ...prevData,
            [fieldName]:  value?.string, // Tipo del contenido multimedia del slide "img" || "video"
            media_src: defaultCoverImage,
          }));
          setMediaCoverType(value);
          
        } else {
          setSectionValues((prevData) => ({
            ...prevData,
            [fieldName]: value,
          }));
        }
    };

    useEffect(() => {
      if( selectedSection?.media_type ) {
        const findedCoverMediaType = coverTypes.find((cover) => cover.string === selectedSection.media_type)
        setMediaCoverType(findedCoverMediaType);
      }
    }, [])

    return (
        <form onSubmit={saveSection}>
          <CustomInputText
            floatLabel={true}
            type={"text"}
            value={sectionValues.title}
            placeholder={t("input-placeholders.title")}
            onChange={(e) => handleInputChange("title", e.target.value)}
            disabled={globalLoading}
          />
          <CustomInputText
            floatLabel={true}
            type={"text"}
            value={sectionValues.subtitle}
            placeholder={t("input-placeholders.subtitle")}
            onChange={(e) => handleInputChange("subtitle", e.target.value)}
            disabled={globalLoading}
          />
          <CustomInputNumber 
            floatLabel={true}
            type={"text"}
            value={sectionValues.position}
            placeholder={t("input-placeholders.position")}
            onChange={(e) => handleInputChange("position", e.value)}
            disabled={globalLoading}
          />

          <CustomTextArea
            value={sectionValues.description}
            placeholder={t("input-placeholders.description")}
            onChange={(e) => handleInputChange("description", e.target.value)}
            floatLabel={true}
          />

          <CustomSelect
              floatLabel={true}
              optionLabel={"name"}
              options={coverTypes}
              valueTemplate={""}
              itemTemplate={""}
              value={mediaCoverType}
              placeholder={t("input-placeholders.coverTypes")}
              onChange={(e) => handleInputChange("media_type", e.target.value)}
          />
        <div className="grid gap-12 mt-10 mb-10 ">
          <BannerCard 
            noresize={noresize}
            setNoresize={setNoresize}
            shoppexStoreId={shoppexStore.id} 
            sectionId={sectionValues.id}
            data={sectionValues} 
            coverType={SECTION}
            coverTypeMedia={mediaCoverType} 
            groupType={groupType} groupId={sectionValues.id}
            onClick={handleDeleteMedia}
            isErasable={true}
            toast={toast} setBannerValues={setSectionValues}

          />
        </div>
        <MainButton
          style={{ marginTop: "20px" }}
          type={"submit"}
          label={t("saveSection")}
          loading={globalLoading}
          // disabled={globalLoading}
          autoFocus
        />
        </form>
    )
}

SectionShoppexStoreForm.propTypes = {
    userData: PropTypes.object, // Datos del usuario
    walletUser: PropTypes.object, // Datos de la billetera del usuario
    toast: PropTypes.object, // Función para mostrar mensajes de notificación
    onClose: PropTypes.func,
    selectedSection: PropTypes.object,
    shoppexStore: PropTypes.object,
    productData: PropTypes.object,
};