import styles from './WhatsappChat.module.css';
import WA from '../../assets/WA.svg'
import { lead } from '../../PIXELs/PIXELs/Events/Events';
export default function WhatsappChat() {
    const openWhatsAppDirectly = (wa_id, message) => {
        lead("click_on_wa_link_landing")
        var phoneNumber = wa_id; // Example 573183667033
        var messageEncoded = encodeURIComponent(message);
        var whatsappUrl = `https://wa.me/${phoneNumber}?text=${messageEncoded}`;
        window.open(whatsappUrl, '_blank');
    }
    return (
        <div onClick={()=>openWhatsAppDirectly("+573054074246", "*Hola, quiero más información* sobre Supplaii")} className={styles.whatsappChatContainer}>
            <img className={styles.WA} src={WA} />
        </div>
    )
}
