import React from 'react'
import styles from './Paragraph.module.css'

export default function Paragraph({title, subtitle, subtitle_style, bold_text, regular_text}) {
  return (
    <div className={styles.Paragraph}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle} style={subtitle_style}>{subtitle}</div>
        <div>
            <span className={styles.bold_text}>
                {bold_text}
            </span>
            <span className={styles.regular_text}>
                {regular_text}
            </span>
        </div>
    </div>
  )
}
