import { useEffect, useState } from 'react';
import { getCitiesByProvince } from '../utils/getCitiesByProvince';
import { useAuthContext } from '../contexts/Authcontext/Authcontext';


/**
 * Hook personalizado para obtener las ciudades basadas en el código de provincia y las zonas desde la base de datos.
 * 
 * @param {Object} department - El objeto del departamento que contiene el código de la provincia.
 * @returns {Object} - Un objeto que contiene las ciudades y las zonas.
 */
const useMatchProvinceAndCity = (department) => {
  const { zonesData} = useAuthContext();
  const [cities, setCities] = useState([]);


  // Obtener las ciudades basadas en el código de provincia o todas las zonas si department es nulo
  useEffect(() => {
    if (!department || !department.province_code) {
      // Si department es nulo o no tiene provincia definida, establecer todas las zonas como ciudades
      setCities(zonesData);
    } else {
      // Obtener ciudades basadas en el código de provincia
      const city = getCitiesByProvince(department.province_code, zonesData);

      setCities(city);
    }
  }, [department, zonesData]);
  return { cities, zones: zonesData };
};

export default useMatchProvinceAndCity;
