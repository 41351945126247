 // Retorna un objeto con un conjunto único de IDs de tiendas de un array de vendedores filtrado
const amountStoresIds = (filteredSellerStores) => {
    if (!filteredSellerStores) return [];
    const storesIds = new Set();
    filteredSellerStores.forEach((store) => {
      if (store.id) {
        storesIds.add(store.id);
      }
    });
    return { storesIds: Array.from(storesIds) };
  };

export default amountStoresIds;
