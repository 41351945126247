import { useRef, useEffect } from 'react';

const useOverlayPanel = () => {
  const opItems = useRef(null);

  const closeOverlayPanel = () => {
    if (opItems.current) {
      opItems.current.hide(); // Cierra el overlay
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      closeOverlayPanel();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return {
    opItems,
    closeOverlayPanel,
  };
};

export default useOverlayPanel;
