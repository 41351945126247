import { Calendar } from 'primereact/calendar';
import styles from './CustomCalendar.module.css';

export default function CustomCalendar({id, floatLabel, placeholder, dates, setDates, selectionMode}) {
    if(floatLabel){
        return (
            <div className={styles.floatLabelContainer}>
                <span className="p-float-label">
                <Calendar
                    value={dates}
                    onChange={setDates}
                    selectionMode={selectionMode}
                    className={styles.customFormComponent}
                    placeholder={placeholder}
                    readOnlyInput
                />
                <label htmlFor={id}>{placeholder}</label>
                </span>
            </div>
        )
    }else{
        return (
            <Calendar
                value={dates}
                onChange={setDates}
                selectionMode={selectionMode}
                className={styles.customFormComponent}
                placeholder={placeholder}

                readOnlyInput
            />
        )
    }
}
