import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import pica from "pica";
import { generateRandomId } from "../../utils/generateRandomId";
import { CREATE, EDIT } from "../router/paths";
import MainButton from "../MainButton/MainButton";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import DeleteButton from "../DeleteButton/DeleteButton";

function ImagesVariations({ updateVariation, variation, variationIndex, mode, handleDeletedImagesFromVariations, onClose }) {
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [mergeImages, setMergeImages] = useState(
    Array.isArray(variation?.images) ? [...variation.images] : []
  );
  
  const [selectedImgId, setselectedImgId] = useState(null);
  const [error, setError] = useState("");
  const MAX_IMAGE_SIZE = 1 * 1024 * 1024; // 1MB

  const [deletedImages, setDeletedImages] = useState([]);
  const [loading, setLoading] = useState(null);

  const handleImageUpload = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    if (file.size > MAX_IMAGE_SIZE) {
      setError("El tamaño de la imagen no debe exceder 2MB.");
      setLoading(false);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      if (mergeImages.length < 8) {
        const img = new Image();
        img.src = reader.result;

        img.onload = async () => {
          const canvas = document.createElement("canvas");
          const scaleFactor = 0.8;
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;

          // Redimensionar la imagen usando pica
          await pica().resize(img, canvas);

          // Convertir el canvas a base64
          const base64Image = canvas.toDataURL("image/jpeg", 0.8).split(",")[1];

          const imagesStructure = {
            img_url: base64Image,
            img_id: generateRandomId(),
            is_main_image: false,
          };

          // Actualizar los estados después de procesar la imagen
          setselectedImgId(imagesStructure.img_id);
          setError("");
          setNewImages((prevImages) => [...prevImages, imagesStructure]);
          setMergeImages([...images, ...newImages, imagesStructure]);
          setLoading(false);
        };
      } else {
        setError("solo puedes agregar 8 imagenes :(");
        setLoading(false);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const setMainImage = (selectedId) => {
    console.log(selectedId);
    const resetImagesArray = (imagesArray) => {
      return imagesArray.map((img) => ({
        ...img,
        is_main_image: false,
      }));
    };

    const setMainOnSelectedImage = (imagesArray) => {
      return imagesArray.map((img) => {
        if (img.img_id === selectedId) {
          return {
            ...img,
            is_main_image: true,
          };
        }
        return img; // Retornamos la imagen sin cambios si no es la seleccionada
      });
    };

    // Primero, reseteamos todas las imágenes en los tres arrays
    const resetedImages = resetImagesArray(images);
    const resetedNewImages = resetImagesArray(newImages);
    const resetedMergeImages = resetImagesArray(mergeImages);

    // Luego, establecemos is_main_image en true solo para la imagen seleccionada en cada array
    const updatedImages = setMainOnSelectedImage(resetedImages);
    const updatedNewImages = setMainOnSelectedImage(resetedNewImages);
    const updatedMergeImages = setMainOnSelectedImage(resetedMergeImages);

    setImages(updatedImages);
    setNewImages(updatedNewImages);
    setMergeImages(updatedMergeImages);
  };
  const handleSave = () => {
    const hasMainImage = mergeImages.some((image) => image.is_main_image);

    if (!hasMainImage) {
      if (images?.length > 0) {
        images[0].is_main_image = true || false;
      }
      if (!newImages || newImages?.length > 0) {
        newImages[0].is_main_image = true;
      }
      for (let i = 1; i < images.length; i++) {
        images[i].is_main_image = false;
      }
    }
    const fieldsToUpdate = {
      NewImages: newImages,
      images: images,
    };
    updateVariation(variationIndex, fieldsToUpdate);
    handleDeletedImagesFromVariations(deletedImages);
    onClose();
  };
  const handleDelete = () => {
    if (selectedImgId !== null) {
      // Encuentra la imagen que se va a eliminar
      const imageToDelete = mergeImages.find((img) => img.img_id === selectedImgId);
      // Si la imagen existe y su URL es de Firebase Storage, agrégala al arreglo de imágenes eliminadas
      if (imageToDelete && imageToDelete.img_url.includes(".googleapis.com")) {
        setDeletedImages((prevDeletedImages) => [
          ...prevDeletedImages,
          {
            ...imageToDelete,
            variation_id: variation?.variation_id, // Asumiendo que tienes acceso al ID de la variación en este componente
          },
        ]);
      }

      const updatedImages = images.filter((img) => img.img_id !== selectedImgId);
      const updatedNewImages = newImages.filter((img) => img.img_id !== selectedImgId);
      const updatedMergeImages = mergeImages.filter((img) => img.img_id !== selectedImgId);

      setImages(updatedImages);
      setNewImages(updatedNewImages);
      setMergeImages(updatedMergeImages);
      setselectedImgId(null);
      setError("");
    }
  };

  useEffect(() => {
    if (variation?.images) {
      setImages(variation?.images);
      if (mode === CREATE && variation.NewImages) {
        setNewImages(variation?.NewImages);
      } else if (mode === EDIT && variation.NewImages) {
        setNewImages(variation?.NewImages);
      }
      setMergeImages([...(variation?.images || []), ...(variation?.NewImages || [])]);
    }
  }, [mode]);

  return (
    <div className="rounded-xl bg-gray-100 p-4">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-2.5 ">
        <div className="w-20 h-20 flex justify-center  md:w-32 md:h-32 border-2 border-black relative ">
          <label className="flex items-center w-full h-full cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <input type="file" className="hidden " onChange={handleImageUpload} />
            <img
              className="opacity-60 p-2"
              src="https://cdn-icons-png.flaticon.com/128/8191/8191585.png?ga=GA1.1.395521382.1694465211"
            />
          </label>
        </div>
        {images?.map((image, index) => (
          <div
            key={image.img_id}
            className={`w-20 h-20 md:w-32 md:h-32 ${
              image.is_main_image
                ? "border-4 border-green-500 rounded-lg"
                : selectedImgId === image.img_id
                ? "border-4 border-blue-500 rounded-lg"
                : "border border-black rounded-lg"
            } relative cursor-pointer`}
            onClick={() => setselectedImgId(image.img_id)}
          >
            {image.img_url && (
              <img
                src={image.img_url || `data:image/*;base64,${image.img_url}`}
                alt={`Imagen ${index}`}
                className="w-full h-full object-cover rounded-lg"
              />
            )}
          </div>
          ))}
          {newImages?.map((image, index) => (
            <div
              key={image?.img_id}
              className={`w-20 h-20 md:w-32 md:h-32 ${
                image?.is_main_image
                  ? "border-4 border-green-500 rounded-lg"
                  : selectedImgId === image?.img_id
                  ? "border-4 border-blue-500 rounded-lg"
                  : "border border-black rounded-lg"
              } relative cursor-pointer`}
              onClick={() => setselectedImgId(image?.img_id)}
            >
              {image?.img_url && (
                <img
                  src={`data:image/*;base64,${image?.img_url}`}
                  alt={`Imagen ${index}`}
                  className="w-full h-full object-cover rounded-lg"
                />
              )}
            </div>
          ))}


{Array(Math.max(0, 8 - (Array.isArray(mergeImages) ? mergeImages.length : 0)))
  .fill(null)
  .map((_, idx) => (
    <div 
      key={idx + (Array.isArray(images) ? images.length : 0)} 
      className="flex justify-center w-20 h-20 md:w-32 md:h-32 border border-black rounded-lg"
    >
      {idx === 0 && loading ? <div className="items-center h-full"></div> : null}
    </div>
  ))}

      </div>

      <div className="mt-14 grid gap-4">
        <p className="text-lg text-red-500 mb-8">{error}</p>
        <SecondaryButton
          // loading={globalLoading}
          className={`secondaryBtn ${selectedImgId === null ? "opacity-50 cursor-not-allowed" : ""}`}
          type={"button"}
          label={"Seleccionar como imagen principal"}
          onClick={() => {
            setMainImage(selectedImgId);
          }}
          disabled={selectedImgId === null}
        />
        <MainButton
          type={"button"}
          label={"Guardar"}
          // loading={globalLoading}
          onClick={handleSave}
          autoFocus
        />
        <DeleteButton
          type={"button"}
          label={"Eliminar"}
          // loading={globalLoading}
          onClick={handleDelete}
        />
      </div>
    </div>
  );
}


ImagesVariations.propTypes = {
  updateVariation: PropTypes.func,
  variation: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        img_url: PropTypes.string.isRequired,
        img_id: PropTypes.string.isRequired,
        is_main_image: PropTypes.bool.isRequired,
      }).isRequired
    ).isRequired,
    variation_id: PropTypes.string.isRequired,
    NewImages: PropTypes.arrayOf(
      PropTypes.shape({
        img_url: PropTypes.string.isRequired,
        img_id: PropTypes.string.isRequired,
        is_main_image: PropTypes.bool.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  variationIndex: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number
  ]).isRequired,
  mode: PropTypes.string.isRequired,
  handleDeletedImagesFromVariations: PropTypes.func,
  onClose: PropTypes.func,
};

export default ImagesVariations;
