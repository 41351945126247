import PropTypes from 'prop-types';
import CustomConfirmDialog from '../../Components/OverlayComponents/CustomConfirmDialog/CustomConfirmDialog';

const OrdersConfirmDialogs = ({
  t,
  visibleConfirmOrderDialog,
  setVisibleConfirmOrderDialog,
  selectedOrderRowData,
  handleOrderConfirmation,
  visibleConfirmFullfillmentDialog,
  setVisibleConfirmFullfillmentDialog,
  handleOrderFulfillment,
  visibleConfirmCancelAnOrderDialog,
  setVisibleConfirmCancelAnOrderDialog,
  handleConfirmCancelAnOrder,
  visibleConfirmReactivateOrderDialog,
  setVisibleConfirmReactivateOrderDialog,
  handleReactiveOrder,
  visibleConfirmDeleteOrderDialog,
  setVisibleConfirmDeleteOrderDialog,
  handleConfirmDeleteOrder,
  visibleConfirmCancelShippingGuideDialog,
  setVisibleConfirmCancelShippingGuideDialog,
  handleCancelShippingGuide,
}) => {
  return (
    <>
      <CustomConfirmDialog
        visible={visibleConfirmOrderDialog}
        onHide={() => setVisibleConfirmOrderDialog(false)}
        message={
          selectedOrderRowData?.order_is_confirmed
            ? t("confirmDialog-confirmOrder.messageDisconfirm")
            : t("confirmDialog-confirmOrder.messageConfirm")
        }
        header={
          selectedOrderRowData?.order_is_confirmed
            ? t("confirmDialog-confirmOrder.headerDisconfirm")
            : t("confirmDialog-confirmOrder.headerConfirm")
        }
        icon="pi pi-question-circle"
        accept={handleOrderConfirmation}
        reject={() => setVisibleConfirmOrderDialog(false)}
        acceptLabel={
          selectedOrderRowData?.order_is_confirmed
            ? t("confirmDialog-confirmOrder.acceptLabelDisconfirm")
            : t("confirmDialog-confirmOrder.acceptLabelConfirm")
        }
        rejectLabel={t("confirmDialog-confirmOrder.rejectLabel")}
      />
      <CustomConfirmDialog
        visible={visibleConfirmFullfillmentDialog}
        onHide={() => setVisibleConfirmFullfillmentDialog(false)}
        message={t("confirmDialog-confirmFullfillment.messageConfirm")}
        header={t("confirmDialog-confirmFullfillment.headerConfirm")}
        icon="pi pi-question-circle"
        accept={handleOrderFulfillment}
        reject={() => setVisibleConfirmFullfillmentDialog(false)}
        acceptLabel={t("confirmDialog-confirmFullfillment.acceptLabelConfirm")}
        rejectLabel={t("confirmDialog-confirmFullfillment.rejectLabel")}
      />
      <CustomConfirmDialog
        visible={visibleConfirmCancelAnOrderDialog}
        onHide={() => setVisibleConfirmCancelAnOrderDialog(false)}
        header={t("confirmDialog-confirmCancelAnOrder.header")}
        message={t("confirmDialog-confirmCancelAnOrder.message")}
        icon="pi pi-question-circle"
        accept={handleConfirmCancelAnOrder}
        reject={() => setVisibleConfirmCancelAnOrderDialog(false)}
        acceptLabel={t("confirmDialog-confirmCancelAnOrder.acceptLabel")}
        rejectLabel={t("confirmDialog-confirmCancelAnOrder.rejectLabel")}
      />
      <CustomConfirmDialog
        visible={visibleConfirmReactivateOrderDialog}
        onHide={() => setVisibleConfirmReactivateOrderDialog(false)}
        header={t("confirmDialog-confirmReactivateOrder.header")}
        message={t("confirmDialog-confirmReactivateOrder.message")}
        icon="pi pi-question-circle"
        accept={handleReactiveOrder}
        reject={() => setVisibleConfirmReactivateOrderDialog(false)}
        acceptLabel={t("confirmDialog-confirmReactivateOrder.acceptLabel")}
        rejectLabel={t("confirmDialog-confirmReactivateOrder.rejectLabel")}
      />
      <CustomConfirmDialog
        visible={visibleConfirmDeleteOrderDialog}
        onHide={() => setVisibleConfirmDeleteOrderDialog(false)}
        header={t("confirmDialog-confirmDeleteOrder.header")}
        message={t("confirmDialog-confirmDeleteOrder.message")}
        icon="pi pi-question-circle"
        accept={handleConfirmDeleteOrder}
        reject={() => setVisibleConfirmDeleteOrderDialog(false)}
        acceptLabel={t("confirmDialog-confirmDeleteOrder.acceptLabel")}
        rejectLabel={t("confirmDialog-confirmDeleteOrder.rejectLabel")}
      />
       <CustomConfirmDialog
          visible={visibleConfirmCancelShippingGuideDialog}
          onHide={() => setVisibleConfirmCancelShippingGuideDialog(false)}
          header={t("confirmDialog-confirmCancelShippingGuide.header")}
          message={t("confirmDialog-confirmCancelShippingGuide.message")}
          icon="pi pi-question-circle"
          accept={handleCancelShippingGuide}
          reject={() => setVisibleConfirmCancelShippingGuideDialog(false)}
          acceptLabel={t("confirmDialog-confirmCancelShippingGuide.acceptLabel")}
          rejectLabel={t("confirmDialog-confirmCancelShippingGuide.rejectLabel")}
        />
    </>
  );
};

OrdersConfirmDialogs.propTypes = {
  t: PropTypes.func.isRequired,
  visibleConfirmOrderDialog: PropTypes.bool.isRequired,
  setVisibleConfirmOrderDialog: PropTypes.func.isRequired,
  selectedOrderRowData: PropTypes.object.isRequired,
  handleOrderConfirmation: PropTypes.func.isRequired,
  visibleConfirmFullfillmentDialog: PropTypes.bool.isRequired,
  setVisibleConfirmFullfillmentDialog: PropTypes.func.isRequired,
  handleOrderFulfillment: PropTypes.func.isRequired,
  visibleConfirmCancelAnOrderDialog: PropTypes.bool.isRequired,
  setVisibleConfirmCancelAnOrderDialog: PropTypes.func.isRequired,
  handleConfirmCancelAnOrder: PropTypes.func.isRequired,
  visibleConfirmReactivateOrderDialog: PropTypes.bool.isRequired,
  setVisibleConfirmReactivateOrderDialog: PropTypes.func.isRequired,
  handleReactiveOrder: PropTypes.func.isRequired,
  visibleConfirmDeleteOrderDialog: PropTypes.bool.isRequired,
  setVisibleConfirmDeleteOrderDialog: PropTypes.func.isRequired,
  handleConfirmDeleteOrder: PropTypes.func.isRequired,
  visibleConfirmCancelShippingGuideDialog: PropTypes.bool.isRequired,
  setVisibleConfirmCancelShippingGuideDialog: PropTypes.func.isRequired,
  handleCancelShippingGuide: PropTypes.func.isRequired,

};

export default OrdersConfirmDialogs;
