import React, {useEffect, useState} from 'react'
import styles from './Navbar.module.css';
import { BiMenu } from 'react-icons/bi';
import SideMainMenu from '../SideMainMenu/SideMainMenu';
import { useAuthContext } from '../../contexts/Authcontext/Authcontext';
import { useLocation } from 'react-router-dom';

export default function Navbar({user_data}) {
  const [viewMenu, setViewMenu] = useState(false);
  const [isCatalogView, setIsCatalogView] = useState(false);

  const { catalogData } = useAuthContext();

  const toggleSidebar = (state) => {
    setViewMenu(state);
    if (state) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/catalog') || location.pathname.startsWith('/thankyouPage')){
      setIsCatalogView(true)
    } else {
      setIsCatalogView(false)
    }
  },[location, user_data]);

  return (
    <div className={styles.Navbar}>
        <div className={styles.Navbar_first_row}>

         <SideMainMenu
              viewMenu={viewMenu}
              toggleSidebar={toggleSidebar}
              user_data={user_data}
            />
          {(user_data || !isCatalogView) && (  
            <BiMenu
              onClick={() => toggleSidebar(true)}
              className={`icon clickable`}
              style={{color:"var(--main-hard-color)", fontSize:"var(--font-size-h2)"}} 
            />
          )}
          <div className={styles.TangoSans}>
           { catalogData && isCatalogView ? (
            <div>
              {catalogData.catalog_name}
            </div>
           ): 
            <div>
                Supplaii 
            </div>
           }
          </div>
        </div>
    </div>
  )
}
