import { ConfirmDialog  } from 'primereact/confirmdialog';
import styles from './CustomConfirmDialog.module.css'

export default function CustomConfirmDialog({visible, onHide, header, message, icon, accept, reject, acceptLabel, rejectLabel}) {
  return (
    <ConfirmDialog
        className={styles.CustomConfirmDialog}
        visible={visible}
        onHide={onHide}
        header={header}
        message={message}
        icon={icon}
        accept={accept}
        reject={reject}
        acceptLabel={acceptLabel}
        rejectLabel={rejectLabel}
    />
  )
}
