import PropTypes from 'prop-types';
import styles from "../../pages/MyOrders/MyOrders.module.css";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Messages } from "primereact/messages";
import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import { SelectButton } from "primereact/selectbutton";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect";
import CustomCalendar from "../FormComponents/CustomCalendar/CustomCalendar";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import MainButton from "../MainButton/MainButton";
import { useState } from 'react';
import { getSellerProductByIdAdmin } from '../../pages/MyOrders/scripts/getOrders';
import { useLoaderContext } from '../../contexts/LoaderContext/LoaderContext';
import CustomChips from '../FormComponents/CustomChips/CustomChips.jsx';
import SecondaryButton from '../SecondaryButton/SecondaryButton.jsx';

const OrdersAdminFilters = ({
  attributesValues,
  attributesValuesOnBlur,
  cleanFilters,
  cleanFiltersAndConsultOrders,
  dates,
  handleBlurChipAttributes,
  handleConsultOrders,
  handleFocusChipAttributes,
  handleVariationSelect,
  hasFilterBySKU,
  msgs,
  multiSelectOptions,
  options,
  orderAttributes,
  orderStates,
  selectAttributeOrders,
  selectedOrderStates,
  selectedVariationOfData,
  sellerBusinessId,
  setAttributesValues,
  setAttributesValuesOnBlur,
  setDates,
  setMultiSelectOptions,
  setSelectAttributeOrder,
  setSelectedOrderStates,
  setSellerBusinessId,
  supplierId,
  setSupplierId,
  setValuesSKUS,
  setValuesSKUSOnBlur,
  setVariationsData,
  setVisibleVinculateItemsBatch,
  t,
  toggleFilterBySKU,
  toast,
  updatePromotionField,
  valueMultiCheckboxGuidesGenerated,
  valuesSKUS,
  valuesSKUSOnBlur,
  setValueMultiCheckboxGuidesGenerated,
  variationsData
  
}) => {
  const { globalLoading } = useLoaderContext();
  const optionsFilterByGeneratedGuide = [
    { value: "Todas", icon: "pi pi-globe" },
    { value: "Generadas", icon: "pi pi-lock-open" },
    { value: "Sin generar", icon: "pi pi-lock" },
  ];

  // Estados para el Insider
 
  const [productId, setProductId] = useState(null);
  const [productData, setProductData] = useState(null);


  const handleGetProduct = async () => {
    try {
      const resultGetSellerProductById = await getSellerProductByIdAdmin(productId, setProductData, setVariationsData, toast, t);
      console.log("resultGetSellerProductById", resultGetSellerProductById);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <main  className='mt-12 mb-8 grid gap-4'
    >
      <SelectButton
        value={multiSelectOptions}
        style={{ marginBottom: "10px", display: "grid", gap: "20px", }}
        onChange={(e) => setMultiSelectOptions(e.value)}
        optionLabel="name"
        options={options}
        multiple // Cambiar a false para permitir una sola selección
      />
      {multiSelectOptions?.length !== 0 && multiSelectOptions?.includes(1) && (
        <CustomInputText
          floatLabel={true}
          type={"text"}
          value={sellerBusinessId}
          placeholder={t("input-placeholders.businessIdToFindOrder")}
          onChange={(e) => setSellerBusinessId(e.target.value)}
        />
      )}
      {multiSelectOptions?.length !== 0 && multiSelectOptions?.includes(2) && (
        <CustomInputText
          floatLabel={true}
          type={"text"}
          value={supplierId}
          placeholder={t("input-placeholders.supplierBusinessIdToFind")}
          onChange={(e) => setSupplierId(e.target.value)}
        />
      )}
      <CustomCalendar
        dates={dates}
        setDates={(e) => setDates(e.target.value)}
        selectionMode="range"
        placeholder={t("input-placeholders.calendarRange")}
      />
      <CustomMultiSelect
        optionLabel={"name"}
        options={orderStates}
        value={selectedOrderStates}
        onValue={setSelectedOrderStates}
        placeholder={t("input-placeholders.states")}
        fieldNameToUpdate={"state"}
        updateField={updatePromotionField}
      />
      <Messages ref={msgs} />

      <h4>Filtro por atributo</h4>
      <CustomSelect
        floatLabel={true}
        optionLabel={"name"}
        options={orderAttributes}
        value={selectAttributeOrders}
        placeholder={t("input-placeholders.aplicableAttribute")}
        onChange={(e) => setSelectAttributeOrder(e.value)}
      />
      <CustomChips
        floatLabel={true}
        type="number"
        value={attributesValues.map((num) => num)}
        onChange={(e) => setAttributesValues(e.value.map((str) => str))}
        onFocus={() => handleFocusChipAttributes(setAttributesValuesOnBlur, attributesValues)}
        onBlur={() => handleBlurChipAttributes(attributesValuesOnBlur, attributesValues, setAttributesValuesOnBlur)}
        separator=","
        keyfilter="num"
        disabled={!selectAttributeOrders}
        placeholder={t("input-placeholders.attributeValues")}
      />

      <Divider />
      <h4>Filtro por producto / SKU </h4>
      <div className="flex items-center gap-4">
        <CustomInputSwitch checked={hasFilterBySKU} onChange={toggleFilterBySKU} />
        <h5>Filtrar por SKU</h5>
      </div>
      {!hasFilterBySKU ? (
        <div>
          <div className="grid mb-8 gap-4">
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={productId}
              placeholder={t("input-placeholders.productId")}
              onChange={(e) => setProductId(e.target.value)}
            />
            <Button label={t("Buscar Producto por id")} type="button" onClick={handleGetProduct} />
          </div>
          <CustomSelect
            floatLabel={true}
            optionLabel={"variation_label"}
            options={variationsData}
            value={selectedVariationOfData}
            placeholder={t("input-placeholders.selectSKU")}
            onChange={(e) => handleVariationSelect(e.value)}
            disabled={!productData || !variationsData}
          />
        </div>
      ) : (
        <CustomChips
          floatLabel={true}
          className="card p-fluid"
          type="number"
          value={valuesSKUS}
          onChange={(e) => setValuesSKUS(e?.value?.map((str) => str))}
          onFocus={() => handleFocusChipAttributes(setValuesSKUSOnBlur, valuesSKUS)}
          onBlur={() => handleBlurChipAttributes(valuesSKUSOnBlur, valuesSKUS, setValuesSKUSOnBlur)}
          separator=","
          placeholder={t("input-placeholders.chipSKUS")}
        />
      )}
      <Divider />
      <h4>Ordenes con guia</h4>
      <div className="flex flex-column align-items-center gap-3">
        <MultiStateCheckbox
          value={valueMultiCheckboxGuidesGenerated}
          onChange={(e) => setValueMultiCheckboxGuidesGenerated(e.value)}
          options={optionsFilterByGeneratedGuide}
          optionValue="value"
          style={{ fontSize: "2em" }} // Ajusta el valor según tus necesidades
        />
        <span>{valueMultiCheckboxGuidesGenerated || "Todas"}</span>
      </div>

      <Divider />

      <Button
        className={styles.secondaryButton}
        type={"button"}
        label={t("cleanFilters")}
        loading={globalLoading}
        onClick={cleanFilters}
      />
       <SecondaryButton
        onClick={cleanFiltersAndConsultOrders}
        type={"button"}
        label={t("cleanAndConsult")}
      />

      {multiSelectOptions?.includes(1) && !multiSelectOptions?.includes(2) && (
        <Button
          className={styles.secondaryButton}
          style={{ marginTop: "20px"}}
          type={"button"}
          label={t("fixOrdersWithNoSyncedItems")}
          loading={globalLoading}
          onClick={() => setVisibleVinculateItemsBatch(true)}
        />
      )}

      <MainButton style={{ marginTop: "20px" }} type={"submit"} label={t("consultOrders")} loading={globalLoading} autoFocus onClick={handleConsultOrders}/>
    </main>
  );
};
OrdersAdminFilters.propTypes = {
    t: PropTypes.func,
    cleanFiltersAndConsultOrders: PropTypes.func,
    multiSelectOptions: PropTypes.array,
    setMultiSelectOptions: PropTypes.func,
    options: PropTypes.array,
    sellerBusinessId: PropTypes.string,
    setSellerBusinessId: PropTypes.func,
    supplierId: PropTypes.string,
    setSupplierId: PropTypes.func,
    dates: PropTypes.array,
    setDates: PropTypes.func,
    orderStates: PropTypes.array,
    selectedOrderStates: PropTypes.array,
    setSelectedOrderStates: PropTypes.func,
    updatePromotionField: PropTypes.func,
    msgs: PropTypes.object,
    orderAttributes: PropTypes.array,
    selectAttributeOrders: PropTypes.string,
    setSelectAttributeOrder: PropTypes.func,
    attributesValues: PropTypes.array,
    setAttributesValues: PropTypes.func,
    handleFocusChipAttributes: PropTypes.func,
    handleBlurChipAttributes: PropTypes.func,
    hasFilterBySKU: PropTypes.bool,
    toggleFilterBySKU: PropTypes.func,
    productId: PropTypes.string,
    setProductId: PropTypes.func,
    handleGetProduct: PropTypes.func,
    variationsData: PropTypes.array,
    setVariationsData: PropTypes.func,
    selectedVariationOfData: PropTypes.string,
    handleVariationSelect: PropTypes.func,
    productData: PropTypes.object,
    valuesSKUS: PropTypes.array,
    setValuesSKUS: PropTypes.func,
    valuesSKUSOnBlur: PropTypes.array,
    attributesValuesOnBlur: PropTypes.array,
    setAttributesValuesOnBlur: PropTypes.func,
    setValuesSKUSOnBlur: PropTypes.func,
    valueMultiCheckboxGuidesGenerated: PropTypes.string,
    setValueMultiCheckboxGuidesGenerated: PropTypes.func,
    optionsFilterByGeneratedGuide: PropTypes.array,
    globalLoading: PropTypes.bool,
    cleanFilters: PropTypes.func,
    setVisibleVinculateItemsBatch: PropTypes.func,
    handleConsultOrders: PropTypes.func,
    toast: PropTypes.object
  };
export default OrdersAdminFilters;
