import { BiCaretDown, BiCaretUp, BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn.jsx";
import { useTranslation } from "react-i18next";
import CustomTable from "../../Components/CustomTable/CustomTable.jsx";
import DetailOptionCard from "../../Components/DetailOptionCard/DetailOptionCard.jsx";
import numeral from "numeral";
import { Toast } from "primereact/toast";
import useAuth from "../../hooks/useAuth.js";
import { db } from "../../firebase/firebase.js";
import {  useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import MainButton from "../../Components/MainButton/MainButton.jsx";
import CustomModal from "../../Components/CustomModal/CustomModal.jsx";
import RequestWithdrawalForm from "../../Components/RequestWithdrawalForm/RequestWithDrawalForm.jsx";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import { WALLETIDCOORDINADORA, WALLETIDENVIA, WALLETIDINTERRAPIDISIMO } from "../../Components/router/paths.js";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import toastFunction from "../../utils/toastFunction.js";

export default function ShippingWallet() {
  const { t } = useTranslation("Wallet");
  const { toast } = useAuth();
  const { shippingCompanies } = useGlobalContext();
  const { globalLoading, setGlobalLoading } = useLoaderContext();

  const [walletTransactions, setWalletTransactions] = useState([]);
  const [walletUserData ,setWalletUserData] = useState(null);

  const [shippingCompany, setSelectedShippingCompany] = useState(null);

  const [unsubscribeProductsSnapshot, setUnsubscribeProductsSnapshot] = useState(null);

  
  const transactionColumns = [
    {
      field: "transaction_direction",
      filterable: false,
      body: (rowData) => {
        return rowData.transaction_direction == 1 ? <BiCaretUp  className="text-green-500 size-10"/> : <BiCaretDown  className="text-red-500 size-10"/>;
      }
    },
    {
      field: 'created_at',
      header: t("table-headers.createdAt"),
      filterable: false,
      // Columna para el campo 'created_at' con encabezado traducido y cuerpo personalizado para formatear la fecha
      body: (rowData) => {
        const createdAt = rowData?.created_at
          ? rowData?.created_at?.toDate()?.toLocaleString()
          : t("table-headers.invalidDateFormat");
        return <span>{createdAt}</span>;
      }, 
    },
    { 
      field: "id",
      header: t("table-headers.transaction"),
      filterable: false,
      sortable: false, 
    },
    {
      field: "transaction_type",
      header: t("table-headers.transactionType"),      
      filterable: false,
      sortable: false, 
      body: (rowData) => decodifiedTransactionField(rowData.transaction_type, "transaction_type")
    },
    { 
      field: "state",
      header: t("table-headers.state"),
      filterable: false,
      sortable: false, 
      body: (rowData) => decodifiedTransactionField(rowData.state, "state")
    },
    { 
      field: "transaction_note",
      header: t("table-headers.transactionNote"),
      filterable: false,
      sortable: false, 
    },
    { 
      field: "amount",
      header: t("table-headers.amount"),
      filterable: false,
      sortable: false,
      body: (rowData) => {
        return numeral(rowData.amount).format(t("cashFormat"))
      }
    },
  ];
  const decodifiedTransactionField = (cellValueCodified, cellField) => {
    const transactionTypes = {
      "1": t("transactionTypes.wallet_topup"),
      "2": t("transactionTypes.subscription_payment"),
      "3": t("transactionTypes.order_payment"),
      "4": t("transactionTypes.funds_withdraw"),
      "5": t("transactionTypes.subscription_refund"),
      "6": t("transactionTypes.order_refund"),
      "7": t("transactionTypes.returned_ship")

    };
    const transactionStates = {
      "1": t("transactionStates.pending"),
      "2": t("transactionStates.paid"),
      "3": t("transactionStates.reject"),
    };
  
    // Determinar el objeto correcto basado en el argumento 'cellField'
    const transactionObject = cellField === 'state' ? transactionStates : transactionTypes;
    return (
      <span>
        {transactionObject[cellValueCodified] || t("Tipo de transaccion desconocida")}
      </span>
    );
  };
  
  useEffect(() => {
    return () => {
      if (unsubscribeProductsSnapshot && typeof unsubscribeProductsSnapshot === "function") {
        unsubscribeProductsSnapshot();
      }
    };
  }, [unsubscribeProductsSnapshot]);
  
//  // Verificar si walletUserData está definido antes de usarlo
//   if (!walletUserData) {
//     return <></>; // O un indicador de carga
//   }


const handleSelectShippingCompany = async (value) => {
  setGlobalLoading(true);
  // Establece la empresa de envíos seleccionada
  setSelectedShippingCompany(value);

  // Si ya hay un snapshot activo, lo desuscribimos
  if (unsubscribeProductsSnapshot && typeof unsubscribeProductsSnapshot === "function") {
    unsubscribeProductsSnapshot();
    setUnsubscribeProductsSnapshot(null);
  }

  // Manejamos el caso de selección de la empresa de envío
  let companyId = null;

  switch (value.code) {
    case 1:
      companyId = WALLETIDCOORDINADORA;
      break;
    case 2:
      companyId = WALLETIDENVIA;
      break;
    case 3:
      companyId = WALLETIDINTERRAPIDISIMO;
      break;
    default:
      console.log("Transportadora desconocida");
      return;
  }

  // Si se selecciona una empresa válida, suscribirse a los cambios
  if (companyId) {
    const unsubscribe = subscribeToCompany(companyId);

    // Guardar la función para desuscribirse cuando sea necesario
    setUnsubscribeProductsSnapshot(() => unsubscribe);
  }
};

// Función para suscribirse a los cambios en la compañía y sus transacciones relacionadas
const subscribeToCompany = (companyId) => {
  const companyDocRef = doc(db, "wallets", companyId);
  const transactionCollectionRef = collection(db, "transactions");

  // Escuchar los cambios en el documento de la compañía
  const unsubscribeCompanySnapshot = onSnapshot(
    companyDocRef,
    (docSnapshot) => {
      if (docSnapshot.exists()) {
        const walletDocuments = docSnapshot.data();
        console.log(`Datos de ${companyId}:`, walletDocuments);
        setWalletUserData(walletDocuments); // Actualiza el estado de los datos de la compañía
      }
    },
    (error) => {
      console.error(`Error obteniendo los datos de ${companyId}:`, error);
    }
  );

  // Consulta para las transacciones donde "related_wallet_id" coincida con "companyId"
  const transactionQuery = query(transactionCollectionRef, where("related_wallet_id", "==", companyId));

  // Escuchar los cambios en las transacciones relacionadas
  const unsubscribeTransactionsSnapshot = onSnapshot(
    transactionQuery,
    (querySnapshot) => {
      const transactions = [];
      querySnapshot.forEach((doc) => {
        transactions.push(doc.data());
      });
      console.log(`Transacciones relacionadas con ${companyId}:`, transactions);
      setWalletTransactions(transactions); // Actualiza el estado de las transacciones relacionadas
      console.log("transactions", transactions)
      if (transactions?.length === 0) {
        toastFunction(toast, "warn", "No hay transacciones por esta transportadora", "", 4000);
      }
    },
    (error) => {
      console.error(`Error obteniendo las transacciones relacionadas con ${companyId}:`, error);
    }
  );
  setGlobalLoading(false);
  // Devuelve una función que desuscribe ambas suscripciones
  return () => {
    unsubscribeCompanySnapshot();
    unsubscribeTransactionsSnapshot();
  };
};



  return (
    <div className="mb-20 ">
      <div style={{ marginBottom: "50px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtnShippingWallet")} />
      </div>

      <div className="mt-4 mb-12">
        <CustomSelect
          required={false}
          floatLabel={true}
          optionLabel="name"
          options={shippingCompanies}
          valueTemplate=""
          itemTemplate=""
          value={shippingCompany}
          placeholder="Selleciona una transportadora"
          onChange={(e) => handleSelectShippingCompany(e.value)}
        />
      </div>
      { shippingCompany ? (
        <div>
      
        <CustomTable
            className="custom-table"
            columns={transactionColumns}
            data={walletTransactions}
            action={() => {}}
          />
        <div className="mt-16">
          <DetailOptionCard
            bold_text={t("balanceTotal")}
            regular_text={numeral(walletUserData?.balance_ongoing + walletUserData?.balance_available)?.format(t("cashFormat"))}
            key={1}
            hidde_btn={true}
          />
          <DetailOptionCard
            bold_text={t("balanceIncomes")}
            regular_text={numeral(walletUserData?.balance_ongoing).format(t("cashFormat"))}
            key={2}
            hidde_btn={true}
          />
          <DetailOptionCard
            bold_text={t("balanceAvailable")}
            regular_text={numeral(walletUserData?.balance_available).format(t("cashFormat"))}
            key={3}
            hidde_btn={true}
          />
        
        </div>
      </div>) : <marquee>Selecciona una transportadora </marquee>}
      <Toast ref={toast} />
    </div>
  );
}
