const convertColorHexToRgb = (hex) =>  {
    console.log(hex)
  // Eliminar el carácter '#' si está presente
  hex = hex.replace(/^#/, '');

  // Verificar que la longitud sea válida (3 o 6 caracteres)
  if (hex.length !== 3 && hex.length !== 6) {
    throw new Error('El color hexadecimal debe tener 3 o 6 caracteres.');
  }

  // Verificar que solo contenga caracteres hexadecimales válidos
  if (!/^[0-9A-Fa-f]{3,6}$/.test(hex)) {
    throw new Error('El color hexadecimal contiene caracteres inválidos.');
  }

  // Si el formato es de 3 caracteres, expandirlo a 6 caracteres
  if (hex.length === 3) {
    hex = hex.split('').map(char => char + char).join('');
  }

  // Convertir los valores hexadecimales a decimales
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return [r, g, b, 1];
}

// Ejemplo de uso
// try {
//   console.log(hexToRgb("#0033ff")); // "rgb(0, 51, 255)"
//   console.log(hexToRgb("03f"));    // "rgb(0, 51, 255)"
//   console.log(hexToRgb("zzzzzz")); // Error: El color hexadecimal contiene caracteres inválidos.
// } catch (error) {
//   console.error(error.message);
// }

export default convertColorHexToRgb;
