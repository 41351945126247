import numeral from "numeral";
import { TieredMenu } from "primereact/tieredmenu";
import SecondaryButton from "../../Components/SecondaryButton/SecondaryButton.jsx";
import AuxStatusBadget from "../../Components/AuxStatusBadget/AuxStatusBadget.jsx";

const InsiderWithdrawalsTableColumns = (
    t,
    userBankAccounts,
    stateColors,
    transactionStates,
    menuItems, menu, isInsider, optionMenu, globalLoading) => [
    {
      field: "id",
      header: t("table-headers.id"),
    },
     {
      field: "related_business_id",
      header: t("table-headers.relatedBusinessId"),
      filterable: false,
      sortable: false,
    },
     {
      field: "related_business_name",
      header: t("table-headers.relatedBusinessName"),
      filterable: false,
      sortable: false,
    },
    {
      field: 'created_at',
      header: t("table-headers.createdAt"),
      filterable: false,
      // Columna para el campo 'created_at' con encabezado traducido y cuerpo personalizado para formatear la fecha
      body: (rowData) => {
        const createdAt = rowData?.created_at
          ? rowData?.created_at?.toDate()?.toLocaleString()
          : t("table-headers.invalidDateFormat");
        return <span>{createdAt}</span>;
      }, 
    },
    { 
      header: t("table-headers.bank"),
      filterable: false,
      sortable: false, 
      body : (rowData) => {
        const bank = userBankAccounts?.find((bank) => bank.id === rowData?.bank_account_id)
        return bank?.alias_account
      }
    },
    { 
        field: "amount",
        header: t("table-headers.amount"),
        filterable: false,
        sortable: false,
        body: (rowData) => {
          return numeral(rowData.amount).format(t("cashFormat"))
        }
      },
    {
      field: "news",
      header: t("table-headers.news"),      
      filterable: false,
      sortable: false, 
      body : (rowData) => {
        const lastNew = [rowData.news.length]-1
        return rowData.news[lastNew]?.text
      }
    },
    { 
        field: "voucher",
        header: t("table-headers.voucher"),
        filterable: false,
        sortable: false, 
        body : (rowData) => {
            const lastNew = [rowData.news.length]-1
            if (rowData.state === 1) {
                return ( <a href={rowData.news[lastNew].file}  target="_blank" rel="noopener noreferrer"> Ver archivo</a>);
            } else {
                return ( <span > Aun no hay ningún comprobante </span>);
            }
          }
      },
      {
        field: "state",
        header: t("table-headers.state"),
        filterable: false,
        sortable: false, 
        body: (rowData) => {
          const matchingStateColor = stateColors[rowData?.state];
          const matchingStateName = transactionStates[rowData?.state];
      
          if (matchingStateColor) {
            return (
              <AuxStatusBadget
                statusLabel={(
                  <span
                    style={{
                      backgroundColor: matchingStateColor,
                      color: "white",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      margin: "4px 0",
                      display: "flex",
                    }}
                  >
                    {matchingStateName}
                  </span>
                )}
              />
            );
          }
          return null; // Opcionalmente puedes devolver null si no se encuentra ningún estado coincidente
        }
      },
  
    {
        header: t("table-headers.options"),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <div className="card flex justify-content-center">
                <TieredMenu model={menuItems} popup ref={menu} breakpoint="767px" />
                <SecondaryButton
                  loading={globalLoading}
                  label={t("options")}
                  onClick={(e) => optionMenu(e, rowData)}
                  disabled={!isInsider || rowData?.is_processed}
                />
          </div>
        ),
      },
]
export default InsiderWithdrawalsTableColumns;