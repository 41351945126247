import PropTypes from "prop-types"
import { generateRandomId } from "../../utils/generateRandomId.js";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import { ReviewStars } from "../ReviewWidget/ReviewWidget.jsx";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import { useTranslation } from "react-i18next";
import BannerCard from "../BannerCard/BannerCard.jsx";
import { COVER_BANNER,  DESKTOP, MOBILE } from "../router/paths.js";
import CustomTextArea from "../FormComponents/CustomTextArea/CustomTextArea.jsx";
import CustomCalendar from "../FormComponents/CustomCalendar/CustomCalendar.jsx";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { collection,  orderBy, query, where } from "firebase/firestore";
import { db, storage } from "../../firebase/firebase.js";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import MainButton from "../MainButton/MainButton.jsx";
import processMediaArray from "../../utils/processMediaArray.js";
import toastFunction from "../../utils/toastFunction.js";
import DeleteButton from "../DeleteButton/DeleteButton.jsx";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import { deleteObject, ref } from "firebase/storage";
import { getPaginatedProducts } from "../../utils/getPaginatedProducts.js";

const DetailReviewForm = ({selectedRowReviewData, onClose, toast}) => {
    const hasEdit = !!Object.keys(selectedRowReviewData)?.length;
    const copySelectedReviewObject = {
        ...selectedRowReviewData
    }
    // Eliminamos los campos que se usaron para marcar el comentario y que el objeto quede identico al original 
    delete copySelectedReviewObject.store_name
    delete copySelectedReviewObject.relatedReviews
    
    const { getDocumentsByQuery, updateArray, removeFromArray, addToArray} = useFirebaseCRUD();
    const { userSellerOwnerData, userData }= useAuthContext()
    const { globalLoading, setGlobalLoading } = useLoaderContext();
    const { t } = useTranslation("Stores");
    const groupType = "review"
    const {defaultCoverImage, coverTypes} = useGlobalContext();

    const [ mediaType, setMediaType ] = useState(null);
    const [ relatedCustomers, setRelatedCustomers ] = useState([]);
    const [ selectedCustomer, setSelectedCustomer ] = useState([]);
    const [ selectedStore, setSelectedStore ] = useState(null);
    const [ selectedProduct, setSelectedProduct ] = useState(null);
    const [ filteredSellerStores, setFilteredSellerStores ] = useState([]);
    const [ syncedProducts, setSyncedProducts ] = useState([]);
    const [ visibleConfirmDeleteReviewDialog, setVisibleConfirmDeleteReviewDialog ] = useState(false);
    const [ score, setScore] = useState(0);


    const reviewInitialValues = { 
        id: selectedRowReviewData?.id || generateRandomId(), // id unico del review,
        product_id: selectedRowReviewData?.product_id || "", // id unico del producto sobre el cual se hace el review
        product_name: selectedRowReviewData?.product_name || "", // id unico del producto sobre el cual se hace el revie
        created_at: selectedRowReviewData?.created_at || new Date(), // timestamp de la fecha en la que se creo el review
        is_published: selectedRowReviewData?.is_published || true, // booleano que indica si el review esta visible

        store_id: selectedRowReviewData?.store_id || null,
        related_customer_id: selectedRowReviewData?.related_customer_id || "" ,// id del cliente que dejo el review // null si no es de un cliente
        related_customer_email: selectedRowReviewData?.related_customer_email || "", // email del cliente que dejo el review
        related_customer_name: selectedRowReviewData?.related_customer_name || "", // nombre del cliente que dejo el review
        order_verified: selectedRowReviewData?.order_verified ||  false, // booleano que indica si el review esta legiticamente asociado a un pedido
        review_text: selectedRowReviewData?.review_text || "", // texto asociado al id,
        media_type: selectedRowReviewData?.media_type || null, // tipo de medio (image, video)
        media_src: selectedRowReviewData?.media_src || defaultCoverImage, // fuente del medio URL de la imagen o del video
        media_path: selectedRowReviewData?.media_path || null, // fuente del medio URL de la imagen o del video
        score: selectedRowReviewData?.score || score  // calificacion entregada por el cliente 
    }
    const [ reviewValues, setReviewValues ] = useState(reviewInitialValues);
    const [ dates, setDates ] = useState(hasEdit ? new Date(reviewValues?.created_at?.seconds * 1000) : new Date);
    
    
    const handleInputChange = (fieldName, value) => {
        if(fieldName === "media_type") {
            setReviewValues((prevState) => {
              return {...prevState, [fieldName]: value.string}
            })
            setMediaType(value)
        } else {
            setReviewValues((prevState) => {
              return {...prevState, [fieldName]: value}
            })
        }
    }
    const handleDeleteCoverBanner = (mediaType) => {
        // Mostrar imagen predeterminada si mediaSrc está vacío
        switch (mediaType) {
            case DESKTOP:
            setReviewValues((prevData) => ({
                ...prevData,
                media_src:  defaultCoverImage,
            }));
            break;
            case MOBILE:
            setReviewValues((prevData) => ({
                ...prevData,
                media_mobile_src: defaultCoverImage,
            }));
            break;

            default:
            break;
        }
        
    }
    const handleStoreSelect = async (store) => {
        setReviewValues((prevValues) => ({
            ...prevValues,
            store_id: store.id,
        }));
        setSelectedStore(store);
    };
    const handleProductSelect = (product) => {
        setSelectedProduct(product)
        setReviewValues((prevData) => {
            return {
                ...prevData,
                product_id: product.id,
                product_name: product.product_name
            }
        })
    }
    const handleCustomerSelect = (customer) => {
        setSelectedCustomer(customer)
        setReviewValues((prevValues) => ({
        ...prevValues,
        related_customer_name: `${customer.first_names}  ${customer.last_names}`,
        related_customer_email: customer.actual_email,
        related_customer_id: customer.id,
        customer_id: customer.id,
      }));
    }
    // Funcion para obtener los clientes relacionados al usuario
    const getCustomers = async () => {
        setGlobalLoading(true);
        const relatedStores = userSellerOwnerData?.seller_stores || userData?.seller_stores || [];
        const relatedStoresIds = relatedStores?.map((store) => store.id);
        try {
            // Referencia a la colección "customers" en la base de datos
            const customersCol = collection(db, "customers");
            // Consulta para obtener clientes filtrados por IDs de tiendas, ordenados por fecha de creación
            const q = query(customersCol, where("related_stores_ids", "array-contains-any", relatedStoresIds), orderBy("created_at", "asc"));
            const shoppexCustomers = await getDocumentsByQuery(q);
            const findedCustomer = shoppexCustomers.find((customer) => customer.id === selectedRowReviewData.related_customer_id)
            setRelatedCustomers(shoppexCustomers);        
            setSelectedCustomer(findedCustomer)
            setGlobalLoading(false);
        } catch (error) {
            return () => {};
        }
    };
    const handleSubmitReview = async (e) => {
        setGlobalLoading(true);
        let result;
        e.preventDefault();
        await processMediaArray([reviewValues])
        // console.log("Antes del submit", selectedRowReviewData.store_id, reviewValues, copySelectedReviewObject)
        if ( hasEdit ) {
            result = await updateArray("reviews", selectedRowReviewData.store_id, "reviews", copySelectedReviewObject, reviewValues );
        } else {
            result = await addToArray("reviews", reviewValues.store_id, "reviews", reviewValues );
        }
        if (result) {
            toastFunction(toast, "success", "Comentario guardado con exito", "", 3000);
            onClose()
        } else {
            toastFunction(toast, "error", "Hubo un error al guardar el comentario", "", 4000);

        }
        setGlobalLoading(false);
    }
    const handleDeleteReview = async () => {
        try {
            const storageRef = ref(storage, selectedRowReviewData.media_path);
            await deleteObject(storageRef);
            const result = await removeFromArray("reviews", reviewValues.store_id, "reviews", copySelectedReviewObject);
            if (result) {
                onClose();
                toastFunction(toast, "success", "Comentario eliminado con exito", "", 3000);
            } else {
                toastFunction(toast, "error", "Hubo un error al eliminar el comentario", "", 3000);
            }
        } catch (error) {
            console.error(error)
                toastFunction(toast, "error", "Hubo un error al eliminar el comentario", "", 3000);
        }
    }
    const handleScore = (e) => {
        setScore(e);
        setReviewValues((prevData) => { 
            return {
                ...prevData,
                score: e,
            }
        })
    }
    // Función para calcular la cantidad de productos y productos sincronizados con Shoppex de todas las tiendas filtradas
    const amountSyncedProductsValues = (filteredSellerStores) => {
        if (!userData || !filteredSellerStores ) return [];
        const productsIds = [];
        filteredSellerStores.forEach((store) => {
        if (store.synced_products) {
            store.synced_products.forEach((item) => {
            if (item?.product_id) {
                productsIds.push(item.product_id);
            }
            });
        }
        });
        // Devolver objetos con IDs de productos y productos sincronizados
        return { productsIds };
    };
   // Trae los productos de la coleccion "products" en base a su Id
    const getProductsFromIds = async (productsIds) => {
        try {
        if ( productsIds.length === 0 ) {
            return []
        }
        const body = {
            productsIds: productsIds,
            quantityDocuments: 20,
            type: "onlyProductsSnapshot",
        };
        const response = await getPaginatedProducts(body)
        setSyncedProducts(response)
        if ( hasEdit ){
            const findedProduct = response?.find((product) => product.id === reviewValues.product_id)
            setSelectedProduct(findedProduct);
        }
        
        if (!response) {
            throw new Error("Error al obtener los productos");
        }
        return response;
        } catch (error) {
        console.error("Error al obtener los productos:", error);
        throw error;
        }
    };
    useEffect( function loadInitialData() {
        setGlobalLoading(true);
        const userStores = userSellerOwnerData?.seller_stores || userData.seller_stores
        const filteredStores = userStores.filter((store) => store.platform.code === 1);
        const findedSellerStore = userStores.find((store) => store.id === selectedRowReviewData?.store_id)
        const findendMediaType = coverTypes.find((type) => type.string == selectedRowReviewData.media_type)
        const {productsIds} = amountSyncedProductsValues(filteredStores);
        getProductsFromIds(productsIds)
        setMediaType(findendMediaType)
        setFilteredSellerStores(filteredStores)
        setSelectedStore(findedSellerStore)
        getCustomers();
        setGlobalLoading(false);
    }, []);

    return (
        <>
            <form className="grid gap-4"
                onSubmit={handleSubmitReview}>
                <CustomSelect
                    floatLabel={true}
                    required={true}
                    optionLabel={"name"}
                    options={filteredSellerStores}
                    value={selectedStore}
                    placeholder={t("input-placeholders.store")}
                    onChange={(e) => handleStoreSelect(e.value)}
                    disabled={hasEdit || globalLoading}
                />
                 <CustomSelect
                    floatLabel={true}
                    optionLabel={"product_name"}
                    required={true}
                    options={syncedProducts}
                    value={selectedProduct}
                    placeholder={t("input-placeholders.product")}
                    onChange={(e) => handleProductSelect(e.value)}
                    disabled={hasEdit || globalLoading}
                />
                <span className="">
                    <ReviewStars reviews={[]} totalScore={reviewValues?.score || 0} totalScoreChange={handleScore} readOnly={false}/>
                </span>
                <CustomSelect
                    floatLabel={true}
                    optionLabel={"name"}
                    options={coverTypes}
                    valueTemplate={""}
                    itemTemplate={""}
                    value={mediaType}
                    placeholder={t("input-placeholders.coverTypes")}
                    onChange={(e) => handleInputChange("media_type", e.target.value)}
                    required={true}
                    disabled={globalLoading || !selectedStore}
                />

                <BannerCard shoppexStoreId={reviewValues.store_id} data={reviewValues} coverTypeMedia={mediaType} coverType={COVER_BANNER} groupType={groupType} groupId={reviewValues.id} mediaType={DESKTOP} onClick={handleDeleteCoverBanner} isErasable={true}  setBannerValues={setReviewValues}/>

                <CustomTextArea
                    value={reviewValues.review_text}
                    placeholder={t("input-placeholders.reviewText")}
                    onChange={(e) => handleInputChange("review_text", e.target.value)}
                    floatLabel={true}
                    disabled={globalLoading}
                />
                <CustomCalendar
                    floatLabel={true}
                    dates={dates}
                    setDates={(e) => setDates(e.target.value)}
                    selectionMode="single"
                    placeholder={t("input-placeholders.createdAt")}
                    required={true}
                    disabled={globalLoading}
                />
                <CustomSelect
                    floatLabel={true}
                    optionLabel={"first_names"}
                    options={relatedCustomers}
                    valueTemplate={""}
                    itemTemplate={""}
                    value={selectedCustomer}
                    placeholder={t("input-placeholders.relatedCustomers")}
                    onChange={(e) => handleCustomerSelect(e.target.value)}
                    disabled={relatedCustomers?.length === 0 || globalLoading || hasEdit}
                />
                <CustomInputText
                    floatLabel={true}
                    type={"text"}
                    value={reviewValues.related_customer_name}
                    placeholder={t("input-placeholders.customerName")}
                    onChange={(e) => handleInputChange("related_customer_name", e.target.value)}
                    disabled={globalLoading}
                    required={true}
                />

                <CustomInputText
                    floatLabel={true}
                    type={"email"}
                    value={reviewValues.related_customer_email}
                    placeholder={t("input-placeholders.email")}
                    onChange={(e) => handleInputChange("related_customer_email", e.target.value)}
                    disabled={globalLoading}
                    required={true}
                />

                <div className="">
                    <CustomInputSwitch
                        checked={reviewValues?.is_published}
                        onChange={(e) => handleInputChange("is_published", e.target.value)}
                        disabled={globalLoading}
                    />
                    <h5>{t("postReview")}</h5>
                </div>
                <div className="mt-12 grid gap-4">
                <MainButton
                    type="submit"
                    label={t("saveReview")}
                    loading={globalLoading}
                />
               { hasEdit && ( <DeleteButton
                    type={"button"}
                    label={t("deleteReview")}
                    loading={globalLoading}
                    onClick={() => setVisibleConfirmDeleteReviewDialog(true)}
                    disabled={globalLoading}
                />)}
            </div>

            </form>
            <CustomConfirmDialog
                 visible={visibleConfirmDeleteReviewDialog}
                 onHide={() => setVisibleConfirmDeleteReviewDialog(false)}
                 header={t("confirmDialog-deleteReview.header")}
                 message={t("confirmDialog-deleteReview.message")}
                 icon="pi pi-question-circle"
                 accept={handleDeleteReview}
                 reject={() => setVisibleConfirmDeleteReviewDialog(false)}
                 acceptLabel={t("confirmDialog-deleteReview.acceptLabel")}
                 rejectLabel={t("confirmDialog-deleteReview.rejectLabel")}
               />
        </>
    )
}
DetailReviewForm.propTypes = {
    selectedRowReviewData: PropTypes.object,
    onClose: PropTypes.func, 
    toast: PropTypes.object,
}

export default DetailReviewForm;


