import React from 'react'
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import styles from './SecondaryButton.module.css';

export default function SecondaryButton({style, label, icon, iconPos, badge, disabled, loading, onClick, type, autoFocus}) {
  return (
    <Button
        style={style}
        className={`clickable ${styles.SecondaryButton}`}
        label={label}
        icon={icon}
        iconPos={iconPos}
        badge={badge}
        disabled={disabled} 
        loading={loading}
        onClick={onClick}
        type={type}
        autoFocus={autoFocus}
    />
  )
}
