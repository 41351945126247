import { BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn.jsx";
import { useTranslation } from "react-i18next";
import { Toast } from "primereact/toast";
import useShopifyRequest from "../../hooks/useShopifyRequest.js";
import ProductGridCard from "../../Components/ProductGridCard/ProductGridCard.jsx";
import CircleCard from "../../Components/CircleCard/CircleCard.jsx";
import { useEffect, useRef, useState } from "react";
import CustomModal from "../../Components/CustomModal/CustomModal.jsx";
import DetailProduct from "../../Components/DetailProduct/DetailProduct.jsx";
import DetailCatalogProductProfitForm from "../../Components/DetailCatalogProductProfitForm/DetailCatalogProductProfitForm.jsx";
import CustomInputText from "../../Components/FormComponents/CustomInputText/CustomInputText.jsx";

import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import HorizontalScrollList from "../../Components/HorizontalScrollList/HorizontalScrollList.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { getPaginatedProducts, getProductsByTerm } from "../../utils/getPaginatedProducts.js";
import  toastFunction  from "../../utils/toastFunction";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import { collection } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import ProductCartForm from "../../Components/ProductCartForm/ProductCartForm.jsx";
import CheckoutShippingCartForm from "../../Components/CheckoutShippingCartForm/CheckoutShippingCartForm.jsx";
import { ShoppingCartWidget } from "../../Components/ShoppingCartComponents/ShoppingCartWidget/ShoppingCartWidget.jsx";
import CustomConfirmDialog from "../../Components/OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import useSaveScrollPosition from "../../hooks/useSaveScrollPosition.js";

// Componente de pagina que muestra los productos de 10X Booster y permite la vinculacion con productos de tiendas de shopify asociadas
export default function Products ({isCatalog, isCatalogOwner, disabledAddToCartBtn, handleToggleHiddenProduct, visibleProducts}) {
  const { userData, userSellerOwnerData, catalogData } = useAuthContext();
  const userOwnerData = userSellerOwnerData || userData;

  const {getDocumentsByQuery} = useFirebaseCRUD();
  const { t } = useTranslation("Products");
  const { toast } = useShopifyRequest();
  const [products10X, setProducts] = useState([]);
  const [categorys10X, setCategorys10X] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [visibleDetailProduct, setVisibleDetailProduct] = useState(false);
  const [selectedCategory, setselectedCategory] = useState(null);
  const [selected10xProduct, setselected10xProduct] = useState();
  const [selectedCartItem, setSelectedCartItem] = useState();

  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const [loadInitialData, setLoadInitialData] = useState(false);
  const [reachedBottom, setReachedBottom] = useState(false);
  
  const [unsubscribeProductsSnapshot, setUnsubscribeProductsSnapshot] = useState(null);
  const documentsPerPage = 20;
  const [quantityDocuments, setQuantityDocuments] = useState(documentsPerPage);
  const isInsider = (userData?.user_custom_claims?.type === "insider" || userData?.user_custom_claims?.type === "master")  ? true : false;

  const [visibleProductCartForm, setVisibleProductCartForm] = useState(false);
  const [visibleCheckoutForm, setVisibleCheckoutForm] = useState(false);
  
  const [visibleConfirmRemoveProductOfCart, setVisibleConfirmRemoveProductOfCart] = useState(false);
  const { deleteProductToShoppingCart } = useGlobalContext();
  const [ ocultLoadMoreProducts, setOcultLoadMoreProducts ] = useState(false);

  const [ visibleProductProfitForm, setVisibleProductProfitForm ] = useState(false)

  const queryProductBody = JSON.parse(localStorage.getItem('productsBodyQuery'));

  const [ productName, setProductName ] = useState(queryProductBody?.productName || null);
  const [ productBodyQuery, setProductBodyQuery ] = useState({});

  const navigate = useNavigate();
  // Hook que guarda la posicion del scroll en el local storage
  useSaveScrollPosition();

  // Función para manejar la selección de una categoría
  const handleSelectCategory = async (category) => {
    let resultCategoryFetch;
    let type = "";
    // Verifica si la categoría seleccionada es diferente a la categoría ya seleccionada
    if (category?.id !== selectedCategory?.id) {
      // Determina el tipo de operación según la categoría seleccionada
      if (category.id === "ALLCATEGORIES") {
        type = "allCategories";
        resultCategoryFetch = await getProductosFromCategory(
          category,
          type,
        );
      } else {
        type = "oneCategory";
        resultCategoryFetch = await getProductosFromCategory(
          category,
          type,
        );
      }
    }
    if (resultCategoryFetch) {
      if (resultCategoryFetch?.products?.length === 0) {
        toastFunction(toast, "warn", t("noProductsWithTheseCategory"), "", 2000);
      }
      else if (resultCategoryFetch?.products?.length > 0) {
        toastFunction(toast, "success", t("succefullQuery"),  "", 3000 );
      } 
    }
    setProductName("");
    setOcultLoadMoreProducts(false);
  };
  // Función para obtener productos de una categoría desde la API de 10X Booster Commercial partners
  const getProductosFromCategory = async (category, type) => {
    setQuantityDocuments(documentsPerPage+documentsPerPage);
    setGlobalLoading(true);
    let body = {};
    if (type === "oneCategory") {
      body = {
        productValuesCategories: category,
        quantityDocuments: documentsPerPage ,
        products10X,
        setProducts,
        setQuantityDocuments,
        type: "byCategories",
        isArray: false,
        isInsider,
        catalogData,
        isCatalogOwner
      };
    } else if (type === "allCategories") {
      body = {
        productValuesCategories: categories,
        quantityDocuments: documentsPerPage,
        products10X,
        setProducts,
        setQuantityDocuments,
        type: "byCategories",
        isArray: true,
        isInsider,
        catalogData,
        isCatalogOwner
      };
    }
    try {
      if (unsubscribeProductsSnapshot && typeof unsubscribeProductsSnapshot === "function") {
        unsubscribeProductsSnapshot();
        setUnsubscribeProductsSnapshot(null);
      }

      const response = await getPaginatedProducts(body);
        
      setUnsubscribeProductsSnapshot(() => {
        // Desuscribirse de la suscripción actual si existe
        if ( unsubscribeProductsSnapshot && typeof unsubscribeProductsSnapshot === "function" 
        ) {
          unsubscribeProductsSnapshot();
        }
        return response;
      });
      // Realiza la solicitud a la API para obtener productos
      if (!response) {
        throw new Error("Error al obtener los productos");
      }else {
        setselectedCategory(category);
      }
      setGlobalLoading(false);
      return response;
    } catch (error) {
      toastFunction(toast,  "error", t("errorQuery"), "", 2000,);
      setGlobalLoading(false);
      return false;
    }
  };
  // Función para obtener las categorías desde la API de 10X Booster Commercial partners
  const getCategorys = async () => {
    setGlobalLoading(true);
    try {
      const q = collection(db, "category_products");
      const response = await getDocumentsByQuery(q);
      
      if (!response) {
        throw new Error("Error al obtener las categorías");
      }
      // Organiza el array de categorías, mostrando primero "ALLCATEGORIES"
      const categoryToDisplayFirst = response.find(
        (category) => category.id === "ALLCATEGORIES"
      );
      const restOfCategories = response.filter(
        (category) => category.id !== "ALLCATEGORIES"
      );
      const reorderedCategories = [categoryToDisplayFirst, ...restOfCategories];

      setCategoriesData(restOfCategories);
      setCategories(response);
      setCategorys10X(reorderedCategories);
      setGlobalLoading(false);
      return response;
    } catch (error) {
      setGlobalLoading(false);
      return false;
    }
  };
  // Función para abrir el modal de tiendas y establecer el producto seleccionado
  const openDetailProductModal = (product) => {
    setVisibleDetailProduct(true);
    if (product.id) {
      setselected10xProduct(product);
    } else {
      setselected10xProduct({});
    }
  };
  // Función para cerrar el modal de tiendas
  const closeDetailProductModal = () => {
    setVisibleDetailProduct(false);
  };

  // Función de debounce
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        timeout = null;
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const debounceScroll = (quantityDocuments, products10X, bottom, productName) => {
    return debounce(() => {
      const isBottom = bottom || (window?.innerHeight + document?.documentElement?.scrollTop === document?.documentElement?.offsetHeight) ;
      if ((isBottom && (!productName || productName === ""))) {
        setReachedBottom(true);
        if (selectedCategory && selectedCategory.id !== "ALLCATEGORIES") {
          getProductsScroll("categoryScroll", quantityDocuments, products10X);
        } else {
          getProductsScroll("productScroll", quantityDocuments, products10X);
        }
      }
    }, 500); // tiempo de espera
  };
  
  // Función para obtener productos desde la API al hacer scroll
  const getProductsScroll = async (type, quantityDocuments, products10X) => {
    try {
      let body = null;
      if (type === "productScroll") {
        body = {
          quantityDocuments,
          setQuantityDocuments,
          type: "productScroll",
          products10X,
          setProducts,
          isInsider,
          setOcultLoadMoreProducts,
          catalogData,
          isCatalogOwner

        };
      } else if (type === "categoryScroll") {
        body = {
          quantityDocuments,
          type: "categoryScroll",
          setQuantityDocuments,
          productValuesCategories: selectedCategory,
          products10X,
          setProducts,
          isInsider,
          setOcultLoadMoreProducts,
          catalogData,
          isCatalogOwner
        };
      }

      setProductBodyQuery(body)

      if (unsubscribeProductsSnapshot && typeof unsubscribeProductsSnapshot === "function") {
        unsubscribeProductsSnapshot();
        setUnsubscribeProductsSnapshot(null);
      }
      const response = await getPaginatedProducts(body);
      setUnsubscribeProductsSnapshot(() => {
        // Desuscribirse de la suscripción actual si existe
        if (unsubscribeProductsSnapshot && typeof unsubscribeProductsSnapshot === "function") {
          unsubscribeProductsSnapshot();
        }
        return response;
      });

      if (!response) {
        throw new Error("Error al obtener los productos");
      }
    } catch (error) {
      console.error("Error al obtener productos:", error);
    }
  };

  const openProductCartModal = (origen, product) => {
    // console.log(product)
    setVisibleProductCartForm(true);
    // Al identificar el origen sabemos si es un produto de 10x o un item del carrito
    let finded10xProduct= null;
    switch (origen) {
      case "10xProducts":
        setselected10xProduct(product);
        setSelectedCartItem({})
        break;
      case "shoppingCart":
        finded10xProduct = products10X.find((product10x) => product10x?.id === product?.product_id);
        setselected10xProduct(finded10xProduct);
        setSelectedCartItem(product)
        break;
    
      default:
        console.log("Origen desconocido")
        break;
    }
  };

  const openProductCartPage = (product) => {
    localStorage.setItem('productsBodyQuery', JSON.stringify(productBodyQuery));
    const catalogProductPageUrl = `/catalogProductPage/?catalogId=${catalogData?.id}&productId=${product?.id}`;
    navigate(catalogProductPageUrl);
  };

  const openProductProfitModal = (product) => {
    console.log(product)
    setVisibleProductProfitForm(true);
    setselected10xProduct(product);
    setSelectedCartItem({})
     
  };
  
  const handleRemoveItemFromCart = (productItem) => {
    setVisibleConfirmRemoveProductOfCart(true);
    setSelectedCartItem(productItem);
  };

  const handleConsultByProductName = async (productName) => {
    setProductName(productName);
    const body = {
      quantityDocuments,
      setQuantityDocuments,
      type: "byProductName",
      products10X: products10X.length,
      setProducts,
      productName,
      catalogData,
      isCatalogOwner
    };
    if(productName === "") {
      body.type = "productScroll";
    }
   
    setProductBodyQuery(body)

    if (unsubscribeProductsSnapshot && typeof unsubscribeProductsSnapshot === "function") {
      unsubscribeProductsSnapshot();
      setUnsubscribeProductsSnapshot(null);
    }
    const response = await getPaginatedProducts(body);
    setUnsubscribeProductsSnapshot(() => {
      // Desuscribirse de la suscripción actual si existe
      if (unsubscribeProductsSnapshot && typeof unsubscribeProductsSnapshot === "function") {
        unsubscribeProductsSnapshot();
      }
      return response;
    });
  };


  // Efecto secundario para agregar y quitar el listener de scroll
  useEffect(function addScrollListener() {
    function handleScroll() {
      debounceScroll(quantityDocuments, products10X.length, false, productName)();
    }

    window.addEventListener("scroll", handleScroll);
    
    return function removeScrollListener() {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [quantityDocuments, reachedBottom, productName]);


  // Efecto para reiniciar reachedBottom cuando cambie la categoría seleccionada o quantityDocuments
  useEffect(function resetReachedBottom() {
    // console.log("reset reached", products10X.length, quantityDocuments);
    setReachedBottom(false);
  }, [selectedCategory, quantityDocuments, products10X]);

  // Efecto secundario para cargar las categorías iniciales y productos al montar el componente
  useEffect(() => {
    setGlobalLoading(true);
    // Carga productos iniciales
    const loadInitialProducts = async () => {
      await getCategorys();

      if (!loadInitialData) {
        try {
          let body = {
            quantityDocuments,
            setQuantityDocuments,
            type: "productScroll", 
            products10X: products10X.length,
            setProducts,
            isInsider,
            catalogData,
            isCatalogOwner,
          };
          // Si hay un query en el localStorage lo usamos para reanudar la busqueda
          if(queryProductBody) {
            body = {...body, ...queryProductBody}
          }
          
          setProductBodyQuery(body)
          if (unsubscribeProductsSnapshot && typeof unsubscribeProductsSnapshot === "function") {
            unsubscribeProductsSnapshot();
            setUnsubscribeProductsSnapshot(null);
          }

          const response = await getPaginatedProducts(body);
          // setQuantityDocuments(prevQuanty => prevQuanty + 1);  
          setUnsubscribeProductsSnapshot(() => {
            // Desuscribirse de la suscripción actual si existe
            if (
              unsubscribeProductsSnapshot &&
              typeof unsubscribeProductsSnapshot === "function" 
            ) {
              unsubscribeProductsSnapshot();
            }

            return response;
          });
        } catch (error) {
          console.error("Error al obtener productos:", error);
        } finally {
          localStorage.removeItem('productsBodyQuery');
          setGlobalLoading(false);
        }
      }
    };
    loadInitialProducts();
    setLoadInitialData(true);
    
  }, [userData, userSellerOwnerData, isCatalog, catalogData]);

  return (
    <div className="mb-24">  
     <ScrollToPosition />
      {!isCatalog &&(<div className="">
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtn")} />
      </div>)}
      <div >
        <HorizontalScrollList>
        {categorys10X.map((category, index) => (
            <CircleCard
              key={index}
              img_url={category?.img_url}
              img_alt={category?.img_alt}
              img_placeholder={category?.img_url}
              label={category?.category_label}
              onClick={() => handleSelectCategory(category)}
              without_border={null}
              out_circle_color={"--main-hard-color"}
              isSelected={category === selectedCategory} // Pasar el estado de selección a cada CircleCard
            />
          ))}
        </HorizontalScrollList>
        <h3 style={{marginBottom:"20px"}}> {selectedCategory?.category_label}</h3>
      </div>
      <div className="mt-2 mb-4 ">
        <CustomInputText
              required={true}
              floatLabel={true}
              type="text"
              value={productName}
              placeholder={t("input-placeholders.productName")}
              onChange={(e) =>handleConsultByProductName(e.target.value)}
              disabled={false}
            />

      </div>
      <div className="">
        {products10X?.length > 0 && loadInitialData ? (
          <div className="grid grid-cols-2 gap-4 md:grid-cols-3 mb-6">
            {products10X?.map((product, index) => (
              <ProductGridCard 
                key={product.id}
                index={index} // Índice del producto en la lista
                user_data={userOwnerData} // Datos de usuario
                product_data={product} // Datos del producto actual del arrayopenDetailProductModal
                img_placeholder={product.product_name} // URL de imagen por defecto

                onClick={((isCatalog && !isCatalogOwner) && !disabledAddToCartBtn)  ? 
                  () => openProductCartPage(product) 
                  : (!isCatalogOwner || disabledAddToCartBtn)  ? 
                    () => openDetailProductModal( product) 
                  : () => openProductCartModal("10xProducts", product)}
        
                onClickAddToCart={((isCatalog && !isCatalogOwner) && !disabledAddToCartBtn)  ?
                   () => openProductCartPage({...product, custom_profits: catalogData?.custom_profits}) :  
                   isCatalogOwner ? () => openProductProfitModal(product) 
                   : () => openProductCartModal("10xProducts", product)}
                
                route={"products"}
                isCatalog={isCatalog}
                isCatalogOwner={isCatalogOwner}
                disabledAddToCartBtn={disabledAddToCartBtn}
                handleToggleHiddenProduct={handleToggleHiddenProduct}
                catalogData={{...catalogData, visible_products: visibleProducts}}
              />
            ))}
          </div>
        ) : (
          <marquee>No se encontraron productos</marquee>
        )}
      </div>
         {( !productName || productName === "") && ( <div className="flex justify-center mt-2 mb-4">
          <Button 
              loading={globalLoading}
              onClick={() => debounceScroll(quantityDocuments + documentsPerPage, products10X.length, true, productName)()}
              label="Cargar mas productos"
              />
          </div>)}
      <div className="fixed-footer">
      {((isCatalog && !isCatalogOwner && !disabledAddToCartBtn) || (!isCatalog && !disabledAddToCartBtn)) && (
        <ShoppingCartWidget 
          products10X={products10X}
          openProductCartForm={openProductCartModal}
          openCheckoutModal={() => setVisibleCheckoutForm(true)}
          handleRemoveFromCart={handleRemoveItemFromCart}
          isCatalog={isCatalog}
        />
      )}

      </div>
        <CustomModal
          visible={visibleProductProfitForm}
          setVisible={setVisibleProductProfitForm}
          object={selected10xProduct}
          // menu_option={{label:t('menu-options.home')}}
          editMode={t("detailProduct")}
          createMode={t("vinculateNewProduct")}
          content={
            <DetailCatalogProductProfitForm
              productData={selected10xProduct}
              categoryProductsData={categoriesData}
              toast={toast}
              onClose={() => setVisibleProductProfitForm(false)}
              userData={userOwnerData}
              isCatalog={isCatalog}
            />
          }
          onClose={closeDetailProductModal}
        />
        <CustomModal
          visible={visibleDetailProduct}
          setVisible={setVisibleDetailProduct}
          object={selected10xProduct}
          // menu_option={{label:t('menu-options.home')}}
          editMode={t("detailProduct")}
          createMode={t("vinculateNewProduct")}
          content={
            <DetailProduct
              productData={selected10xProduct}
              categoryProductsData={categoriesData}
              toast={toast}
              onClose={closeDetailProductModal}
              userData={userOwnerData}
              isCatalog={isCatalog}
            />
          }
          onClose={closeDetailProductModal}
        />
        <CustomModal
          visible={visibleProductCartForm}
          setVisible={setVisibleProductCartForm}
          object={selected10xProduct}
          content={
            <ProductCartForm
              selectedCartItem={selectedCartItem}
              selectedProduct={selected10xProduct}
              onClose={() => setVisibleProductCartForm(false)}
              toast={toast}
              isCatalog={isCatalog}
            />
          }
          onClose={() => setVisibleProductCartForm(false)}
        />
        <CustomModal
          visible={visibleCheckoutForm}
          setVisible={setVisibleCheckoutForm}
          object={selected10xProduct}
          content={
            <CheckoutShippingCartForm
              selectedCartItem={selectedCartItem}
              selectedProduct={selected10xProduct}
              toast={toast}
              onClose={() => setVisibleCheckoutForm(false)}
              isCatalog={isCatalog}
              catalogData={catalogData}

            />
          }
          onClose={() => setVisibleCheckoutForm(false)}
        />
      <CustomConfirmDialog
          visible={visibleConfirmRemoveProductOfCart}
          onHide={() => setVisibleConfirmRemoveProductOfCart(false)}
          header={t("confirmDialog-deleteProductFromCart.header")}
          message={t("confirmDialog-deleteProductFromCart.message")}
          icon="pi pi-question-circle"
          accept={() => deleteProductToShoppingCart(selectedCartItem)}
          reject={() => setVisibleConfirmRemoveProductOfCart(false)}
          acceptLabel={t("confirmDialog-deleteProductFromCart.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteProductFromCart.rejectLabel")}
      />
      <Toast ref={toast} />
    </div>
  );
}

// Funcion que obtiene el valor del scroll del localstorage e intenta llegar hasta el valor del scroll
// eslint-disable-next-line react/prop-types
const ScrollToPosition = () => {
  useEffect(() => {
    const previousLocationRef = localStorage.getItem('previousLocationRef');

    if (previousLocationRef === "/catalogProductPage/") {
      const savedScrollPosition = localStorage.getItem('scrollPosition');
      if (savedScrollPosition) {
        const scrollToSavedPosition = (position, retries = 10) => {
          window.scrollTo(0, parseInt(position, 10));

          // Verificar si el scroll llegó a la posición esperada
          const currentScrollPosition = window.scrollY;
          if (currentScrollPosition !== parseInt(position, 10) && retries > 0) {
            // Vuelve a intentarlo si no se ha alcanzado la posición correcta
            setTimeout(() => {
              scrollToSavedPosition(position, retries - 1);
            }, 100); // Intervalo de reintento (ajústalo según sea necesario)
          }
        };

        // Intenta desplazarse a la posición guardada
        scrollToSavedPosition(savedScrollPosition);
      }
    }
  }, []);

  return null; // Este es un componente que solo maneja el scroll
};