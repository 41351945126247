import { onSnapshot } from "firebase/firestore";
import toastFunction from "./toastFunction";

const getOnsnapshotByQuery = (query, callback, toast) => {
    const unsubscribe = onSnapshot(query, (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data() });
      });
      callback(data);
      if(data.length === 0) {
        toastFunction(toast, "info", "No se obtuvieron datos con la consulta", "", 3000)
      }
    });
    return unsubscribe;
  };

export default getOnsnapshotByQuery;
