import React from 'react'
import MainButton from '../../MainButton/MainButton'
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';


export default function CTA({label, navigateTo, style, restrictWidthOnDesktop = true}) {
  const navigate = useNavigate();
  const { isDesktop } = useGlobalContext();

  const btnStyle = {
    ...style,
  }

  return (
    <div style={{maxWidth:isDesktop && restrictWidthOnDesktop && "40%", marginLeft:"auto", marginRight:"auto"}}>
        <MainButton onClick={()=>navigate(navigateTo)} style={btnStyle} label={label} />
    </div>
  )
}
