import { RiHome2Line, RiSettings2Line, RiHeartLine, RiBookmarkLine } from 'react-icons/ri'; // Importa los iconos que desees utilizar
import { HiOutlineTruck } from "react-icons/hi2";
import { MdOutlinePayments } from "react-icons/md";
import { GiPayMoney } from "react-icons/gi";
import { MdOutlinePayment } from "react-icons/md";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { IoIosPricetags } from "react-icons/io";
import { MdPriceChange } from "react-icons/md";
import { AiOutlineWarning } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb"

import { FaLaptop } from "react-icons/fa";
import { IconContext } from 'react-icons'
import PropTypes from "prop-types";
// Componente de icono individual

// Función para obtener el componente de icono correspondiente al nombre
const getIconComponent = (name) => {
  switch (name) {
    case 'RiHome2Line':
      return RiHome2Line;
    case 'RiSettings2Line':
      return RiSettings2Line;
    case 'RiHeartLine':
      return RiHeartLine;
    case 'RiBookmarkLine':
      return RiBookmarkLine;
    case "FaLaptop":
      return FaLaptop
    case "AiOutlineWarning":
      return AiOutlineWarning
    case "HiOutlineTruck":
      return HiOutlineTruck
    case "MdPriceChange":
      return MdPriceChange
    case "MdOutlinePayments":
      return MdOutlinePayments
    case "GiPayMoney":
      return GiPayMoney
    case "MdOutlinePayment":
      return MdOutlinePayment
    case "MdOutlineProductionQuantityLimits":
      return MdOutlineProductionQuantityLimits
    case "IoIosPricetags":
      return IoIosPricetags
    case "TbTruckDelivery":
      return TbTruckDelivery
    default:
      return null;

  }
};

const IconProvider = ({ name, onClick, colorIcon, sizeIcon }) => {
  const IconComponent = getIconComponent(name, );
  const color = IconComponent ? colorIcon : "000000"; // Establece el color amarillo si no se encuentra el icono
  const size = sizeIcon ? sizeIcon : "2em"

  return (
    <div className="icon" onClick={() => onClick(name)}>
      <IconContext.Provider value={{ size: size, color: color }}>
        { IconComponent ? <IconComponent /> : <RiSettings2Line/> }
      </IconContext.Provider>
    </div>
  );
};
IconProvider.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    colorIcon: PropTypes.string,
    getIconComponent: PropTypes.func,
    sizeIcon: PropTypes.string,
}
export default IconProvider