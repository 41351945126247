import styles from './InfoBox.module.css';

export default function InfoBox ({text, copyCode, onClick, labelBtn ="COPIAR"}) {
  return (
      <div className={styles.card}>
        <div className={styles.cardHeader} onClick={copyCode}>
          {text}
        </div>
        <div className={`clickable ${styles.cardFooter}`} onClick={onClick}>
          {labelBtn}
        </div>
      </div>
    );
  }


