import { useEffect } from 'react';

const useSaveScrollPosition = () => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      localStorage.setItem('scrollPosition', scrollPosition);
    };

    // Agregar listener de scroll
    window.addEventListener('scroll', handleScroll);

    // Limpiar el listener al desmontar
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};

export default useSaveScrollPosition;
