import { useTranslation } from "react-i18next";
import MainButton from "../../Components/MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import CHECK  from "../../assets/LOTTIE/CHECK.json"
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import styles from "./CatalogCreatorSuccess.module.css"
import { useNavigate, useSearchParams } from "react-router-dom";


export default function CatalogCreatorSuccess() {
    const { t } = useTranslation("Catalog");
    const { globalLoading } = useLoaderContext();
    const navigate = useNavigate();

    // const shareCatalog = async () => {
    //     const message = `Hola, te comparto mi catalogo \n\n *${"sellerCatalogUrl"}*`;
    //     // waUrlHook("withoutPhone", null, message);
    // };

    const [searchParams] = useSearchParams();
    const catalogId = searchParams.get('catalogId');

    const sellerCatalogUrl = `/catalog/?catalogId=${catalogId}`;
    // const sellerCatalogUrl = `https://supplaii.com/catalog/?id=${catalogId}`;

    const openPreview = () => {
      navigate(sellerCatalogUrl)

    };
    return (
        <div className="flex flex-col  justify-between"
        style={{minHeight: "80vh"}}>
            <div className="">
                <h4 className="font-bold mt-2">Todo listo para previsualizar y compartir tu catalogo</h4>
                <div className="flex justify-center mt-16">
                    <LottieAnimation animationData={CHECK} style={{maxWidth:"80%"}} />
                </div>
                <h4 className={styles.catalogSucess}>Tu catalogo esta listo</h4>
            </div>
                
            <div  className="sticky-footer" >
                <MainButton
                    type={"button"}
                    label={t("viewMyCatalog")}
                    onClick={openPreview}
                    autoFocus
                    disabled={globalLoading}
                    loading={globalLoading}
                />
            </div>
        </div>
    );
}
