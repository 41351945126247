import { useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import { useSearchParams } from "react-router-dom";
import ProductCartForm from "../../Components/ProductCartForm/ProductCartForm.jsx";
import { Toast } from "primereact/toast";
import useAuth from "../../hooks/useAuth.js";
import { BiChevronLeft } from "react-icons/bi";
import GoBackBtnCatalog from "../../Components/GoBackBtn/GoBackBtnCatalog.jsx";

export default function CatalogProductPage() {
    const { toast } = useAuth();
    const isCalogProduct = location.pathname.startsWith('/catalogProductPage')

    const [searchParams] = useSearchParams();
    const catalogId = searchParams.get('catalogId');
    const productId = searchParams.get('productId');
    const [globalLoading, setGlobalLoading] = useState(false);
    const [catalogProduct, setCatalogProduct] = useState(undefined);

    useEffect(() => {
        let unsubscribeCatalog = null;
        let unsubscribeProduct = null;

         localStorage.setItem('previousLocationRef', location.pathname);


        if (window.location.pathname.startsWith('/catalog')) {
            setGlobalLoading(true);

            const qMyCatalog = query(collection(db, 'catalogs'), where('id', '==', catalogId));
            const qProduct = query(collection(db, 'products'), where('id', '==', productId));

            // Obtener datos del catálogo
            unsubscribeCatalog = onSnapshot(qMyCatalog, (querySnapshot) => {

                if (!querySnapshot.empty) {
                    const catalogDoc = querySnapshot.docs[0].data();

                    // Obtener datos del producto
                    unsubscribeProduct = onSnapshot(qProduct, (querySnapshot) => {
                        if (!querySnapshot.empty) {
                            const productData = querySnapshot.docs[0].data();
                            // console.log("Producto fetch",productData )
                            if (catalogDoc?.custom_profits?.[productId] !== undefined) {
                                const customCatalogProductProfit = catalogDoc.custom_profits[productId];
                                productData.custom_cost = productData.commercial_partner_price_with_VAT_with_service_fee + customCatalogProductProfit;
                                productData.custom_profit = customCatalogProductProfit;
                            }
                            
                            setCatalogProduct(productData);
                        } else {
                            console.log("No se encontró el producto para productId:", productId);
                        }
                    }, (error) => {
                        console.error("Error al obtener el producto:", error);
                    });
                } else {
                    console.log("No se encontró el catálogo para catalogId:", catalogId);
                }
                setGlobalLoading(false);
            }, (error) => {
                console.error("Error al obtener el catálogo:", error);
                setGlobalLoading(false);
            });
        }

        // Limpieza cuando el componente se desmonta o la ubicación cambia
        return () => {
            if (unsubscribeCatalog) {
                unsubscribeCatalog(); // Cancela la suscripción del catálogo
            }
            if (unsubscribeProduct) {
                unsubscribeProduct(); // Cancela la suscripción del producto
            }
        };
    }, [catalogId, productId]);

    // console.log("Catalog ID:", catalogId);
    // console.log("Product ID:", productId);
    console.log("Catalog Product:", catalogProduct);
    // console.log("Catalog Values:", catalogValues, isCalogProduct);
    // console.log("Catalog toast:", toast);

    return (
        <div className="">
            {globalLoading ? 
                <div>
                </div> 
            : (
                <div className="mb-12">
                    <div className="mb-8">
                        <GoBackBtnCatalog icon={BiChevronLeft} text={("Catalogo")} />
                    </div>
                    <ProductCartForm
                        selectedCartItem={{}}
                        selectedProduct={catalogProduct}
                        onClose={() => {}}
                        toast={toast}
                        isCatalog={isCalogProduct || true}
                    />

                </div>
            )}
            <Toast ref={toast} />

        </div>
    );
}
