import styles from "./WhatsAppCRM.module.css";
import { FaHome, FaComment, FaPhone, FaUsers, FaThLarge, FaFolder, FaCog, FaPencilAlt  } from "react-icons/fa";

function WhatsAppCRM() {
  return (
    <div className={styles.appContainer}>
      <SidebarMenu/>
      <Sidebar />
      {/* <ChatWindow /> */}
    </div>
  );
}

function SidebarMenu() {
  return (
    <div className={styles.sidebarMenu}>
      {/* Logo */}
      <div className={styles.logo}>
        <h2>Chatty</h2>
      </div>
      
      {/* Menú de navegación */}
      <ul className={styles.menuList}>
        <li>
          <FaHome className={styles.icon} />
          <span>Historias</span>
        </li>
        <li>
          <FaComment className={styles.icon} />
          <span>Mensajes</span>
        </li>
        <li>
          <FaPhone className={styles.icon} />
          <span>Llamadas</span>
        </li>
        <li>
          <FaUsers className={styles.icon} />
          <span>Comunidad</span>
        </li>
        <li>
          <FaThLarge className={styles.icon} />
          <span>Dashboard</span>
        </li>
        <li>
          <FaFolder className={styles.icon} />
          <span>Archivos</span>
        </li>
        <li>
          <FaCog className={styles.icon} />
          <span>Configuración</span>
        </li>
      </ul>
      
      {/* Perfil del usuario */}
      <div className={styles.profile}>
        <img src="profile.jpg" alt="Foto de perfil" className={styles.profileImage} />
        <div>
          <p className={styles.profileName}>Samuel</p>
          <p className={styles.logout}>Cerrar sesión</p>
        </div>
      </div>
    </div>
  );
}

function MessageSearch() {
  return (
    <div className={styles.messageSearch}>
      <input type="text" placeholder="Buscar..." />
     
    </div>
  );
}

export function Sidebar() {
  return (
    <div className={styles.sidebar}>
        <div className={styles.sidebarHeader}>
            <h2 className={styles.sidebarTitle}>Mensajes</h2>
            <button className={styles.sidebarPencilBtn}>
                <FaPencilAlt className={styles.iconSidebarPencilBtn} />
            </button>
        </div>
      <MessageSearch />
    
      <p className={styles.pinnedMessageText}>° Mensajes fijos</p>
      <ul className={styles.messageList}>
        <MessageCard />
        <MessageCard />
        <MessageCard />
      </ul>
      <p className={styles.pinnedMessageText}>° Todos los mensajes</p>
      <ul className={styles.messageList}>
        <MessageCard />
        <MessageCard />
        <MessageCard />
        <MessageCard />
        <MessageCard />
        <MessageCard />
        <MessageCard />
        <MessageCard />
      </ul>
    </div>
  );
}

function MessageCard() {
  return (
    <li className={styles.messageCard}>
      <div className={styles.contactAvatar}>
        <img src="contact-avatar.png" alt="contact" />
      </div>
      <div className={styles.contactInfo}>
        <h4>Contact Name</h4>
        <p>Last message...</p>
      </div>
      <div className={styles.messageTime}>09:26 PM</div>
    </li>
  );
}

export default WhatsAppCRM;
