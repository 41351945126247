export function getObjectFromLocalStorage(key) {
    const jsonString = localStorage.getItem(key);
    if (jsonString) {
        try {
            return JSON.parse(jsonString);
        } catch (error) {
            console.error('Error parsing JSON from localStorage:', error);
            return null;
        }
    }
    return null; // Devuelve null si no hay dato en localStorage
}

export function saveObjectToLocalStorage(key, obj) {
    try {
        const jsonString = JSON.stringify(obj);
        localStorage.setItem(key, jsonString);
    } catch (error) {
        console.error('Error saving object to localStorage:', error);
    }
}

export function removeObjectFromLocalStorage(key) {
    localStorage.removeItem(key);
}
