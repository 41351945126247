
import PropTypes from "prop-types"
import styles from "./LandingProductSection.module.css";
import VideoCardCover from "../VideoCardCover/VideoCardCover.jsx";
// import { useEffect, useState } from "react";
import { Divider } from "primereact/divider";


export default function LandingProductSection ({t, landingSection, 
    // borderRadius,
   onDelete, onEdit}) {
    // const mobileHeight = '200px';
    // const desktopHeight = '400px'
    // const [windowWidth, setWindowWidth] = useState(0);
    // const currentHeight = windowWidth > 2000 ? desktopHeight : mobileHeight;

    // useEffect(() => {
    //     const handleResize = () => {
    //       setWindowWidth(window.innerWidth);
    //     };
    //     setWindowWidth(window.innerWidth);
        
    //     window.addEventListener('resize', handleResize);   
    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };        
    // }, [])

    return (
        <div key={landingSection.id} className="mt-6 mb-6 grid gap-4">
          <Divider/>
          <h3 className="font-extrabold ">{landingSection.title}</h3>
          <h4 className="font-semibold">{landingSection.subtitle}</h4>
          <h5 className={`font-semibold w-full ${styles.description}`}>
            {landingSection.description}
          </h5>
          <span>
            { landingSection?.media_type === "img" ? (
              <img src={landingSection.media_src} 
              className={styles.slideImage}
              // style={{minWidth: minWidth,minHeight: currentHeight, maxHeight: currentHeight, borderRadius }}  
              />
            ) : (
              landingSection?.media_type === "video" ? ( <VideoCardCover noresize={true} video_feed_url={landingSection.media_src} />) : <div/>
            )}
          </span>
    
          <div className="flex justify-end gap-4 mr-2 mt-4">
            <button type="button" 
            onClick={() => onDelete(landingSection)}
             className={styles.buttonOverlayDelete}>{t("DELETE")}</button>
            <button type="button" 
            onClick={() => onEdit(landingSection)}
             className={styles.buttonOverlayEdite}>{t("EDIT")}</button>            
          </div>
          <Divider/>
        </div>
    )
    
}
LandingProductSection.propTypes = {
    landingSection: PropTypes.object,
    borderRadius: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    t: PropTypes.func,
}