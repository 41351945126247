import React from 'react';
import styles from './CartWidget.module.css';
import { PiShoppingCartSimple } from "react-icons/pi";

export default function CartWidget({style, items, iconSize}) {
    let itemCount = items ? items.length : 0;
    let displayCount = itemCount > 100 ? '+99' : itemCount;
    let shapeClass = itemCount > 100 ? styles.rectangle : '';

    return (
      <div style={style} className={`clickable ${styles.cartContainer}`}>
          <PiShoppingCartSimple 
              className='icon'
              style={{fontSize: iconSize ?? "var(--font-size-h3)"}} 
          />
          {itemCount > 0 && (
              <span className={`${styles.itemCount} ${shapeClass}`}>{displayCount}</span>
          )}
      </div>
    );
}

