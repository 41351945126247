
export const LOGIN ='/login';
export const PRIVATE ='/PRIVATE';
export const PRIVATEPEDIDOS ='/PRIVATE/pedidos';
export const PRIVATEPRODUCTOS ='/PRIVATE/productos';
export const PRIVATEMICUENTA ='/PRIVATE/mi-cuenta';
export const PRIVATEUSUARIOS ='/PRIVATE/usuarios';
export const PRIVATEDASHBOARD ='/PRIVATE/dashboard';
export const PRIVATEMINEGOCIO ='/PRIVATE/mi-negocio';
export const PRIVATEROLESYPERMISOS ='/PRIVATE/roles-permisos';
export const PRIVATEBILLETERA ='/PRIVATE/billetera';

export const CREATE = 'CREATE'
export const VIEW = 'VIEW'
export const STRICTVIEW = 'STRICTVIEW'
export const EDIT = 'EDIT'  

export const PREDETERMINATEROLEOWNERID = '9zJ63GlxmLf76LtuTo6i'
export const PREDETERMINATEROLEOWNERLABEL = 'Rol Partner Owner'

export const PREDETERMINATEROLECOLLABORATEID = '7whQhry1MtgI9fN8J443'
export const PREDETERMINATEROLECOLLABORATELABEL = 'Rol Partner Collaborator'

export const COVER_BANNER = 'cover_banner'
export const COVER_COLLECTION = 'cover_collection'
export const SECTION = 'section'
export const DESKTOP = 'desktop'
export const MOBILE = 'mobile'


// Identificadores de las billeteras de transportadoras
export const WALLETIDCOORDINADORA = 'fWIRLHVy87Boi3TQFs7d';   // 1
export const WALLETIDENVIA = 'Oc60XQv0BeoL6euycU8Z'; // 2
export const WALLETIDINTERRAPIDISIMO = 'L4WjOMHAIjrRcD7okQZk'; // 3


export const REVIEWDocument = {
    store_id: "", // id del documento y de la tienda donde se creo el review, cada store tendra este doc unico
    store_name: "", // Nombre de la tienda asociada al review
    reviews: []
}