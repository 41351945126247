import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/Authcontext/Authcontext';

function PrivateRoute({ children }) {
    const { userSignedIn, loading } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!loading && !userSignedIn) {
            console.log("bandera PrivateRoute")
            navigate('/login');
        }
    }, [userSignedIn, loading, navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userSignedIn) {
        return null;
    }

    return children;
}

export default PrivateRoute;
