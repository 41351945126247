import PropTypes from "prop-types"
import  { useEffect, useState } from 'react';
import styles from './VideoCardCover.module.css';

const VideoCardCover = ({ video_feed_url, video_placeholder ,
  borderRadius,
  mobileHeight = '200px',
  desktopHeight = '400px', 
  noresize,
}) => {
  const [windowWidth, setWindowWidth] = useState(0);
  const currentHeight = windowWidth > 2000 ? desktopHeight : mobileHeight;
  const minWidth = "100%"
  // eslint-disable-next-line no-unused-vars
  const [videoError, setVideoError] = useState(false);


  useEffect(() => {
    setWindowWidth(window.innerWidth);
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener('resize', handleResize);   
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  }, [video_feed_url])


  return (
    <div className={styles.card}>
      {!videoError ? (
        <video style={noresize ? {display: ""} : {
          minWidth: minWidth,
          minHeight: currentHeight, maxHeight: currentHeight, borderRadius, 
        }}  
          src={video_feed_url} type="video/mp4"  controls={true} ></video>
      ) : (
        <img src={video_placeholder} alt="Video no disponible" />
      )}
      <div className={styles.card_gradient}></div>

    </div>
  );
};

VideoCardCover.propTypes = {
  borderRadius: PropTypes.string,
  mobileHeight: PropTypes.string,
  desktopHeight: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  isErasable: PropTypes.bool,
  mediaType: PropTypes.string,
  toast: PropTypes.object,
  shoppexStoreId: PropTypes.string,
  video_feed_url: PropTypes.string,
  video_placeholder: PropTypes.string,
  setBannerValues: PropTypes.func,
  noresize: PropTypes.bool

}
export default VideoCardCover;

