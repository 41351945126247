import styles from './CustomInputText.module.css';
import { InputText } from 'primereact/inputtext';

export default function CustomInputText({id, floatLabel, type, value, placeholder, onChange, required, disabled, inColumTable}) {

  if(floatLabel){
    return (
      <div className={styles.floatLabelContainer}>
        <span className="p-float-label">
          <InputText
            required={required}
            type={type}
            value={value}
            onChange={onChange}
            className={inColumTable ? styles.customTableComponent: styles.customFormComponent}
            disabled={disabled}
            autoComplete="on"
          />
          <label htmlFor={id}>{placeholder}</label>
        </span>
      </div>
    )
  }else{
    return (
      <InputText
        id={id}
        required={required}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={inColumTable ? styles.customTableComponent: styles.customFormComponent}
        disabled={disabled}
        autoComplete="on"
      />
    )
  }
}
