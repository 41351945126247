const sortArrayByCreatedAt = (array) => {
  const extractDate = (created_at) => {
    if (created_at?.nanoseconds !== undefined && created_at?.seconds !== undefined) {
      // Formato con segundos y nanosegundos
      return new Date(created_at.seconds * 1000 + created_at.nanoseconds / 1000000);
    } else if (created_at.toDate !== undefined) {
      // Formato de Firebase Timestamp
      return created_at.toDate();
    } else {
      // Asumir que es un timestamp en milisegundos o una cadena de fecha
      return new Date(created_at);
    }
  };

  return array?.sort((a, b) => {
    const dateA = extractDate(a.created_at);
    const dateB = extractDate(b.created_at);
    return dateB - dateA  ; // Orden ascendente
  });
};
export default sortArrayByCreatedAt;
