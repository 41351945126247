import { signInWithPhoneNumber } from 'firebase/auth';

export const sendSMSVerificationCode = async (auth, phoneNumberWithCountryCode, recaptchaVerifier) => {
    let verificator = null
    try {
        verificator = await signInWithPhoneNumber(auth, phoneNumberWithCountryCode, recaptchaVerifier);
        return {success: true, verificator, error:null}
    } catch (error) {
        console.error(error)
        return {success: false, verificator, error}
    }
};