import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  deleteDoc,
  writeBatch,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase/firebase";
import { deleteObject, listAll, ref } from "firebase/storage";

const useFirebaseCRUD = () => {

  const updateDocument = async (collectionName, documentId, updatedData) => {
    try {
      if (documentId) {
        const docRef = doc(db, collectionName, documentId);
        await updateDoc(docRef, updatedData);
        return true;
      }
    } catch (error) {
      console.error("Error en la actualizacion:", error);
      return false;
    }
  };
  const addToArray = async (collectionName, documentId, arrayKey, newValue) => {
    try {
      const docRef = doc(db, collectionName, documentId);
      await updateDoc(docRef, {
        [arrayKey]: arrayUnion(newValue),
      });
      return true;
    } catch (error) {
      console.error("Error al agregar al array:", error);
      return false;
    }
  };
  const removeFromArray = async (
    collectionName,
    documentId,
    arrayKey,
    valueToRemove
  ) => {
    try {
      const docRef = doc(db, collectionName, documentId);
      await updateDoc(docRef, {
        [arrayKey]: arrayRemove(valueToRemove),
      });
      return true;
    } catch (error) {
      console.error("Error al eliminar del array:", error);
      return false;
    }
  };
  const createDocument = async (collectionName, documentId, documentData) => {
    const documentRef = doc(db, collectionName, documentId);
    try {
      await setDoc(documentRef, documentData);
      // console.log(
      //   `Documento creado en la colección ${collectionName} con ID: ${documentId}`
      // );
      return true;
    } catch (error) {
      console.error("Error al crear el documento:", error);
      return false;
    }
  };
  const deleteDocument = async (collectionName, documentId) => {
    const documentRef = doc(db, collectionName, documentId);
    try {
      await deleteDoc(documentRef);
      // console.log(
      //   `Documento con ID: ${documentId} eliminado de la colección ${collectionName}`
      // );
      return true;
    } catch (error) {
      console.error("Error al eliminar el documento:", error);
      return false;
    }
  };
  const updateArray = async (collectionName, documentId, arrayKey, arrayToRemove, arrayAdd) => {
    const batch = writeBatch(db);
    const docRef = doc(db, collectionName, documentId);
    try {
        batch.update(docRef, {
            [arrayKey]: arrayRemove(arrayToRemove)
        });
        batch.update(docRef, {
            [arrayKey]: arrayUnion(arrayAdd)
        });
        await batch.commit();
        return true;
    } catch (error) {
        console.error('Error al actualizar el array:', error);
        return false;
    }
  };
  const getDocumentsByQuery = (query) => {
    return new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(query, (snapshot) => {
        const documents = [];
        snapshot.forEach((doc) => {
          documents.push(doc.data());
        });
        unsubscribe(); // Cancelamos la suscripción una vez que recibimos los datos
        resolve(documents); // Resolvemos la promesa con los datos recibidos
      }, (error) => {
        reject(error); // En caso de error, rechazamos la promesa
      });
    });
  };
  const deleteFolderStorageAndContents = async (folderPath)  => { 
    const storageRef = ref(storage, folderPath);
    try {
      // Obtener la lista de elementos dentro de la carpeta
      const { items, prefixes } = await listAll(storageRef);
      // Eliminar todos los elementos dentro de la carpeta
      await Promise.all(items.map(itemRef => deleteObject(itemRef)));
      // Recorrer y eliminar todas las subcarpetas
      await Promise.all(prefixes.map(prefix => deleteFolderStorageAndContents(prefix.fullPath)));

      // console.log(`Carpeta ${folderPath} y su contenido eliminados del almacenamiento.`);
    } catch (error) {
      console.error(`Error al intentar eliminar la carpeta ${folderPath} y su contenido del almacenamiento:`, error);
    }
  }
  const getDocumentById = async (collectionName, documentId) => {
    try {
        // Construye la referencia al documento utilizando su ID
        const documentRef = doc(db, collectionName, documentId); // Reemplaza 'nombre_coleccion' con el nombre de tu colección
        // Obtiene el documento
        const documentSnapshot = await getDoc(documentRef);

        if (documentSnapshot.exists()) {
            // Devuelve los datos del documento si existe
            return documentSnapshot.data();
        }
    } catch (error) {
        console.error('Error al obtener el documento:', error);
        throw error;
    }
}

  return {
    updateDocument,
    addToArray,
    removeFromArray,
    createDocument,
    deleteDocument,
    updateArray,
    getDocumentsByQuery,
    deleteFolderStorageAndContents,
    getDocumentById
  };
};

export default useFirebaseCRUD;
