import { PDFDocument, degrees } from 'pdf-lib';

export const transformBase64Guide = async (shipping_guide_pdf_base64, shipping_agency) => {
    console.log(shipping_agency, "1");
    let transformed_shipping_guide_pdf = shipping_guide_pdf_base64;
    if(shipping_agency==="Coordinadora"){
        transformed_shipping_guide_pdf = await rotateAndCropPDF_Coordinadora(shipping_guide_pdf_base64);
    } else if (shipping_agency==="Interrapidisimo") {
    console.log(shipping_agency, "2");

        transformed_shipping_guide_pdf = await rotateAndCropPDF_Interrapidisimo(shipping_guide_pdf_base64);
    } else if (shipping_agency==="Envia"){
        transformed_shipping_guide_pdf = await rotateAndCropPDF_Envia(shipping_guide_pdf_base64);
    }
    return transformed_shipping_guide_pdf;
}

async function rotateAndCropPDF_Interrapidisimo(base64String) {
    const pdfBytes = await fetch(`data:application/pdf;base64,${base64String}`)
        .then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const [firstPage] = pdfDoc.getPages();
    firstPage.setRotation(degrees(360));
    const { width, height } = firstPage.getSize();
    firstPage.setMediaBox(34, 100, width - 110*3, height - 100);
    const outputBytes = await pdfDoc.save();
    const outputBase64 = await blobToBase64(new Blob([outputBytes]));
    return outputBase64;
}

async function rotateAndCropPDF_Coordinadora(base64String) {
    const pdfBytes = await fetch(`data:application/pdf;base64,${base64String}`)
        .then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const [firstPage] = pdfDoc.getPages();
    const { width, height } = firstPage.getSize();
    firstPage.setMediaBox(0, -70, 350, 350);
    const outputBytes = await pdfDoc.save();
    const outputBase64 = await blobToBase64(new Blob([outputBytes]));
    console.log("en rotateAndCropPDF_Coordinadora", width, height);

    return outputBase64;
}

async function rotateAndCropPDF_Envia(base64String) {
    return base64String;
}

function blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result.split(',')[1]);
        };
    });
}
