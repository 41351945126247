import printJS from 'print-js' //
import { transformBase64Guide } from './transformGuide'

// Funcion solo para descargar cualquier archivo en PDF (base64)
export const download_pdf_file = (base64pdf, title) => {
    let a = document.createElement('a')
    a.href = `data:application/pdf;base64,${base64pdf}`
    a.target = "_blank"
    a.download = title
    a.click()
    a.remove()
}

// Funcion para abrir el asistente de impresion con el archivo en PDF (General para cualquier PDF)
export const print_pdf_file_general = (base64pdf, onPrintDialogCloseFunction) => {
    let button = document.createElement('button')
    button.type = "button"
    button.onclick = printJS({printable: base64pdf, type: 'pdf', base64: true, onPrintDialogClose: onPrintDialogCloseFunction})
    button.click()
    button.remove()
}

// Funcion para abrir el asistente de impresion con LA GUIA EN PDF (ESPECIALZIADO PARA LA GUIA)
export const print_pdf_guide = async (base64pdf, onPrintDialogCloseFunction, shipping_agency, titlePDF) => {
    let transformed_shipping_guide_pdfbase64 = await transformBase64Guide(base64pdf, shipping_agency)
    try {
        let button = document.createElement('button')
        button.type = "button"
        if(shipping_agency === "Interrapidisimo") {
            download_pdf_file(transformed_shipping_guide_pdfbase64, titlePDF);     
        }else if (shipping_agency !== "Envia"){
            button.onclick = printJS({printable: transformed_shipping_guide_pdfbase64, type: 'pdf', base64: true, onPrintDialogClose: onPrintDialogCloseFunction})
            button.click()
            button.remove()
        }
        else{
            window.open(transformed_shipping_guide_pdfbase64, 'newwindow', 'width=800,height=600,left=200,top=200');

        }
    } catch (error) {
        console.error(error)
    }
}

