import React, { useState, useEffect } from 'react';
import { RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../../../firebase/firebase';

export default function InvisibleCatpchaWrapper({children}) {
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);

    useEffect(() => {
        initRecaptcha()
        return () => {
            if (recaptchaVerifier) {
                recaptchaVerifier.clear();
            }
        };
    }, []);

    const initRecaptcha = () => {
        if (recaptchaVerifier) {
            recaptchaVerifier.clear();
        }
        const auxRecaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            'size': 'invisible'
        });
        setRecaptchaVerifier(auxRecaptchaVerifier);
    }

    // Clonar children y pasar las props deseadas
    const childrenWithProps = React.Children.map(children, child => 
        React.cloneElement(child, { recaptchaVerifier })
    );

    return (
        <>
            {childrenWithProps}
            <div id={'recaptcha-container'} style={{display:"none"}}></div>
        </>
    )
}
