import styles from './ProductSlider.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

export default function ProductSlider({ user_data, product_data, product_selected_variant }) {
  const modules = [Pagination, Scrollbar, A11y, Navigation];
  const height = "450px";
  let images = [];

  if (product_data?.variations) {
    const variations = product_data.variations;
    images = variations.flatMap((variation) => {
      return (variation.images || []).filter(image => image.img_url);
    });
  }

  if (images?.length === 0 && product_data?.main_img?.url) {
    images.push({ img_url: product_data.main_img.url });
  }

  return (
    <Swiper
      modules={modules}
      scrollbar={{ draggable: true }}
      spaceBetween={0}
      slidesPerView={1}
      pagination={{ clickable: true }}
      navigation={true}
      style={{ width: "100%" }}
    >
      {images.map((image, index) => (
        <SwiperSlide
          key={index}
          className={styles.slideContainer}
          style={{ minHeight: height, maxHeight: height }}
        >
          <picture>
            <img
              className={styles.slideImage}
              src={image.img_url}
              alt={product_data?.product_name}
              style={{ width: '100%', minHeight: height, maxHeight: height }}
            />
          </picture>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
