import { useTranslation } from "react-i18next";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn.jsx";
import {  BiCheckCircle, BiChevronLeft } from "react-icons/bi";
import { useEffect, useRef, useState } from "react";
import ShippingNewsFilters from "../../Components/shippingNewsFilters/shippingNewsFilters.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { getTrackedNews } from "../MyOrders/scripts/getOrders.js";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import useShopifyRequest from "../../hooks/useShopifyRequest.js";
import DetailOptionCard from "../../Components/DetailOptionCard/DetailOptionCard.jsx";
import { TieredMenu } from "primereact/tieredmenu";
import SecondaryButton from "../../Components/SecondaryButton/SecondaryButton.jsx";
import CustomTable from "../../Components/CustomTable/CustomTable.jsx";
import { Button } from "primereact/button";
import CustomModal from "../../Components/CustomModal/CustomModal.jsx";
import DetailSolutionNewsForm from "../../Components/DetailSolutionNewsForm/DetailSolutionNewsForm.jsx";
import { Toast } from "primereact/toast";
import { IoWarning } from "react-icons/io5";
import { OverlayPanel } from "primereact/overlaypanel";
import toastFunction from "../../utils/toastFunction.js";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import CustomConfirmDialog from "../../Components/OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";

const ShippingNews = () => {
    const { toast } = useShopifyRequest();
    const { t } = useTranslation("ShippingNews");
    const { updateDocument } = useFirebaseCRUD();
    const { userSellerOwnerData, userData } = useAuthContext();
    const { globalLoading, setGlobalLoading } = useLoaderContext();
    const authName = `${userData?.first_names} ${userData?.last_names}`

    const [ selectedShippingCompany, setSelectedShippingCompany] = useState();
    const [ queryDates, setQueryDates] = useState();
    const [ selectedNew, setSelectedNew] = useState(null);
    const [ selectedShippingNew, setSelectedShippingNew] = useState(null);
    const [ selectedState, setSelectedState] = useState(null);
    const [ guideNumber, setGuideNumber] = useState(null);
    const [ orderNews, setOrderNews ] = useState(null);
    const userBusinessId = userSellerOwnerData?.business_id || userData?.business_id
    const [visibleModalSolutionNews, setVisibleModalSolutionNews] = useState(false);
    const [unsubscribeOrderStateSnapshot, setUnsubscribeOrderStateSnapshot] = useState(null);
    const [visibleCloseOrderNewDialog, setVisibleCloseOrderNewDialog] = useState(false);
    const menu = useRef(null);

    const items = [
        {
          label: t("Cerrar novedad"),
          icon: "pi pi-fw pi-file",
          command: 
            () => setVisibleCloseOrderNewDialog(true)
        },
        // {
        //   label: "Foo",
        //   icon: "pi pi-fw pi-file",
        // },
    ];
    const handleCloseNew = async () => {
      console.log("selectedNew", selectedNew)
      const updateResult = await updateDocument(
        "traked_orders",
        selectedNew?.order_id, 
        { 
            order_with_unresolved_shipping_news: false,
            last_shipping_new: 
            {
                ...selectedNew, 
                autorizado: authName,
                }
        },
    );
    if (updateResult) {
      toastFunction(
          toast,
          "info",
          `${t("Solucion cerrada por ")} ${authName}`,
          10000
        );
    }
    }
    const shippingNewsColums = [
        {
        header: t("table-headers.date"),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <div className="card flex justify-content-center">
              {rowData?.created_at?.toDate()?.toLocaleString()}
          </div>
        ),
      },
      {
        header: t("table-headers.shippingCompany"),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <>
          {rowData?.shipping_company_details?.name}
          </>
        ),
      },
      {
        header: t("table-headers.guideNumber"),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <>
            {rowData?.guide_number}
          </>
        ),
      },
      {
        field: "city",
        header: t("table-headers.customer"),
        filterable: false,
        sortable: false,
        body: (rowData) => {
          return(
        <>{rowData.customer}</>)},
      },
      {
        header: t("table-headers.destiny"),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <div className="card flex justify-content-center">
            {rowData?.destiny}
          </div>
        ),
      },
      {
        header: t("table-headers.news"),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <div className="card flex justify-content-center">
            {rowData?.new?.name}
          </div>
        ),
      },
      {
        header: t("table-headers.state"),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <div className="card flex justify-content-center">
            {rowData?.state?.name}
          </div>
        ),
      },
      {
        header: t("table-headers.solutionErrorLogs"),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <div className="flex gap-2">
            <Button
              type="button"
              icon="pi pi-search"
              label={rowData?.solution_error_logs?.length}
              onClick={(e) => openLogsOverlayPanel(rowData, e)}
            />
            <span>
              {rowData.order_with_unresolved_shipping_news ? (
                <BiCheckCircle style={{ fontSize: "2.5em" }} />
              ) : (
                <IoWarning style={{ fontSize: "2.5em" }} />
              )}
            </span>
          </div>
        ),
      },
      {
        header: t(""),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <div className="card flex justify-content-center">
            <div className="card flex justify-content-center">
              <Button
                label={t("solution")}
                onClick={() => openModalSolutionNews(rowData)}
              />
            </div>
          </div>
        ),
      },
      {
        header: t(""),
        filterable: false,
        sortable: false,
        body: (rowData) => (
          <div className="card flex justify-content-center">
            <div className="card flex justify-content-center">
              <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
              <SecondaryButton
                loading={globalLoading}
                label={t("...")}
                onClick={(e) => optionMenu(e, rowData)}
              />
            </div>
          </div>
        ),
      },
    ];
    // Función para manejar el menú de opciones para cada novedad en la tabla
    const optionMenu = (e, rowData) => {
      // Abre el menú de opciones y establece la fila de la novedad seleccionada
      menu.current.toggle(e);
      setSelectedNew(rowData);
    };
    const opLogs = useRef(null);

    const openLogsOverlayPanel = (rowData, e) => {
      setSelectedShippingNew(rowData); // Guardar los datos de la fila seleccionada en el estado
      opLogs.current.toggle(e); // // Abre el overlay
    };

    const openModalSolutionNews = (novelty) => {
      setVisibleModalSolutionNews(true)
      if (novelty) {
        setSelectedNew(novelty);
      }else {
        setSelectedNew({});
      }
    }

    const handleConsultShippingNews = async (e, isDefault) => {
      setGlobalLoading(true);
      let unsubscribeOrders = null;
      if (e) {
        e.preventDefault();
      }
      // Desuscribirse de la suscripción actual si existe
      if (unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function") {
        unsubscribeOrderStateSnapshot();
        setUnsubscribeOrderStateSnapshot(null);
      }

      // Iniciar la nueva suscripción
      unsubscribeOrders = await getTrackedNews(
        isDefault, queryDates[0],
        queryDates[1], setOrderNews,
        toast, t,  userBusinessId, 
        guideNumber, selectedShippingCompany?.code,
         selectedNew?.code, selectedState?.code);
          // Establecer la nueva función de desuscripción
      setUnsubscribeOrderStateSnapshot(() => {
      if (unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function" ) {
        unsubscribeOrderStateSnapshot();
      }
        return unsubscribeOrders;
      });
      setGlobalLoading(false);
    }

    const fetchInitialData = async (unsubscribeOrders) => {
      setGlobalLoading(true);
      try {
        // Desuscribirse de la suscripción actual si existe
        if ( unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function" ) {
          const currentUnsubscribe = unsubscribeOrderStateSnapshot;
          currentUnsubscribe();
          setUnsubscribeOrderStateSnapshot(unsubscribeOrders);
        }
       
        // Establecer la fecha inicial y final del día actual
        const initialDate = new Date();
        initialDate.setDate(initialDate.getDate() - 15); // Restamos 15 días
        initialDate.setHours(0, 0, 0, 0); // Ajustamos la hora al inicio del día

        const finishDate = new Date();
        finishDate.setHours(23, 59, 59, 999); // Ajustamos la hora al final del día
        setQueryDates([initialDate, finishDate]);

        unsubscribeOrders = await getTrackedNews(true, initialDate, finishDate, setOrderNews, toast, t,  userBusinessId);
        // Establecer la nueva función de desuscripción
        setUnsubscribeOrderStateSnapshot(() => {
          if (unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function" ) {
            unsubscribeOrderStateSnapshot();
          }
          return unsubscribeOrders;
        });
      } catch (error) {
        console.error("Error al obtener los pedidos:", error);
      } finally {
        setGlobalLoading(false);
      }
    };

    const cleanFiltersAndConsultOrders = async () => {
      cleanFilters();
      await handleConsultShippingNews(false, true);
    }

    const cleanFilters = () => {
      setSelectedShippingCompany(null);
      setQueryDates([]);
      setSelectedNew(null);
      setSelectedShippingNew(null);
      setSelectedState(null);
      setGuideNumber("");
    }

    useEffect(function loadInitialData(){
        let unsubscribeOrders = null;
        if (userData && !unsubscribeOrders) {
          fetchInitialData(unsubscribeOrders);
        } 
        return () => {
          if (unsubscribeOrders) {
              unsubscribeOrders(); // Desuscribirse de la consulta al desmontar el componente
          }
        };
    }, []);

    const pendingSolutionsCount = (orderNews || []).filter(news => news.state.code !== 3).length;
    
    const orderGuideLogsTableColumns =  [
      {
        header: "Mensaje",
        filterable: false,
        sortable: false,
        body : (msj) =>
          <span>
            {msj}  
          </span>
      },
    ];

    return ( 
      <main className="mb-12">
        <div
            style={{marginBottom: "40px"}}
        >
          <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtnShippingNews")} />
        </div>
      
        <ShippingNewsFilters 
            selectedShippingCompany={selectedShippingCompany}
            setSelectedShippingCompany={setSelectedShippingCompany}
            queryDates={queryDates}
            setQueryDates={setQueryDates}
            selectedNew={selectedNew}
            setSelectedNew={setSelectedNew}
            selectedShippingNew={selectedShippingNew} 
            setSelectedShippingNew={setSelectedShippingNew}
            selectedState={selectedState} 
            setSelectedState={setSelectedState}
            guideNumber={guideNumber}
            setGuideNumber={setGuideNumber}
            t={t} globalLoading={globalLoading}
            handleConsultShippingNews={handleConsultShippingNews}
            cleanFilters={cleanFilters}
            cleanFiltersAndConsultOrders={cleanFiltersAndConsultOrders}
        />

        <div className="mt-8">
          <DetailOptionCard
            bold_text={t("pendingSolutions")}
            regular_text={pendingSolutionsCount}
            key={1}
            hidde_btn={true}
          />

        </div>
        <div className="mt-12">
          <CustomTable
            className="custom-table"
            columns={shippingNewsColums}
            data={orderNews}
            action={() => {}}
          />
        </div>
        <div className="card flex flex-column align-items-center gap-3">
        <OverlayPanel ref={opLogs} showCloseIcon closeOnEscape dismissable={true}>
          <CustomTable 
              selectionMode={"single"}
              className="custom-table"
              columns={orderGuideLogsTableColumns}
              data={selectedShippingNew?.solution_error_logs}
              action={() => {}} 
            />
        </OverlayPanel>
      </div>
        <CustomModal
          visible={visibleModalSolutionNews}
          setVisible={setVisibleModalSolutionNews}
          createMode={t("newSolution")}
          content={
            <DetailSolutionNewsForm
              toast={toast}
              onClose={() => setVisibleModalSolutionNews(false)}
              t={t}
              globalLoading={globalLoading}
              selectedNew={selectedNew}
            />
          }
        onClose={() => setVisibleModalSolutionNews(false)}
        />
        <Toast ref={toast} />
        <CustomConfirmDialog
          visible={visibleCloseOrderNewDialog}
          onHide={() => setVisibleCloseOrderNewDialog(false)}
          message={t("confirmDialog-closeNew.messageConfirm")}
          header={t("confirmDialog-closeNew.headerConfirm")}
          icon="pi pi-question-circle"
          accept={handleCloseNew}
          reject={() => setVisibleCloseOrderNewDialog(false)}
          acceptLabel={t("confirmDialog-closeNew.acceptLabelConfirm")}
          rejectLabel={t("confirmDialog-closeNew.rejectLabel")}
        />
      </main>
    )

};

export default ShippingNews;
