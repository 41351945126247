import React from 'react'
import styles from './AuxButton.module.css';

export default function AuxButton({data, onClick, label, style, disable}) {
  return (
    <div
        style={{
            ...style,
            opacity: disable? "50%":"100%"
        }}
        onClick={disable? null : () => onClick(data)}
        className={`clickable ${styles.AuxButton}`}
    >
        {label}
    </div>
  )
}
