import PropTypes from "prop-types";

import style from "./DetailBankAccountForm.module.css";
import { useEffect, useState } from "react";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import DeleteButton from "../DeleteButton/DeleteButton";
import MainButton from "../MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";

import { generateRandomId } from "../../utils/generateRandomId.JS";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";


const DetailBankAccountForm = ({
  selectedBankAccount,
  userData,
  labelDelete,
  onClose,
  toast,
  isInsider,
  isMaster,
}) => {
  const { t } = useTranslation("Home");
  const { addToArray, removeFromArray } = useFirebaseCRUD();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { typeAccountOptions, bankOptions, typeIdUserOptions } =
    useGlobalContext();

  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const collectionName = isMaster || isInsider ? "master_user" : "seller_users"; 


  const timestamp = new Date();
  const hasEdit = !!Object.keys(selectedBankAccount).length;
  const [bankAccountValues, setBankAccountValues] = useState({
    id: selectedBankAccount?.id || generateRandomId(),
    alias_account: selectedBankAccount?.alias_account || "",
    created_at: selectedBankAccount.created_at || timestamp,
    id_user_account: selectedBankAccount?.id_user_account || "",
    type_user_id_account: selectedBankAccount?.type_user_id_account || null,
    name_user_account: selectedBankAccount?.name_user_account || "",
    type_account: selectedBankAccount?.type_account || null,
    number_account: selectedBankAccount?.number_account || "",
    bank: selectedBankAccount?.bank || null,
  });

  const [selectedTypeAccount, setSelectedTypeAccount] = useState("");
  const [selectedTypeIdUser, setSelectedTypeIdUser] = useState("");
  const [selectedBank, setSelectedBank] = useState("");

 

  const handleInputChange = (field, value) => {
    setBankAccountValues((prevValues) => {
      // Guardar los valores previos antes de la actualización
      const previousValues = { ...prevValues };
      // Actualizar el campo específico con el nuevo valor
      previousValues[field] = value;
      return previousValues;
    });
  };
  const handleInputSelectChange = (fieldName, e) => {
    // Guardar los valores previos antes de la actualización
    if (fieldName === "type_user_id_account") {
      setSelectedTypeIdUser(e);
    } else if (fieldName === "type_account") {
      setSelectedTypeAccount(e);
    } else if (fieldName === "bank") {
      setSelectedBank(e);
    }
    setBankAccountValues((prevValues) => ({
      ...prevValues,
      [fieldName]: e.id,
    }));
  };
  const handleCRUDBankAccount = async (e) => {
    e.preventDefault();
    setGlobalLoading(true);

    try {
      if (hasEdit) {
        await removeFromArray(collectionName, userData.uid, "bank_accounts", selectedBankAccount);
      }
      await addToArray(collectionName, userData.uid, "bank_accounts", bankAccountValues);
      toast?.current?.show({
        severity: "success",
        summary: `El banco fue ${hasEdit ? "actualizado" : "agregado"} con éxito`,
        detail: "",
      });
    } catch (error) {
      const errorMessage = error?.data?.message || "Hubo un error al agregar el banco";
      toast?.current?.show({
        severity: "error",
        summary: errorMessage,
        detail: "",
      });
    } finally {
      setGlobalLoading(false);
      onClose(false);
    }
  };
  const handleDeleteAccount = async () => {
    setGlobalLoading(true);
    // console.log("antes de borrar", selectedBankAccount , bankAccountValues)
    const result = await removeFromArray(
      collectionName,
      userData.uid,
      "bank_accounts",
      selectedBankAccount
    );
    if (result) {
      toast?.current?.show({
        severity: "success",
        summary: "El banco fue agregado con exito",
        detail: "",
      });
    } else {
      if (result.data.success) {
        toast?.current?.show({
          severity: "error",
          summary: "Hubo un error al agregar el banco",
          detail: "",
        });
      }
    }
    setGlobalLoading(false);
    onClose(false);
  };
  const openConfirmDialog = () => {
    setVisibleConfirmDialog(true);
  };


  useEffect(() => {
    if(hasEdit){
      const matchTypeUserIdAccount = typeIdUserOptions.find((typeId) => {
        return typeId.id === selectedBankAccount.type_user_id_account
      })
      const matchTypeAccount = typeAccountOptions.find((typeId) => {
        return typeId.id === selectedBankAccount.type_account
      })
      const matchBank = bankOptions.find((typeId) => {
        return typeId.id === selectedBankAccount.bank
      })
      setSelectedTypeIdUser(matchTypeUserIdAccount);
      setSelectedTypeAccount(matchTypeAccount);
      setSelectedBank(matchBank);
    }
  }, []);

  return (
    <form onSubmit={handleCRUDBankAccount}>
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={bankAccountValues.alias_account}
        placeholder={t("input-placeholders.alias")}
        onChange={(e) => handleInputChange("alias_account", e.target.value)}
        required={true}
      />
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={bankAccountValues.name_user_account}
        placeholder={t("input-placeholders.fullName")}
        onChange={(e) => handleInputChange("name_user_account", e.target.value)}
        required={true}
      />
      <CustomSelect
        required={true}
        floatLabel={true}
        optionLabel={"name"}
        options={typeIdUserOptions}
        valueTemplate={""}
        itemTemplate={""}
        value={selectedTypeIdUser}
        placeholder={t("input-placeholders.typeUserId")}
        onChange={(e) =>
          handleInputSelectChange("type_user_id_account", e.target.value)
        }
      />
      <CustomInputText
        required={true}
        floatLabel={true}
        type={"text"}
        value={bankAccountValues.id_user_account}
        placeholder={t("input-placeholders.idUser")}
        onChange={(e) =>
          handleInputChange("id_user_account", e.target.value)
        }
      />
      <CustomSelect
        required={true}
        floatLabel={true}
        optionLabel={"name"}
        options={bankOptions}
        valueTemplate={""}
        itemTemplate={""}
        value={selectedBank}
        placeholder={t("input-placeholders.bank")}
        onChange={(e) => handleInputSelectChange("bank", e.target.value)}
      />
      <CustomSelect
        required={true}
        floatLabel={true}
        optionLabel={"name"}
        options={typeAccountOptions}
        valueTemplate={""}
        itemTemplate={""}
        value={selectedTypeAccount}
        placeholder={t("input-placeholders.typeAccount")}
        onChange={(e) =>
          handleInputSelectChange("type_account", e.target.value)
        }
      />
      <CustomInputText
        required={true}
        floatLabel={true}
        type={"text"}
        value={bankAccountValues.number_account}
        placeholder={t("input-placeholders.numberAccount")}
        onChange={(e) => handleInputChange("number_account", e.target.value)}
      />
      <div className={style.containerBtns}>
        {hasEdit && (
          <DeleteButton
            type={"button"}
            label={labelDelete}
            loading={globalLoading}
            onClick={() => openConfirmDialog(true)}
          />
        )}
        <MainButton
          type={"onSubmit"}
          label={hasEdit ? t("labelEditBankAccount") : t("labelCreateBankAccount")}
          loading={globalLoading}
          autoFocus
        />
      </div>
      <div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteBankAccount.header")}
          message={t("confirmDialog-deleteBankAccount.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteAccount}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteBankAccount.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteBankAccount.rejectLabel")}
        />
      </div>
    </form>
  );
};

DetailBankAccountForm.propTypes = {
  selectedBankAccount: PropTypes.object,
  toast: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  index: PropTypes.number,
  isInsider: PropTypes.bool,
  isMaster: PropTypes.bool
};

export default DetailBankAccountForm;
