import PropTypes from "prop-types"
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import MainButton from "../MainButton/MainButton.jsx";
import toastFunction from "../../utils/toastFunction.js";
import CustomCalendar from "../FormComponents/CustomCalendar/CustomCalendar.jsx";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import { fetchFromCloudFunction } from "../../services/cloudFunctinons/fetchFromCloudFunction.js";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import CustomTextArea from "../FormComponents/CustomTextArea/CustomTextArea.jsx";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import { formatDateToString } from "../../utils/formatDateToString.js";

const DetailSolutionNewsForm = ({toast, onClose, t, selectedNew}) =>  {
    const isEdit = selectedNew?.state?.code === 3 && selectedNew?.solution;

    const { updateDocument } = useFirebaseCRUD();
    const {shippingSolutions} = useGlobalContext();
    const {userData, zonesData } = useAuthContext();
    const authName = `${userData?.first_names} ${userData?.last_names}`
    const {globalLoading, setGlobalLoading} = useLoaderContext();

    const newSolutionInitialValues = {
        codigo_remision: selectedNew.guide_number ||null,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
        codigo_novedad: "",
        direccion: "",
        ciudad: "",
        order_id: selectedNew.order_id  ||null, 
        destinatario: "",
        esSolucion: true,
        fecha: formatDateToString(new Date),
        autorizado: authName,
        apiKey: "apikey",
        clave: "clave",
        // para envia
        telefono: "",
        tipo_categoria: null,
        comentario: "",
    }
    const [ newSolutionValues, setNewSolutionValues ] = useState(isEdit ? selectedNew.solution : newSolutionInitialValues);
    // console.log("valores de la solucion", newSolutionValues, shippingSolutions);
    const [ selectedShippingSolution, setSelectedShippingSolution ] = useState(null);
    const [ selectedZone, setSelectedZone ] = useState();

    // eslint-disable-next-line no-unused-vars
    const [ cities, setCities ] = useState(zonesData);
    const [ dates, setDates ] = useState(new Date());

    
    const handleSubmitSolution = async (e) => {
        setGlobalLoading(true);
        e.preventDefault();
        // const endpoint = "Guias_reportarNovedadIn";
        let endpoint;
        let response;

        switch (selectedNew?.shipping_company_details?.code) {
            // Coordinadora
            case 1:
                endpoint = "coordinadoraAPI/reportarNovedad";
                break;
            // Envia
            case 2:
                endpoint = "shippingNews/gestionarSolucionarGuiasEnvia";
                break;
            default:
                break;
        }
        const body = newSolutionValues;
        try {
        response = await fetchFromCloudFunction(endpoint, body, "POST");
        console.log("Respuesta solucion ", response)
        if (response?.status === 200) {
          const updateResult = await updateDocument(
                "traked_orders",
                selectedNew?.order_id, 
                { 
                    order_with_unresolved_shipping_news:false,
                    last_shipping_new: 
                    {
                        ...selectedNew, 
                        state: {code: 3, name: "Solucion aprobada"},
                        solution: response.data.solutionValues
                        }
                },
            );
          if (updateResult) {
            toastFunction(
                toast,
                "success",
                `${t("Solucion aprobada ")} ${newSolutionValues.codigo_remision}`,
                response?.data?.detail,
                10000
              );
              onClose(); 
          }
        } else {
            toastFunction(
                toast,
                "error",
                `${response?.data?.message} `,
                response?.data?.detail,
                8000
              );
        }
        } catch (error) {
            toastFunction(toast, "error", "La solucion no fue aprobada", response?.detail, 3000);
            
        }
        setGlobalLoading(false);
    }

    const handleInputChange = (newValue, fieldName) => {
        if (fieldName === "direccion") {
            // Indicador 0: Entrega normal, 1: Devolucion, 2 :Para Redireccion,
            const tipoCategoria = (selectedShippingSolution?.code === 1 || selectedShippingSolution?.code === 4) 
                ? 2: selectedShippingSolution?.code === 3 ? 1 : 0
            
            setNewSolutionValues((prevValues) => ({
                ...prevValues,
                [fieldName]: newValue,
                tipo_categoria: tipoCategoria,
            }))
        } else if(fieldName === "ciudad") {
            setSelectedZone(newValue)
            setNewSolutionValues((prevValues) => ({
                ...prevValues,
                [fieldName]: newValue.city_code,
            }))    
        } else if(fieldName === "fecha") {
            setDates(newValue)
            setNewSolutionValues((prevValues) => ({
                ...prevValues,
                [fieldName]: formatDateToString(newValue),
            }))    
        }else {
            setNewSolutionValues((prevValues) => ({
                ...prevValues,
                [fieldName]: newValue,
            }))
        }
    }

    const handleSelectShippingSolution = (shippingNew) => {
        const tipoCategoria = (selectedShippingSolution?.code === 1 || selectedShippingSolution?.code === 4) 
                ? 2: selectedShippingSolution?.code === 3 ? 1 : 0
        setSelectedShippingSolution(shippingNew);

        setNewSolutionValues((prevValues) => ({
            ...prevValues,
            codigo_novedad: shippingNew.code, 
            novedad: shippingNew.name,
            tipo_categoria: tipoCategoria,
        }));
    }
 
    const matchSolutionFields = () => {
        const foundedNew = shippingSolutions.find((solution) => selectedNew.solution.codigo_novedad === solution.code);
        setSelectedShippingSolution(foundedNew);
        if (foundedNew.code === 1  || foundedNew.code === 4) {
            const foundedZone = cities.find((city) => selectedNew.solution.ciudad === city.city_code)
            setSelectedZone(foundedZone);
        }
    }
    useEffect(function loadInitialData() {
        if(isEdit) {
            matchSolutionFields();
        }
    }, []);


    return (
        <>
        <form onSubmit={handleSubmitSolution}>
            <h4 className="mb-4">
                {selectedNew?.guide_number}
            </h4>
            <div className="grid gap-1 mb-8">
                <p className="font-semibold"> {t("new")} </p>
                <p>
                    {selectedNew?.new?.name}
                </p>
            </div>
            <CustomSelect
                disabled={globalLoading || isEdit}    
                floatLabel={true}
                optionLabel={"name"}
                options={shippingSolutions}
                value={selectedShippingSolution}
                placeholder={t("input-placeholders.selectSolution")}
                required={true}
                onChange={(e) => handleSelectShippingSolution(e.value)}
            />

            {/* Obligatorio cuando la solucion es "Cambio de direccion o Cambio de destinatario o ambas */}
            {(selectedShippingSolution?.code === 1 || selectedShippingSolution?.code === 4)&& (
                <CustomInputText
                floatLabel={true}
                disabled={globalLoading || isEdit}    
                required={true}
                type={"text"}
                value={newSolutionValues.direccion}
                placeholder={t("input-placeholders.newAddress")}
                onChange = {(e) => handleInputChange(e.target.value, "direccion")}
                />
            )}
            {(selectedShippingSolution?.code === 1 || selectedShippingSolution?.code === 4)&& (
                <>
                    <CustomSelect
                        floatLabel={true}
                        disabled={globalLoading || isEdit}    
                        optionLabel={"city_name"}
                        options={cities}
                        value={selectedZone}
                        placeholder={t("input-placeholders.selectCityCode")}
                        required={true}
                        onChange={(e) => {
                            const newZone = e.value;
                            handleInputChange(newZone, "ciudad")
                        }}
                    />
                     <CustomInputText
                        disabled={globalLoading || isEdit}    
                        floatLabel={true}
                        required={true}
                        type={"text"}
                        value={newSolutionValues.telefono}
                        placeholder={t("input-placeholders.phone")}
                        onChange={(e) => handleInputChange(e.target.value, "telefono")}             
                    />

                </>
            )}
            {/* Obligatorio cuando la novedad es "Cambio de Direccion a otra sede (cambio de Direccion y Destinatario" */}
            { selectedShippingSolution?.code === 4 && ( 
                <CustomInputText
                    disabled={globalLoading || isEdit}    
                    floatLabel={true}
                    required={true}
                    type={"text"}
                    value={newSolutionValues.destinatario}
                    placeholder={t("input-placeholders.newNameAddresse")}
                    onChange={(e) => handleInputChange(e.target.value, "destinatario")}             
                />
            )}
            {/* Obligatorio cuando la novedad es "Cambio de Fecha de entrega" */}
            { selectedShippingSolution?.code === 2 &&(   
                <CustomCalendar
                    disabled={globalLoading || isEdit}    
                    required={true}
                    dates={dates}
                    setDates={(e) => handleInputChange(e.target.value, "fecha")}
                    selectionMode="single"
                    placeholder={t("input-placeholders.newShippingDate")}
                />
            )}
            {/* Obligatorio cuando la novedad es "Cancelar la Entrega" */}
            { selectedShippingSolution?.code === 3 && ( 
                <CustomInputText
                    disabled={globalLoading || isEdit}    
                    floatLabel={true}
                    required={true}
                    type={"text"}
                    value={newSolutionValues.autorizado}
                    placeholder={t("input-placeholders.whoAuthorizes")}
                    onChange={(e) => handleInputChange(e.value, "autorizado")}
                />
            )}
        {/* Si la compañia es "envia" se puede adjuntar un comentario e indicar si es solucion */}
            {selectedNew?.shipping_company_details?.code === 2 && (
                <CustomTextArea
                    value={newSolutionValues.comentario}
                    placeholder={t("input-placeholders.comment")}
                    onChange={(e) => handleInputChange(e.target.value, "comentario")}
                    floatLabel={true}
                    required={true}
                    />
            )}

            {selectedNew?.shipping_company_details?.code === 2 && (
             <div className={"flex gap-4 "}>
                <CustomInputSwitch
                checked={newSolutionValues.esSolucion}
                onChange={(e) => handleInputChange("esSolucion", e.target.value)}
                />
                <h5>¿Es solucion?</h5>
            </div>)}

            <MainButton style={{ marginTop: "20px" }} type={"submit"} label={t("createSolution")} loading={globalLoading} autoFocus />
            
            </form >
        </>
    )   
}
DetailSolutionNewsForm.propTypes = {
    selectedNew: PropTypes.object,
    setGuideNumber: PropTypes.func,
    t: PropTypes.func, 
    globalLoading: PropTypes.bool,
    toast: PropTypes.object,
    onClose: PropTypes.func,
}
export default DetailSolutionNewsForm;
