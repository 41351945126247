import  { useRef, useState, useEffect } from 'react';
import ShoppingCartBox from '../ShoppingCartBox/ShoppingCartBox';
import ShoppingCartBoxPriceList from '../ShoppingCartBoxPriceList/ShoppingCartBoxPriceList';
import styles from './ShoppingCartWidget.module.css';
import MainButton from '../../MainButton/MainButton.jsx';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext.jsx';


export function ShoppingCartWidget({products10X, showJustItems, openProductCartForm, openCheckoutModal, handleRemoveFromCart, isCatalog}) {
  
  const widgetRef = useRef(null);
  const {shoppingCart } = useGlobalContext();
  const [salePrice, setSalePrice] = useState(0);
  const [costPrice, setCostPrice] = useState(0);
  const [revenue, setRevenue] = useState(0);


  useEffect(() => {
    const adjustBodyPadding = () => {
      document.body.style.paddingBottom = `${widgetRef.current.clientHeight}px`;
    };
    adjustBodyPadding();
    window.addEventListener('resize', adjustBodyPadding);
    return () => {
      window.removeEventListener('resize', adjustBodyPadding);
    };
  }, [products10X]);

  useEffect(function calculatePricesOfCartItems() {
    let totalSalePrice = 0;
    let totalCostPrice = 0;
    let revenue = 0;


    shoppingCart.forEach((item) => {
      const salePrice = item?.unit_price || 0;
      const costPrice = (item?.unitary_commercial_partner_charge_with_VAT_with_service_fee ) * (item?.quantity || 1);

      const sellerRevenue = salePrice - costPrice;

      
      revenue += sellerRevenue;
      totalSalePrice += salePrice;
      totalCostPrice += costPrice;
    });

    setSalePrice(totalSalePrice);
    setCostPrice(totalCostPrice);
    setRevenue(revenue);
  }, [shoppingCart]);

  return (
    <div className={shoppingCart?.length > 0 ? (showJustItems ? styles.shoppingCartWidgetForm : styles.shoppingCartWidget) : styles.shoppingCartWidgetEmpty} ref={widgetRef}>
      {shoppingCart?.length > 0 ? (
        <div>
          <div className={styles.shoppingCartWidgetTitle}>Carrito de compra ({shoppingCart?.length})</div>
          <div className={styles.shoppingCartWidgetContent}>
            <div className={showJustItems ? styles.shoppingCartWidgetUpperItemsContainerAll : styles.shoppingCartWidgetUpperItemsContainer}>
              <div className={styles.shoppingCartWidgetUpperItemsContainerUpper}>
                {shoppingCart?.map((item, index )=>
                  <ShoppingCartBox key={index} item={item} remove_from_cart={() => handleRemoveFromCart(item)} select_item_from_cart={() => openProductCartForm("shoppingCart", item)}/>
                )}
              </div>
            </div>
            {showJustItems ? null : (
              <div className={styles.shoppingCartWidgetUpperOrderPrice}>
                <ShoppingCartBoxPriceList key={`sale-${salePrice}`} price={salePrice} label={isCatalog ? "PRODUCTOS ":"VENTA"} color={"black"} fontWeight={"500"} fontWeightLabel={"200"} symbol={"+"} />
                {isCatalog && (<ShoppingCartBoxPriceList key={`cost-${costPrice}`} price={null} label={"ENVIOS **"} color={"black"} fontWeight={"500"} fontWeightLabel={"200"} symbol={"- "} />)}

                {!isCatalog && (<ShoppingCartBoxPriceList key={`cost-${costPrice}`} price={costPrice} label={"COSTOS"} color={"black"} fontWeight={"500"} fontWeightLabel={"200"} symbol={"- "} />)}
                <ShoppingCartBoxPriceList key={`revenue-${revenue}`} isTotal={true} price={isCatalog ? salePrice : revenue} label={isCatalog ? "SUBTOTAL ":"TU GANANCIA"} color={"#02c861"} fontWeight={"500"} fontWeightLabel={"200"} symbol={"= "} />
              </div>
            )}
          </div>
          {showJustItems ? null : (
            <div className='fixed-footer'>
              <MainButton loading={false} label={"CREAR PEDIDO"} onClick={openCheckoutModal}/>
            </div>
          )}
        </div>
      ) : (
        <div>Carrito de Compra Vacío</div>
      )}
    </div>
  );
}
