import React from 'react';
import styles from './OptionsBoxes.module.css';

// INICIO EJEMPLO USO:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
{/* <OptionsBoxes 
    optionName={"Color"} 
    optionValues={
    [
        {name:"Rojo", option_id:"iyuHAUy82", is_active: true},
        {name:"Azul", option_id:"Aaaaidy8eAed2", is_active: true},
        {name:"Verde", option_id:"xzzeAaeada1111", is_active: true},
    ]
    }
    optionValueSelected={option}
    onSelectOptionValue={(option)=>setOption(option)}
/> */}
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// FINAL EJEMPLO USO:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

export default function OptionsBoxes({ optionName, optionValues, optionValueSelected, onSelectOptionValue }) {
  return (
    <div className={styles.optionsBoxes}>
        <div className={styles.boxLabel}>
            {optionName}: <span>{optionValueSelected?.name}</span>
        </div>
        <div className={styles.boxesContainer}>
            {optionValues.map((value, valueIndex) =>
                <div
                    key={valueIndex}
                    className={`${value.option_id === optionValueSelected?.option_id ? styles.selected : styles.box}`}
                    onClick={() => onSelectOptionValue(value)}
                >
                    {value.name}
                </div>
            )}
        </div>
    </div>
  );
}
