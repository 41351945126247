import { Timestamp } from "firebase/firestore";

/**
 * Convierte un objeto Timestamp de Firebase a una instancia de Date.
 *
 * @param {any} date - La fecha a convertir. Puede ser un objeto Timestamp, una fecha, o un valor inválido.
 * @returns {Date} - Una instancia de Date si el parámetro era un Timestamp o una fecha válida, o null si el parámetro no es una fecha válida.
 */
const convertToDate = (date) => {
    if (date instanceof Timestamp) {
      return date.toDate();
    } else if (date instanceof Date) {
      return date;
    } else {
      return null;
    }
  };

export default convertToDate;