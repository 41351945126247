import PropTypes from "prop-types"
import { useState } from "react";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../utils/generateRandomId";
import getRandomDarkHexColor from "../../utils/getRandomDarkHexColor";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import MainButton from "../MainButton/MainButton";
import { useEffect } from "react";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import toastFunction from "../../utils/toastFunction";

const CustomerTagForm = ({
  selectedCustomerRowData,
  sellerTags,
  userData,
  closeTagModal,
  toast,
  isInsider,
}) => {
  const { t } = useTranslation("MyCustomers");
  const [tagId, setTagId] = useState(generateRandomId());
  const [tagName, setTagName] = useState("");
  const [tagColor, setTagColor] = useState(getRandomDarkHexColor());
  const [selectedCustomerTag, setSelectedCustomerTag] = useState("");
  const [dissabledTagName, setDissabledTagName] = useState(false);
  const { addToArray } = useFirebaseCRUD();
  const [insiderAssetsTags, setInsiderAssetsTags] = useState([]);

  // Inicializa un objeto con los valores del nuevo tag
  let newCustomTagValues = {
    id: tagId,
    label: tagName,
    color: tagColor,
  };

  // Función para manejar la creación de un nuevo tag para el cliente
  const handleCreateCustomerTag = async (e) => {
    e.preventDefault();
    const tagsInDocument = isInsider  ? insiderAssetsTags : sellerTags;

    // Validaciones para asegurar que el tag no exista previamente en la fila
    const validationCustomerTagId = selectedCustomerRowData?.tags?.some(
      (sellerTag) => sellerTag.id === newCustomTagValues.id
    );
    const validationCustomerTagName = selectedCustomerRowData?.tags?.some(
      (sellerTag) => sellerTag.label === newCustomTagValues.label
    );

    const validationTagId = tagsInDocument.some(
      (oldTag) => oldTag.id === newCustomTagValues.id
    );
    const validationTagName = tagsInDocument.some(
      (oldTag) => oldTag.label === newCustomTagValues.label
    );
    const validationCustomerNewTagNameVsOldTagsNames = tagsInDocument?.find(
      (sellerTag) => sellerTag.label === tagName
    );
       
    if (
      validationCustomerNewTagNameVsOldTagsNames &&
      !validationCustomerTagId &&
      !validationCustomerTagName
    ) {
      // Si el tag ya existe en los tags del cliente, usa ese tag existente
      newCustomTagValues = {
        id: validationCustomerNewTagNameVsOldTagsNames.id,
        label: validationCustomerNewTagNameVsOldTagsNames.label,
        color: validationCustomerNewTagNameVsOldTagsNames.color,
      };
      // Añade el tag al array de tags del cliente en Firestore
      const resultUnionTagCustomer = await addToArray(
        "customers",
        selectedCustomerRowData.id,
        "tags",
        newCustomTagValues
      );
      if (resultUnionTagCustomer) {
        // Muestra un mensaje de éxito si se añade el tag correctamente
      toastFunction(toast, "success", t("toast-summary.addTagSuccess"),  "", 4000);
      }
    } else if (
      !validationCustomerTagId &&
      !validationCustomerTagName &&
      !validationCustomerNewTagNameVsOldTagsNames
    ) {
      // Si el tag no existe previamente, añádelo al vendedor y al cliente
      if (!validationTagId && !validationTagName) {
        const collectionName = isInsider ? "config" : "seller_users";
        const documentId = isInsider ? "insiders_global_assets" : userData.uid;
        const fieldName = isInsider ? "tags" : "customers_tags";
        // Añade el tag al array de tags del vendedor en Firestore
        await addToArray(
          collectionName,
          documentId,
          fieldName,
          newCustomTagValues
        );
      }
      // Añade el tag al array de tags del cliente en Firestore
      const resultUnionTagCustomer = await addToArray(
        "customers",
        selectedCustomerRowData.id,
        "tags",
        newCustomTagValues
      );
      if (resultUnionTagCustomer) {
        // Muestra un mensaje de éxito si se añade el tag correctamente
      toastFunction(toast, "success", t("toast-summary.addTagSuccess"),  "", 4000);
      }
    } else {
      // Muestra un mensaje de advertencia si el tag ya existe
      toastFunction(toast, "warn", t("toast-summary.warnAlreadyExistTag"),  "", 4000);
    }

    // Cierra el modal después de realizar las operaciones
    closeTagModal();
  };

  // Función para manejar la selección de un tag de Shopify para el cliente
  const handleProductShopifySelect = (tag) => {
    // Actualiza el objeto newCustomTagValues con los valores del tag seleccionado
    newCustomTagValues.id = tag.id;
    newCustomTagValues.label = tag.label;
    newCustomTagValues.color = tag.color;

    // Actualiza los estados con los valores del tag seleccionado
    setTagId(tag.id);
    setSelectedCustomerTag(tag);
    setTagName(tag.label);
    setTagColor(tag.color);
    setDissabledTagName(true);
  };

  useEffect(() => {
    if (isInsider) {
      const fetchConfig = async () => {
        try {
          const configCollection = collection(db, "config");
          const documentInsidersGlobalAssetsRef = doc(configCollection, "insiders_global_assets");
          const documentSnapshot = await getDoc(documentInsidersGlobalAssetsRef);
        
          if (documentSnapshot.exists()) {
            const insiderAssetsTagsData = documentSnapshot.data();
            setInsiderAssetsTags(insiderAssetsTagsData.tags);
          } else {
            console.error("El documento insider_global_assets no existe.");
          }
        } catch (error) {
          console.error("Error al obtener y establecer los activos de insider:", error.message);
        }
      }
      fetchConfig();
    }  
  }, [isInsider]);
  console.log(insiderAssetsTags);

  return (
    <form onSubmit={handleCreateCustomerTag}>
      <CustomSelect
        floatLabel={true}
        optionLabel={"label"}
        options={isInsider ? insiderAssetsTags : sellerTags}
        value={selectedCustomerTag}
        placeholder={t("input-placeholders.selectCustomTag")}
        onChange={(e) => handleProductShopifySelect(e.target.value)}
        disabled={isInsider ? insiderAssetsTags.length === 0 : sellerTags.length === 0}
      />
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={tagName}
        placeholder={t("input-placeholders.tagName")}
        onChange={(e) => setTagName(e.target.value)}
        disabled={dissabledTagName}
        required={true}
      />
      <MainButton type="submit" label={t("addTag")} />
    </form>
  );
};
CustomerTagForm.propTypes = {
    selectedCustomerRowData: PropTypes.object,
    sellerTags: PropTypes.array,
    userData: PropTypes.object,
    closeTagModal: PropTypes.func,
    toast: PropTypes.object,
    isInsider: PropTypes.bool,
  };
export default CustomerTagForm;
