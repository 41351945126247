import PropTypes from "prop-types";
import { useState, useEffect } from 'react';
import styles from './CircleCard.module.css';
import { useAuthContext } from '../../contexts/Authcontext/Authcontext';
import VideoCardCover from "../VideoCardCover/VideoCardCover.jsx";

const CircleCard = ({coverTypeMedia, img_url, img_alt, img_placeholder, out_circle_color, label, without_border, onClick, isSelected, isEditable, handleSelectGroup, group, disabled}) => {
  const [image, setImage] = useState(img_placeholder);
  const { userSignedIn } = useAuthContext();

  useEffect(() => {
    const img = new Image();
    img.src = img_url;
    img.onload = () => {
      userSignedIn && setImage(img_url);
    };
  }, [img_url]);

  return (
    <div className={isEditable ? styles.circleCardContainerEditable : styles.circleCardContainer}>
      <div
        className={`clickable ${isEditable ? styles.circle_containerEditable : styles.circle_container}`}
        style={{ border: without_border ? 'none' : `3px solid ${isSelected ? `var(${out_circle_color})`: "transparent"}` }}
        onClick={onClick}
      >
        { coverTypeMedia?.code === 1 ? ( // Codigo de tipo de media para "video"
           <VideoCardCover video_feed_url={img_url}/>
        ) :
          <img
            className={styles.circle_image}
            src={image}
            alt={img_alt}
          />
        }
      </div>
      <p className={styles.circleLabel}>{label}</p>
      {isEditable && (
        <button disabled={disabled} type="button" onClick={() => handleSelectGroup(group, "group")} className={styles.buttonOverlay}>Editar</button>
      )}
    </div>
  );
}
CircleCard.propTypes = {
  img_url: PropTypes.string, // URL de la imagen principal
  img_alt: PropTypes.string, // Texto alternativo de la imagen
  img_placeholder: PropTypes.string, // URL de la imagen por defecto
  out_circle_color: PropTypes.string, // Color del borde del círculo
  label: PropTypes.string, // Etiqueta o texto para mostrar debajo del círculo
  without_border: PropTypes.bool, // Indica si se debe mostrar un borde o no
  onClick: PropTypes.func, // Función para manejar el clic en la tarjeta
  isSelected: PropTypes.bool, // Indica si la tarjeta está seleccionada o no
  isEditable: PropTypes.bool,
  handleSelectGroup: PropTypes.func,
  group: PropTypes.object,
  disabled: PropTypes.bool,
  coverTypeMedia: PropTypes.object
};
export default CircleCard;
