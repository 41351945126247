import React from 'react'
import styles from './MoneyDetailsTable.module.css';
import numeral from 'numeral';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import 'moment/locale/es';  // Importa el paquete de localización para español

export default function MoneyDetailsTable({ transactions, labelTableTitle, valueTableTitle }) {
    const formatDate = (date) => {
        return moment(date).format('DD-MM-YYYY');
    };

    const formatValue = (value) => {
        return numeral(value).format('$ 0,0[.]00');
    };

    const normalLabel = (label) => {
        return label ? label.toString() : '';
    };

    let labelFormat = (rowData) => normalLabel(rowData.label)
    switch (labelTableTitle) {
        case "Fecha":
            labelFormat = (rowData) => formatDate(rowData.label)
            break;
        default:
            break;
    }

    const determineColor = (value) => {
        return value < 0 ? 'var(--secondary-color)' : 'var(--main-hard-color)';
    };

    return (
        <div className={styles.MoneyDetailsTable}>
            <DataTable className={styles.table} value={transactions}>
                <Column
                    field="label" 
                    header={labelTableTitle} 
                    body={labelFormat} 
                    style={{width: '65%'}}
                />
                <Column
                    field="value" 
                    header={valueTableTitle} 
                    body={(rowData) => {
                        const value = formatValue(rowData.value);
                        const color = determineColor(rowData.value);
                        return <span style={{ color, fontWeight:"var(--font-weight-bold)"}}>{value}</span>;
                    }}
                />
            </DataTable>
        </div>
    );
}
