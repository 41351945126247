import { BiCaretDown, BiCaretUp, BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn.jsx";
import { useTranslation } from "react-i18next";
import CustomTable from "../../Components/CustomTable/CustomTable";
import DetailOptionCard from "../../Components/DetailOptionCard/DetailOptionCard";
import numeral from "numeral";
import { Toast } from "primereact/toast";
import useAuth from "../../hooks/useAuth.js";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import MainButton from "../../Components/MainButton/MainButton.jsx";
import CustomModal from "../../Components/CustomModal/CustomModal.jsx";
import RequestWithdrawalForm from "../../Components/RequestWithdrawalForm/RequestWithDrawalForm.jsx";

export default function Wallet() {
  const { t } = useTranslation("Wallet");
  const { toast } = useAuth();
  const { userData, userSellerOwnerData, isCoworker, isInsider } = useAuthContext();
  const { globalLoading, setGlobalLoading } = useLoaderContext();

  const [walletTransactions, setWalletTransactions] = useState([]);
  const [walletUserData ,setWalletUserData] = useState(null);
  const [visibleRequestWithdrawalForm, setVisibleRequestWithdrawalForm] = useState(false);
  const transactionColumns = [
    {
      field: "transaction_direction",
      filterable: false,
      body: (rowData) => {
        return rowData.transaction_direction == 1 ? <BiCaretUp  className="text-green-500 size-10"/> : <BiCaretDown  className="text-red-500 size-10"/>;
      }
    },
    {
      field: 'created_at',
      header: t("table-headers.createdAt"),
      filterable: false,
      // Columna para el campo 'created_at' con encabezado traducido y cuerpo personalizado para formatear la fecha
      body: (rowData) => {
        const createdAt = rowData?.created_at
          ? rowData?.created_at?.toDate()?.toLocaleString()
          : t("table-headers.invalidDateFormat");
        return <span>{createdAt}</span>;
      }, 
    },
    { 
      field: "id",
      header: t("table-headers.transaction"),
      filterable: false,
      sortable: false, 
    },
    {
      field: "transaction_type",
      header: t("table-headers.transactionType"),      
      filterable: false,
      sortable: false, 
      body: (rowData) => decodifiedTransactionField(rowData.transaction_type, "transaction_type")
    },
    { 
      field: "state",
      header: t("table-headers.state"),
      filterable: false,
      sortable: false, 
      body: (rowData) => decodifiedTransactionField(rowData.state, "state")
    },
    { 
      field: "transaction_note",
      header: t("table-headers.transactionNote"),
      filterable: false,
      sortable: false, 
    },
    { 
      field: "amount",
      header: t("table-headers.amount"),
      filterable: false,
      sortable: false,
      body: (rowData) => {
        return numeral(rowData.amount).format(t("cashFormat"))
      }
    },
  ];
  const decodifiedTransactionField = (cellValueCodified, cellField) => {
    const transactionTypes = {
      "1": t("transactionTypes.wallet_topup"),
      "2": t("transactionTypes.subscription_payment"),
      "3": t("transactionTypes.order_payment"),
      "4": t("transactionTypes.funds_withdraw"),
      "5": t("transactionTypes.subscription_refund"),
      "6": t("transactionTypes.order_refund"),
      "7": t("transactionTypes.returned_ship")

    };
    const transactionStates = {
      "1": t("transactionStates.pending"),
      "2": t("transactionStates.paid"),
      "3": t("transactionStates.reject"),
    };
  
    // Determinar el objeto correcto basado en el argumento 'cellField'
    const transactionObject = cellField === 'state' ? transactionStates : transactionTypes;
    return (
      <span>
        {transactionObject[cellValueCodified] || t("Tipo de transaccion desconocida")}
      </span>
    );
  };
  
  useEffect(() => {
    let userOwnerData;
    if ((isCoworker && userSellerOwnerData) || (isInsider && userSellerOwnerData)) {
      userOwnerData = userSellerOwnerData;
    } else {
      userOwnerData = userData;
    }
    if (!userOwnerData ) {
      setGlobalLoading(false);
      return;
    }
    const transactionsCollection = collection(db, "transactions");
    const walletsCollection = collection(db, "wallets");
    // console.log("Wallet relacionado", userOwnerData.related_wallet_id)
    const qTransactions = query(
      transactionsCollection, 
      where("related_wallet_id", "==", userOwnerData.related_wallet_id),
      orderBy("created_at", "asc")
    );
   const qWalletUser = query(
      walletsCollection, 
      where("id", "==", userOwnerData.related_wallet_id)
      )
    let unsubscribeTransactions;
    let unsubscribeWallet;

    try {
      unsubscribeTransactions = onSnapshot(qTransactions, (resultTransactions) => {
        const insiderDocuments = resultTransactions.docs.map(doc => doc.data());
        setGlobalLoading(false);
        setWalletTransactions(insiderDocuments);
      });
      unsubscribeWallet = onSnapshot(qWalletUser, (resultTransactions) => {
        const walletDocuments = resultTransactions.docs.map(doc => doc.data());
        setGlobalLoading(false);
        // console.log("documento de wallet >>", walletDocuments[0])
        setWalletUserData(walletDocuments[0]);
      });
    } catch (error) {
      console.error("Hubo un error", error);
    }
  
    return () => {
      unsubscribeTransactions && unsubscribeTransactions() &&
      unsubscribeWallet && unsubscribeWallet()
    };

  }, []);
 // Verificar si walletUserData está definido antes de usarlo
  if (!walletUserData) {
    return <></>; // O un indicador de carga
  }

  return (
    <div className="mb-20 ">
      <div style={{ marginBottom: "50px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtn")} />
      </div>
      <CustomTable
          className="custom-table"
          columns={transactionColumns}
          data={walletTransactions}
          action={() => {}}
        />
      <div className="mt-16">
        <DetailOptionCard
          bold_text={t("balanceTotal")}
          regular_text={numeral(walletUserData?.balance_ongoing + walletUserData?.balance_available)?.format(t("cashFormat"))}
          key={1}
          hidde_btn={true}
        />
        <DetailOptionCard
          bold_text={t("balanceIncomes")}
          regular_text={numeral(walletUserData?.balance_ongoing).format(t("cashFormat"))}
          key={2}
          hidde_btn={true}
        />
        <DetailOptionCard
          bold_text={t("balanceAvailable")}
          regular_text={numeral(walletUserData?.balance_available).format(t("cashFormat"))}
          key={3}
          hidde_btn={true}
        />
       
      </div>
      <Toast ref={toast} />
      <MainButton
        style={{ marginTop: "20px" }}
        type={"button"}
        label={t("requestWithdrawal")}
        loading={globalLoading}
        onClick={() => setVisibleRequestWithdrawalForm(true)}
        autoFocus
      />
       <CustomModal
        visible={visibleRequestWithdrawalForm}
        setVisible={setVisibleRequestWithdrawalForm}
        // menu_option={{label:t('menu-options.home')}}
        createMode={t("Solicitar retiro")}
        content={
          <RequestWithdrawalForm userData={userSellerOwnerData || userData } walletUser={walletUserData} toast={toast} onClose = {() => setVisibleRequestWithdrawalForm(false)}/>
        }
        onClose={() => setVisibleRequestWithdrawalForm(false)}
      />
    </div>
  );
}
