import { auth, functions } from "../firebase/firebase";
import { useEffect, useRef, useState } from "react";
import { sendSMSVerificationCode } from "../services/auth/sendSMSVerificationCode/sendSMSVerificationCode";
import { verifySmsCode } from "../services/auth/verifySmsCode/verifySmsCode";
import { useLoaderContext } from "../contexts/LoaderContext/LoaderContext";
import { signup } from "../services/auth/signup/signup";
import { useTranslation } from "react-i18next";
import { emailSignin } from "../services/auth/emailSignin/emailSignin";
import {
  sendPasswordResetEmail,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import useFirebaseCRUD from "./useFirebaseCRUD";
import toastFunction from "../utils/toastFunction";

function useAuth() {
  const toast = useRef(null);
  const { t } = useTranslation("useAuth");
// eslint-disable-next-line no-unused-vars
  const [type, setType] = useState("seller");
  const { updateDocument } = useFirebaseCRUD();
  const [smsCodeVerificator, setSmsCodeVerificator] = useState(null);
  const [verifyingSMS, setVerifyingSMS] = useState(false);
  const [smsCodeAsk, setSmsCodeAsk] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  useEffect(() => {
    if (globalLoading === false && verifyingSMS === true) {
      setGlobalLoading(true);
    }
  }, [globalLoading]);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setGlobalLoading(true);
      await createUser("auth/signupWithGoogle", {
        uid: user.uid,
        type,
        email: user.email,
        user_full_name: user?.displayName,
        cellphone: user?.phoneNumber,
        user_img_profile: user?.photoURL,
        ux_type: "online_seller"
      });
      updateDocument("seller_users", user.uid, { last_sigIn: new Date() });
    } catch (error) {
      toastFunction(toast, "error", `Algo salio mal ${error.message}`,  error.code, 4000);
      // console.error("Error al autenticarse con Google:", error.message);
    }

    setGlobalLoading(false);
  };

  const signInWithEmailHook = async (email, password) => {
    try {
      const res = await emailSignin(email, password);

      if (res.successSignIn === true) {
        toastFunction(toast, "success", "Sesion iniciada exitosamente",  "Seras redirigido a tu cuenta", 4000);
      } else {
        toastFunction(toast, "error", "Los datos no coinciden vuelve a intentarlo",  "Verifica tu email o tu contraseña", 4000);
      }
    } catch (error) {
      toastFunction(toast, "error", "Algo salio mal",  "Vuelve a intentarlo", 4000);
      setGlobalLoading(false);
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      toastFunction(toast, "success", `Hemos enviado un correo electronico de recuperacion a ${email}`,  "Sigue las instrucciones para recuperar tu contraseña", 4000);
    } catch (error) {
      // console.error("Ocurrio un error al recuperar contraseña", error);
      toastFunction(toast, "error", "Algo salio mal", error.code, 4000);
    }
  };

  const signInWithPhoneHook = async (
    phoneNumberWithCountryCode,
    recaptchaVerifier
  ) => {
    let res = await sendSMSVerificationCode(
      auth,
      phoneNumberWithCountryCode,
      recaptchaVerifier
    );
    if (res.success) {
      // console.log("res.verificator", res.verificator);

      setSmsCodeVerificator(res.verificator);
      setSmsCodeAsk(true);
      setGlobalLoading(false);
      startCountdown();
    } else {
      toast.current.show({
        severity: "error",
        summary: t(res.error.code),
        detail: "",
      });
      setGlobalLoading(false);
    }
  };

  const startCountdown = () => {
    setIsResendDisabled(true); // Desactivar el botón
    setCountdown(30); // Establecer el tiempo inicial

    let interval = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(interval); // Limpiar el intervalo si el contador ha terminado
          setIsResendDisabled(false); // Reactivar el botón
          return 0;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000); // Actualizar cada segundo
  };

  const verifySMSHook = async (smsCode) => {
    setVerifyingSMS(true);
    setGlobalLoading(true);
    let res = await verifySmsCode(smsCodeVerificator, smsCode);
    if (res.success) {
      return { success: true, uid: res.uid };
    } else {
      toastFunction(toast, t(res.error.code), "", );
      setVerifyingSMS(false);
      setGlobalLoading(false);
      return { success: false, uid: null };
    }
  };

  const createUser = async (endpoint, userData) => {
    try {
      const res = await signup(endpoint, userData);
      // // console.log("Res", res);
      if (res.successSignup === true) {
        toast.current.show({
          severity: "success",
          summary: "Inicio exitoso",
          detail: "Seras redirigido a tu cuenta",
        });
      } else {
        console.error(res.error);
        toast.current.show({
          severity: "error",
          summary: t(res.error),
          detail: "",
        });
        setVerifyingSMS(false);
        setGlobalLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Algo salio mal",
        detail: "Vuelve a intentarlo",
      });
      setVerifyingSMS(false);
      setGlobalLoading(false);
    }
  };

  const createCoWorkerUser = async (coWorkerData) => {
    // // console.log("coworkerdata antes del fetch", coWorkerData);
    setGlobalLoading(true);
    const createCoWorkerFunction = httpsCallable(functions, "createCoWorker");
    try {
      const result = await createCoWorkerFunction({
        ...coWorkerData,
      });
      // // console.log("coworkerdata despues del fetch", result, toast);
      return result;
    } catch (error) {
      console.error("Error llamando la función:", error);
      toastFunction(toast, "error", error.code, "", 4000);
    }
    setGlobalLoading(false);
  };

  const createInsiderUser = async (insiderData) => {
    // console.log("insiderdata antes del fetch", insiderData);
    setGlobalLoading(true);
    const createInsiderUserFunction = httpsCallable(functions, "createInsider");
    try {
      const result = await createInsiderUserFunction({
        ...insiderData,
      });
      // console.log("insiderdata despues del fetch", result);
      return result;
    } catch (error) {
      console.error("Error llamando la función:", error);
      toast.current.show({
        severity: "error",
        summary: t(error.code),
        detail: "",
      });
    }
    setGlobalLoading(false);
  };

  const setCustomClaims = async (uid, claimsArray) => {
    setGlobalLoading(true);
    const setCustomClaimsCoWorkerFunction = httpsCallable(functions, "setCustomClaimsCoWorker");
    try {
      const result = await setCustomClaimsCoWorkerFunction({
        uid,
        type,
        claimsArray,
      });
      if (result.data.success) {
        toast.current.show({
          severity: "success",
          summary: "Actualizado con exito",
          detail: "",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: t(result.data.error.code),
          detail: "",
        });
      }
    } catch (error) {
      console.error("Error llamando la función:", error);
      toast?.current?.show({
        severity: "error",
        summary: t(error.code),
        detail: "",
      });
    }
    setGlobalLoading(false);
  };

  const setCustomClaimsMasterUser = async () => {
    setGlobalLoading(true);
    const setCustomClaimsUserMasterFunction = httpsCallable(functions, "setCustomClaimsMaster");
    try {
      const uid = "56z5lV7Nl4QTzqaHjWO2NvufWnI3"
      const claimsArray = [
        // { claim: "isAdmin", value: true },
        // { claim: "is_master", value: true },
        // { claim: "businessId", value: "jW3fTStn8uZTdo5s8jaC"},
        // {claim: "type", value: "master" },
        // {claim: "is_insider", value: true }
      ]
      // console.log("antes de llamar", uid)
      const result = await setCustomClaimsUserMasterFunction({
        uid,
        type: "master_user",
        claimsArray,
      });
      // console.log("despues de llamar", result)
      if (result.data.success) {
        toast.current.show({
          severity: "success",
          summary: "Actualizado con exito",
          detail: "",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: t(result.data.error.code),
          detail: "",
        });
      }
    } catch (error) {
      console.error("Error llamando la función:", error);
      toast.current.show({
        severity: "error",
        summary: t(error.code),
        detail: "",
      });
    }
    setGlobalLoading(false);
  };

  const seeCustomClaims = async (user) => {
   try{
    const idTokenResult = await user.getIdTokenResult()
    // // console.log("Custom Claims:", idTokenResult.claims);
    return idTokenResult.claims
    }catch(error) {
      // console.log("Error obteniendo custom claims:", error);
    }
  };

  const getLastSession = (userData) => {
    const lastSessionTimestamp = userData?.last_session || 0; // Obtiene la marca de tiempo de la última sesión (0 si no está presente)
    const currentTime = Date.now(); // Obtiene la marca de tiempo actual
    // Calcula la diferencia de tiempo en milisegundos entre el tiempo actual y la última sesión
    const timeDifference = currentTime - lastSessionTimestamp;
    // Convierte los milisegundos a horas
    const hoursDifference = timeDifference / (1000 * 60 * 60); // 1000 ms * 60 segundos * 60 minutos
    // Si han pasado más de 24 horas desde la última sesión, devuelve true, de lo contrario, devuelve false
    const hasPassed24Hours = hoursDifference >= 24;
    const updateSession = lastSessionTimestamp === 0 && hasPassed24Hours;

    // Retorna la fecha actual en formato de marca de tiempo y el indicador si han pasado las 24 horas
    const firebaseTimestamp = new Date();

    return {
      timestamp: firebaseTimestamp,
      updateSession,
    };
  };

  const updateUserDataInAuth = async (
    userType,
    uid,
    properties,
    dataToUpdate
  ) => {
    const updateUserFunction = httpsCallable(functions, "updateUser");
    try {
      // // console.log( "enupdateuser", userType, uid, properties, dataToUpdate);
      const result = await updateUserFunction({
        userType,
        uid,
        properties,
        dataToUpdate,
      });
      if (result.data.success) {
        toast?.current?.show({
          severity: "success",
          summary: "Se actualziaron los datos con exito",
          detail: "",
        });
        return { success: true };
      } else {
        toast?.current?.show({
          severity: "error",
          summary: t(result.data.error.code),
          detail: "",
        });
        console.error(result.data.error.code);

        return { success: false };
      }
    } catch (error) {
      toast?.current?.show({
        severity: "error",
        summary: t(error.code),
        detail: "",
      });
      console.error(error.code);
      return { success: false };
    }
  };

  return {
    toast,
    type,
    smsCodeAsk,
    setSmsCodeAsk,
    countdown,
    isResendDisabled,
    signInWithGoogle,
    signInWithEmailHook,
    resetPassword,
    signInWithPhoneHook,
    verifySMSHook,
    createUser,
    createCoWorkerUser,
    createInsiderUser,
    setCustomClaims,
    setCustomClaimsMasterUser,
    seeCustomClaims,
    getLastSession,
    updateUserDataInAuth,
  };
}

export default useAuth;

// use
// const { toast, type, smsCodeAsk, countdown, isResendDisabled, signInWithEmailHook, resetPassword, signInWithPhoneHook, verifySMSHook, createUser } = useAuth();
