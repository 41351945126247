import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import { useTranslation } from "react-i18next";
import MainButton from "../MainButton/MainButton";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { generateRandomId } from "../../utils/generateRandomId.JS";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import DeleteButton from "../DeleteButton/DeleteButton";
import toastFunction from "../../utils/toastFunction";
import { collection, doc, getDoc, query, where } from "firebase/firestore";
import { db, storage } from "../../firebase/firebase.js";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber.jsx";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import { deleteObject } from "firebase/storage";
// Componente de formulario que permite encontrar si existe una vinculacion o vincular un producto de una tienda de shopify
const VinculateProductForm = ({ productData, userData, toast, onClose,  }) => {
  const { t } = useTranslation("Products");
  const { fetchShopifyProductConnection } = useShopifyRequest();
  const { updateArray, addToArray, removeFromArray, getDocumentsByQuery, updateDocument} = useFirebaseCRUD();
  const [storeSelect, setStoreSelect] = useState([]);
  const [productSelect, setProductSelect] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const sellerStores = userData?.seller_stores ? userData?.seller_stores.filter((store) => store.platform.code !== 3) :  []
  const [shoppexStoreData, setShoppexStoreData] = useState();
  const [productHasVinculated, setProductHasVinculated] = useState(false);
  const [disabledProductAndStoreSelect, setDisabledProductAndStoreSelect] = useState(false);
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const [syncedproductId, setSyncedProductId] = useState(null)
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  
   // Objeto para un nuevo producto sincronizado
  const syncedProduct = {
    id: syncedproductId || generateRandomId(),
    product_id: productData?.id,
    product_name: productData?.product_name,
    synced_product_id: productSelect?.id || productData?.id,
    synced_product_name: productSelect?.name || null,
  };

  const relatedSyncedShoppexProductInitialValues = {
      id: syncedproductId || syncedProduct.id,
      product_id: productData.id, // Id del producto ( ide del documento del producto en la coleccion "products")
      // TODO: Hereda el nombre para facilitar la vinculacion  
      custom_title: productData?.product_name|| "", // Titulo personalizado del producto
      premium_service_fee: 0, // Tarifa de servicio premium (Usado si la tienda is_a_buying_club: true )
      basic_service_fee: 0, // Tarifa de servicio basica (seria la ganancia del seller)
      with_fixed_price: false, // Si el monto total que pagará el consumidor final por el producto es fijo (Significa que si el precio de proveedor cambia, el precio en la tienda no se verá afectado, si es false, entonces el precio en tienda se actualiza automáticamente si cambia el precio del proveedor ya que se sumará el supplier_price + basic_service_fee
      is_active: true, // Indicador de si el producto esta activo
      suggested_retail_price: productData?.suggested_retail_price||  0, // Precio sugerido de venta, se guarda para emparejar el producto correctamente
      fixed_price: productData?.suggested_retail_price, // Monto FIJO total que pagara el consumidor final. Usado si with_fixed_price: true)
      product_cost: productData?.commercial_partner_price_with_VAT_with_service_fee,
      comparative_price: 0, // Precio de comparacion
  };

  const [ relatedSyncedShoppexProductValues, setRelatedSyncedShoppexProductValues ] = useState(relatedSyncedShoppexProductInitialValues)
  const [ relatedSyncedShoppexProductValuesCopyToDelete, setRelatedSyncedShoppexProductValuesCopyToDelete ] = useState();
  const [ relatedSyncedShoppexProductLandingContentCopyToDelete, setRelatedSyncedShoppexProductLandingContentCopyToDelete ] = useState();
  // console.log("relatedSyncedShoppexProductValues", relatedSyncedShoppexProductValues, productData, productData?.commercial_partner_price_with_VAT + productData?.service_fee)
  const [ premiumServiceFeeValue, setPremiumServiceFeeValue ] = useState();
  const [ comparativePriceValue, setComparativePriceValue ] = useState();


  const isFirstRender = useRef(true); 
  // Función para encontrar la tienda de un producto vinculado
  const findStoreOfVinculatedProduct = ( productId, relatedSyncedId, sellerStores) => {
    // Función auxiliar para buscar un producto en una lista de productos sincronizados
    const findProductInSyncedList = (syncedList, relatedSyncedId) => {
      return syncedList?.find(product => product?.id === relatedSyncedId);
    };
    // Itera sobre cada tienda y verifica si el producto se encuentra en alguna de ellas
    for (const store of sellerStores) {
      const syncedShopifyProduct = findProductInSyncedList(store.synced_products, relatedSyncedId);

      if (syncedShopifyProduct && syncedShopifyProduct.product_id === productId) {
        return {
          vinculatedStore: store,
          vinculatedProduct: syncedShopifyProduct
        };
      }
    }
  
    return {}; // Si no se encuentra el producto en ninguna tienda, retorna null
  };
  const handleDeleteVinculation = async () => {
    setGlobalLoading(true)
     if (storeSelect?.platform?.code === 2 && productSelect.length===0) {
      toastFunction( toast, "info", "Para eliminar una vinculacion con Shopify es necesario seleccionar el producto", "", 3000);
        return
    }

    const storeSyncedProducts = storeSelect?.synced_products?.filter((storeSyncedProduct) => {return storeSyncedProduct?.id !== syncedProduct?.id})
    const newSellerStores = { ...storeSelect, synced_products: storeSyncedProducts, };
    if (storeSelect?.platform?.code === 1) { // SI la tienda seleccionada es de shoppex hay que eliminar el objeto relacion de related_products en la tienda shoppex
      // Eliminamos el fixed price al igual que los objetos de la vinculacion
        const newFixedprices = {
          ...shoppexStoreData.fixed_prices,
        }
        delete newFixedprices[relatedSyncedShoppexProductValues.product_id]
        await updateDocument("stores", shoppexStoreData.id, {fixed_prices: newFixedprices});
        await removeFromArray("stores", storeSelect.id, "related_products", relatedSyncedShoppexProductValuesCopyToDelete);
        await removeFromArray("stores", storeSelect.id, "related_products_landing_content", relatedSyncedShoppexProductLandingContentCopyToDelete);
        if (relatedSyncedShoppexProductLandingContentCopyToDelete){
          for (const section of relatedSyncedShoppexProductLandingContentCopyToDelete.sections) {
            if (section.media_path) {
              await deleteObject(storage, section.media_path);
            }
          }
        }
        toastFunction( toast, "success", "Vinculacion eliminada con exito", "", 3000);
    }
    
     await updateArray("seller_users", userData.uid, "seller_stores", storeSelect,newSellerStores);
 
    setTimeout(() => {
      onClose();
      setGlobalLoading(false)      
    }, 500);
  }

  // Manejar la selección de una tienda
  const handleStoreSelect = async (store) => {
    setStoreSelect(store);
    const hasId = store.synced_products?.some((item) => item.product_id === productData.id);
    setGlobalLoading(true);
    switch (store?.platform?.code) {
      case 1:       // Si la plataforma es shoppex, al elegir el producto calculamos y actualizamoslos valores en base al producto seleccionado
        if (hasId) {
          setProductHasVinculated(true);
          toastFunction(toast, "warn", `${t("toast-summary.theProduct")}${productData.product_name} ${t("toast-summary.productIsVinculated")}`,  "", 4000);
        } else {
          const collectionShoppexStoresRef = collection(db, "stores");
          const qShoppexStore = query(collectionShoppexStoresRef, where("id", "==", store.id));
          const shoppexStoreData =  await getDocumentsByQuery(qShoppexStore);

          setShoppexStoreData(shoppexStoreData[0]);
          setProductHasVinculated(false);
          toastFunction(toast, "success", `${t("toast-summary.theProduct")}${productData.product_name} ${t("toast-summary.productIsNotVinculated")}`,  "", 4000);
          setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            product_cost: productData?.commercial_partner_price_with_VAT_with_service_fee,
            fixed_price: productData?.suggested_retail_price,
            basic_service_fee: productData.suggested_retail_price - (productData?.commercial_partner_price_with_VAT_with_service_fee),
            // se agrega el precio sugerido para poder emparejar los productDataos despues
            suggested_retail_price: productData.suggested_retail_price,
          }));
        }
        break;
      case 2:
        await fetchShopifyProductsData(store);
        break;
      default:
        console.log("Plataforma no especificada");
        break;
    }
    setGlobalLoading(false);
  };
  const fetchShoppexSellerProducts = async (businessId) => {
    const queryRef = query(collection(db, "products"), where("commercial_partner_id", "==", businessId));
    const resultFetchShoppex =  await getDocumentsByQuery(queryRef);
    const extractedProductShoppexProperties = resultFetchShoppex.map((product) => {
      const { product_name, id, commercial_partner_price_with_VAT, suggested_retail_price } = product;
      return { name: product_name, id: id, commercial_partner_price_with_VAT, suggested_retail_price };
    });

    // Si se selecciona un producto del cual el usario es dueño no podra vincularlo con el mismo producto
     if (userData?.business_id === productData?.commercial_partner_id) {
       const filteredExtractedDataWhithoutSelectedShoppexProduct = extractedProductShoppexProperties?.filter((shoppexProduct) => {
         return shoppexProduct?.id !== productData?.id
       })
       setProductsData(filteredExtractedDataWhithoutSelectedShoppexProduct);
     }else {
       setProductsData(extractedProductShoppexProperties);

     }

  };
  // Manejar la selección de un producto Shopify
  const handleProductSelect = (product) => {
  // Verificar si el producto ya está vinculado a la tienda seleccionada
    const hasId = storeSelect.synced_products?.some(
      (item) => item.synced_product_id === product.id
    );
    console.log(hasId)
    if (hasId) {
      setProductHasVinculated(true);
      toastFunction(toast, "warn", `${t("toast-summary.theProduct")}${product.name} ${t("toast-summary.productIsVinculated")}`,  "", 4000);
    } else {
      setProductHasVinculated(false);
      toastFunction(toast, "success", `${t("toast-summary.theProduct")}${product.name} ${t("toast-summary.productIsNotVinculated")}`,  "", 4000);
    }
    // Aquí puedes añadir lógica adicional según sea necesario después de la verificación
    setProductSelect(product);
  };

  // Obtener productos de Shopify para una tienda
  const fetchShopifyProductsData = async (shopifyStore) => {
    setGlobalLoading(true);
    const body = {
      shopifyUrl: shopifyStore.store_url,
      tokenId: shopifyStore.product_sync_shopify_app_token,
    };
    try {
      // Realizar la solicitud para obtener productos de Shopify
      const resultFetchProductsShopify = await fetchShopifyProductConnection(
        body
      );
    
      // Actualizar el estado con los productos extraídos
      const newArrayWithProductUsableProperties = extractProductShopifyProperties(
        resultFetchProductsShopify?.data.products
      );

      setProductsData(newArrayWithProductUsableProperties);

      if (resultFetchProductsShopify.status === 200) {
        toastFunction(toast, "success", t("succefullProductsConnection"),  "", 4000);
      }
      if (resultFetchProductsShopify?.data.productsLength === 0) {
        toastFunction(toast,  "warn", t("noProductsInShopify"),  "", 4000 );
      }
      return true;
    } catch (error) {
      setProductsData([]);
      setProductHasVinculated(true);
      console.error(error);
      toastFunction(toast, "error", t("toast-summary.errorProductsConnection"), "", 4000 );
      return false;
    }
  };

  // Extraer propiedades necesarias de los productos
  const extractProductShopifyProperties = (products) => {
    const extractedData = products.map((product) => {
      const { title, id } = product;
      return { name: title, id: id }; // Cambia los nombres de las claves según tu preferencia
    });
    return extractedData;
  };

  // Función para vincular un producto de Shopify a una tienda
  const vinculateProduct = async (e) => {
    let newrelatedSyncedShoppexProductValues = {...relatedSyncedShoppexProductValues, comparative_price: comparativePriceValue, categories_ids: productData?.categories_ids} ;
    e.preventDefault();
    if (storeSelect?.platform?.code === 2 && productSelect.length===0) {
      toastFunction( toast, "success", "Para eliminar una vinculacion con Shopify es necesario seleccionar el producto", "", 3000);
        return
    }
    let resultUpdateArray;
    // Verificar si la tienda está vinculada con el producto relacionado
    const {vinculatedStore} = findStoreOfVinculatedProduct(productData?.id, productData?.related_synced_id, sellerStores);
    if (storeSelect?.is_a_buying_club) {
      newrelatedSyncedShoppexProductValues.premium_service_fee = premiumServiceFeeValue - relatedSyncedShoppexProductValues.product_cost;

    }
    // console.log("Nuevo objeto", newrelatedSyncedShoppexProductValues)
    // console.log("Objeto agregar", newrelatedSyncedShoppexProductValues)
    // console.log("Objeto borrar", relatedSyncedShoppexProductValuesCopyToDelete)

    setGlobalLoading(true);
    if (!vinculatedStore){
      // Si no está vinculada, agregar el nuevo producto sincronizado a la tienda del seller
      // Si la tienda es de shopify elegimos el nombre sera el del producto seleccionado, si es de shoppex sera el el titulo custom
      const syncedProductName = storeSelect?.platform?.code === 2 ? productSelect?.name: relatedSyncedShoppexProductValues?.custom_title
      const amountSyncedProducts = [
        ...storeSelect.synced_products,
        {...syncedProduct, synced_product_name: syncedProductName},
      ];
      const newSellerStores = {
        ...storeSelect,
        synced_products: amountSyncedProducts,
      };
      resultUpdateArray = await updateArray( "seller_users", userData.uid, "seller_stores", storeSelect,  newSellerStores);
      // Si la tienda es SHOPPEX, agregar el nuevo producto sincronizado a la tienda en "stores" ademas la base del contenido de la landing
      if (storeSelect.platform.code === 1) {
        const newFixedprices = {
          ...shoppexStoreData.fixed_prices,
          [newrelatedSyncedShoppexProductValues.product_id]: newrelatedSyncedShoppexProductValues.with_fixed_price, 
        }
        await updateDocument("stores", shoppexStoreData.id, {fixed_prices: newFixedprices});
        await addToArray(
          "stores", 
          storeSelect.id,
          "related_products",
          {...newrelatedSyncedShoppexProductValues, id: relatedSyncedShoppexProductInitialValues.id});
        await addToArray( "stores", storeSelect.id, "related_products_landing_content", {id: relatedSyncedShoppexProductInitialValues.id, product_id: productData.id, sections: []});
      }
    } else if (vinculatedStore) {
      if (storeSelect?.platform?.code === 1) {
        // Filtramos los productos sincroinzados para sacar el producto anterior y luego agregar el nuevo
        const filteredSyncedProducts = storeSelect.synced_products.filter((syncedProduct) => productData?.related_synced_id !==  syncedProduct.id);
        const newSyncedproducts = [
          ...filteredSyncedProducts, 
          {...syncedProduct, synced_product_name: newrelatedSyncedShoppexProductValues?.custom_title }
        ];
        const newSellerStore = {...storeSelect, synced_products: newSyncedproducts}
        // Actualizamos fixed_prices de la tienda de shoppex
        const newFixedprices = {
          ...shoppexStoreData.fixed_prices,
          [newrelatedSyncedShoppexProductValues.product_id]: newrelatedSyncedShoppexProductValues.with_fixed_price, 
        }
        await updateDocument("stores", shoppexStoreData.id, {fixed_prices: newFixedprices});
        resultUpdateArray =  await updateArray("seller_users", userData.uid, "seller_stores", storeSelect, newSellerStore);
        resultUpdateArray =  await updateArray("stores", storeSelect.id, "related_products", relatedSyncedShoppexProductValuesCopyToDelete, newrelatedSyncedShoppexProductValues);
      }
    }
    if (resultUpdateArray) {
        toastFunction(toast, "success", t("succefullVinculate"),  "", 4000);
        onClose();
        setGlobalLoading(false);
    }
  };
  const handleToggleSwitchFixedPrice = (e) => {
    // Actualiza el estado utilizando el anterior valor de product_is_active
    setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            with_fixed_price: e.value,

        }));
  };
  const handleToggleSwitchIsActive = (e) => {
    // Actualiza el estado utilizando el anterior valor de product_is_active
    setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            is_active: e.value,

        }));
  };
  const onBlurSetFixedPrice = () => {
    if( relatedSyncedShoppexProductValues.fixed_price <= productData.commercial_partner_price_with_VAT){
        const sellerRevenue = relatedSyncedShoppexProductValues.suggested_retail_price - productData.commercial_partner_price_with_VAT
        toastFunction(toast, "warn", "El precio minimo es el precio del proveedor", "", 4000);
        setRelatedSyncedShoppexProductValues((prevValues) => ({
            ...prevValues,
            fixed_price: productData.suggested_retail_price,
            basic_service_fee: sellerRevenue,
        }));
      }
  };

  useEffect(() => {
    setGlobalLoading(true);
    // SI el producto esta marcado con un id de vinculacion, buscamos la tienda con el id del producto y de la vinculacion para mostrarla al montar el componente 

    if (productData?.related_synced_id) {
    // encontramos entre las tiendas del seller el producto con el que coincidan los ids de la sincronizacion y la tienda, para seteear los valores del formulario
      const { vinculatedStore, vinculatedProduct } = findStoreOfVinculatedProduct( 
          productData?.id,
          productData?.related_synced_id,
          sellerStores
        );
        setSyncedProductId(vinculatedProduct?.id)

      let vinculatedProductSelected;      
      if ( vinculatedStore ) {
        toastFunction(toast, "info", t("Estamos obteniendo tus productos"),  "", 4000 );
        setStoreSelect(vinculatedStore);
        if (vinculatedStore?.platform.code === 1) {  // Si la plataforma es Shoppex buscamos en la coleccion 'stores' el objeto de la vinculacion 
          // Traemos los productos que esten relacionado con el businessId del usuarios
          fetchShoppexSellerProducts(userData?.business_id)
          const collectionShoppexStoresRef = collection(db, "stores");
          const docShoppexStoreRef = doc(collectionShoppexStoresRef, vinculatedStore.id);
          // Id del objeto de la vinculacion de la tienda shoppex en "stores"
          const syncedIdOfShoppexStoreProduct = vinculatedProduct.id
          getDoc(docShoppexStoreRef)
            .then((docSnapshot) => {
              if (docSnapshot.exists()) {
                const shoppexStoreData = docSnapshot.data();
                // Construimos el objeto de la vinculacion para que coincida con algun objeto de la lista de productos que se obtienen en `fetchShoppexSellerProducts`
                const findedVinculationShoppexObject =  shoppexStoreData.related_products.find((product) => product.id === syncedIdOfShoppexStoreProduct)
                const findedVinculationShoppexProductLandingContent =  shoppexStoreData.related_products_landing_content.find((landingContent) => landingContent.id === syncedIdOfShoppexStoreProduct)
                 vinculatedProductSelected = {
                    id: vinculatedProduct?.synced_product_id,
                    name: vinculatedProduct?.synced_product_name,
                    suggested_retail_price: findedVinculationShoppexObject?.suggested_retail_price,
                    commercial_partner_price_with_VAT:  findedVinculationShoppexObject?.product_cost
                };
                setShoppexStoreData(shoppexStoreData);
                setDisabledProductAndStoreSelect(true);
                setProductSelect(vinculatedProductSelected);
                // console.log("Objeto vinculado", findedVinculationShoppexObject)
                if (vinculatedStore?.is_a_buying_club) {
                  setPremiumServiceFeeValue(findedVinculationShoppexObject.premium_service_fee + findedVinculationShoppexObject?.product_cost)
                }
                setComparativePriceValue(findedVinculationShoppexObject?.comparative_price)
                setRelatedSyncedShoppexProductValues(findedVinculationShoppexObject);
                setRelatedSyncedShoppexProductValuesCopyToDelete(findedVinculationShoppexObject);
                setRelatedSyncedShoppexProductLandingContentCopyToDelete(findedVinculationShoppexProductLandingContent);

              } else {
                console.log("No se encontró ningún documento para el ID proporcionado.");
              }
            })
            .catch((error) => {
              console.error("Error al obtener el documento:", error);
            });
        } else if (vinculatedStore.platform.code === 2) {
         vinculatedProductSelected = {
            id: vinculatedProduct?.synced_product_id,
            name: vinculatedProduct?.synced_product_name,
          };
          // setProductHasVinculated(true);
          setDisabledProductAndStoreSelect(true);
          fetchShopifyProductsData(vinculatedStore);
          setProductSelect(vinculatedProductSelected);
        }
      }
      // Actualizar la bandera de primer render después del primer render
      isFirstRender.current = false;
    }
    setGlobalLoading(false);
  }, [userData]);

  console.log(storeSelect)
  return (
    <form onSubmit={vinculateProduct}>
      <div className="mt-8">
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={sellerStores}
          value={storeSelect}
          placeholder={t("input-placeholders.selectStore")}
          onChange={(e) => handleStoreSelect(e.target.value)}
          required={true}
          disabled={disabledProductAndStoreSelect}
        />
        
        {/* Que solo aparezca el select del producto cuando la tienda es shopify */}
        { storeSelect && storeSelect?.platform?.code  === 2 && (<CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={productsData}
          value={productSelect}
          placeholder={t("input-placeholders.selectProduct")}
          onChange={(e) => handleProductSelect(e.target.value)}
          required={true}
          disabled={productsData?.length === 0 || disabledProductAndStoreSelect}
        />)}

        {/* Que solo aparezca los campos adicionales para la vinculacion cuando la tienda es shoppex */}
        {(storeSelect?.platform?.code === 1 && !productHasVinculated)&&(
           <>
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={relatedSyncedShoppexProductValues?.custom_title}
              placeholder={t("input-placeholders.customTitle")}
              onChange={(e) => setRelatedSyncedShoppexProductValues((prevValues) => ({
                  ...prevValues,
                  custom_title: e?.target?.value,
              }))}
              disabled={globalLoading}
              required={true}
            />
               <CustomInputNumber
                required={false}
                floatLabel={true}
                value={comparativePriceValue}
                min={0}
                prefix={"$ "}
                onChange={(e) => {
                  const comparativePrice = e.value ;
                  setComparativePriceValue(comparativePrice)
                }}
                // max={walletUser.balance_available}
                placeholder={t("input-placeholders.comparativePrice")}
                disabled={!storeSelect?.is_a_buying_club}
              />
             { storeSelect?.is_a_buying_club && (  
              <CustomInputNumber
                required={false}
                floatLabel={true}
                value={premiumServiceFeeValue}
                min={0}
                prefix={"$ "}
                onChange={(e) => {
                  const premiumValue = e.value ;
                  setPremiumServiceFeeValue(premiumValue)
                }}
                placeholder={t("input-placeholders.premiumServiceFee")}
                disabled={!storeSelect?.is_a_buying_club}
              />
              )}
         
            <CustomInputNumber
              required={true}
              floatLabel={true}
              value={relatedSyncedShoppexProductValues?.fixed_price}
              prefix={"$ "}
              placeholder={t("input-placeholders.fixedPrice")}
              onChange={(e) => {
                const sellerRevenue = e.value -( productData?.commercial_partner_price_with_VAT_with_service_fee);
                setRelatedSyncedShoppexProductValues((prevValues) => ({
                  ...prevValues,
                  fixed_price: e.value,
                  basic_service_fee: sellerRevenue,
                }));
              }}
              onBlur={onBlurSetFixedPrice}
              disabled={globalLoading}
            />
            <CustomInputNumber
              required={true}
              floatLabel={true}
              value={relatedSyncedShoppexProductValues?.product_cost}
              min={0}
              prefix={"$ "}
              placeholder={t("input-placeholders.productCost")}
              disabled={true}
            />
            <CustomInputNumber
              required={true}
              floatLabel={true}
              value={relatedSyncedShoppexProductValues?.basic_service_fee}
              min={0}
              prefix={"$ "}
              // max={walletUser.balance_available}
              placeholder={t("input-placeholders.basicServiceFee")}
              disabled={true}
            />
           
          <span className="flex items-center gap-4 mt-4 mb-4">
            <CustomInputSwitch
                checked={relatedSyncedShoppexProductValues?.with_fixed_price}
                name={"with_fixed_price"}
                onChange={(e) => handleToggleSwitchFixedPrice(e)}
                disabled={globalLoading}
            /> Fijar el precio
          </span>
          <p>{t("fixedPriceMessage")}</p>
          <span className="flex items-center gap-4 mt-4 mb-4">
            <CustomInputSwitch
                checked={relatedSyncedShoppexProductValues?.is_active}
                name={"is_active"}
                onChange={(e) => handleToggleSwitchIsActive(e)}
                disabled={globalLoading}
            /> Activar el producto
          </span>
           </>
        )}
      </div>
      <div className="mt-12 grid gap-4">
        <MainButton
          type={"onSubmit"}
          label={t("vinculateProduct")}
          autoFocus
          loading={globalLoading}
          disabled={globalLoading  || productHasVinculated 
            || (storeSelect?.platform?.code === 2 && disabledProductAndStoreSelect)
          }
        />
         { productData?.related_synced_id && (
            <DeleteButton
                type={"button"}
                label={t("Deshacer vinculacion")}
                loading={globalLoading}
                onClick={() => setVisibleConfirmDialog(true)}
                disabled={globalLoading}

            />)}
      </div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteVinculation.header")}
          message={t("confirmDialog-deleteVinculation.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteVinculation}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteVinculation.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteVinculation.rejectLabel")}
        />
    </form>
  );
};
VinculateProductForm.propTypes = {
  selectedSellerStore: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  productData: PropTypes.object,
  toast: PropTypes.object,
};
export default VinculateProductForm;
