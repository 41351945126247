const getRandomDarkHexColor = () => {
  // Genera componentes RGB aleatorios entre 0 y 127
  const red = Math.floor(Math.random() * 210);
  const green = Math.floor(Math.random() * 210);
  const blue = Math.floor(Math.random() * 210);

  // Convierte los componentes RGB a formato hexadecimal
  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  // Combina los componentes en un color hexadecimal oscuro
  const darkColor = `#${redHex}${greenHex}${blueHex}`;

  return darkColor;
};

export default getRandomDarkHexColor;