import PropTypes from "prop-types";

import { useState } from "react";
import styles from "./DetailVolumeTableRange.module.css";

import { useTranslation } from "react-i18next";
import MainButton from "../../MainButton/MainButton";
import { useLoaderContext } from "../../../contexts/LoaderContext/LoaderContext";
import DeleteButton from "../../DeleteButton/DeleteButton";
import CustomConfirmDialog from "../../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import CustomInputNumber from "../CustomInputNumber/CustomInputNumber";
import toastFunction from "../../../utils/toastFunction";

const DetailVolumeTableRange = ({
  onClose,
  selectedVolumeRange,
  promotionData,
  toast,
  updatePromotionValues, // Función para actualizar el campo de la promoción
}) => {
  const { t } = useTranslation("Promotions");
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const hasEdit = !!Object.keys(selectedVolumeRange).length;
  // Estados locales para gestionar los valores de los rangos de volumen y su confirmación
  const [minValue, setMinValue] = useState(selectedVolumeRange?.min || 1);
  const [maxValue, setMaxValue] = useState(selectedVolumeRange?.max || null);
  const [discountVolumenValue, setDiscountVolumenValue] = useState(selectedVolumeRange?.value || 0);
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  // Función para validar los campos del nuevo rango
  const validateRangeFields = (newRange) => {
    // Validación de confirmación de contraseña
    if (!newRange.min) {
      toastFunction(toast, "error", t("toast-summary.errorRangeMin"),  "", 3000);
      return false;
    }
    // Validación de pais
    if (!newRange.max) {
      toastFunction(toast, "error", t("toast-summary.errorRangeMax"),  "", 3000);
      return false;
    }
    // Validacion de longitud de contraseña
    if (!newRange.value) {
      toastFunction(toast, "error", t("toast-summary.errorRangeAmount"),  "", 3000);
      return false;
    }
    // Validación de que range.max no sea menor que range.min
    if (newRange.max < newRange.min) {
      toastFunction(toast, "error", t("toast-summary.errorMaxLessThanMin"),  "", 3000);
      return false;
    }
    return true;
  };
  // Manejador de eventos para la presentación del formulario de rango

  const handleRangeSubmit = () => {
    // Construye el objeto de nuevo rango
    const newRange = {
      min: minValue,
      max: maxValue,
      value: discountVolumenValue,
    };
    if (hasEdit) {
      // Filtra los rangos actualizados excluyendo el rango seleccionado

      const updatedVolumenDiscounts = promotionData.volumen_discounts.filter(
        (range) => {
          return !(
            range.min === selectedVolumeRange.min &&
            range.max === selectedVolumeRange.max
          );
        }
      );
      // Verifica si hay superposición con otros rangos

      const overlap = updatedVolumenDiscounts.some((range) => {
        return (
          (newRange.min >= range.min && newRange.min <= range.max) ||
          (newRange.max >= range.min && newRange.max <= range.max) ||
          (range.min >= newRange.min && range.min <= newRange.max) ||
          (range.max >= newRange.min && range.max <= newRange.max) ||
          (range.min <= newRange.min && range.max >= newRange.max)
        );
      });
      // Si no hay superposición, valida los campos y actualiza los rangos

      if (!overlap) {
        if (validateRangeFields(newRange)) {
          const finalUpdatedVolumenDiscounts = [
            ...updatedVolumenDiscounts,
            newRange,
          ];
          updatePromotionValues(
            "volumen_discounts",
            finalUpdatedVolumenDiscounts
          );
          toastFunction(toast, "success", t("toast-summary.successRangeAmount"),  "", 3000);
        }
        // Actualizar el campo 'volumen_discounts' de promotionValues con el nuevo rango
      } else {
        // Mostrar un mensaje de error, notificación o ejecutar alguna acción según sea necesario
        toastFunction(toast, "warn", t("toast-summary.errorRangeAmountOverlap"),  "", 3000);
      }
    } else {
      // Verifica la superposición con otros rangos

      const overlap = promotionData.volumen_discounts.some((range) => {
        return (
          (newRange.min >= range.min && newRange.min <= range.max) ||
          (newRange.max >= range.min && newRange.max <= range.max) ||
          (range.min >= newRange.min && range.min <= newRange.max) ||
          (range.max >= newRange.min && range.max <= newRange.max)
        );
      });
      // Si no hay superposición, valida los campos y agrega el nuevo rango
      if (!overlap) {
        if (validateRangeFields(newRange)) {
          const updatedVolumenDiscounts = [
            ...promotionData.volumen_discounts,
            newRange,
          ];
          updatePromotionValues("volumen_discounts", updatedVolumenDiscounts);
          toastFunction(toast, "success",  t("El rango fue creado con exito"),  "", 3000);
        }
        // Actualizar el campo 'volumen_discounts' de promotionValues con el nuevo rango
      } else {
        // Mostrar un mensaje de error, notificación o ejecutar alguna acción según sea necesario
        toastFunction(toast, "warn",  t("Los rangos se superponen. No se puede agregar el nuevo rango"),  "", 3000);
      }
    }
    onClose(false);
  };
  // Manejador de eventos para eliminar el rango de volumen seleccionado
  const handleDeleteVolumeRange = () => {
    setGlobalLoading(true);

    // Realiza la lógica para eliminar el rango de volumen seleccionado
    const updatedVolumenDiscounts = promotionData.volumen_discounts.filter(
      (range) => {
        // Comprueba si el rango actual es diferente al rango seleccionado
        return !(
          range.min === selectedVolumeRange.min &&
          range.max === selectedVolumeRange.max
        );
      }
    );
    // Actualiza promotionData con los volumen_discounts actualizados
    updatePromotionValues("volumen_discounts", updatedVolumenDiscounts);
    // Simulación de la respuesta de eliminación (puedes ajustarlo según tu lógica real)
    const resultDeleteFromVolumenRangesArray = true;
    if (resultDeleteFromVolumenRangesArray) {
      toastFunction(toast, "success", t("toast-summary.deleteVolumeRange"),  "", 3000);
    } else {
      toastFunction(toast, "error", t("toast-summary.errorVolumeRange"),  "", 3000);
    }
    setGlobalLoading(false);
    onClose(false);
  };

  const updateVolumenDiscountValue = (fieldName, value) => {
    let newValue = value;
    let newAmount = value; // Se utiliza para actualizar el estado 'amount' si es necesario
    switch (fieldName) {    
      case "amount":
        console.log("unityType.code", promotionData?.discount_unit?.code, value)
        if (promotionData?.discount_unit?.code === 0) {
          // Si promotionData.discount_unit.code es igual a 0, asegúrate de que el valor no sea más de 100
          const numericValue = parseInt(value); // Convertir a número
          if (!isNaN(numericValue)) {
              newAmount = Math.min(numericValue, 100); // Limitar el valor a 100 si es mayor
              newValue = parseInt(newAmount); // Convertir a número entero
          }
        } else if (promotionData?.discount_unit?.code === 1) {
          // Si promotionData.discount_unit.code es igual a 1, formatea el valor como pesos colombianos usando numeral
          const numericValue = parseFloat(value); // Convertir a número
          if (!isNaN(numericValue)) {
            newValue = numericValue;
          }
        }
        setDiscountVolumenValue(newValue);
        break;
      default:
        console.log("entre default", fieldName, value)
        setDiscountVolumenValue(value);

      break;
    }
  
  };
  return (
    <div>
      <CustomInputNumber
        floatLabel={true}
        type={"text"}
        placeholder={"min"}
        value={minValue}
        onChange={(e) => setMinValue(e.value)}
      />
      <CustomInputNumber
        floatLabel={true}
        type={"text"}
        placeholder={"max"}
        value={maxValue}
        onChange={(e) => setMaxValue(e.value)}
      />
      <CustomInputNumber
        placeholder={"monto"}
        floatLabel={true}
        prefix={promotionData?.discount_unit?.code === 1 ? "$" : ""}
        suffix={promotionData?.discount_unit?.code === 0 ? "%" : ""}
        value={discountVolumenValue}
        onChange={(e) => updateVolumenDiscountValue("amount", e.value)}
        disabled={!promotionData.discount_unit}
      />
      <div className={styles.containerBtns}>
        <MainButton
          type={"button"}
          label={hasEdit ? t("updateVolumenRange") : t("AddVolumeRange")}
          loading={globalLoading}
          onClick={handleRangeSubmit}
        />
        {hasEdit && (
          <DeleteButton
            type={"button"}
            label={t("deleteVolumeRange")}
            loading={globalLoading}
            onClick={() => setVisibleConfirmDialog(true)}
          />
        )}
      </div>
      <div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteVolumeRange.header")}
          message={t("confirmDialog-deleteVolumeRange.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteVolumeRange}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteVolumeRange.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteVolumeRange.rejectLabel")}
        />
      </div>
    </div>
  );
};

DetailVolumeTableRange.propTypes = {
  onClose: PropTypes.func,
  selectedVolumeRange: PropTypes.object,
  userData: PropTypes.object,
  promotionData: PropTypes.object,
  labelDelete: PropTypes.string,
  labelSuccess: PropTypes.string,
  toast: PropTypes.object,
  updatePromotionValues: PropTypes.func,
};
export default DetailVolumeTableRange;
