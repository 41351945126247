import styles from "./ShippingNewsFilters.module.css"
import PropTypes from "prop-types";
import CustomCalendar from "../FormComponents/CustomCalendar/CustomCalendar.jsx";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import MainButton from "../MainButton/MainButton.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import { Button } from "primereact/button";
import SecondaryButton from "../SecondaryButton/SecondaryButton.jsx";

const ShippingNewsFilters = ({
    selectedShippingCompany, setSelectedShippingCompany,
    queryDates, setQueryDates,
    selectedShippingNew,setSelectedShippingNew,
    selectedState, setSelectedState,
    guideNumber, setGuideNumber,
    t, globalLoading,
    handleConsultShippingNews,
    cleanFilters,
    cleanFiltersAndConsultOrders,
}) => {

    const { shippingCompanies, coordinadoraShippingNews, enviaShippingNews, stateNews  } = useGlobalContext();
    const shippingNews =  selectedShippingCompany?.code === 1 ?  coordinadoraShippingNews: selectedShippingCompany?.code === 2 ? enviaShippingNews : [] ;
    return (
    <>
      <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={shippingCompanies}
          value={selectedShippingCompany}
          placeholder={t("input-placeholders.selectShippingCompany")}
          onChange={(e) => setSelectedShippingCompany(e.value)}
          disabled={globalLoading}
        />
        <CustomCalendar
          dates={queryDates}
          setDates={(e) => setQueryDates(e.target.value)}
          selectionMode="range"
          placeholder={t("input-placeholders.calendarRange")}
        />
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={shippingNews}
          value={selectedShippingNew}
          placeholder={t("input-placeholders.selectNew")}
          onChange={(e) => setSelectedShippingNew(e.value)}
        />
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={stateNews}
          value={selectedState}
          placeholder={t("input-placeholders.selectState")}
          onChange={(e) => setSelectedState(e.value)}
        />
        <CustomInputText
            floatLabel={true}
            type={"text"}
            value={guideNumber}
            placeholder={t("input-placeholders.guideNumber")}
            onChange={(e) => setGuideNumber(e.target.value)}
        />
        
        <div className="grid gap-4 mt-4">
          <Button
            className={styles.secondaryButton}
            type={"button"}
            label={t("cleanFilters")}
            loading={globalLoading}
            onClick={cleanFilters}
          />
          <SecondaryButton
            onClick={cleanFiltersAndConsultOrders}
            type={"button"}
            label={t("cleanAndConsult")}
          />
          <MainButton type={"submit"} label={t("consultNews")} loading={globalLoading} autoFocus onClick={handleConsultShippingNews}/>
        </div>
    </>
    )
}

ShippingNewsFilters.propTypes = {
    selectedShippingCompany: PropTypes.object,
    setSelectedShippingCompany: PropTypes.func,
    queryDates: PropTypes.array,
    setQueryDates: PropTypes.func,
    selectedShippingNew: PropTypes.object,
    setSelectedShippingNew: PropTypes.func, 
    selectedState: PropTypes.object,
    setSelectedState: PropTypes.func,
    guideNumber: PropTypes.string,
    setGuideNumber: PropTypes.func,
    t: PropTypes.func, 
    globalLoading: PropTypes.bool,
    handleConsultShippingNews: PropTypes.func,
    cleanFilters: PropTypes.func,
    cleanFiltersAndConsultOrders: PropTypes.func,
}
export default ShippingNewsFilters;
