import PropTypes from "prop-types";
import styles from "./DetailCoWorkerForm.module.css";
import { useEffect, useState } from "react";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import DeleteButton from "../DeleteButton/DeleteButton";
import MainButton from "../MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { generateRandomId } from "../../utils/generateRandomId.JS";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import useAuth from "../../hooks/useAuth";
import { userFieldsValidation } from "../../pages/Signup/scripts/userFieldsValidation";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch";
import { doc, onSnapshot } from "firebase/firestore";
import CustomTable from "../CustomTable/CustomTable";
import { db } from "../../firebase/firebase";
import { useTranslation } from "react-i18next";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import CustomModal from "../CustomModal/CustomModal";
import toastFunction from "../../utils/toastFunction";
import { defProvinces } from "../../utils/defProvinces";
import useMatchProvinceAndCity from "../../hooks/useMatchProvinceAndCity";

const DetailCoWorkerForm = ({
    onClose,
    selectedCoWorkerUser,
    userData,
    labelDelete,
    labelSuccess,
    toast
}) => {
  const hasEdit = !!Object.keys(selectedCoWorkerUser).length;
  // Importación de hooks y utilidades necesarias
const { t } = useTranslation("Users"); // Hook de traducción
const isInsider = userData?.user_custom_claims?.type === "insider" || userData?.user_custom_claims?.type === "master"  ? true : false;
// const isMaster = userData?.user_custom_claims?.type === "master"  ? true : false;

const {setCustomClaims} = useAuth();
const { updateDocument, deleteDocument } = useFirebaseCRUD(); // Operaciones CRUD con Firebase
const { globalLoading, setGlobalLoading } = useLoaderContext(); // Control de estado de carga global
const { countries, coworkerStates } = useGlobalContext(); // Estados y países globales

// Estado local utilizando el hook useState
const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);

// Uso del hook de autenticación
const { createCoWorkerUser, createInsiderUser } = useAuth();

// Estados locales para la información del coworker
const [email, setEmail] = useState(selectedCoWorkerUser?.email || "");
const [password, setPassoword] = useState("");
const [confirmPassword, setConfirmPassword] = useState("");
const [names, setNames] = useState(selectedCoWorkerUser?.first_names || "");
const [surnames, setSurNames] = useState( selectedCoWorkerUser?.last_names || "");
const [country, setCountry] = useState(selectedCoWorkerUser?.country || "");

const [province, setProvince] = useState(selectedCoWorkerUser?.province || "");
const [city, setCity] = useState(selectedCoWorkerUser?.city || "");

const [cellphone, setCellphone] = useState( selectedCoWorkerUser?.main_cellphone || "");
const [customNameRole, setCustomNameRole] = useState( selectedCoWorkerUser?.role || "");
const [state, setState] = useState(selectedCoWorkerUser?.state || "");
const [visibleTermsAndConditions, setVisibleTermsAndConditions] = useState(false);


const { zones, cities  } = useMatchProvinceAndCity(province);
const [cityOptions, setCityOptions] = useState(cities);
const [ readyToRender, setReadyToRender ] = useState(false)


// Creación de un timestamp para la fecha actual
const timestamp = new Date();

// Estado local para la aceptación de términos
const [acceptTerms, setAcceptTerms] = useState(true);

// Estado local para los permisos por defecto del coworker
const [defaultPermissions, setDefaultPermissions] = useState([]);
// Objeto que contiene los valores del coworker a ser manipulados
const coworkerValues = {
  ...selectedCoWorkerUser,
  uid: selectedCoWorkerUser?.uid || generateRandomId(),
  email: email,
  password: password,
  first_names: names,
  last_names: surnames,
  user_full_name: `${names} ${surnames}`,
  country: country,
  country_code: country.code,
  city: city,
  province: province,
  state: state,
  main_cellphone: cellphone,
  full_number: country.code + cellphone,
  role: customNameRole,
  claimsArray: defaultPermissions,

  // user_custom_claims: {type: isMaster ? "insider" : "seller"},
  // type: isMaster ? "insider" : "seller"
};

  const PermissionsTableColumns = [
    { field: "label", header: t("table-headers.permission") },
    {
      field: "value",
      header: t("table-headers.state"),
      filterable: false,
      sortable: false,
      // Componente de interruptor de entrada para los estados
      body: (rowData) => (
        <CustomInputSwitch
          checked={rowData.value}
          onChange={() => handlePermissionChange(rowData)}
          disabled={globalLoading}
        />
      ),
    },
  ];
  // Función para manejar la creación o actualización de un usuario coworker
  const handleCRUDCoworkerUser = async (e) => {
    e.preventDefault();
    setGlobalLoading(true);
    let result;
    if (!hasEdit) {
      // Validar campos del usuario antes de la creación
      const fieldsOk = userFieldsValidation(
        toast,
        password,
        confirmPassword,
        country,
        acceptTerms
      );

      if (fieldsOk) {
        // Crear un nuevo usuario coworker
        if(userData.user_custom_claims.is_master) {
          result = await createInsiderUser({
            ...coworkerValues,
            type: "insider"
          });
        }else {
          result = await createCoWorkerUser({
            ...coworkerValues,
            type: "seller"
          });
        }
        // console.log("result create", result);
        if (result.data.success) {
          toastFunction(toast, "success", t("toast-summary.createUser"),  "", 3000);
          onClose(false);

        } else if (result.data.error) {
          toastFunction(toast, "error", t("toast-summary.createrrorCreateUsereUser"),  result.data.error.code, 3000);
          setGlobalLoading(false);
        }
      }
    } else {
      // Actualizar usuario coworker existente
      try {
        // // console.log("antes de actualizar", coworkerValues);
        if(coworkerValues.user_custom_claims.type === "insider") {
          // // console.log("antes de actualizar el insider", coworkerValues)
          result =  await updateDocument("insider_users", coworkerValues.uid, {
            ...coworkerValues,
            updated_at: timestamp,
          });
           await setCustomClaims(coworkerValues.uid, coworkerValues.claimsArray)
          // // console.log("resultadoclaims", resCustomClaim)
        }else {
          // // console.log("antes de actualizar el seller",coworkerValues)
          result = await updateDocument("seller_users", coworkerValues.uid, {
            ...coworkerValues,
            updated_at: timestamp,
          });
          await setCustomClaims(coworkerValues.uid, coworkerValues.claimsArray)
          // // console.log("resultadoclaims", resCustomClaim)
        }
        // console.log("result update", result);

        if (toast) {
          toastFunction(toast, "success", t("toast-summary.updateUser"),  "", 3000);
          onClose(false);
        }
      } catch (error) {
        if (toast) {
          toastFunction(toast, "error", t("toast-summary.errorUpdateUser"),  error, 3000);
          setGlobalLoading(false);
        }
      }
    }
    setGlobalLoading(false);
  };
  // Función para manejar la eliminación de un usuario coworker
  const handleDeleteCoworkerUser = async () => {
    let result;
    setGlobalLoading(true);
    // Eliminar el usuario coworker
    if(isInsider) {
      if(coworkerValues.user_custom_claims.type === "seller") {
        // console.log("borrare, a un seller")
        result = await deleteDocument("seller_users", coworkerValues.uid);

      }else if(coworkerValues.user_custom_claims.type === "insider") {
        // console.log("borrare, a un insider")
        result = await deleteDocument("insider_users", coworkerValues.uid);
      }
    }else if (!isInsider){
      // console.log("borrare, a un seller")
      result = await deleteDocument("seller_users", coworkerValues.uid);
    }
    // console.log("result", result);
    if (result) {
      toastFunction(toast, "success", t("toast-summary.deleteUser"),  "", 3000);
      onClose(false);
    } else {
      toastFunction(toast, "error", t("toast-summary.errorDeleteUser"),  "", 3000);
    }
    setGlobalLoading(false);
  };
  // Función para manejar el cambio de permisos en un usuario coworker
  const handlePermissionChange = (rowData) => {
    // Actualizar los permisos predeterminados
    const updatedPermissions = defaultPermissions.map((permission) => {
      if (permission.claim === rowData.claim) {
        return {
          ...permission,
          value: !permission.value,
        };
      }
      return permission;
    });
    setDefaultPermissions(updatedPermissions);
  };


  // Efecto que funciona como delay para que cuando el select este renderizaod ya contenga los valores esperados
  useEffect(() => {
    setCityOptions(cities)
    if(cities.length > 0) {
      setReadyToRender(true)
    }
  }, [cities])

''
  useEffect(() => {
    const defaultPermissionsConfig = doc(db, "config", "default_permissions");
    const userDocUnsubscribe = onSnapshot(
      defaultPermissionsConfig,
      (docSnapshot) => {
        // Verificar si el documento existe
        if (docSnapshot.exists()) {
          // Obtener el arreglo de permisos predeterminados del documento
          let claimsArrayDefault = docSnapshot.data();
          claimsArrayDefault = claimsArrayDefault.claims;
          // Verificar si hay ediciones y si el usuario tiene un arreglo de permisos personalizado
          if (hasEdit && selectedCoWorkerUser.claimsArray) {
            // Establecer los permisos personalizados del usuario seleccionado
            setDefaultPermissions(selectedCoWorkerUser.claimsArray);
          } else {
            // Establecer los permisos predeterminados si no hay ediciones o no hay permisos personalizados

            setDefaultPermissions(claimsArrayDefault);
          }
        }
      }
    );
    // Devolver una función de limpieza para desconectar el listener cuando el componente se desmonta
    return () => {
      userDocUnsubscribe();
    };
  }, [selectedCoWorkerUser]);

  
  return (
   [ readyToRender && ( <form onSubmit={handleCRUDCoworkerUser} className={styles.containerInputs}>
      <CustomInputText
        floatLabel={true}
        type={"email"}
        value={email}
        placeholder={t("input-placeholders.email")}
        onChange={(e) => setEmail(e.target.value)}
        disabled={hasEdit || globalLoading}
      />
      {!hasEdit && (
        <>
          <CustomInputText
            floatLabel={true}
            type={"password"}
            value={password}
            placeholder={t("input-placeholders.password")}
            onChange={(e) => setPassoword(e.target.value)}
            disabled={hasEdit || globalLoading}
          />
          <CustomInputText
            floatLabel={true}
            type={"password"}
            value={confirmPassword}
            placeholder={t("input-placeholders.confirmPassword")}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={hasEdit || globalLoading}
          />
        </>
      )}
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={names}
        placeholder={t("input-placeholders.firstNames")}
        onChange={(e) => setNames(e.target.value)}
        disabled={globalLoading}
      />
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={surnames}
        placeholder={t("input-placeholders.lastNames")}
        onChange={(e) => setSurNames(e.target.value)}
        disabled={globalLoading}
      />
      <CustomSelect
        floatLabel={true}
        optionLabel={"name"}
        options={countries}
        valueTemplate={""}
        itemTemplate={""}
        value={country}
        placeholder={t("input-placeholders.country")}
        onChange={(e) => setCountry(e.target.value)}
        required={true}
        disabled={globalLoading}

      />
      <CustomSelect
            floatLabel={true}
            optionLabel={"province_name"}
            options={defProvinces(zones)}
            valueTemplate={""}
            itemTemplate={""}
            value={province}
            placeholder={t("input-placeholders.province")}
            onChange={(e) => {
              setProvince(e.target.value)
            }}
            disabled={globalLoading }

          />
            <CustomSelect
              floatLabel={true}
              optionLabel={"city_name"}
              options={cityOptions}
              valueTemplate={""}
              itemTemplate={""}
              value={city}
              placeholder={t("input-placeholders.city")}
              onChange={(e)=> setCity(e.target.value)}
              disabled={globalLoading  || !province }

            />
      <CustomInputText
        floatLabel={true}
        type={"number"}
        value={cellphone}
        placeholder={t("input-placeholders.cellphone")}
        onChange={(e) => setCellphone(e.target.value)}
        disabled={hasEdit || globalLoading}
        required={true} 

      />
      <CustomSelect
        floatLabel={true}
        optionLabel={"name"}
        options={coworkerStates}
        valueTemplate={""}
        itemTemplate={""}
        value={state}
        placeholder={t("input-placeholders.state")}
        onChange={(e) => setState(e.target.value)}
        required={true}
        disabled={globalLoading}
      />
      <span style={{ marginBottom: "20px" }}>
        <CustomInputText
          floatLabel={true}
          type={"text"}
          value={customNameRole}
          placeholder={t("input-placeholders.roleCustomName")}
          onChange={(e) => setCustomNameRole(e.target.value)}
          required={true}
          disabled={globalLoading}

        />
      </span>
      {defaultPermissions && (
        <CustomTable
          className={"custom-table"}
          columns={PermissionsTableColumns}
          data={defaultPermissions}
          action={() => {}}
        />
      )}
      {!hasEdit && (
        <div className={styles.inputSwitch}>
          <CustomInputSwitch
            checked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
            disabled={globalLoading}
          />
          <label
            className="clickable"
            onClick={() => setVisibleTermsAndConditions(true)}
            style={{ color: "var(--font-body-color)" }}
          >
            Aceptar términos y condiciones
          </label>
        </div>
      )}
       
      <div className={styles.containerBtns}>
        {hasEdit && (
          <DeleteButton
            type={"button"}
            label={labelDelete}
            loading={globalLoading}
            onClick={() => setVisibleConfirmDialog(true)}
            disabled={globalLoading}
          />
        )}
        <MainButton
          type={"onSubmit"}
          label={hasEdit ? t("updateUser") : labelSuccess}
          loading={globalLoading}
          disabled={globalLoading}
          autoFocus
        />
      </div>
      <div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteUser.header")}
          message={t("confirmDialog-deleteUser.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteCoworkerUser}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteUser.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteUser.rejectLabel")}
        />
        <CustomModal
          visible={visibleTermsAndConditions}
          setVisible={setVisibleTermsAndConditions}
          content={<p> Terminos y condiciones</p>}
          onClose={() => setVisibleTermsAndConditions(false)}
        />
      </div>
    </form>)]
  );
};

DetailCoWorkerForm.propTypes = {
  selectedCoWorkerUser: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  index: PropTypes.number,
  toast: PropTypes.object,
};

export default DetailCoWorkerForm;
