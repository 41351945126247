import { TieredMenu } from "primereact/tieredmenu";
import { BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { useTranslation } from "react-i18next";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { useEffect, useRef, useState } from "react";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import SecondaryButton from "../../Components/SecondaryButton/SecondaryButton";
import { Toast } from "primereact/toast";
import CustomModal from "../../Components/CustomModal/CustomModal";
import CustomerTagForm from "../../Components/CustomerTagForm/CustomerTagForm";
import CustomerForm from "../../Components/CustomerForm/CustomerForm";

import AuxStatusBadget from "../../Components/AuxStatusBadget/AuxStatusBadget";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import { Button } from "primereact/button";
import MainButton from "../../Components/MainButton/MainButton";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toastFunction from "../../utils/toastFunction";

const MyCustomers = () => {
  const { t } = useTranslation("MyCustomers");
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { userData, userSellerOwnerData} = useAuthContext();
  const isCoworker = !userData.isAdmin  && userData.related_seller_id && userData.is_coworker
  const [sellerOwnerData, setSellerOwnerData] = useState(null);

  const { toast } = useShopifyRequest();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerRowData, setSelectedCustomerRowData] = useState({});
  const [sellerTags, setSellerTags] = useState([]);
  const { removeFromArray } = useFirebaseCRUD();
  const [rowEditStates, setRowEditStates] = useState({});
  const [currentlyEditingRowId, setCurrentlyEditingRowId] = useState(null);
  const isInsider = userData?.user_custom_claims?.type === "insider" || userData?.user_custom_claims?.type === "master"  ? true : false;

  const [visibleTagForm, setVisibleTagForm] = useState(false);
  const [visibleCustomerForm, setVisibleCustomerForm] = useState(false);

  const opPhones = useRef(null);
  const opEmails = useRef(null);

  const menu = useRef(null);
  const items = [
    {
      label: t("tieredMenu-label.createTag"),
      icon: "pi pi-fw pi-file",
      command: () => {
        handleGetItem();
      },
    },
    // {
    //   label: "Foo",
    //   icon: "pi pi-fw pi-file",
    // },
  ];

  // Función para manejar el clic en el botón de "Agregar Etiqueta"
  const handleGetItem = () => {
    setVisibleTagForm(true);
  };

  // Función para alternar el modo de edición de una fila en la tabla de clientes
  const toggleEditCustomer = (rowData) => {
    setCurrentlyEditingRowId(rowData.id);
    setVisibleCustomerForm(true);
    setSelectedCustomerRowData(rowData);
    
    // Desactiva la fila actualmente en modo de edición (si la hay)
    if (currentlyEditingRowId !== null) {
      setRowEditStates((prevRowEditStates) => ({
        ...prevRowEditStates,
        [currentlyEditingRowId]: false,
      }));
    }
    // Activa la nueva fila en modo de edición
    setRowEditStates((prevRowEditStates) => ({
      ...prevRowEditStates,
      [rowData.id]: false,
    }));
 
  };
  const CustomerTableColumns = [
    {
      header: t("table-headers.edit"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <>
          {!rowEditStates[rowData.id] && ( // Verifica si la fila está en modo de edición
            <Button
              icon="pi pi-pencil"
              onClick={() => toggleEditCustomer(rowData)} // Pasa el ID de la fila a la función toggleEditCustomer
              className="p-button-rounded p-button-outlined"
            />
          )}
        </>
      ),
    },
    {
      header: t("table-headers.customer"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <>
          {rowData.first_names} {rowData.last_names}
        </>
      ),
    },
    {
      field: "city",
      header: t("table-headers.city"),
      filterable: false,
      sortable: false,
      body: (rowData) => {
        const shippingCity = rowData.shipping_address?.length > 0 ? rowData?.shipping_address[0]?.city?.city_name : ""
        return(
      <>{shippingCity}</>)},
    },
    {
      field: "phones",
      header: t("table-headers.phones"),
      filterable: false,
      sortable: false,
      body: (rowData) => {
        const lastPhone = rowData.phones[rowData.phones.length - 1];
        return (
          <div className="grid gap-2">
            <span className="flex justify-center">
              {lastPhone}
            </span>
            <span className="">
              <Button
                type="button"
                icon="pi pi-search"
                label={rowData.phones.length}
                onClick={(e) => openPhonesOverlayPanel(rowData, e)}
              />
            </span>
          </div>
        );
      },
    },
    {
      field: "emails",
      header: t("table-headers.emails"),
      filterable: false,
      sortable: false,
      body: (rowData) => {
        const lastEmail = rowData.emails[rowData.emails.length - 1];
        return (
          <div className="grid gap-2">
            <span className="flex justify-center">
              {lastEmail}
            </span>
            <Button
              type="button"
              icon="pi pi-search"
              label={rowData.emails.length}
              onClick={(e) => openEmailsOverlayPanel(rowData, e)}
            />
          </div>
        );
      },
    },

    {
      header: t("table-headers.successfulOrders"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          {rowData?.succesful_orders}
        </div>
      ),
    },
    {
      header: t("table-headers.failedOrders"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          {rowData?.failed_orders}
        </div>
      ),
    },
    {
      header: t("table-headers.firstPurchase"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          <span>
            {rowData?.first_succesful_order?.toDate()?.toLocaleString()}
          </span>
        </div>
      ),
    },
    {
      header: t("table-headers.lastPurchase"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          <span>
            {rowData?.last_succesful_order?.toDate()?.toLocaleString()}
          </span>
        </div>
      ),
    },
    {
      header: t("table-headers.CLTV"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          {formatCurrency(rowData?.cltv)}
        </div>
      ),
    },
    {
      header: t("table-headers.tags"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          <AuxStatusBadget
            statusLabel={rowData?.tags?.map((tag) => (
              <div
                key={tag?.id}
                style={{
                  backgroundColor: tag.color,
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  margin: "4px 0",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>{tag?.label}</span>{" "}
                <button
                  onClick={() => handleDeleteTag(tag, rowData)}
                  style={{ marginLeft: "10px" }}
                >
                  x
                </button>
              </div>
            ))}
          />
        </div>
      ),
    },
    {
      header: t("table-headers.options"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          <div className="card flex justify-content-center">
            <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
            <SecondaryButton
              loading={globalLoading}
              label={t("options")}
              onClick={(e) => optionMenu(e, rowData)}
            />
          </div>
        </div>
      ),
    },
  ];
  // Abre el panel de superposición de telefonos con los datos de la fila seleccionada
  const openPhonesOverlayPanel = (rowData, e) => {
    if (opEmails.current) {
      opEmails.current.hide(); // Cierra el OverlayPanel anterior si está abierto
    }
    setSelectedCustomerRowData(rowData);
    opPhones.current.toggle(e);
  };

  // Abre el panel de superposición de emails con los datos de la fila seleccionada
  const openEmailsOverlayPanel = (rowData, e) => {
    if (opPhones.current) {
      opPhones.current.hide(); // Cierra el OverlayPanel anterior si está abierto
    }
    setSelectedCustomerRowData(rowData);
    opEmails.current.toggle(e);
  };

  // Función para manejar la eliminación de una etiqueta de cliente
  const handleDeleteTag = async (tag, rowData) => {
    // Elimina la etiqueta del array de etiquetas del cliente en la base de datos
    const resultRemoveCustomerTag = await removeFromArray(
      "customers",
      rowData.id,
      "tags",
      tag
    );
    // Muestra una notificación de éxito si la etiqueta se eliminó correctamente
    if (resultRemoveCustomerTag) {
      toast.current.show({
        severity: "info",
        summary: t("toast-summary.deleteTagSuccess"),
        detail: tag.label,
        life: 4000,
      });
    }
  };

  // Función para manejar el menú de opciones para cada cliente en la tabla
  const optionMenu = (e, rowData) => {
    // Abre el menú de opciones y establece la fila de cliente seleccionada
    menu.current.toggle(e);
    setSelectedCustomerRowData(rowData);
  };

  // Función para dar formato a un valor como moneda
  const formatCurrency = (value) => {
    return value?.toLocaleString("es-CO", {
      style: "currency",
      currency: "COP",
    });
  };

  // Retorna un objeto con un conjunto único de IDs de tiendas de un array de vendedores filtrado
  const amountStoresIds = (filteredSellerStores) => {
    if (!filteredSellerStores) return [];
    const storesIds = new Set();
    filteredSellerStores.forEach((store) => {
      if (store.id) {
        storesIds.add(store.id);
      }
    });
    return { storesIds: Array.from(storesIds) };
  };

 // Función para obtener clientes por IDs de tiendas con ordenamiento por fecha de creación si el usuario es de un tipo diferente de INSIDER
  const getCustomerByIdsDateDefault = async (arrayStoresIds) => {
    try {
      // Referencia a la colección "customers" en la base de datos
      const customersCol = collection(db, "customers");
      // Consulta para obtener clientes filtrados por IDs de tiendas, ordenados por fecha de creación
      const q = query(
        customersCol,
        where(
          "related_stores_ids",
          "array-contains-any",
          arrayStoresIds.storesIds
        ),
        orderBy("created_at", "asc")
      );

      // Establecer un "escucha" para la consulta, actualizando el estado cuando cambian los datos
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const shoppexCustomers = [];
        // Iterar sobre los documentos devueltos por la consulta
        snapshot.forEach((doc) => {
          const customer = doc.data();
          shoppexCustomers.push(customer);
        });

        // Actualizar el estado de los clientes (si es necesario)
        setCustomers(shoppexCustomers);
      });

      return unsubscribe; // Devuelve la función de "desuscripción" para detener el escucha cuando sea necesario
    } catch (error) {
      // Manejo de errores: muestra un mensaje de error y retorna una función vacía
      toast.current.show({
        severity: "error",
        summary: t("toast-summary.consultError"),
        life: 4000,
      });
      return () => {};
    }
  };
   // Función para obtener todos los clientes de la db si es el usuario es INSIDER o MASTER
  const getAllCustomers = async () => {
  try {
    // Referencia a la colección "customers" en la base de datos
    const customersCol = collection(db, "customers");
    // Consulta para obtener clientes filtrados por IDs de tiendas, ordenados por fecha de creación
    const q = query(customersCol, orderBy("created_at", "asc"));

    // Establecer un "escucha" para la consulta, actualizando el estado cuando cambian los datos
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const shoppexCustomers = [];
      // Iterar sobre los documentos devueltos por la consulta
      snapshot.forEach((doc) => {
        const customer = doc.data();
        shoppexCustomers.push(customer);
      });

      // Actualizar el estado de los clientes (si es necesario)
      setCustomers(shoppexCustomers);
    });

    return unsubscribe; // Devuelve la función de "desuscripción" para detener el escucha cuando sea necesario
  } catch (error) {
    // Manejo de errores: muestra un mensaje de error y retorna una función vacía
    toast.current.show({
      severity: "error",
      summary: t("toast-summary.consultError"),
      life: 4000,
    });
    return () => {};
  }
  };

  // Cierra el modal del formulario de etiquetas
  const closeTagModal = () => {
    setVisibleTagForm(false);
  };

  // Cierra el modal del formulario de clientes
  const closeCustomerModal = () => {
    setVisibleCustomerForm(false);
  };

  // Abre el modal para crear un nuevo cliente
  const openCreateCustomerModal = () => {
    // Limpia los datos de la fila seleccionada y muestra el formulario de clientes
    setSelectedCustomerRowData({});
    setVisibleCustomerForm(true);
  };


  useEffect(() => {
    // Variable para almacenar la función de desuscripción de la consulta a la base de datos
    let unsubscribeOrders = null;
    let userStores = null
    let userTags = null
    if(isCoworker && userSellerOwnerData) {
      userStores = userSellerOwnerData.seller_stores;
      userTags = userSellerOwnerData.customers_tags;
      setSellerOwnerData(userSellerOwnerData);
    } else {
      userStores = userData.seller_stores;
      userTags = userData.customers_tags;
      setSellerOwnerData(userData);
    }
    setSellerTags(userTags || []);
    
    // Función para realizar la carga inicial de datos
    const fetchInitialData = async () => {
      setGlobalLoading(true);
      try {
        // Obtener los pedidos por fecha con onSnapshot
        if (isInsider) {
          console.log("es insider", isInsider)
          unsubscribeOrders = await getAllCustomers();
        }else {
          const arrayStoresIds = amountStoresIds(userStores );
          unsubscribeOrders = await getCustomerByIdsDateDefault(arrayStoresIds);
        }
      } catch (error) {
        toastFunction(toast, "error", t("toast-summary.getCostumersError"), "", 4000);
      } finally {
        setGlobalLoading(false);
      }
    };
    // Verificar si userData está disponible y ejecutar la carga inicial de datos
    if (userData) {
      fetchInitialData();
    }
    // Efecto de limpieza para desuscribirse de la consulta al desmontar el componente
    return () => {
      if (unsubscribeOrders) {
        unsubscribeOrders(); // Desuscribirse de la consulta al desmontar el componente
      }
    };
  }, [userData]);

  return (
    <>
      <div style={{ marginBottom: "40px" }}>
        <GoBackButton icon={BiChevronLeft} text={ isInsider ? t("labelGoBackBtnCustomers") :t("labelGoBackBtnMyCustomers")  } />
      </div>
      <div style={{ marginBottom: "40px" }}>
        <MainButton
          label={t("createCustomer")}
          onClick={openCreateCustomerModal}
        />
      </div>
      <main className="mb-20">
        <CustomTable
          className="custom-table"
          columns={CustomerTableColumns}
          data={customers}
          action={() => {}}
        />
      </main>
      <Toast ref={toast} />
      <div>
        <CustomModal
          visible={visibleTagForm}
          setVisible={setVisibleTagForm}
          createMode={t("createTag")}
          content={
            <CustomerTagForm
              selectedCustomerRowData={selectedCustomerRowData}
              sellerTags={sellerTags}
              userData={sellerOwnerData}
              closeTagModal={closeTagModal}
              toast={toast}
              isInsider={isInsider}
            />
          }
          onClose={closeTagModal}
        />
      </div>
      <div>
        <CustomModal
          visible={visibleCustomerForm}
          setVisible={setVisibleCustomerForm}
          createMode={t("createCustomer")}
          content={
            <CustomerForm
              selectedCustomerRowData={selectedCustomerRowData}
              sellerTags={sellerTags}
              userData={sellerOwnerData}
              closeCustomerModal={closeCustomerModal}
              toast={toast}
              isInsider={isInsider}
            />
          }
          onClose={closeCustomerModal}
        />
      </div>
      <div >
        <OverlayPanel
          ref={opPhones}
          showCloseIcon
          closeOnEscape
          dismissable={false}
        >
          <DataTable
            value={selectedCustomerRowData?.phones?.map((phone, index) => ({
              name: phone,
              id: index,
            }))}
            selectionMode="single"
            paginator
            rows={5}
          >
            <Column
              field="name"
              header={t("dataTable-headers.phones")}
              style={{ minWidth: "12rem" }}
            />
          </DataTable>
        </OverlayPanel>
      </div>
      <div >
        <OverlayPanel
          ref={opEmails}
          showCloseIcon
          closeOnEscape
          dismissable={false}
        >
          <DataTable
            value={selectedCustomerRowData?.emails?.map((email, index) => ({
              name: email,
              id: index,
            }))}
            selectionMode="single"
            paginator
            rows={5}
          >
            <Column
              field="name"
              header={t("dataTable-headers.emails")}
              style={{ minWidth: "12rem" }}
            />
          </DataTable>
        </OverlayPanel>
      </div>
    </>
  );
};
export default MyCustomers;
