let fbq;

function loadFacebookPixelScript() {
  (function(f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function() {
      n.callMethod
        ? n.callMethod.apply(n, arguments)
        : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
}

async function initializeFacebookPixel(ID_DE_PIXEL) {
  try {
    if (ID_DE_PIXEL !== undefined && ID_DE_PIXEL !== null && ID_DE_PIXEL !== '') {
      loadFacebookPixelScript();
      fbq = window.fbq;
      fbq('init', ID_DE_PIXEL);
    } else {
      console.log('No such document!');
    }
  } catch (error) {
    console.log('Error setting up fb pixel');
  }
}

export { initializeFacebookPixel, fbq };

