import PropTypes from "prop-types";
import {  useEffect, useState } from "react";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import { Divider } from "primereact/divider";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { generateRandomId } from "../../utils/generateRandomId.js";
import MainButton from "../MainButton/MainButton.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect.jsx";
import BannerCard from "../BannerCard/BannerCard.jsx";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import DeleteButton from "../DeleteButton/DeleteButton.jsx";
import { COVER_BANNER, COVER_COLLECTION, DESKTOP, MOBILE } from "../router/paths.js";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber.jsx";

const DetailGroupForm = ({selectedGroup, categoriesData,  toast, t, storeTags, shoppexStoreId, onClose, shoppexStoreValues, setShoppexStoreValues}) => {
    const groupType = "group"
    let hasEdit;
    if (selectedGroup) {
        hasEdit = !!Object.keys(selectedGroup)?.length;
    }
    const { deleteFolderStorageAndContents, removeFromArray } = useFirebaseCRUD();
    const { globalLoading } = useLoaderContext();
    const { coverTypes, collectionTypes, defaultCoverImage } = useGlobalContext();
    
    const [visibleConfirmDeleteGroupModal, setVisibleConfirmDeleteGroupModal] = useState(false);

    const groupInitialValues = {
        id: selectedGroup?.id || generateRandomId(), // Identificador del grupo
        position: selectedGroup?.position || 0, // Posicion del grupo
        collection_id: selectedGroup?.collection_id || generateRandomId(), //Id de la colección
        // ** group_img_src? o img_src para mantener la estrucutra de datos
        // group_img_src: selectedGroup?.group_img_src || defaultCoverImage,  // Fuente de la imagen del grupo 
        media_type: selectedGroup?.media_type || null,
        media_src: selectedGroup?.media_src || defaultCoverImage,  // Fuente de la imagen del grupo 
        media_path: selectedGroup?.media_path || null, // URL del contenido multimedia
        group_title: selectedGroup?.group_title || "", // Titulo del grupo 
        is_vertical_video: selectedGroup?.is_vertical_video || false, // Indicador de si el grupo abrira el video en vertical
        vertical_video_product_id: selectedGroup?.vertical_video_product_id || [], // Array de Ids de los videos de los prouctos a mostrar al abrir. grupoel 
        collection_type:  selectedGroup?.collection_type || "", // En caso que “is_vertical_video” sea false. Tipo de coleccion: “byCategorie”, “byTag”
        category_ids:  selectedGroup?.category_ids || [], // Ids de las categorias que conforman la coleccion
        tag_ids:  selectedGroup?.tag_ids || [], // Ids de los tags que conforman la coleccion 
        
        collection_banner_media: selectedGroup?.collection_banner_media || [{
            id: generateRandomId(),
            media_type: null,
            media_src: defaultCoverImage , // URL del contenido multimedia
            media_path: null,
            media_mobile_src: defaultCoverImage, // URL del contenido multimedia mobile
            media_mobile_path: null,
            alt: "Imagen predeterminada",
           }],
    }
    const [groupValues, setGroupValues] = useState(groupInitialValues)
    const [collectionTypesOptions, setCollectionTypesOptions] = useState();

    const [selectedCollectionTypesValues, setSelectedCollectionTypesValues] = useState();

    const [ collectionType, setCollectionType ] = useState([]);
    
    const [ coverCollectionType, setCoverCollectionType ] = useState([]);
    const [ coverBannerType, setCoverBannerType ] = useState(null);



    const handleInputChange = (fieldName, e) => {
        let value = e;
        const collectionMediaBannerObject = groupValues.collection_banner_media[0];
        if (fieldName === "collection_type") {
          console.log(value)
          switch (value?.code) {  // SI el codigo de el tipo de collecion es 0 es por "byCategorie"
            case 0:
              setGroupValues((prevData) => ({
                ...prevData,
                [fieldName]: value?.string,
                category_ids: [],
              }));
              setSelectedCollectionTypesValues([]);
              setCollectionTypesOptions(categoriesData);
              break;
            case 1:  // SI el codigo de el tipo de collecion es 1 es por "byTag"
              setGroupValues((prevData) => ({
                ...prevData,
                [fieldName]: value?.string,
                tag_ids: [],

              }));
              setSelectedCollectionTypesValues([]);

              setCollectionTypesOptions(storeTags);
              break;
            default:
              setGroupValues((prevData) => ({
                ...prevData,
                [fieldName]: value?.string,
              }));
              break;
          }
            setCollectionType(value);
        } else if ( fieldName === "media_type") {
          setGroupValues((prevData) => ({
            ...prevData,
            [fieldName]:  value?.string, // Tipo del contenido multimedia del slide "img" || "video"
          }));
          setCoverBannerType(value);
        } else if ( fieldName === "collection_banner_media.media_type") {
          setGroupValues((prevData) => ({
            ...prevData,
            collection_banner_media:  [{
              ...collectionMediaBannerObject,
              media_type: value?.string, // Tipo del contenido multimedia del slide "img" || "video"
            }]
          }));
          setCoverCollectionType(value);
        } else if ( fieldName === "collection_banner_media.alt") {
          setGroupValues((prevData) => ({
            ...prevData,
            collection_banner_media:  [{
              ...collectionMediaBannerObject,
              alt: value, // Tipo del contenido multimedia del slide "img" || "video"
            }]
          }));
        } else {
          setGroupValues((prevData) => ({
            ...prevData,
            [fieldName]: value,
          }));
        }
    }; 
    const updateCollectionTypesField = (fieldName, newValue) => {
        if (fieldName === "tag_ids") {
          setGroupValues((prevValues) => ({
            ...prevValues,
            [fieldName]: newValue?.map((value) => value),
          }));
        } else if (fieldName === "category_ids") {
          setGroupValues((prevValues) => ({
            ...prevValues,
            [fieldName]: newValue?.map((value) => value?.id),
          }));
        }
        
    };
    const handleDeleteCoverColection = (mediaType) => {
        const collectionMediaBannerObject = groupValues.collection_banner_media[0];
        // Mostrar imagen predeterminada si mediaSrc está vacío
       switch (mediaType) {
         case DESKTOP:
          setGroupValues((prevData) => ({
            ...prevData,
            collection_banner_media:  [{
              ...collectionMediaBannerObject,
              media_src:  defaultCoverImage,
            }]
          }));
          break;
         case MOBILE:
          setGroupValues((prevData) => ({
            ...prevData,
            collection_banner_media:  [{
              ...collectionMediaBannerObject,
              media_mobile_src: defaultCoverImage,
            }]
          }));
          break;
  
         default:
           break;
       }
       
    };
    const handleDeleteCoverBanner = (mediaType) => {
        // Mostrar imagen predeterminada si mediaSrc está vacío
       switch (mediaType) {
         case DESKTOP:
           setGroupValues((prevData) => ({
             ...prevData,
             media_src:  defaultCoverImage,
             alt: 'Imagen predeterminada',
           }));
           break;
         case MOBILE:
           setGroupValues((prevData) => ({
             ...prevData,
             media_mobile_src: defaultCoverImage,
             alt: 'Imagen predeterminada'
           }));
           break;
 
         default:
           break;
       }
       
    };

    const saveGroupValues = async (e) => {
        e.preventDefault();
          const filteredGroups = shoppexStoreValues?.main_groups?.filter((group) => group?.id !== groupValues.id);
          setShoppexStoreValues((prevData) => {
            return { ...prevData, main_groups: [...filteredGroups, groupValues] };
          });
          onClose();
        // }
    };
    const handleDeleteGroup = async () => {
      const pathToDelete = `shoppexStores/${shoppexStoreId}/${groupType}/${groupValues.id}/`;
    
      try {
        // Eliminar la ruta del almacenamiento
        await deleteFolderStorageAndContents(pathToDelete);
        console.log('Ruta eliminada exitosamente del almacenamiento de Firebase.', groupValues);
        
        if (hasEdit)      {
          await removeFromArray("stores", shoppexStoreId, "main_groups", groupValues);          
        }
        // Si el proceso de eliminación se completa correctamente, actualizar el estado o realizar otras acciones necesarias
        const filteredGroups = shoppexStoreValues?.main_groups?.filter((group) => group?.id !== groupValues.id);
        setShoppexStoreValues((prevData) => {
          return { ...prevData, main_groups: filteredGroups};
        });
        onClose();
      } catch (error) {
        console.error('Error al eliminar la ruta del almacenamiento de Firebase:', error);
      }
    }
    console.log("groupValues", groupValues);

    useEffect(() => {
        if (selectedGroup) {
          let findedCollectionTypes = null;
          let findedCollectionOptions = null;
          const findedCollectionType = collectionTypes?.find((collectionType) => selectedGroup?.collection_type === collectionType?.string);
  
          switch (findedCollectionType?.code) {
            case 0: // SI el codigo de el tipo de collecion es 0 es por "byCategorie"
              findedCollectionTypes = selectedGroup?.category_ids?.map(categoryId =>
                categoriesData?.find(category => category.id === categoryId)
              )?.filter(category => category !== undefined);
              findedCollectionOptions = categoriesData;
              break;
            case 1:  // SI el codigo de el tipo de collecion es 1 es por "byTag"
              findedCollectionTypes = selectedGroup?.tag_ids?.map(tagId =>
                storeTags?.find(tag => tag === tagId)
              )?.filter(tagId => tagId !== undefined);
              findedCollectionOptions = storeTags;
              break;
            default:
              break;
          }
          
          const findedCoverBannerType = coverTypes?.find((bannerType) => selectedGroup?.media_type === bannerType?.string)
          const findedCoverCollectionType = coverTypes?.find((bannerType) => selectedGroup?.collection_banner_media[0]?.media_type === bannerType?.string)
          setSelectedCollectionTypesValues(findedCollectionTypes)
          setCollectionTypesOptions(findedCollectionOptions);
          setCollectionType(findedCollectionType);
          setCoverBannerType(findedCoverBannerType);
          setCoverCollectionType(findedCoverCollectionType)
        }
      }, [selectedGroup]);

    return (
        <>
        <Divider/>
          <CustomInputNumber
            floatLabel={true}
            type={"text"}
            value={groupValues.position}
            placeholder={t("input-placeholders.position")}
            onChange={(e) => handleInputChange("position", e.value)}
            disabled={globalLoading}
          />

        <CustomSelect
            floatLabel={true}
            optionLabel={"name"}
            options={collectionTypes}
            valueTemplate={""}
            itemTemplate={""}
            value={collectionType}
            placeholder={t("input-placeholders.collectionTypes")}
            onChange={(e) => handleInputChange("collection_type", e.target.value)}
            required={true}
          />
          <CustomMultiSelect
            optionLabel={collectionType?.code === 0 ? "category_label": ""}
            options={collectionTypesOptions}
            value={selectedCollectionTypesValues}
            onValue={setSelectedCollectionTypesValues}
            placeholder={collectionType?.code === 0 ? t("input-placeholders.categoryProduct") : collectionType?.code === 1  ? t("input-placeholders.tagProduct") : t("input-placeholders.tagAndCategorysProduct")}
            updateField={updateCollectionTypesField}
            fieldNameToUpdate={collectionType?.code === 0 ? "category_ids": "tag_ids"}
            disabled={globalLoading || collectionTypesOptions?.length === 0}
          />

          <Divider/>
          <h4>Cover del banner</h4>

          <CustomSelect
              floatLabel={true}
              optionLabel={"name"}
              options={coverTypes}
              valueTemplate={""}
              itemTemplate={""}
              value={coverBannerType}
              placeholder={t("input-placeholders.collectionTypes")}
              onChange={(e) => handleInputChange("media_type", e.target.value)}
              required={true}
          />

          <CustomInputText
              floatLabel={true}
              type={"text"}
              value={groupValues?.group_title}
              placeholder={t("input-placeholders.groupTitle")}
              onChange={(e) => handleInputChange("group_title", e.target.value)}
              required={true}
          />

          <div className="mt-8 mb-4">
              <BannerCard isCircleCard={true} shoppexStoreId={shoppexStoreId} coverTypeMedia={coverBannerType} coverType={COVER_BANNER} data={groupValues}  mediaType={DESKTOP} groupType={groupType} groupId={groupValues.id} onClick={handleDeleteCoverBanner} isErasable={true} toast={toast} setBannerValues={setGroupValues} />
          </div>
      
          <Divider/>
          <h4>Cover de la coleccion</h4>
          <CustomSelect
              floatLabel={true}
              optionLabel={"name"}
              options={coverTypes}
              valueTemplate={""}
              itemTemplate={""}
              value={coverCollectionType}
              placeholder={t("input-placeholders.collectionTypes")}
              onChange={(e) => handleInputChange("collection_banner_media.media_type", e.target.value)}
              required={true}
          />
          <CustomInputText
              floatLabel={true}
              type={"text"}
              value={groupValues?.collection_banner_media[0]?.alt}
              placeholder={t("input-placeholders.alt")}
              onChange={(e) => handleInputChange("collection_banner_media.alt", e.target.value)}
              required={true}
          />

        <div className="grid gap-12 mt-10 ">
          <BannerCard shoppexStoreId={shoppexStoreId} coverTypeMedia={coverCollectionType} coverType={COVER_COLLECTION} data={groupValues?.collection_banner_media[0]} mediaType={DESKTOP} groupType={groupType} groupId={groupValues.id} onClick={handleDeleteCoverColection} isErasable={true} toast={toast} setBannerValues={setGroupValues} />
          <BannerCard shoppexStoreId={shoppexStoreId} coverTypeMedia={coverCollectionType} coverType={COVER_COLLECTION} data={groupValues?.collection_banner_media[0]} mediaType={MOBILE} groupType={groupType} groupId={groupValues.id} onClick={handleDeleteCoverColection} isErasable={true} toast={toast} setBannerValues={setGroupValues} />
        </div>

        <div style={{ marginTop: "100px", display: "grid", gap: "20px"}}>
          <MainButton
            loading={globalLoading}
            label={t("save")}
            type={"button"}
            onClick={saveGroupValues}
          />
          {hasEdit && (
            <span>
              <DeleteButton
                type={"button"}
                label={t("deleteGroup")}
                loading={globalLoading}
                onClick={() => setVisibleConfirmDeleteGroupModal(true)}
                disabled={globalLoading}
              />
            </span>
          )}
        </div>
        <CustomConfirmDialog
          visible={visibleConfirmDeleteGroupModal}
          onHide={() => setVisibleConfirmDeleteGroupModal(false)}
          header={t("confirmDialog-deleteGroup.header")}
          message={t("confirmDialog-deleteGroup.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteGroup}
          reject={() => setVisibleConfirmDeleteGroupModal(false)}
          acceptLabel={t("confirmDialog-deleteGroup.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteGroup.rejectLabel")}
        />
        </>
    )
}
DetailGroupForm.propTypes = {
    selectedGroup: PropTypes.object,
    categoriesData: PropTypes.array,
    storeTags: PropTypes.array,
    updateCategoryField: PropTypes.func,
    toast: PropTypes.object,
    t: PropTypes.func,
    shoppexStoreId: PropTypes.string,
    onClose: PropTypes.func,
    shoppexStoreValues: PropTypes.object,
    setShoppexStoreValues: PropTypes.func,


}
export default DetailGroupForm;