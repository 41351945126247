import PropTypes from "prop-types";
import {  useState } from "react";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import { Divider } from "primereact/divider";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { generateRandomId } from "../../utils/generateRandomId.js";
import { ColorPicker } from 'primereact/colorpicker';
import MainButton from "../MainButton/MainButton.jsx";
import BadgetCard from "../BadgetCard/BadgetCard.jsx";
import IconProvider from "../IconProvider/IconProvider.jsx";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import DeleteButton from "../DeleteButton/DeleteButton.jsx";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber.jsx";


const DetailBadgetForm = ({selectedBadget, t, onClose, shoppexStoreValues, setShoppexStoreValues}) => {
  let hasEdit;
  if (selectedBadget) {
      hasEdit = !!Object.keys(selectedBadget)?.length;
  }
  const [visibleConfirmDeleteBadgetModal, setVisibleConfirmDeleteBadgetModal] = useState(false);
  
  // Lista de nombres de iconos
  const iconNames = [
    { name: 'RiHome2Line'},
    { name: 'RiSettings2Line'},
    { name: 'RiHeartLine' },
    { name: 'RiBookmarkLine' },
    { name: 'HiOutlineTruck' },
    { name: 'FaLaptop' },
    { name: 'AiOutlineWarning' },
    { name: 'MdPriceChange' },
    { name: 'MdOutlinePayments' },
    { name: 'GiPayMoney' },
    { name: 'MdOutlinePayment' },
    { name: 'MdOutlineProductionQuantityLimits' },
    { name: 'IoIosPricetags' }
  ];
  const { globalLoading} = useLoaderContext();
  const badgetInitialValues = {
      id: selectedBadget?.id || generateRandomId(),
      name: selectedBadget?.name || "", // Nombre del badget
      position: selectedBadget?.position || 0, // Posicion del badge
      icon: selectedBadget?.icon || "RiSettings2Line", // Icono del badge
      badge_color: selectedBadget?.badge_color || "", // Variable css del color a usar en el badge
      bold_text: selectedBadget?.bold_text || "Titulo", // Parte en negrilla del badge
      regular_text: selectedBadget?.regular_text || "Subtitulo", // Parte en regular del badge
      destine_url: selectedBadget?.destine_url ||  "Url", // URL del destino a clickear en el badge (opcional)
  }

  const [badgetValues, setBadgetValues] = useState(badgetInitialValues)
  const [color, setColor] = useState("000000");
  const [selectedIcon, setSelectedIcon] = useState(badgetInitialValues.icon);
  const handleIconSelect = (iconObject) => {
      setSelectedIcon(iconObject.name);
      setBadgetValues((prevData) => {return {...prevData, icon: iconObject.name}})
  };

  const handleInputChange = (fieldName, e) => {
      let value = e;
      if(fieldName === "badge_color") {
          setBadgetValues((prevData) => ({
            ...prevData,
            [fieldName]: value,
          }));
          setColor(value);
      } else {
        setBadgetValues((prevData) => ({
          ...prevData,
          [fieldName]: value,
        }));
      }
  };
  const saveBadgetValues = async (e) => {
        e.preventDefault();
        const filteredBadget = shoppexStoreValues?.main_badges?.filter((badge) => badge?.id !== badgetValues.id);
        setShoppexStoreValues((prevData) => {
          return { ...prevData, main_badges: [...filteredBadget, badgetValues] };
        });
        onClose();
  };
  const handleDeleteBadget = async () => {
      try {
        // Si el proceso de eliminación se completa correctamente, actualizar el estado o realizar otras acciones necesarias
        const filteredBadget = shoppexStoreValues?.main_badges?.filter((badget) => badget?.id !== badgetValues.id);
        setShoppexStoreValues((prevData) => {
          return { ...prevData, main_badges: filteredBadget};
        });
        onClose();
      } catch (error) {
        console.error('Error al eliminar la ruta del almacenamiento de Firebase:', error);
      }
    }

  return (
      <>
      <Divider/>
       <CustomInputNumber
            floatLabel={true}
            type={"text"}
            value={badgetValues.position}
            placeholder={t("input-placeholders.position")}
            onChange={(e) => handleInputChange("position", e.value)}
            disabled={globalLoading}
          />
          <CustomInputText
              floatLabel={true}
              type={"text"}
              value={badgetValues?.name}
              placeholder={t("input-placeholders.name")}
              onChange={(e) => handleInputChange("name", e.target.value)}
              required={true}
              />
          <CustomInputText
              floatLabel={true}
              type={"text"}
              value={badgetValues?.bold_text}
              placeholder={t("input-placeholders.boldText")}
              onChange={(e) => handleInputChange("bold_text", e.target.value)}
              required={true}
          />
          <CustomInputText
              floatLabel={true}
              type={"text"}
              value={badgetValues?.regular_text}
              placeholder={t("input-placeholders.regularText")}
              onChange={(e) => handleInputChange("regular_text", e.target.value)}
              required={true}
          />
          <CustomInputText
              floatLabel={true}
              type={"text"}
              value={badgetValues?.destine_url}
              placeholder={t("input-placeholders.destineUrl")}
              onChange={(e) => handleInputChange("destine_url", e.target.value)}
              required={true}
          />

          <Divider/>
          <h4 className=""> Colores e iconos : </h4>
          {/* <div className="flex gap-4 mt-12 items-center">
              <h4> Icono seleccionado : </h4>
              <IconProvider key={1} name={selectedIcon} onClick={() => handleIconSelect({name:selectedIcon})} className="w-1/10" colorIcon={color}/>
              <ColorPicker value={color} onChange={(e) => handleInputChange("badge_color", e.value)}/>
          </div> */}
          <BadgetCard
            key={1}
            data={badgetValues}
            Icon={selectedIcon}
            bold_text={badgetValues.bold_text}
            regular_text={badgetValues.regular_text}
            color={badgetValues.badge_color}
          />
          <div className="md:grid lg:grid grid-cols-2 sm:flex sm:flex-col lg:p-4 md:p-4 shadow-xl pb-8">
              <div className="flex flex-wrap gap-1 mt-12 mb-14">
                  {iconNames.map((iconObject) => (
                    <div 
                      key={iconObject?.name} 
                      onClick={() => handleIconSelect(iconObject)} 
                      className="w-1/10 border border-gray-300 p-4 cursor-pointer hover:opacity-20" 
                    >
                    <span>
                      <IconProvider name={iconObject?.name}  colorIcon={color}/>
                    </span>
                   </div>
                  ))}
              </div>
              <span className="w-full items-center flex justify-center ">
                  <ColorPicker value={color} onChange={(e) => handleInputChange("badge_color", e.value)} inline />
              </span>
          </div>
          
          <Divider/>

        <div style={{ marginTop: "100px", display: "grid", gap: "20px"}}>
          <MainButton
            loading={globalLoading}
            label={t("save")}
            type={"button"}
            onClick={saveBadgetValues}
          />
          {hasEdit && (
            <span>
              <DeleteButton
                type={"button"}
                label={t("deleteBadget")}
                loading={globalLoading}
                onClick={() => setVisibleConfirmDeleteBadgetModal(true)}
                disabled={globalLoading}
              />
            </span>
          )}
      </div>
      <CustomConfirmDialog
          visible={visibleConfirmDeleteBadgetModal}
          onHide={() => setVisibleConfirmDeleteBadgetModal(false)}
          header={t("confirmDialog-deleteProduct.header")}
          message={t("confirmDialog-deleteProduct.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteBadget}
          reject={() => setVisibleConfirmDeleteBadgetModal(false)}
          acceptLabel={t("confirmDialog-deleteProduct.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteProduct.rejectLabel")}
      />
    </>
  )
}
DetailBadgetForm.propTypes = {
    selectedBadget: PropTypes.object,
    categoriesData: PropTypes.array,
    updateCategoryField: PropTypes.func,
    toast: PropTypes.object,
    t: PropTypes.func,
    onClose: PropTypes.func,
    shoppexStoreValues: PropTypes.object,
    setShoppexStoreValues: PropTypes.func
}

export default DetailBadgetForm;