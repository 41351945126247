import  {useEffect, useState} from 'react';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { Toast } from 'primereact/toast';
import styles from './Signup.module.css';
import CustomInputSwitch from '../../Components/FormComponents/CustomInputSwitch/CustomInputSwitch';
import CustomInputText from '../../Components/FormComponents/CustomInputText/CustomInputText';
import CustomSelect from '../../Components/FormComponents/CustomSelect/CustomSelect';
import MainButton from '../../Components/MainButton/MainButton';
import ThirdButton from '../../Components/ThirdButton/ThirdButton';
import { userFieldsValidation } from './scripts/userFieldsValidation';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoaderContext } from '../../contexts/LoaderContext/LoaderContext';
import useAuth from '../../hooks/useAuth';
import ContinueWithGoogleBtn from '../../Components/ContinueWithGoogleBtn/ContinueWithGoogleBtn';
import CustomModal from "../../Components/CustomModal/CustomModal";
import OptionWithTitleAndText from '../../Components/OptionWithTitleAndText/OptionWithImgAndText.jsx';
import { defProvinces } from '../../utils/defProvinces.js';
import getZones from '../../utils/getZones.js';
import { getCitiesByProvince } from '../../utils/getCitiesByProvince.js';
import TyC from '../../Legal/TyC.jsx';

export default function Signup({recaptchaVerifier}) {
    const [searchParams] = useSearchParams();
    const referralCode = searchParams.get('referralCode');


    const { toast, type, smsCodeAsk, setSmsCodeAsk, countdown, isResendDisabled, signInWithGoogle, signInWithPhoneHook, verifySMSHook, createUser } = useAuth();
    const { t } = useTranslation('Signup');
    const navigate = useNavigate();
    const {countries} = useGlobalContext();
    const {globalLoading, setGlobalLoading} = useLoaderContext();
    const [user_img_profile, setUser_img_profile] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [names, setNames] = useState('');
    const [surnames, setSurnames] = useState('');
    const [country, setCountry] = useState('');
    const [ province, setProvince ] = useState(null);
    const [city, setCity] = useState('');
    const [cellphone, setCellphone] = useState('');
    const [smsCode, setSmsCode] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(true);
    const [signupWithEmail, setSignupWithEmail] = useState(true);
    const [signupWithPhone, setSignupWithPhone] = useState(true);
    const [visibleTermsAndConditions, setVisibleTermsAndConditions] = useState(false);
    const [ isSupplier, setIsSupplier ] = useState(false);
    const [selectedTypeSeller, setSelectedTypeSeller] = useState("online_seller");
    const [ zones, setZones] = useState([]);
    const [ cities, setCities] = useState([]);
    const [ referralCodeValue, setReferralCodeValue ] = useState(referralCode || "");
    
    useEffect(() => {
        getZones()
        const fetchInitialZones = async () => {
            setZones(await getZones());
        }
        fetchInitialZones();
        if (!province || !province.province_code) {
          // Si province es nulo o no tiene provincia definida, establecer todas las zonas como ciudades
          setCities(zones);
        } else {
          // Obtener ciudades basadas en el código de provincia  
          const city = getCitiesByProvince(province.province_code, zones);
          setCities(city);
        }
      }, [province]);


    const userData = {
        type,
        user_img_profile,
        email,
        password,
        names,
        surnames,
        user_full_name: names + " " + surnames,
        country,
        country_name: country.name?? null,
        country_code: country.code?? null,
        province,
        city,
        cellphone,
        signupWithEmail,
        signupWithPhone,
        ux_type: selectedTypeSeller,
        referral_code: referralCodeValue,
        is_supplier: isSupplier
    };


    const signUp = async (e) => {
        e.preventDefault();
        setGlobalLoading(true)
        const fieldsOk = userFieldsValidation(toast, password, confirmPassword, country, acceptTerms);
        if (fieldsOk) {
            if (signupWithPhone) {
                signUpWithPhoneNumber();
            } else {
                signUpWithEmail();
            }
        } else {
            setGlobalLoading(false);
        }
    };

    const signUpWithEmail = async () => {
        userData.is_supplier = isSupplier;
        await createUser("auth/signupWithEmail", userData);
    };

    const signUpWithPhoneNumber = async () => {
        console.log(country)
        const phoneNumberWithCountryCode = `${country.code}${cellphone}`
        await signInWithPhoneHook(phoneNumberWithCountryCode, recaptchaVerifier)
    };

    const resendSMSCode = (e) => {
        setGlobalLoading(true);
        signUpWithPhoneNumber(e);
    };

    const verifySMS = async (e) => {
        e.preventDefault();
        let res = await verifySMSHook(smsCode)
        if(res.success){
            userData.uid = res.uid;
            userData.is_supplier = isSupplier;

            await createUser("auth/signupWithPhone", userData);
        }
    };

console.log(userData);
    return (
        <div className={styles.signupContainer}>
            <h2>Crea tu cuenta</h2>
            <ContinueWithGoogleBtn icon={"pi pi-google"} onClick={()=>signInWithGoogle()} label={"CONTINUAR CON GOOGLE"} />

            {
                !smsCodeAsk?
                <>
                    <form onSubmit={signUp}>
                        <CustomInputText required={true} floatLabel={true} type="email" value={email} placeholder="Correo" onChange={(e) => setEmail(e.target.value)} />
                        <CustomInputText required={true} floatLabel={true} type="password" value={password} placeholder="Contraseña" onChange={(e) => setPassword(e.target.value)} />
                        <CustomInputText required={true} floatLabel={true} type="password" value={confirmPassword} placeholder="Confirmar contraseña" onChange={(e) => setConfirmPassword(e.target.value)} />
                        <CustomInputText required={true} floatLabel={true} type="text" value={names} placeholder="Nombres" onChange={(e) => setNames(e.target.value)} />
                        <CustomInputText required={true} floatLabel={true} type="text" value={surnames} placeholder="Apellidos" onChange={(e) => setSurnames(e.target.value)} />
                        <CustomInputText required={false} floatLabel={true} type="text" value={referralCodeValue} placeholder="Codigo de referido" onChange={(e) => setReferralCodeValue(e.target.value)} />
                        <CustomSelect    required={false} floatLabel={true} optionLabel="name" options={countries} valueTemplate="" itemTemplate="" value={country} placeholder="Pais" onChange={(e) => setCountry(e.value)} />
                        <CustomSelect
                            floatLabel={true}
                            optionLabel={"province_name"}
                            options={defProvinces(zones)}
                            valueTemplate={""}
                            itemTemplate={""}
                            value={province}
                            placeholder={t("Departamento")}
                            onChange={(e) => {
                            setProvince(e.target.value)
                            }}
                            disabled={globalLoading}

                        />
                        <CustomSelect
                            floatLabel={true}
                            optionLabel={"city_name"}
                            options={cities}
                            valueTemplate={""}
                            itemTemplate={""}
                            value={city}
                            placeholder={t("Ciudad")}
                            onChange={(e)=> setCity(e.target.value)}
                            disabled={globalLoading }

                            />
                        {/* <CustomInputText required={true} floatLabel={true} type="text" value={city} placeholder="Ciudad" onChange={(e) => setCity(e.target.value)} /> */}
                        <CustomInputText required={signupWithPhone} floatLabel={true} type="number" value={cellphone} placeholder={`${country.code??""} Celular`} onChange={(e) => setCellphone(e.target.value)} />

                        <h5 className='mt-2'>¿Con que tipo de vendedor de identificas mas?</h5>
                        <div className='gridCardContainer mt-4 mb-6 '>
                            <OptionWithTitleAndText 
                                optionData={{
                                    data: "online_seller",
                                    id: "online_seller",
                                    isSelected: selectedTypeSeller,
                                    selectFunction: setSelectedTypeSeller,
                                    title: "Online",
                                    text:"Ideal para ti si disfrutas vendiendo a través de plataformas en línea y manejas con soltura herramientas digitales."
                                }}
                            />
                            <OptionWithTitleAndText 
                                optionData={{
                                    data: "independent_seller",
                                    id: "independent_seller",
                                    isSelected: selectedTypeSeller,
                                    selectFunction: setSelectedTypeSeller,
                                    title: "Independiente",
                                    text:"Perfecto si prefieres gestionar tus ventas de manera autónoma usando tecnología de ecommerce."
                                }}
                            />
                            <OptionWithTitleAndText 
                                   optionData={{
                                    data: "catalog_seller",
                                    id: "catalog_seller",
                                    isSelected: selectedTypeSeller,
                                    selectFunction: setSelectedTypeSeller,
                                    title: "Catalogo",
                                    text:"Adecuado para quienes se especializan en ventas mediante catálogos digitales y buscan eficiencia en el manejo de inventarios y pedidos."
                                }}   
                                />
                        </div>

                        <div className={styles.inputSwitch}>
                            <CustomInputSwitch checked={isSupplier} onChange={(e)=>setIsSupplier(!isSupplier)} />
                            <label onClick={() => setIsSupplier(true)} className='clickable' style={{color: "var(--font-body-color)"}}>¿Eres proveedor?</label>
                        </div>
                        <div className={styles.inputSwitch}>
                            <CustomInputSwitch checked={acceptTerms} onChange={(e)=>setAcceptTerms(!acceptTerms)} />
                            <label onClick={() => setVisibleTermsAndConditions(true)} className='clickable' style={{color: "var(--font-body-color)"}}>Aceptar términos y condiciones</label>
                        </div>

                        <div className={styles.inputSwitch}>
                            <CustomInputSwitch checked={signupWithPhone} onChange={()=>setSignupWithPhone(!signupWithPhone)} />
                            <label className='clickable' style={{color: "var(--font-body-color)"}}>Registro con Celular</label>
                        </div>
                        <div className={`fixed-footer clickable`}>
                            <MainButton loading={globalLoading} type={"submit"} label={"CREAR CUENTA"}/>
                        </div>
                    </form>
                    <div style={{marginLeft:"auto", marginRight:"auto", textAlign:"center"}}>
                        <div>
                            <ThirdButton style={{maxWidth:"max-content"}} onClick={()=>navigate('/login')} label={"Ya tengo una cuenta"} />
                        </div>
                    </div>
                    <CustomModal
                        visible={visibleTermsAndConditions}
                        setVisible={setVisibleTermsAndConditions}
                        content={<TyC/>}
                        onClose={() => setVisibleTermsAndConditions(false)}
                    />
                </>

                :
                
                <>
                    <form onSubmit={verifySMS}>
                        <h4 style={{marginBottom:"10px"}}>Ingresa el codigo que recibiste en {country.code} {cellphone}</h4>
                        <CustomInputText required={true} floatLabel={true} type="number" value={smsCode} placeholder="Codigo SMS" onChange={(e) => setSmsCode(e.target.value)} />
                        <div className={`fixed-footer clickable`}>
                            <MainButton loading={globalLoading} type={"submit"} label={"VERIFICAR CODIGO"}/>
                        </div>
                    </form>
                    <div style={{marginLeft:"auto", marginRight:"auto", textAlign:"center"}}>
                        <div>
                            <ThirdButton style={{maxWidth:"max-content"}} onClick={(e)=>resendSMSCode(e)} label={countdown > 0 ? `Reenviar código (${countdown} s)` : "Reenviar código"} disabled={isResendDisabled}/>
                        </div>
                        <div>
                            <ThirdButton style={{maxWidth:"max-content"}} onClick={()=>setSmsCodeAsk(false)} label={"Cambiar numero telefonico"} />
                        </div>
                    </div>
                </>
            }

            <Toast ref={toast} />

        </div>
    );
}
