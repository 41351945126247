import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/Authcontext/Authcontext';
import { register } from '../PIXELs/PIXELs/Events/Events';
import useFirebaseCRUD from '../hooks/useFirebaseCRUD';

function GuestRoute({ children }) {
    const { userData, userSignedIn, loading } = useAuthContext();
    const { updateDocument } = useFirebaseCRUD();
    const navigate = useNavigate();
    useEffect(() => {
        console.log("bandera 1 GuestRoute")
        if (!loading && userSignedIn) {
            if (userData.onboarding_done) {
                console.log("bandera 2 GuestRoute")
                navigate('/home')

            } else {
        console.log("bandera 3 GuestRoute")

                // TODO: actualizar el doc del usuario con un boolenao que indique 'onboarding_done = true'
                if (userData?.type === "seller") {
                    updateDocument("seller_users", userData.uid, { onboarding_done: true })
                        .then(result => {
                            console.log("Resultado onboarding_done", result);
        console.log("bandera 4 GuestRoute")
                            register(); // Se dispara unicamente si está creando la cuenta
                            navigate('/myCatalog'); // Redirecciona después de actualizar el documento
                        })
                        .catch(error => {
                            console.error("Error onboarding_done", error);
                        });
                } else {
                console.log("bandera 5 GuestRoute")

                    navigate('/home'); // Redirecciona a la página de inicio o dashboard si el usuario ya está autenticado
                }
            }
        }
    }, [userSignedIn, loading, navigate]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (userSignedIn) {
        return null;
    }

    return children;
}

export default GuestRoute;
