import styles from './Landing.module.css';
import Hero from '../../Components/LandingComponents/Hero/Hero';
import Benefits from '../../Components/LandingComponents/Benefits/Benefits';
import Reviews from '../../Components/LandingComponents/Reviews/Reviews';
import Footer from '../../Components/LandingComponents/Footer/Footer';
import WhatsappChat from '../../Components/WhatsappChat/WhatsappChat';

export default function Landing() {
  return (
    <div className={styles.landing_container} style={{color:"white"}}>
      <WhatsappChat/>
      <Hero />
      <Benefits />
      <Reviews />
      <Footer />
    </div>
  )
}
