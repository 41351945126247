
import  { useState } from 'react'
import styles from './SideMainMenu.module.css';
import { Sidebar } from 'primereact/sidebar';
import { BiUser, BiHomeAlt2, BiLogoWhatsapp, BiWallet, BiInfoCircle, BiLockAlt, BiLogOutCircle, BiBookOpen , BiLogInCircle,BiWinkSmile , BiBriefcase, BiArchive, BiArchiveOut, BiArchiveIn, BiMaleFemale, BiDuplicate, BiBookAdd, BiBookHeart } from "react-icons/bi";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { LiaStoreSolid } from "react-icons/lia";
import { FaShopify } from "react-icons/fa";

import { FiUsers } from "react-icons/fi";
import HorizontalUserWidget from '../HorizontalUserWidget/HorizontalUserWidget';
import MenuOption from '../MenuOption/MenuOption';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { useAuthContext } from '../../contexts/Authcontext/Authcontext';
import CustomConfirmDialog from '../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog';



export default function SideMainMenu({user_data, viewMenu, toggleSidebar}) {
  const { t } = useTranslation('MenuOption');
  const { isDesktop, currentPage, whatsappUrl } = useGlobalContext();
  const { userSignedIn, logout} = useAuthContext();
  const [ confirmPopupVisible, setConfirmPopupVisible] = useState(false);
  const isInsider = user_data?.user_custom_claims?.type === "insider" || user_data?.user_custom_claims?.type === "master"  ? true : false;
  const logoutFromSession = () => {
    toggleSidebar(false)
    logout()
  }

  const confirmLogout = () => {
    logoutFromSession()
    setConfirmPopupVisible(false);
  };
    const handleSupportClick = () => {
        window.open(whatsappUrl, '_blank');
    };

  const rejectLogout = () => {
    setConfirmPopupVisible(false);
  };

  return (
    <div>
        <Sidebar
            modal={isDesktop? false : true}
            // visible={isDesktop? true : viewMenu}
            visible={viewMenu}
            // style={{minWidth: isDesktop? desktopSidebarWidth : "auto", maxWidth:isDesktop? desktopSidebarWidth : "70vw", backgroundColor: "var(--secondary-hard-color)" }} 
            style={{maxWidth:"70vw", backgroundColor: "var(--third-hard-color)" }}
            position="left"
            showCloseIcon={false}
            onHide={() => toggleSidebar(false)}
        >
                <div className={styles.sidebarHeader}>
                <HorizontalUserWidget user_data={user_data} toggleSidebar={toggleSidebar} />
            </div>
            <div className={`${styles.sidebarContent}`}>
                {
                    userSignedIn?
                        <div>
                            <Link to="/home">
                                <MenuOption onClick={() => toggleSidebar(false)} Icon={BiHomeAlt2} selected_option={currentPage} menu_option={{label:t('menu-options.home'), value:"home"}}/>
                            </Link>
                            <Link to="/account">
                                <MenuOption onClick={() => toggleSidebar(false)} Icon={BiUser} selected_option={currentPage} menu_option={{label:t('menu-options.myAccount'), value:"account"}}/>
                            </Link>
                            <Link to="/myCatalog">
                                    <MenuOption onClick={() => toggleSidebar(false)} Icon={BiBookOpen} selected_option={currentPage} menu_option={{label: isInsider ? t('menu-options.myCatalog') : t('menu-options.myCatalog'), value:"myCatalog"}}/>
                            </Link>
                            <Link to="/myOrders">
                                    <MenuOption onClick={() => toggleSidebar(false)} Icon={ BiArchiveIn} selected_option={currentPage} menu_option={{label: isInsider ? t('menu-options.Orders') :t('menu-options.myOrders'), value:"myOrders"}}/>
                            </Link>
                           {user_data?.is_supplier && ( <Link to="/consolidatedOrders">
                                    <MenuOption onClick={() => toggleSidebar(false)} Icon={BiArchiveOut} selected_option={currentPage} menu_option={{label: isInsider ? t('menu-options.consolidatedOrders') :t('menu-options.consolidatedOrders'), value:"consolidatedOrders"}}/>
                            </Link>)}
                            <Link to="/myCustomers">
                                    <MenuOption onClick={() => toggleSidebar(false)} Icon={BiWinkSmile} selected_option={currentPage} menu_option={{label: isInsider ? t('menu-options.customers') : t('menu-options.myCustomers'), value:"myCustomers"}}/>
                            </Link>
                            <Link to="/products">
                                <MenuOption onClick={() => toggleSidebar(false)} Icon={MdOutlineProductionQuantityLimits} selected_option={currentPage} menu_option={{label:t('menu-options.products'), value:"products"}}/>
                            </Link>


                            {isInsider && ( 
                                <Link to="/users">
                                        <MenuOption onClick={() => toggleSidebar(false)} Icon={FiUsers} selected_option={currentPage} menu_option={{label:t('menu-options.users'), value:"users"}}/>
                                </Link>
                            )}
                            {(!isInsider && (user_data?.ux_type !== "catalog_seller" && user_data?.ux_type !== "independent_seller" )) && (
                                <>
                                   {user_data?.is_supplier && ( <Link to="/myCreatedProducts">
                                        <MenuOption onClick={() => toggleSidebar(false)} Icon={BiBookAdd} selected_option={currentPage} menu_option={{label: isInsider ? t('menu-options.createdProducts') : t('menu-options.myCreatedProducts'), value:"myCreatedProducts"}}/>
                                    </Link>)}
                                    <Link to="/myVinculatedProducts">
                                        <MenuOption onClick={() => toggleSidebar(false)} Icon={BiDuplicate} selected_option={currentPage} menu_option={{label:t('menu-options.myVinculatedProducts'), value:"myVinculatedProducts"}}/>
                                    </Link>
                                    <Link to="/myStores">
                                        <MenuOption  onClick={() => toggleSidebar(false)} Icon={FaShopify} selected_option={currentPage} menu_option={{label: t('menu-options.myStores'), value:"myStores"}}/>
                                    </Link>
                                    {user_data?.show_shoppex_options && (<Link to="/myShoppexStores">
                                        <MenuOption onClick={() => toggleSidebar(false)} Icon={LiaStoreSolid} selected_option={currentPage} menu_option={{label: t('menu-options.myShoppexStores'), value:"myShoppexStores"}}/>
                                    </Link> )}
                                    <Link to="/promotions">
                                        <MenuOption onClick={() => toggleSidebar(false)} Icon={BiBriefcase} selected_option={currentPage} menu_option={{label: isInsider ? t('menu-options.promotions') : t('menu-options.myPromotions'), value:"promotions"}}/>
                                    </Link>
                                    <Link to="/myUsers">
                                        <MenuOption onClick={() => toggleSidebar(false)} Icon={FiUsers} selected_option={currentPage} menu_option={{label:t('menu-options.myUsers'), value:"myUsers"}}/>
                                    </Link>
                                </>
                            )}
                            

                    
                            <Link to="/wallet">
                                <MenuOption onClick={() => toggleSidebar(false)} Icon={BiWallet} selected_option={currentPage} menu_option={{label: isInsider ? t('menu-options.wallet') :t('menu-options.myWallet'), value:"wallet"}}/>
                            </Link>
                            {isInsider && ( 
                                <Link to="/shippingWallet">
                                        <MenuOption onClick={() => toggleSidebar(false)} Icon={FiUsers} selected_option={currentPage} menu_option={{label:t('menu-options.shippingWallet'), value:"shippingWallet"}}/>
                                </Link>
                            )}
                            <Link to="/withdrawals">
                                    <MenuOption onClick={() => toggleSidebar(false)} Icon={BiArchive} selected_option={currentPage} menu_option={{label: isInsider ? t('menu-options.withdrawals') :t('menu-options.withdrawals'), value:"withdrawals"}}/>
                            </Link>
                            <Link to="/shippingNews">
                                <MenuOption onClick={() => toggleSidebar(false)} Icon={MdOutlineProductionQuantityLimits} selected_option={currentPage} menu_option={{label:t('menu-options.shippingNews'), value:"shippingNews"}}/>
                            </Link>
                            {/* <Link to="/subscriptions">
                                <MenuOption onClick={() => toggleSidebar(false)} Icon={BiBookHeart} selected_option={currentPage} menu_option={{label:t('menu-options.subscriptions'), value:"subscriptions"}}/>
                            </Link> */}
                          
                            
                        </div>
                    :
                        <div>
                            <Link to="/">
                                <MenuOption onClick={() => toggleSidebar(false)} Icon={BiInfoCircle} selected_option={currentPage} menu_option={{label:t('menu-options.supplaii'), value:""}}/>
                            </Link>
                            <div style={{opacity:0.3}}>
                                <MenuOption onClick={null} Icon={BiLockAlt} selected_option={currentPage} menu_option={{label:t('menu-options.myAccount')}}/>
                                <MenuOption onClick={null} Icon={BiLockAlt} selected_option={currentPage} menu_option={{label:t('menu-options.myStores')}}/>
                                <MenuOption onClick={null} Icon={BiLockAlt} selected_option={currentPage} menu_option={{label:t('menu-options.myShoppexStores')}}/>
                                <MenuOption onClick={null} Icon={BiLockAlt} selected_option={currentPage} menu_option={{label:t('menu-options.myPromotions')}}/>
                                <MenuOption onClick={null} Icon={BiLockAlt} selected_option={currentPage} menu_option={{label:t('menu-options.products')}}/>
                                <MenuOption onClick={null} Icon={BiLockAlt} selected_option={currentPage} menu_option={{label:t('menu-options.myCreatedProducts')}}/>
                                <MenuOption onClick={null} Icon={BiLockAlt} selected_option={currentPage} menu_option={{label:t('menu-options.myVinculatedProducts')}}/>
                                <MenuOption onClick={null} Icon={BiLockAlt} selected_option={currentPage} menu_option={{label:t('menu-options.myOrders')}}/>
                                <MenuOption onClick={null} Icon={BiLockAlt} selected_option={currentPage} menu_option={{label:t('menu-options.myCustomers')}}/>
                            </div>
                        </div>
                }

            </div>
            <div className={styles.sidebarFooter}>
                {
                    userSignedIn?
                        // <MenuOption ref={confirmPopup} onClick={()=>logoutFromSession()} Icon={BiLogOutCircle} selected_option={currentPage} menu_option={{label:t('menu-options.logout'), value:"logout"}}/>
                        <MenuOption onClick={() => setConfirmPopupVisible(true)} Icon={BiLogOutCircle} selected_option={currentPage} menu_option={{label:t('menu-options.logout'), value:"logout"}}/>
                    :
                    <>
                        <Link to={"/login"}>
                            <MenuOption onClick={() => toggleSidebar(false)} Icon={BiLogInCircle} selected_option={currentPage} menu_option={{label:t('menu-options.login'), value:"login"}}/>
                        </Link>
                        <Link to={"/signup"}>
                            <MenuOption onClick={() => toggleSidebar(false)} Icon={BiUser} selected_option={currentPage} menu_option={{label:t('menu-options.signup'), value:"signup"}}/>
                        </Link>
                    </>
                }

                <MenuOption onClick={handleSupportClick} Icon={BiLogoWhatsapp} selected_option={currentPage} menu_option={{label:t('menu-options.support'), value:"support"}}/>
            </div>
        </Sidebar>

        <CustomConfirmDialog
            visible={confirmPopupVisible}
            onHide={() => setConfirmPopupVisible(false)}
            header={"Cerrar sesion"}
            message="¿Deseas cerrar sesion?"
            icon="pi pi-question-circle"
            accept={confirmLogout}
            reject={rejectLogout}
            acceptLabel='CERRAR SESION'
            rejectLabel='VOLVER'
        />
    
    </div>
  )
}
