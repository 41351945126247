
import styles from './GoBackBtn.module.css'
import { useNavigate } from 'react-router-dom';

const GoBackBtnCatalog = ({ icon: IconComponent, text }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1); // Navega hacia atrás en el historial
  };

  return (
    <div className={`clickeable ${styles.containerBtn} `}>
      {IconComponent &&
        <span  onClick={goBack}>
            <IconComponent className={`icon ${styles.containerBtn} cursor-pointer`} />
        </span>
      }
      {text && <h2 className="">{text}</h2>}
    </div>
  );
};

export default GoBackBtnCatalog;
