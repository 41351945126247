import React from 'react';
import numeral from 'numeral';
import styles from './ShoppingCartBoxPriceList.module.css';

const ShoppingCartBoxPriceList = ({ isTotal, price, label, color, fontWeight, fontWeightLabel, symbol, fontSize }) => {

    return (
        <div className={`${styles.shoppingCartBoxPriceList} ${isTotal ? styles.isTotal : ''}`} style={{ color }}>
            <div className={styles.shoppingCartBoxPriceListPrice} style={{ fontWeight, fontSize }}>
                {symbol}{numeral(price).format('$ 0,0')}
            </div>
            <div className={styles.shoppingCartBoxPriceListLabel} style={{ fontWeight: fontWeightLabel }}>
                {label}
            </div>
        </div>
    );
}

export default ShoppingCartBoxPriceList;
