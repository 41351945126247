import PropTypes from "prop-types"
import { useTranslation } from "react-i18next";
import styles from "./DetailProduct.module.css";

import MainButton from "../MainButton/MainButton";
import CustomModal from "../CustomModal/CustomModal";
import { useEffect, useState } from "react";
import numeral from "numeral";
import ProductSlider from "../ProductSlider/ProductSlider.jsx";
import ProductForm from "../ProductForm/ProductForm.jsx";
import { EDIT } from "../router/paths.js";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { BiPlusCircle } from "react-icons/bi";
import SectionShoppexStoreForm from "../SectionShoppexStoreForm/SectionShoppexStoreForm.jsx";
import LandingProductSection from "../LandingProductSection/LandingProductSection.jsx"
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import toastFunction from "../../utils/toastFunction.js";
import sortObjectsByPosition from "../../utils/sortObjectsByPosition.js";
import VinculateProductForm from "../VinculateProductForm/VinculateProductForm.jsx";
// Componente de interfaz que muestra informacion y las imagenes de las variaciones del Producto de 10x, contiene el boton que abre el modal de la vinculacion
const DetailProduct = ({ productData, toast, onClose, onLastProductIndex, categoryProductsData, userData, isCatalog}) => {
  const { t } = useTranslation("Products");
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { deleteFolderStorageAndContents, updateArray } = useFirebaseCRUD();
  const [visibleVinculateForm, setVisibleVinculateForm] = useState(false);
  const [visibleProductForm, setVisibleProductForm] = useState(false);
  const [visibleSectionForm, setVisibleSectionForm] = useState(false);
  const [visibleConfirmDeleteLandingProductSection, setVisibleConfirmDeleteLandingProductSection] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);
  const isInsider = userData?.user_custom_claims?.type === "insider" || userData?.user_custom_claims?.type === "master"  ? true : false;
  const isProductOwner = userData?.business_id === productData?.commercial_partner_id;
  const [shoppexStore, setShoppexStore] = useState(null);  
  const [shoppexLandingSectionsSortedByPositions, setShoppexLandingSectionsSortedByPositions] = useState([]);

  console.log("productData en DetailProduct", productData)
  // Función para abrir y cerrar el modal de la vinculacion.
  const openVinculateProductModal = () => {
    setVisibleVinculateForm(true);
  };

  const closeVinculateProductModal = () => {
    setVisibleVinculateForm(false);
    onClose();
  };
  const closeProductModal = () => {
    setVisibleProductForm(false);
    onClose();
  };
  const openSectionModal = () => {
    setVisibleSectionForm(true);
    setSelectedSection({})
  };
   const openConfirmDeleteSectionModal = (section) => {
    setVisibleConfirmDeleteLandingProductSection(true);
    setSelectedSection(section);
  };

  
  const handleDeleteSection = async () => {
    const pathToDelete = `shoppexStores/${shoppexStore.id}/section/${selectedSection.id}/`;
    // Borro la informacion de la seccion del storage
    await deleteFolderStorageAndContents(pathToDelete);
    // Encuentro la seccion antigua para sacarla del documento y actualizar todo el objeto de landing anidado  en `related_products_landing_content` 
    const relatedLandingContent = shoppexStore?.related_products_landing_content;
    const findedProductLandingContent = relatedLandingContent?.find((relatedProductLanding) => relatedProductLanding?.id === productData?.related_synced_id);
    const sectionsWhitoutSectionToDelete = findedProductLandingContent.sections.filter((landingSection) => landingSection.id !== selectedSection.id);
    const newProductLandingContent = {...findedProductLandingContent, sections: sectionsWhitoutSectionToDelete}
    // console.log("Nuevo landing del producto", newProductLandingContent);
// aca habria que volver a calcular las posiciones 
    const resultDeleteSection =  await updateArray("stores", shoppexStore.id, "related_products_landing_content",findedProductLandingContent, newProductLandingContent );
    // console.log("Resultado de borrar la seccion", resultDeleteSection);
    resultDeleteSection ? toastFunction(toast, "success", "Seccion removida con exito ", "", 3000) : toastFunction(toast, "error", "No se pudo remover la seccion con exito ", "", 3000) ; 
  }
  const handleEditSection = (section) => {
    setSelectedSection(section);
    setVisibleSectionForm(true);
  }

  useEffect(() => {  
    if( productData?.related_synced_id) { // Si el producto ya tiene un id de vinculacion, verificamos que los obejtos de vinculacion existan
      const findedSyncedStore = userData?.seller_stores?.find((store) => {
        return store.synced_products?.find((syncedProduct) => syncedProduct.id === productData?.related_synced_id);
      })
      if (findedSyncedStore?.platform?.code === 1) { // Si el objeto de la vinculacion corresponde y la tienda es Shoppex, traemos los documentos de tiendas relacionadas
        const storesCollection = collection(db, "stores");
        const qStores = query( storesCollection,  where("related_business_id", "==", userData.business_id));
        let unsubscribeStores;
    
        try {
         unsubscribeStores = onSnapshot(qStores, (resultTransactions) => {
            const shoppexStoresDocuments = resultTransactions.docs.map(doc => doc.data());
            setGlobalLoading(false);

            // Verificamos si el objeto existe también en las tiendas shoppex
            const findedShoppexStore = shoppexStoresDocuments?.find((shoppexStore) => {
                return shoppexStore?.related_products?.find((relatedProducts) => relatedProducts.id === productData?.related_synced_id);
            });
            const findedRelatedLandingContent = findedShoppexStore?.related_products_landing_content?.find((productLandingContent) => productLandingContent?.id === productData?.related_synced_id);

            if (findedShoppexStore) {
                setShoppexStore(findedShoppexStore);
                // Hacer una copia del array de secciones antes de ordenarlo
                const sectionsCopy = [...findedRelatedLandingContent.sections];
                // Sorteamos el contenido según las posiciones
                const sortedArrayByPosition = sortObjectsByPosition(sectionsCopy);
                setShoppexLandingSectionsSortedByPositions(sortedArrayByPosition);
            } else {
                setShoppexStore(null);
            }
        });

        } catch (error) {
          console.error("Hubo un error", error);
        }
    
        return () => {
          unsubscribeStores && unsubscribeStores()
        };
      }
    }
  }, [userData]);

  return (
    <div >
      <div>
        <ProductSlider user_data={userData} product_data={productData} />
        <h2
          className="main-gradient-text "
          style={{ color: "var(--font-head-color)", marginBottom: "20px" }}
          >
          {productData?.product_name}
        </h2>
        <h4> {numeral(productData?.commercial_partner_price_with_VAT_with_service_fee).format("$ 0,0[.]" )} </h4>
        <span>{t("supplierPriceLabel")}</span>
      </div>
      <div style={{ color: "var(--main-gradient)" }} className="mb-12">
        <h4>
          {" "}
          {numeral(productData?.suggested_retail_price).format("$ 0,0[.]")}
        </h4>

        <span>{t("suggestedRetailPrice")}</span>

      </div>
      {shoppexStore && (
        <>
          <div className="grid w-full">
          {shoppexLandingSectionsSortedByPositions.map((landingSection) => {
            return ( <LandingProductSection key={ landingSection.id} landingSection={landingSection} onDelete={openConfirmDeleteSectionModal} t={t}
              onEdit={handleEditSection}/> )
          })}
          </div>
          <div className="flex items-center mt-8 gap-4 cursor-pointer"
              onClick={openSectionModal}
          >
            <span disabled={globalLoading} className={styles.plusIcon}
              >
              <BiPlusCircle />
            </span>
            <p className="text-4xl">
              Agregar Seccion
            </p>
          </div>
        </>
        )}
      <div className="mt-12 gap-4 grid ">
        {/* Botón para vincular el producto. */}
        { !isInsider && !isCatalog && (<MainButton
          type={"button"}
          label={t("vinculateProduct")}
          onClick={openVinculateProductModal}
        />
        )}
       { (isInsider || isProductOwner) && (<MainButton
          type={"button"}
          label={t("editProduct")}
          onClick={() => setVisibleProductForm(true)}
        />
        )}
      </div> 
        <CustomModal
          visible={visibleVinculateForm}
          setVisible={setVisibleVinculateForm}
          editMode={t("vinculateProduct")}
          createMode={t("vinculateProduct")}
          content={
            <VinculateProductForm
              onLastProductIndex={onLastProductIndex}
              productData={productData}
              userData={userData}
              toast={toast}
              onClose={closeVinculateProductModal}
            />
          }
          onClose={() => setVisibleVinculateForm(false)}
        />
        <CustomModal
          visible={visibleProductForm}
          setVisible={setVisibleProductForm}
          object={productData}
          content={
            <ProductForm
              mode={EDIT}
              selectedProduct={productData}
              categoryProductsData={categoryProductsData}
              toast={toast}
              onClose={closeProductModal}
            />
          }
          onClose={() => setVisibleProductForm(false)}
        />
        <CustomModal
          visible={visibleSectionForm}
          setVisible={setVisibleSectionForm}
          object={selectedSection}
          editMode={t("editSection")}
          createMode={t("addSection")}
          content={
            <SectionShoppexStoreForm
              toast={toast}
              shoppexStore={shoppexStore}
              selectedSection={selectedSection}
              productData={productData}
              userData={userData}
              onClose={() => setVisibleSectionForm(false)}
              />
              }
          onClose={() => setVisibleSectionForm(false)}
        />
         <CustomConfirmDialog
            visible={visibleConfirmDeleteLandingProductSection}
            onHide={() => setVisibleConfirmDeleteLandingProductSection(false)}
            header={t("confirmDialog-deleteSection.header")}
            message={t("confirmDialog-deleteSection.message")}
            icon="pi pi-question-circle"
            accept={handleDeleteSection}
            reject={() => setVisibleConfirmDeleteLandingProductSection(false)}
            acceptLabel={t("confirmDialog-deleteSection.acceptLabel")}
            rejectLabel={t("confirmDialog-deleteSection.rejectLabel")}
          />
    </div>
  );
};
DetailProduct.propTypes = {
  productData: PropTypes.object, // Ajusta según la estructura exacta del objeto
  toast: PropTypes.object,
  onClose: PropTypes.func,
  onLastProductIndex: PropTypes.func,
  categoryProductsData: PropTypes.array, // Ajusta según la estructura exacta del array
  module: PropTypes.string,
  userData: PropTypes.object,
  isCatalog: PropTypes.bool,
};
export default DetailProduct;
