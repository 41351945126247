import { useEffect, useState } from 'react';
import styles from './QuantityControl.module.css';
import { FaMinus, FaPlus } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";


export default function QuantityControl({ disabledControllers, qty, updateQty, deleteItem }) {
  const [quantity, setQuantity] = useState(null);

  useEffect(() => {
    setQuantity(qty);
  }, [])
  

  const handleIncrease = async () => {
    setQuantity(prevQuantity => prevQuantity + 1);
    await updateQty(quantity + 1);
  };

  const handleDecrease = async () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
      await updateQty(quantity - 1);
    }
  };

  const handleDelete = () => {
    // Aquí agregar la lógica para eliminar el ítem
    deleteItem();
    setQuantity(1); // Resetear la cantidad a 1
  };

  return (
    <div className={styles.quantityControl}>
      {quantity > 1 ? (
        <button
          onClick={handleDecrease}
          type='button'
          className={disabledControllers === true ? `text-gray-300` : `text-black`}
          disabled={disabledControllers === true}
        >
            <FaMinus 
                className="icon"
            />
        </button>
      ) : (
        <button
          style={{marginRight:"-6px"}}
          onClick={handleDelete}
          type='button'
          className={disabledControllers === true ? `text-gray-300` : `text-black`}
          disabled={disabledControllers === true}
        > 
            <AiOutlineDelete 
            size={"1.3rem"}
            className="icon"
            /> 
        </button>
      )}
      <span>{quantity}</span>
      <button
        onClick={handleIncrease}
        type='button'
        className={disabledControllers === true ? `text-gray-300` : `text-black`}
        disabled={disabledControllers === true}
      >
        <FaPlus 
            className="icon"
        />
      </button>
    </div>
  );
}

