import React, {useRef, useEffect, useState} from 'react';
import styles from './ReviewWidget.module.css';
import { useTranslation } from 'react-i18next';
import { Rating } from 'primereact/rating';
import { VscVerifiedFilled } from "react-icons/vsc";
import moment from 'moment';
import 'moment/locale/es';  // Importa el paquete de localización para español
// import { hideString } from '@/utils/hideString';

export default function ReviewWidget({ reviews }) {
  const { t } = useTranslation('ReviewWidget');
  const totalScore = reviews.reduce((acc, review) => acc + review.score, 0) / reviews.length;
  const keywordCounts = reviews.flatMap(review => review.keyword).reduce((acc, keyword) => {acc[keyword] = (acc[keyword] || 0) + 1;return acc;}, {});
  const allKeywords = Object.entries(keywordCounts).map(([label, qty]) => ({ label, qty }));
  
  return (
    <div>
      <div>
        <h3>{t("reviewTitle")}</h3>
        <ReviewStars reviews={reviews} totalScore={totalScore} readOnly={true}/>
      </div>
      <div style={{marginTop:"40px"}}>
        <ReviewKeywords allKeywords={allKeywords} />
      </div>
      <div style={{marginTop:"20px"}}>
        <ReviewCards reviews={reviews} />
      </div>
    </div>
  );
}

export function ReviewStars({reviews, totalScore, totalScoreChange, readOnly, showReviewQty}) {
  return (
    <div style={{display:"flex"}}>
        <Rating className={styles.ReviewStars} color='var(--warning-color)' onChange={(e) => totalScoreChange(e.value)} value={totalScore} readOnly={readOnly} cancel={false} stars={5} />
        <div style={{marginLeft:"10px", color:"var(--dark-color)"}}>{totalScore} { showReviewQty && (`(${reviews.length})`)}</div>
    </div>
  )
}

export function ReviewKeywords({ allKeywords }) {
  return (
    <div className={styles.ReviewKeywords}>
      {allKeywords.map((keyword, index) => (
        <div className={styles.reviewKeywordCard} key={index}>{keyword.label} ({keyword.qty})</div>
      ))}
    </div>
  );
}

export function ReviewCards({ reviews }) {
  return (
    <div className={styles.ReviewCards}>
      {reviews.map((review) => (
        <ReviewCard key={review.id} review={review} />
      ))}
    </div>
  );
}

export function ReviewCard({ review }) {
  const { t } = useTranslation('ReviewWidget');

  return (
    <div style={{paddingTop: `${review.review_img_src ? "160px" : undefined}`}} className={styles.ReviewCard}>
        {review.review_img_src && (
            <img src={review.review_img_src} alt="Review" />
        )}
        <Rating style={{marginBottom: "10px"}} className={styles.ReviewStars} color='var(--warning-color)' value={review.score} readOnly={true} cancel={false} stars={5} />
        <div className={styles.verifiedBadget}><VscVerifiedFilled className="icon"/> {t("verified")}</div>
        <div className={styles.userNickName}>
            {/* @{hideString(review.user_nick_name, 1, 2, 3)}  */}
        </div>
        <div className={styles.reviewTextContainer}> 
            <div className={styles.scrollableContent}>
                {review.review}
            </div>
            <div className={styles.gradientOverlay}></div>
        </div>
        <span style={{fontSize: "var(--font-size-h6)"}}>{moment(review.created_at).fromNow()}</span>
    </div>
  );
}

