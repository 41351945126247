import PropTypes from "prop-types";
import styles from "./DetailSuscriptionForm.module.css";
import { useEffect, useState } from "react";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import MainButton from "../MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { useTranslation } from "react-i18next";
// import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
// import DeleteButton from "../DeleteButton/DeleteButton";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber";
import toastFunction from "../../utils/toastFunction";
import { Calendar } from "primereact/calendar";
import convertToDate from "../../utils/convertToDate";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import { arrayUnion } from "firebase/firestore";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import { generateRandomId } from "../../utils/generateRandomId";


// Componente de formulario que muestra el formulario para crear o editar una suscripcion
const DetailSuscriptionForm = ({
  selectedSubscription,
  labelSuccess,
  userData,
  // labelDelete,
  onClose,
  toast,
}) => {
const { t } = useTranslation("Subscriptions");
const {
   updateDocument,
  //  deleteDocument, 
  //  createDocument 
  } = useFirebaseCRUD();
const { globalLoading, setGlobalLoading } = useLoaderContext();
const { subscriptionStates } = useGlobalContext();

const subscriptionsColl = "subscriptions";

const initialDate = new Date();
initialDate.setHours(0, 0, 0, 0);
const finishDate = new Date();
finishDate.setHours(23, 59, 59, 999);

// const [ visibleConfirmDialog, setVisibleConfirmDialog ] = useState();
const hasEdit = !!Object.keys(selectedSubscription).length;
// Objeto que contiene los valores iniciales de la subscripcion
const initialSubscriptionValues = {
    id: selectedSubscription?.id || generateRandomId(), // Id de la suscripción
    related_customer_id: selectedSubscription?.related_customer_id || null, // Id del cliente asociado a la suscripción
    related_store_id: selectedSubscription?.related_store_id || null, // Id de la tienda asociada
    related_plan_id: selectedSubscription?.related_plan_id || null, // Id del plan asociado
    month_price: selectedSubscription?.month_price || 0, // Valor mensual del plan
    frequency: selectedSubscription?.frequency || 1, // Valor de la frecuencia en meses
    subscription_states: selectedSubscription?.subscription_states || [],
    start_date: convertToDate(selectedSubscription?.start_date) || new Date(),
    end_date: convertToDate(selectedSubscription?.end_date) || new Date(),
    last_state: selectedSubscription?.last_state || 0, // Último estado de la suscripción
    last_state_date: selectedSubscription?.last_state_date || new Date(), // Fecha del último estado de la suscripción
    related_transactions_ids: selectedSubscription?.related_transactions_ids || [], // Array de string cada string representa el id de una transacción relacionada a la suscripción
  };

  // Estado para las fechas
  const [dates, setDates] = useState([initialSubscriptionValues.start_date, initialSubscriptionValues.end_date]);
  const [subscriptionValues, setSubscriptionValues] = useState(initialSubscriptionValues);
  const [selectedLastState, setSelectedLasState] = useState( initialSubscriptionValues?.last_state);

  // const handleDeletePromotion = async () => {
  //   setGlobalLoading(true);
  //   // Eliminar la subscripcion de la base de datos
  //   const resultDeleteFromFirestore = await deleteDocument( subscriptionsColl,subscriptionValues.id );
  //   if (resultDeleteFromFirestore) {
  //     toastFunction(toast, "success", t("toast-summary.deleteSubscripcion"),  "", 3000);
  //   } else {
  //     toastFunction(toast, "error", t("toast-summary.errorDeleteSubscripcion"),  "", 3000);
  //   }
  //   setGlobalLoading(false);
  //   onClose(false);
  // };

  // Función para actualizar varios campos de la subscripcion basándose en el campo que recibe por parametros
  const updateSubscripcionValues = (fieldName, value) => {
    switch (fieldName) {
      case "last_state":
        setSubscriptionValues((prevValues) => ({
          ...prevValues,
          [fieldName]: value.code, // Insertamos el codigo del estado
        }));
        setSelectedLasState(value);
        break;
      default:
        setSubscriptionValues((prevValues) => ({
          ...prevValues,
          [fieldName]: value,
        }));
        break;
    } 
  
  };

  // Función para manejar las operaciones (Create, Update) en una subscripcion
  const handleCRUDSubscripcion = async (e) => {
    e.preventDefault();
    setGlobalLoading(true);

    let subscriptionLastStatesUpdate = {};
  
    const newSubscriptionState = {
      created_at: new Date(), // Fecha
      state: selectedLastState.code, // Estado actual de la suscripción codificado.1: “Creado”, 2: “Activo”, 3: “Expirado”, 4. “Pausado”
      before_state: selectedSubscription?.last_state ? selectedSubscription?.last_state : 0, // Si ya viene un ultimo estado lo agregamos sino 0 
    }

    // Comprobar si se está editando una subscripcion existente
    if (hasEdit) {
      if (selectedSubscription.last_state !== selectedLastState.code ) {
        subscriptionLastStatesUpdate = {
          subscription_states: arrayUnion(newSubscriptionState), // insertamos el nuevo estado
          last_state_date: new Date(),
        };
      }
      try {
        // console.log("Valores antes de editar la suscripcion",   {...subscriptionLastStatesUpdate, ...subscriptionValues});
        // Actualizar la subscripcion existente en la base de datos
        const resultUpdateSubscription = await updateDocument(
          subscriptionsColl,
          selectedSubscription.id,
          {...subscriptionLastStatesUpdate, ...subscriptionValues}
        );

        console.log(resultUpdateSubscription);
        if (resultUpdateSubscription) {
          toastFunction(toast, "success", t("toast-summary.updateSubscription"), "", 3000);
        }
        onClose(false);
      } catch (error) {
        toastFunction(toast, "error", t("toast-summary.errorUpdateSubscription"), error?.message, 3000);
      }
    } 
    // else {
    //   try {
    //     //  Crear una nueva subscripcion en la base de datos
    //     // console.log("antes de crear la suscripcion", { ...subscriptionValues, subscription_states: [newSubscriptionState]});
        
    //     const result = await createDocument(subscriptionsColl, subscriptionValues.id, {
    //       created_at: new Date(),
    //       ...subscriptionValues, subscription_states: [newSubscriptionState], related_store_id: "id de la tienda",
    //     });
    //     console.log(result);         
    //     toastFunction(toast, "success", t("toast-summary.createSubscription"),  "", 3000);
    //   } catch (error) {
    //     toastFunction(toast, "error", t("toast-summary.errorCreateSubscription"),  error.message, 3000);
    //   }
    // }
    setGlobalLoading(false);
    onClose(false);
    
  };

  const matchSucriptionLastStates = () => {
    const matchLastSubscriptionState = subscriptionStates.find((state) => state.code === selectedSubscription.last_state )
    setSelectedLasState(matchLastSubscriptionState)
  }
  
  // console.log("subscriptionValues", subscriptionValues);
  
  useEffect(() => {
    if(selectedSubscription.last_state) {
      matchSucriptionLastStates()
    }

  }, [userData]);


  return (
    <>
      {/* // Formulario para la creación, edición o eliminación de promociones */}
     <form onSubmit={handleCRUDSubscripcion} className={styles.containerInputs}> 
         <CustomInputNumber
          floatLabel={true}
          type={"number"}
          value={subscriptionValues?.month_price}
          placeholder={t("input-placeholders.monthPrice")}
          onChange={(e) =>  updateSubscripcionValues("month_price", e.target.value)}
          required={true}
          disabled={globalLoading}        

        />
        <CustomInputText
          floatLabel={true}
          type={"number"}
          value={subscriptionValues?.frequency}
          placeholder={t("input-placeholders.frequency")}
          onChange={(e) =>  updateSubscripcionValues("frequency", e.target.value)}
          disabled={globalLoading}        
          required={true}
        />
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={subscriptionStates}
          valueTemplate={""}
          itemTemplate={""}
          value={selectedLastState}
          placeholder={t("input-placeholders.subscriptionState")}
          onChange={(e) => { updateSubscripcionValues("last_state", e.target.value); }}
          disabled={globalLoading}        

        />
        <h5>Fecha inicio - Fecha termina</h5>
        <Calendar
          value={dates}
          onChange={(e) => setDates(e.value)}
          selectionMode="range"
          readOnlyInput
          placeholder={t("calendarPlaceholder")}
          style={{ width: "100%", marginBottom: "40px" }}
          disabled={globalLoading}        

        />
        <div className={styles.containerBtns}>
          <MainButton
            type={"onSubmit"}
            label={hasEdit ? t("updateSubscription") : labelSuccess}
            loading={globalLoading}
            disabled={globalLoading}        

            autoFocus
          />
          {/* {hasEdit && (
            <DeleteButton
              type={"button"}
              label={labelDelete}
              loading={globalLoading}
              onClick={() => setVisibleConfirmDialog(true)}
              disabled={globalLoading}        
            />
          )} */}
        </div>
        {/* Confirmación personalizada para eliminar la subscripcion */}
        <div>
          {/* <CustomConfirmDialog
            visible={visibleConfirmDialog}
            onHide={() => setVisibleConfirmDialog(false)}
            header={t("confirmDialog-deleteSubscription.header")}
            message={t("confirmDialog-deleteSubscription.message")}
            icon="pi pi-question-circle"
            accept={handleDeletePromotion}
            reject={() => setVisibleConfirmDialog(false)}
            acceptLabel={t("confirmDialog-deleteSubscription.acceptLabel")}
            rejectLabel={t("confirmDialog-deleteSubscription.rejectLabel")}
          /> */}
        </div>
      </form> : 
    </>
  );
};

DetailSuscriptionForm.propTypes = {
  selectedSubscription: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  index: PropTypes.number,
  toast: PropTypes.object,
};

export default DetailSuscriptionForm;
