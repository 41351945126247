import React from 'react'
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import styles from './ThirdButton.module.css';

export default function ThirdButton({style, label, icon, iconPos, badge, disabled, loading, onClick, type}) {
  return (
    <Button
        style={{...style}}
        className={`clickable ${styles.ThirdButton}`}
        label={label} 
        type={type}
        icon={icon}
        iconPos={iconPos}
        badge={badge}
        disabled={disabled} 
        loading={loading}
        onClick={onClick} 
    />
  )
}
