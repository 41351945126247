import styles from "../../pages/MyOrders/MyOrders.module.css";
import { useEffect, useState } from "react";
import { collection, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import CustomInputText from "../../Components/FormComponents/CustomInputText/CustomInputText.jsx";
import { useTranslation } from "react-i18next";
import useShopifyRequest from "../../hooks/useShopifyRequest.js";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext.jsx";
import { Toast } from "primereact/toast";
import MainButton from "../../Components/MainButton/MainButton.jsx";
import toastFunction from "../../utils/toastFunction.js";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import DetailOptionCard from "../../Components/DetailOptionCard/DetailOptionCard.jsx";
import CustomModal from "../../Components/CustomModal/CustomModal.jsx";
import DetailBankAccountForm from "../../Components/DetailBankAccountForm/DetailBankAccountForm.jsx";
import ThirdButton from "../../Components/ThirdButton/ThirdButton.jsx";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomInputSwitch from "../../Components/FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import { updateAllSellerWallets } from "../../utils/updateAllProducts.js";

// Componente de pagina que muestra los productos de 10X Booster y permite la vinculacion con productos de tiendas de shopify asociadas
export default function MyCatalog() {
    const { toast } = useShopifyRequest();
  const { userSellerOwnerData, userData,  } = useAuthContext();
  const { updateDocument } = useFirebaseCRUD();
  const userOwnerData = userSellerOwnerData || userData;
  const { t } = useTranslation("Catalog");
  const {setGlobalLoading} = useLoaderContext();
  const {countries} = useGlobalContext();
  const { getDocumentsByQuery } = useFirebaseCRUD();
  const [catalogValues, setCatalogValues] = useState();
  const { globalLoading } = useLoaderContext();

  const [country, setCountry] = useState("");

  const [visibleBankAccountModal, setVisibleBankAccountModal] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const isInsider = userData?.user_custom_claims?.type === "insider"  ? true : false;
  const isMaster = userData?.user_custom_claims?.type === "master" && userData?.user_custom_claims.is_master && userData.user_custom_claims.isAdmin
  const navigate = useNavigate();

  const [freeShippingFreight, setFreeShippingFreight] = useState(catalogValues?.free_shippings || false)
  console.log(freeShippingFreight)


    const fetchCatalogData = async () => {
        let catalogData = {};
        const qMyCatalog = query(collection(db, 'catalogs'), 
            where('id', '==', userOwnerData?.related_catalog_id)
            );
        try {
            const catalogDocuments = await getDocumentsByQuery(qMyCatalog);
            catalogData = catalogDocuments[0];
            console.log("Catalog Data", catalogData);
            // console.log("businessName", );
            setCatalogValues(catalogData);
            setFreeShippingFreight(catalogData?.free_shippings)
            setCountry(catalogData?.contact_country || "")
        } catch (error) {
            console.error("Error al obtener datos del vendedor:", error);
        }
    };
  
    // Función para manejar el cambio en los valores de contacto del vendedor
    const handleCatalogValues = (fieldName, newValue) => {
        if ( fieldName === "contact_country" ) {
            setCountry(newValue);
            setCatalogValues((prevValues) => ({
                ...prevValues,
                [fieldName]: newValue,
            }));
        }
        else if ( fieldName === "contact_number") {
            setCatalogValues((prevValues) => ({
                ...prevValues,
                [fieldName]: newValue,
                full_contact_number: `${country.code}${newValue}`,
            }));
        } else {
        // Actualiza el estado de checkoutValues con la dirección de envío actualizada
        setCatalogValues((prevValues) => ({
            ...prevValues,
            [fieldName]: newValue,
        }));
        }
    };
        // Función para manejar el cambio de estado
        const handleFreeShippingToggle = (value) => {
            const newValue = value ? true : false ;
            setFreeShippingFreight((prevState) => !prevState);
            
            // Asumiendo que `fieldName` y `newValue` son accesibles en el alcance
            setCatalogValues((prevValues) => ({
            ...prevValues,
            free_shippings: newValue,
            }));
        };
        console.log(catalogValues)

    const saveCatalogValues = async (e) => {
        setGlobalLoading(true);
        e.preventDefault()
      
        console.log("antes de actualizar ", catalogValues)
        const resultUpdateCatalog  = await updateDocument(
            "catalogs",
            userOwnerData?.related_catalog_id,
            {
                ...catalogValues, 
                last_update: new Date(),
                catalog_created_done: true,
            });
        if(catalogValues?.catalog_created_done) {
            navigate(`/catalog/?catalogId=${userOwnerData?.related_catalog_id}`)
            return
        }
        if ( resultUpdateCatalog ) {
            toastFunction(toast, "success", "Catalogo actualziado con exito", "", 3000 );
            navigate(`/catalogCreatorSuccess/?catalogId=${userOwnerData?.related_catalog_id}`)
        } else {
            toastFunction(toast, "error", "Hubo un error actualizando tu catalogo", "", 3000)
        }
        setGlobalLoading(false);
    };

    const openBankAccountModal = (account) => {
        setVisibleBankAccountModal(true);
    if (account.id) {
        setSelectedBankAccount(account);
    } else {
        setSelectedBankAccount({});
    }
    };

    useEffect(() => {
        const fetchData = async () => {
            setGlobalLoading(true);
            try {
                await fetchCatalogData();
            } catch (error) {
                console.log("catalog", catalogValues)
            } finally {
                setGlobalLoading(false);
            }
        };
        fetchData();
    }, [userOwnerData, userData]);

    

    return (
         <div  className="gap-4 grid grid-cols-1 ">
            {!catalogValues?.catalog_created_done && (<h4 className="mb-4 mt-2 ">😎 Creemos tu catálogo </h4>)}
         
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={catalogValues?.catalog_name || ""}
              placeholder={t("input-placeholders.catalogName")}
              onChange={(e) => handleCatalogValues("catalog_name", e.target.value)} 
              disabled={globalLoading}
              required={true}
            />
            <CustomSelect 
                required={false}
                floatLabel={true}
                optionLabel="name"
                options={countries}
                valueTemplate=""
                itemTemplate=""
                value={country}
                placeholder="Pais"
                onChange={(e) => handleCatalogValues("contact_country", e.value)} />

       
            <CustomInputText
              floatLabel={true}
              type={"number"}
              value={catalogValues?.contact_number || ""}
              placeholder={`${country.code??""} ${t("input-placeholders.contactNumberWA")}`}
              onChange={(e) => handleCatalogValues("contact_number", e.target.value)} 
              disabled={globalLoading|| !country.code}
              required={true}
            />
           <div className={styles.inputSwitch}>
                <CustomInputSwitch
                    checked={freeShippingFreight}
                    onChange={(e) => handleFreeShippingToggle(e.value)}
                />
                <label
                    onClick={(e) => handleFreeShippingToggle(e.value)}
                    className="clickable"
                    style={{ color: "var(--font-body-color)" }}
                >
                    Ofrecer fletes gratis a mis clientes
                </label>
            </div>

            <Toast ref={toast} />
            <div className="mt-8">
            <h3 className="mb-2"> {t("labelMyBanks")}</h3>
                {userOwnerData?.bank_accounts?.length > 0 ?
                userOwnerData?.bank_accounts?.map((account, index) => {
                    const createdAt = new Date(
                    account?.created_at?.seconds * 1000 +
                        account?.created_at?.nanoseconds / 1000000
                    );
                    return (
                    <DetailOptionCard
                        bold_text={account?.alias_account}
                        regular_text={account?.number_account}
                        statusLabel={`${t("dateLabel")} ${moment(createdAt).format(t("dateFormat"))}`} // Aquí se formatea la fecha
                        key={index}
                        onClick={() => openBankAccountModal(account)}
                        hidde_btn={false}
                    />
                    );
                }) : <marquee className="mt-8 mb-6">Aun no haz registrado cuentas de banco </marquee>}
               {userOwnerData?.bank_accounts?.length === 0 && ( <ThirdButton 
                 floatLabel={true}
                 onClick={openBankAccountModal}
                 type={"button"}
                 label={t("addNewBankAccount")}
                 disabled={globalLoading}
                 loading={globalLoading}
                />)}
            </div>
            <div className="mt-2 mb-2 grid gap-4">
                <MainButton
                    floatLabel={true}
                    className={styles.secondaryButton}
                    type={"button"}
                    label={catalogValues?.catalog_created_done ? "Ver mi catalogo y guardar ":t("createCatalog")}
                    loading={globalLoading}
                    onClick={saveCatalogValues}
                    disabled={globalLoading}

                />
            </div>
            <CustomModal
                visible={visibleBankAccountModal}
                setVisible={setVisibleBankAccountModal}
                object={selectedBankAccount}
                editMode={t("updateBankAccount")}
                createMode={t("createNewBankAccount")}
                content={
                    <DetailBankAccountForm
                    onClose={setVisibleBankAccountModal}
                    selectedBankAccount={selectedBankAccount}
                    userData={userOwnerData}
                    labelDelete={t("deleteBankAccount")}
                    toast={toast}
                    isInsider={isInsider}
                    isMaster={isMaster}
                    />
                }
                onClose={() => setVisibleBankAccountModal(false) }
                />
            </div>

    );
    
}
