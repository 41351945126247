import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect";
import { useTranslation } from "react-i18next";
import MainButton from "../MainButton/MainButton";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import { Messages } from "primereact/messages";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import toastFunction from "../../utils/toastFunction";

const VinculateStoreCustomer = ({
  toast,
  onClose,
  sellerStores,
  emailsDocuments,
  phonesDocuments,
  closeCustomerModal,
  isInsider,
  foundedStoreByInsider
}) => {
  console.log(isInsider, foundedStoreByInsider);
  const [selectedSellerStore, setSelectedSellerStore] = useState([]);
  const { addToArray } = useFirebaseCRUD();
  const { userData } = useAuthContext();
  const { t } = useTranslation("MyCustomers");
  const [relatedStoreIdsCart, setRelatedStoreIdsCart] = useState([]);
  const [customersIdsCart, setCustomersIdsCart] = useState([]);
  const [customerRelatedStoreIds, setCustomerRelatedStoreIds] = useState([]);

  const [messagesShown, setMessagesShown] = useState(false);
  const msgsChip = useRef(null);

  // Función para actualizar el campo de related_stores_ids con IDs únicos
  const updateRelatedStoresField = (fieldName, newValue) => {
    // Crear un nuevo conjunto para almacenar los IDs únicos
    const uniqueIdsSet = new Set([]);
    // Agregar los IDs de cada objeto en newValue al conjunto
    newValue.forEach((obj) => {
      if (obj && obj.id) {
        uniqueIdsSet.add(obj.id);
      }
    });
    // Concatenar los IDs de customerRelatedStoreIds al conjunto (evitando duplicados)
    customerRelatedStoreIds.forEach((str) => {
      if (str && !uniqueIdsSet.has(str)) {
        uniqueIdsSet.add(str);
      }
    });
    // Actualizar relatedStoreIdsCart con el conjunto de IDs únicos
    const relatedStoresIds = Array.from(uniqueIdsSet);
    setRelatedStoreIdsCart(relatedStoresIds);
  };
  // Función para encontrar las tiendas coincidentes
  const findMatchingStores = (sellerStores, relatedStoreIds) => {
    // Filtrar los objetos de seller_stores cuyos IDs coinciden con los IDs en related_stores_ids
    const matchingStores = sellerStores.filter((store) =>
      relatedStoreIds?.includes(store.id)
    );
    return matchingStores;
  };
  // Función para mostrar mensajes de información
  const addMessage = () => {
    msgsChip.current.show([
      {
        severity: "info",
        summary: "info",
        detail: t("toast-detail.infoCustomerAlreadyExist"),
        sticky: true,
        closable: false,
      },
      {
        severity: "info",
        summary: "Info",
        detail: t("toast-detail.infoCustomerAlreadyExist2"),
        sticky: true,
        closable: false,
      },
    ]);
  };

  // Función para vincular tiendas a clientes
  const vinculateStoresToCustomers = async () => {
    for (const customerId of customersIdsCart) {
      console.log("customer id", customerId, relatedStoreIdsCart);
      try {
        // Actualizar el documento del cliente con related_stores_ids
        for ( const storeId of relatedStoreIdsCart ) {
          console.log("Agregando Ids", storeId)
          await addToArray("customers", customerId, "related_stores_ids", storeId )

        }
        // Mostrar mensaje de éxito
        toastFunction(toast, "success", t("toast-summary.customerVinculateStoresSuccess"),  "", 4000);
      } catch (error) {
        // Mostrar mensaje de error
        toastFunction(toast, "error", t("toast-summary.customerVinculateStoresError"),  "", 4000);
      }
    }
    // Cerrar modales después de vincular tiendas a clientes
    onClose();
    closeCustomerModal();
  };
  console.log("related store ids cart", relatedStoreIdsCart);

  // Efecto secundario que se ejecuta cuando cambia el estado de messagesShown
  useEffect(() => {
    // Crear conjuntos para almacenar IDs únicos
    const uniqueRelatedSellerStoresIdsSet = new Set([]);
    const uniqueCustomerIdsSet = new Set([]);
    // Agregar IDs de cada objeto en newValue al conjunto
    emailsDocuments.forEach((obj) => {
      if (obj && obj.id) {
        obj.related_stores_ids.forEach((storeId) => {
          uniqueRelatedSellerStoresIdsSet.add(storeId);
        });
        uniqueCustomerIdsSet.add(obj.id);
      }
    });
    phonesDocuments.forEach((obj) => {
      if (obj && obj.id) {
        obj.related_stores_ids.forEach((storeId) => {
          uniqueRelatedSellerStoresIdsSet.add(storeId);
        });
        uniqueCustomerIdsSet.add(obj.id);
      }
    });
    // Actualizar relatedStoreIdsCart con el conjunto de IDs únicos
    const relatedStoresIds = Array.from(uniqueRelatedSellerStoresIdsSet);
    const customerIds = Array.from(uniqueCustomerIdsSet);
    console.log(customerIds, "customerIds");

    const matchingStores = findMatchingStores( userData.seller_stores, relatedStoresIds);
    // Filtrar customerIds para mantener solo los que no coinciden con ningún ID en matchingStores
    const filteredCustomerIds = relatedStoresIds.filter(
      (customerId) => !matchingStores.some((store) => store.id === customerId)
    );

    console.log("Efecto vinculacion", relatedStoresIds, matchingStores, filteredCustomerIds, customerIds)
    // Actualizar estados locales con datos calculados
    setSelectedSellerStore(matchingStores);
    setCustomerRelatedStoreIds(filteredCustomerIds);
    setCustomersIdsCart(customerIds);
    if (isInsider){
      setRelatedStoreIdsCart([foundedStoreByInsider.id]);
    } else {
      setRelatedStoreIdsCart(relatedStoresIds);
    }

    // Mostrar mensajes después de un pequeño retraso
    const delay = setTimeout(() => {
      // Verificar si los mensajes aún no se han mostrado
      if (!messagesShown) {
        addMessage();
        setMessagesShown(true);
      }
    }, 100);
    return () => clearTimeout(delay);
  }, [messagesShown]);
  console.log(customerRelatedStoreIds, customersIdsCart);
  return (
    <div style={{ marginBottom: "40px", display: "grid", gap: "20px" }}>
      <Messages ref={msgsChip} />

      {!isInsider ? 
        <CustomMultiSelect
          optionLabel={"name"}
          options={sellerStores}
          value={selectedSellerStore}
          onValue={setSelectedSellerStore}
          placeholder={t("input-placeholders.applicableStores")}
          updateField={updateRelatedStoresField}
          fieldNameToUpdate={"related_stores_ids"}
          required={true}
        />
        :
        <CustomInputText
          floatLabel={true}
          type={"text"}
          value={foundedStoreByInsider.name + " " + foundedStoreByInsider.id}
          placeholder={t("input-placeholders.foundedStore")}
          disabled={true}
        />
      }
 
      <MainButton
        type="button"
        label={t("vinculate")}
        onClick={vinculateStoresToCustomers}
      />
    </div>
  );
};

VinculateStoreCustomer.propTypes = {
  toast: PropTypes.object,
  onClose: PropTypes.func,
  sellerStores: PropTypes.array,
  customerValues: PropTypes.object,
  emailsDocuments: PropTypes.array,
  phonesDocuments: PropTypes.array,
  closeCustomerModal: PropTypes.func,
  isInsider: PropTypes.bool,
  foundedStoreByInsider: PropTypes.object,
};
export default VinculateStoreCustomer;
