import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common from './common.json';
import MenuOption from '../components/MenuOption/MenuOption.json';
import SideMainMenu from '../components/SideMainMenu/SideMainMenu.json';
import CustomSelect from '../components/FormComponents/CustomSelect/CustomSelect.json';
import HorizontalUserWidget from '../Components/HorizontalUserWidget/HorizontalUserWidget.json';
import PromotionalUrlBox from '../Components/PromotionalUrlBox/PromotionalUrlBox.json';
import VideoCard from '../Components/VideoCard/VideoCard.json';
import Signup from '../pages/Signup/Signup.json';
import Login from '../pages/Login/Login.json';
import useAuth from '../hooks/useAuth.json';
import Account from '../pages/Account/Account.json';
import Users from '../pages/MyUsers/Users.json';
import Home from '../pages/Home/Home.json';
import Stores from '../pages/Stores/Stores.json';
import Promotions from '../pages/Promotions/Promotions.json';
import Products from '../pages/Products/Products.json';
import ProductGridCard from '../Components/ProductGridCard/ProductGridCard.json';
import MyOrders from '../pages/MyOrders/MyOrders.json';
import MyCustomers from '../pages/MyCustomers/MyCustomers.json';
import Wallet from '../pages/Wallet/Wallet.json';
import Withdrawals from '../pages/Withdrawals/Withdrawals.json';
import ShippingNews from '../pages/ShippingNews/ShippingNews.json';
import ConsolidatedOrders from '../pages/ConsolidatedOrders/ConsolidatedOrders.json';
import Subscriptions from '../pages/Subscriptions/Subscriptions.json';
import Catalog from '../pages/Catalog/Catalog.json';



const i18n_resources = {
  es: {
    common: common.es,
    MenuOption: MenuOption.es,
    SideMainMenu: SideMainMenu.es,
    CustomSelect: CustomSelect.es,
    HorizontalUserWidget: HorizontalUserWidget.es,
    PromotionalUrlBox: PromotionalUrlBox.es,
    VideoCard: VideoCard.es,
    Signup: Signup.es,
    Login: Login.es,
    useAuth: useAuth.es,

    Account: Account.es,
    Users: Users.es,
    Home: Home.es,
    Stores: Stores.es,
    Promotions: Promotions.es,
    Products: Products.es,
    ProductGridCard: ProductGridCard.es,
    MyOrders: MyOrders.es,
    MyCustomers: MyCustomers.es,
    Wallet: Wallet.es,
    Withdrawals: Withdrawals.es,
    ShippingNews: ShippingNews.es,
    ConsolidatedOrders: ConsolidatedOrders.es,
    Subscriptions: Subscriptions.es,
    Catalog: Catalog.es,


  },
  en: {
    common: common.en,
    MenuOption: MenuOption.en,
    SideMainMenu: SideMainMenu.en,
    CustomSelect: CustomSelect.en,
    HorizontalUserWidget: HorizontalUserWidget.en,
    PromotionalUrlBox: PromotionalUrlBox.en,
    VideoCard: VideoCard.en,
    Signup: Signup.en,
    Login: Login.en,
    useAuth: useAuth.en,

    Account: Account.en,
    Users: Users.en,
    Home: Home.en,
    Stores: Stores.en,
    Promotions: Promotions.en,
    Products: Products.en,
    ProductGridCard: ProductGridCard.en,
    MyOrders: MyOrders.en,
    MyCustomers: MyCustomers.en,
    Wallet: Wallet.en,
    Withdrawals: Withdrawals.en,
    ShippingNews: ShippingNews.en,
    ConsolidatedOrders: ConsolidatedOrders.en,
    Subscriptions: Subscriptions.en,
    Catalog: Catalog.en,


  }
};

i18n
  .use(initReactI18next)
  .init({
    resources: i18n_resources,
    lng: "es", // Idioma por defecto si no se detecta ninguno
    interpolation: {
      escapeValue: false
    },
    ns: [
      "common",
      "MenuOption",
      "SideMainMenu",
      "CustomSelect",
      "HorizontalUserWidget",
      "PromotionalUrlBox",
      "Signup",
      "Login",
      "useAuth",
      "Account",
      "Users",
      "Home",
      "Stores",
      "Products",
      "ProductGridCard",
      "MyOrders",
      "MyCustomers",
      "Wallet",
      "Withdrawals",
      "Catalog"
    ],
    defaultNS: "common"
  })

export default i18n;
