import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./VinculateItemsBatch.module.css";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import { useEffect, useRef, useState } from "react";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import { getPaginatedProducts } from "../../utils/getPaginatedProducts";

import { getAndAgroupItemsNotSyncedInOrders } from "../../pages/MyOrders/scripts/getOrders";
import { Button } from "primereact/button";
import CustomTable from "../CustomTable/CustomTable";
import { fetchFromCloudFunction } from "../../services/cloudFunctinons/fetchFromCloudFunction";
import { Messages } from "primereact/messages";
import toastFunction from "../../utils/toastFunction";

// Componente de formulario que se uestra al seleccionar un item de la tabla del OverLayPanel, muestra el detalle del Producto que formara el Item para la Orden
const VinculateItemsBatch = ({
  visible,
  amountSellerStoresIds,
  filteredSellerStores,
  toast,
  onClose,
}) => {
  const { t } = useTranslation("MyOrders");
  const { globalLoading, setGlobalLoading } = useLoaderContext();

  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const [itemsDataTable, setItemsDataTable] = useState([]);
  const [productsData, setProductsData] = useState([]);

  const [rowLoading, setRowloading] = useState([]);
  const [messagesShown, setMessagesShown] = useState(false);

  const msgs = useRef(null);

  // Función para obtener la cantidad de productos de las tiendas de un vendedor
  const amountProductsOfSellerStores = (filteredSellerStores) => {
    if (!filteredSellerStores) return [];
    // Utiliza un conjunto para asegurar que no haya duplicados en los IDs de productos sincronizados que luego se usaran en el fetch

    const syncedProductsIds = new Set();
    // Itera sobre las tiendas y sus productos sincronizados
    filteredSellerStores.forEach((store) => {
      if (store.synced_products) {
        store.synced_products.forEach((item) => {
          if (item?.product_id) {
            syncedProductsIds.add(item.product_id);
          }
        });
      }
    });
    // Devuelve un objeto con los IDs únicos de los productos sincronizados
    return { syncedProductsIds: Array.from(syncedProductsIds) };
  };

  const handleRowChange = (rowData, fieldName, newValue) => {
    let updatedRowData = { ...rowData };
    if (fieldName === "shipping_company_details") {
      // Actualiza los datos de la fila según el campo y el nuevo valor
      updatedRowData = {
        ...updatedRowData,
        ["shipping_company"]: newValue.code,
        [fieldName]: newValue,
      };
    } else if (fieldName === "shipping_destination") {
      updatedRowData = {
        ...updatedRowData,
        ["city_code"]: newValue.city_code,
        [fieldName]: newValue,
      };
    } else {
      updatedRowData = {
        ...updatedRowData,
        [fieldName]: newValue,
      };
    }
    // Actualiza la lista de pedidos con la fila modificada
    const updateItemsData = itemsDataTable.map((item) =>
      item.id === rowData.id ? updatedRowData : item
    );
    // Actualiza el estado de los pedidos y la fila de datos seleccionada
    setItemsDataTable(updateItemsData);
  };

  const handleVinculateItemsBatch = async (rowData) => {
    if (rowLoading.includes(rowData.id)) {
      // Si está en estado de carga, eliminar el ID para indicar que la carga ha terminado
      setRowloading((prevLoading) =>
        prevLoading.filter((id) => id !== rowData.id)
      );
      return;
    }
    // Establecer el ID en estado de carga para mostrar el loader
    setRowloading((prevLoading) => [...new Set([...prevLoading, rowData.id])]);

    const endpoint = "batchUtils/vinculateItemsBatch";
    try {
      const vinculateBatchRes = await fetchFromCloudFunction( endpoint, rowData, "POST" );
      console.log("Respuesta batch vinvualte items", vinculateBatchRes);
      if (vinculateBatchRes.status === 200) {
        toastFunction(toast, "success", "Correccion exitosa", "", 3000);
      }
    } catch (error) {
      // setRowloading((prevLoading) => prevLoading.filter((id) => id !== rowData.id));
    }
    setRowloading((prevLoading) =>
      prevLoading.filter((id) => id !== rowData.id)
    );
  };

  const tableVinculateItemsColumns = [
    {
      field: "name",
      header: t("table-headers.shopifyProductNoSynced"),
      filterable: false,
      sortable: false,
    },
    {
      field: "size",
      header: t("table-headers.foundProducts"),
      filterable: false,
      sortable: false,
    },
    {
      header: t("table-headers.selectProduct"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <>
          <CustomSelect
            floatLabel={true}
            optionLabel={"product_name"}
            options={productsData}
            value={rowData.productToVinculate}
            placeholder={t("input-placeholders.productName")}
            onChange={(e) =>
              handleRowChange(rowData, "productToVinculate", e.target.value)
            }
            required={true}
            loading={rowLoading.includes(rowData.id)}
            disabled={
              rowLoading.includes(rowData.id) || productsData.length === 0
            }
          />
        </>
      ),
    },
    {
      header: t("table-headers.vinculateBatch"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <Button
          label={t("vinculateProduct")}
          onClick={() => handleVinculateItemsBatch(rowData)}
          className="p-button-rounded p-button-outline"
          loading={rowLoading.includes(rowData.id)}
          disabled={
            rowLoading.includes(rowData.id) || !rowData.productToVinculate.id
          }
        />
      ),
    },
  ];

  const addMessage = () => {
    msgs.current.show([{ severity: "info", summary: "Info", detail: t( "toast-detail.needVinculateProducts" ), sticky: true, closable: false, }     ]);
  };

  useEffect(() => {
    // Obtiene los IDs de productos sincronizados de las tiendas del vendedor
    let unsubscribeOrders;
    const { syncedProductsIds } = amountProductsOfSellerStores(filteredSellerStores);
    const body = {
      type: "onlyProducts",
      productsIds: syncedProductsIds,
    };

    // Función asíncrona para realizar la lógica de la carga de datos
    const fetchData = async () => {
      setGlobalLoading(true);

      unsubscribeOrders = await getAndAgroupItemsNotSyncedInOrders(
        amountSellerStoresIds,
        setItemsDataTable
      );
      const productsDataFetch = await getPaginatedProducts(body);
      setProductsData(productsDataFetch);
    };
    // Llama a la función fetchData al montar el componente o cuando la dependencia 'orders' cambia
    if (visible) {
      fetchData();
      setGlobalLoading(false);
    }
    return () => {
      if (unsubscribeOrders) {
        unsubscribeOrders();
      }
    };
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      if (!messagesShown && productsData.length === 0) {
        // Muestra mensajes
        addMessage();
        setMessagesShown(true);
      }
      if (messagesShown && itemsDataTable.length === 0) {
        onClose();
        toastFunction(toast, "info", t("toast-summary.notEnoughOrdersToFix"),  "", 4000);
      }
    }, 100);

    // Limpia el temporizador cuando el componente se desmonta o las dependencias cambian
    return () => clearTimeout(delay);
  }, [itemsDataTable, messagesShown]);


  return (
    <main className={styles.containerInputs}>
      <Messages ref={msgs} />
      {itemsDataTable.length > 0 ? (
        <div>
          <CustomTable
            className="custom-table"
            columns={tableVinculateItemsColumns}
            data={itemsDataTable}
            action={() => {}}
          />
        </div>
      ) : (
        <div className="flex justify-center">
          <Button loading={globalLoading} disabled={true} />
        </div>
      )}

      <div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteItem.header")}
          message={t("confirmDialog-deleteItem.message")}
          icon="pi pi-question-circle"
          // accept={(e) => handleDeleteProductItem(e)}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteItem.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteItem.rejectLabel")}
        />
      </div>
    </main>
  );
};
VinculateItemsBatch.propTypes = {
  visible: PropTypes.bool,
  amountSellerStoresIds: PropTypes.array,
  orders: PropTypes.array,
  filteredSellerStores: PropTypes.array,
  productItemData: PropTypes.object,
  toast: PropTypes.object,
  onClose: PropTypes.func,
};
export default VinculateItemsBatch;
