import React from 'react';
import styles from './OptionWithTitleAndText.module.css';
import LottieAnimation from '../LottieAnimation/LottieAnimation';

const OptionWithTitleAndText = ({ optionData }) => {
  const {id, data, title, text, isSelected, selectFunction, disabled, disabled_message, animationData, img} = optionData
  return (
    // <div style={{opacity:disabled?"0.5":"1"}} onClick={disabled? null : ()=>selectFunction(data)} className={`clickable ${styles.card} ${isSelected===true?styles.selectedCard:""}`}>
    <div onClick={()=>selectFunction(data)} className={`clickable ${styles.card} ${isSelected===id?styles.selectedCard:""}`}>
      <div className={`${styles.title}`}>{title}</div>
      <div className={`${styles.text}`}>{text}</div>
      {
        animationData && (
          <LottieAnimation animationData={animationData} style={{minWidth:"100%"}} />
        )
      }
      {
        img && (
          <img src={img} style={{maxWidth:"100%"}}/>
        )
      }
      {
        disabled && (
          <div style={{color:"white", marginTop:"20px"}}>{disabled_message}</div>
        )
      }
    </div>
  );
};

export default OptionWithTitleAndText;
