import PropTypes from "prop-types";
import formatDate from "../../utils/formatDate.js";

const ConsolidatedOrderCard = ({ consolidatedOrder, productsData }) => {
  // console.log("consolidatedOrder", consolidatedOrder)
  const renderVariationInfo = (product) => {
    const renderArray = [];
    const auxiliaryRenderArray = [];
    const groupedVariations = {};
    const foundedProduct = productsData.find((dbProduct) => dbProduct.id === product.product_id);
  
    // Verificar si el producto tiene variaciones
    const hasVariations = foundedProduct?.product_with_variations;
    
    // Acumulador para productos sin variaciones
    let totalSkuReqQty = 0;
  
    // Procesar variaciones del producto
    product.variations.forEach((variation) => {
      if (!hasVariations) {
        totalSkuReqQty += variation.sku_req_qty; // Acumular la cantidad total
        return; // Saltar el resto del procesamiento si no tiene variaciones
      }
  
      if (foundedProduct?.options) {
        handleVariationsWithOptions(foundedProduct, variation, groupedVariations, auxiliaryRenderArray, renderArray);
      }
    });
  
    // Agregar la información de productos sin variaciones al renderArray si corresponde
    if (!hasVariations && totalSkuReqQty > 0) {
      renderArray.push(renderNoVariation(product, totalSkuReqQty));
    }
  
    // Acumular las cantidades de `sku_req_qty` de los que tienen el mismo `variation_id`
    Object.entries(groupedVariations).forEach(([key, variations]) => {
      const accumulatedVariations = {};
  
      variations.forEach((variation) => {
        if (accumulatedVariations[variation.variation_id]) {
          accumulatedVariations[variation.variation_id].sku_req_qty += variation.sku_req_qty;
        } else {
          accumulatedVariations[variation.variation_id] = { ...variation };
        }
      });
  
      groupedVariations[key] = Object.values(accumulatedVariations);
    });
  
    return renderCompleteComponent(product, groupedVariations, auxiliaryRenderArray, renderArray);
  };
  
  const renderNoVariation = (product, totalSkuReqQty) => (
    <div key={product.product_id}>
      <div className="text-lg mb-2 grid items-center">
        <div className="flex">
          <p className="font-semibold mr-2">Cantidad Total: &nbsp;</p>
          <p className="font-thin">{totalSkuReqQty}</p>
        </div>
      </div>
    </div>
  );
  

  const renderVariationNoSize = (variation) => (
    <div key={variation.id}>
      <div className="text-lg mb-2 grid items-center">
        <p className="font-bold">{variation?.sku_label}</p>
        <div className="flex">
          <p className="font-semibold mr-2">Cantidad: &nbsp;</p>
          <p className="font-thin">{variation?.sku_req_qty}</p>
        </div>
      </div>
    </div>
  );

  const handleVariationsWithOptions = (foundedProduct, variation, groupedVariations, auxiliaryRenderArray, renderArray) => {
    const isSize = foundedProduct.options.some((option) => option?.is_size);
    if (isSize) {
      handleAuxiliaryOptions(foundedProduct, auxiliaryRenderArray);
      handleSizeOptions(foundedProduct, variation, groupedVariations, renderArray);
    } else {
      renderArray.push(renderVariationNoSize(variation));
      handleAuxiliaryOptions(foundedProduct, auxiliaryRenderArray);
    }
  };

  const handleSizeOptions = (foundedProduct, variation, groupedVariations, renderArray) => {
    const foundedVariation = foundedProduct.variations?.find((v) => v.variation_id === variation.variation_id);
    if (foundedVariation) {
      const optionIds = foundedVariation.options;
      const names = getNames(foundedProduct, optionIds, false);
      const sizeNames = getNames(foundedProduct, optionIds, true);
      const key = names.join(", ");
      if (!groupedVariations[key]) groupedVariations[key] = [];
      groupedVariations[key].push({ ...variation, sizeNames });
    } else {
      renderArray.push(<div key={`${variation.id}-${variation.variation_id}`}>Variación no encontrada</div>);
    }
  };

  const handleAuxiliaryOptions = (foundedProduct, auxiliaryRenderArray) => {
    const optionTypes = foundedProduct.options
      .filter((option) => option.type !== "Talla" && option.type !== "TALLA") // Filtrar opciones que no sean de tipo "Talla" o "TALLA"
      .map((option) => option.type)
      .join(" / ");

    if (optionTypes) {
      auxiliaryRenderArray.push(optionTypes);
    }
  };

  const getNames = (product, optionIds, isSize) => {
    return product.options
      .filter((option) =>
        option.display_names.some((displayName) => optionIds.includes(displayName.option_id)) && option.is_size === isSize
      )
      .flatMap((option) =>
        option.display_names
          .filter((displayName) => optionIds.includes(displayName.option_id))
          .map((displayName) => displayName.name)
      );
  };

  const renderCompleteComponent = (product, groupedVariations, auxiliaryRenderArray, renderArray) => {
    return (
      <div key={product.product_id}>
        <div className="mt-4 mb-4">
          <p className="font-extrabold text-2xl">{product.product_label}</p>
          {auxiliaryRenderArray.length > 0 && (
            <p className="text-2xl mt-2 mb-4 font-thin">{auxiliaryRenderArray[0]}</p>
          )}
          {Object.entries(groupedVariations).map(([key, variations]) => (
            <div key={key} className="mb-4">
              <div className="mr-2">
                <div className="font-bold flex">
                  {key.split(", ").map((name, nameIndex, arr) => (
                    <p className="flex text-lg font-extrabold" key={nameIndex}>
                      {name}
                      {nameIndex < arr.length - 1 ? " / " : " "}
                    </p>
                  ))}
                </div>
                <div className="flex flex-wrap">
                  {variations.map((variation) => (
                    <div key={`${variation.id}-${variation.variation_id}`} className="text-lg mr-4">
                      <div className="border-b-2 border-black w-10">
                        {variation.sizeNames.map((name, nameIndex) => (
                          <p className="flex font-bold justify-center" key={`${nameIndex}-${variation.id}`}>
                            {name}
                          </p>
                        ))}
                      </div>
                      <p className="font-thin flex justify-center p-2 w-10">
                        {variation.sku_req_qty}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
          {renderArray}
        </div>
      </div>
    );
  };

  const amountProducts = (products) => {
    const productMap = products.reduce((acc, product) => {
      if (!acc[product.product_id]) {
        acc[product.product_id] = {
          ...product,
          variations: [...product.variations]
        };
      } else {
        acc[product.product_id].variations.push(...product.variations);
      }
      return acc;
    }, {});

    return Object.values(productMap);
  };

  const agrupedProductsAndVariations = amountProducts(consolidatedOrder.products);

  return (
    <div className="bg-white rounded-xl shadow-xl p-4">
      <p className="text-grey-100">Pedido consolidado</p>
      <p>Fecha :{formatDate(consolidatedOrder?.created_at)}</p>
      <p>ID :{consolidatedOrder?.id}</p>
      {agrupedProductsAndVariations.map((product) => renderVariationInfo(product))}
    </div>
  );
};

ConsolidatedOrderCard.propTypes = {
  productsData: PropTypes.array,
  consolidatedOrder: PropTypes.object,
};

export default ConsolidatedOrderCard;
