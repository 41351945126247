import PropTypes from "prop-types"
import { MultiSelect } from "primereact/multiselect";
import styles from "./CustomMultiSelect.module.css";

export default function CustomMultiSelect(props) {
  const { value, updateField, onValue, options, optionLabel, placeholder, disabled, required, fieldNameToUpdate, floatLabel, id, itemTemplate} = props;

  const handleChange = (e) => {
    if (updateField && fieldNameToUpdate && onValue) {
      updateField(fieldNameToUpdate, e?.value);
      onValue(e?.value);
    }
  };
  if(floatLabel){
    return (
      <div className={styles.floatLabelContainer}>
        <span className="p-float-label">
        <MultiSelect
          value={value}
          onChange={handleChange}
          options={options}
          optionLabel={optionLabel}
          placeholder={placeholder}
          maxSelectedLabels={3}
          disabled={disabled}
          className={styles.customFormComponent}
          required={required}
          emptymessage={"No se encontraron resultados"}
        />
        <label htmlFor={id}>{placeholder}</label>
        </span>
      </div>
    )
  }else{
    return (
      <MultiSelect
          value={value}
          onChange={handleChange}
          options={options}
          optionLabel={optionLabel}
          placeholder={placeholder}
          maxSelectedLabels={3}
          disabled={disabled}
          required={required}
          itemTemplate={itemTemplate}
          className={styles.customFormComponent}
        />
    )
  }
}

CustomMultiSelect.propTypes = {
  value: PropTypes.any,
  updateField: PropTypes.func,
  onValue: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  optionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  fieldNameToUpdate: PropTypes.string,
  floatLabel: PropTypes.bool,
  id: PropTypes.string,

};