import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web';

export default function LottieAnimation({animationData, style={}}) {
  const animationContainer = useRef(null);
  let animation;

  useEffect(() => {
    // Función para manejar la intersección
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Agregar un retraso de 2 segundos antes de reproducir la animación
          setTimeout(() => {
            animation.play();
          }, 500);
        } else {
          animation.pause();
        }
      });
    };

    // Crear el Intersection Observer
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // El viewport
      rootMargin: '0px',
      threshold: 0.1 // El porcentaje de visibilidad del elemento
    });

    // Inicializar la animación de Lottie
    animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: false, // Desactivar el autoplay
      animationData: animationData
    });

    // Observar el contenedor de la animación
    if (animationContainer.current) {
      observer.observe(animationContainer.current);
    }

    // Limpiar la animación y el observer cuando el componente se desmonte
    return () => {
      animation.destroy();
      observer.disconnect();
    };
  }, []);

  return <div style={style} ref={animationContainer} id="lottie-animation"></div>;
}
