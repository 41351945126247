import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../utils/generateRandomId";
import MainButton from "../MainButton/MainButton";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import { useEffect, useRef, useState } from "react";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect";
import { Messages } from "primereact/messages";
import { collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import CustomModal from "../CustomModal/CustomModal";
import VinculateStoreCustomer from "../VinculateStoreCustomer/VinculateStoreCustomer.jsx";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import toastFunction from "../../utils/toastFunction";
import CustomChips from "../FormComponents/CustomChips/CustomChips.jsx";
import { defProvinces } from "../../utils/defProvinces.js";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import useMatchProvinceAndCity from "../../hooks/useMatchProvinceAndCity.js";

const CustomerForm = ({ selectedCustomerRowData, userData, toast, closeCustomerModal, isInsider }) => {
  const { typeEntitys, typeIdUserOptions, countries } = useGlobalContext();
  const {globalLoading, setGlobalLoading} = useLoaderContext()
  const { t } = useTranslation("MyCustomers");
  const { updateDocument, addToArray } = useFirebaseCRUD();
  const [selectedSellerStore, setSelectedSellerStore] = useState(selectedCustomerRowData?.related_stores_ids || []);
  const [phonesCart, setPhonesCart] = useState(selectedCustomerRowData?.phones || []);
  const hasEdit = !!Object?.keys(selectedCustomerRowData)?.length;
  const [emailsCart, setEmailsCart] = useState(selectedCustomerRowData?.emails || []);
  const [relatedStoreIdsCart, setRelatedStoreIdsCart] = useState(selectedCustomerRowData?.related_stores_ids || []);
  const [optionsToInsider, setOptionsToInsider] = useState([]);
  const [shippingAddressValues, setShippingAddressValues] = useState(() => {
    if (selectedCustomerRowData?.shipping_address) {
      return { ...selectedCustomerRowData.shipping_address[0] };
    } else {
      return {
        address_lines_one: "",
        address_lines_two: "",
        city: "",
        country: "",
        currrent_address: false,
        id: generateRandomId(),
        name: "",
        state: "",
        zip_code: "",
      };
    }
  });
  const [billingAddressValues, setBillingAddressValues] = useState(() => {
    if (selectedCustomerRowData?.billing_data) {
      return { ...selectedCustomerRowData.billing_data[0] };
    } else {
      return {
        entity_zip_code: "",
        entity_city: "",
        entity_state: "",
        entity_type: "",
        identification_type: "",
        entity_address: "",
        id: generateRandomId(),
        entity_email: "",
      };
    }
  });

  const [customerValues, setCustomerValues] = useState({
    id: selectedCustomerRowData.id || generateRandomId(),
    cltv: 0,
    created_at: selectedCustomerRowData.created_at || new Date(),
    emails: emailsCart,
    failed_orders: 0,
    first_names: selectedCustomerRowData.first_names || "",
    last_names: selectedCustomerRowData.last_names || "",
    has_different_billing_address: selectedCustomerRowData.has_different_billing_address || false,
    phones: phonesCart,
    related_stores_ids: relatedStoreIdsCart,
    shipping_address: [shippingAddressValues],
    billing_data: [billingAddressValues],
  });
  const [visibleModalVinculateCustomer, setVisibleModalVinculateCustomer] = useState(false);

  const [messagesShown, setMessagesShown] = useState(false);
  const msgsChip = useRef(null);

  const [emailsDocuments, setEmailsDocuments] = useState([]);
  const [phonesDocuments, setPhonesDocuments] = useState([]);

  const [hasBillingAddress, setHasBillingAddress] = useState(selectedCustomerRowData.has_different_billing_address || false);

  const [typeEntityBilling, setTypeEntityBilling] = useState({});
  const [identificationTypeBilling, setIdentificationTypeBilling] = useState({});
  const [foundedStoreByInsider, setFoundedStoreByInsider] = useState({});
  const [relatedStoreValues, setRelatedStoreValues] = useState({
    seller_id: null,
    related_store_id: null,
  });

  // Obtenemos la primera direccion de shipping y de billing para precargar la informacion
  const firstBillingData = selectedCustomerRowData?.billing_data?.length > 0 ? selectedCustomerRowData?.billing_data[0] : {};
  const firstCustomerShippingAddress = selectedCustomerRowData?.shipping_address?.[0] || {};

  const [ province, setProvince ] = useState(firstCustomerShippingAddress?.state || {});
  const [ city, setCity ] = useState(firstCustomerShippingAddress?.city || {});

  const [ billingProvince, setBillingProvince ] = useState(firstBillingData?.entity_state || {});
  const [ billingCity, setBillingCity ] = useState(firstBillingData.entity_city || {});

  const { cities, zones } = useMatchProvinceAndCity(province);
  const { cities: shippingBillingCities, zones: shippingBillingZones  } = useMatchProvinceAndCity(billingProvince);

  // console.log("Validacion selectedCustomerRowData", selectedCustomerRowData)
  // console.log("Validacion firstBillingData", firstBillingData)
  // console.log("Validacion billing ciudaddes y ciudad", billingCity)

  // Función que maneja el cambio en los datos de una fila (customer) para un campo específico (fieldName) con un nuevo valor (newValue)
  const handleCustomerRowChange = (fieldName, newValue) => {
    setCustomerValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
  };

  // Función para manejar el cambio en los números de teléfono del cliente
  const handleCustomerPhonesChipChange = (newValue) => {
    // Elimina espacios en blanco de cada número de teléfono
    const trimmedValues = trimArray(newValue);
    // Actualiza el estado local de los números de teléfono
    setPhonesCart(trimmedValues);
    // Actualiza el estado de customerValues con los números de teléfono actualizados
    setCustomerValues((prevValues) => ({
      ...prevValues,
      phones: trimmedValues,
    }));
  };

  // Función utilitaria para eliminar espacios en blanco de cada valor en un array
  const trimArray = (newValue) => {
    const trimmedValues = newValue.map((value) => value.replace(/\s+/g, ""));
    return trimmedValues;
  };

  // Función para manejar el cambio en los correos electrónicos del cliente
  const handleCustomerEmailsChipChange = (newValue) => {
    // Elimina espacios en blanco de cada dirección de correo electrónico
    const trimmedValues = trimArray(newValue);
    // Actualiza el estado local de las direcciones de correo electrónico
    setEmailsCart(trimmedValues);
    // Actualiza el estado de customerValues con las direcciones de correo electrónico actualizadas
    setCustomerValues((prevValues) => ({
      ...prevValues,
      emails: trimmedValues,
    }));
  };

  // Función para manejar el cambio en la dirección de envío del cliente
  const handleCustormerShippingAddressChange = (fieldName, value) => {
    // Actualiza el estado local de la dirección de envío
    setShippingAddressValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
    // Actualiza el estado de customerValues con la dirección de envío actualizada
    setCustomerValues((prevValues) => ({
      ...prevValues,
      shipping_address: [{ ...prevValues.shipping_address[0], [fieldName]: value }],
    }));
    if (fieldName === "state"){
      setCity({
          city_name: "Selecciona una ciudad",
          city_code: ""
        })
      setProvince(value)
    } else if ( fieldName === "city") {
      setCity(value);
    }
  };

  // Función para manejar el cambio en la dirección de facturación del cliente
  const handleCustormerBillingAddressChange = (fieldName, value) => {
    // console.log("validacion control BILLING", fieldName, value);
    // Verifica el nombre del campo y actualiza los estados correspondientes
    if (fieldName === "entity_type" || fieldName === "identification_type") {

      setBillingAddressValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value.id,
      }));
      // Actualiza el estado dependiendo del tipo de campo
      if (fieldName === "entity_type") {
        setTypeEntityBilling(value);
      } else if (fieldName === "identification_type") {
        setIdentificationTypeBilling(value);
      }
      // Actualiza el estado de customerValues con la dirección de facturación actualizada
      setCustomerValues((prevValues) => ({
        ...prevValues,
        billing_data: [{ ...prevValues?.billing_data[0], [fieldName]: value.id }],
      }));
    } else {
      if (fieldName === "entity_state"){
        setBillingCity({
            city_name: "Selecciona una ciudad",
            city_code: ""
          })
        setBillingProvince(value)
      } else if ( fieldName === "entity_city") {
        setBillingCity(value);
      }
      // Actualiza el estado local de la dirección de facturación
      setBillingAddressValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value,
      }));
      // Actualiza el estado de customerValues con la dirección de facturación actualizada
      setCustomerValues((prevValues) => ({
        ...prevValues,
        billing_data: [{...prevValues.billing_data[0], [fieldName]: value }],
      }));
    }
  };

  // Función para actualizar los campos relacionados con las tiendas
  const updateRelatedStoresField = (fieldValue, newValue) => {
    // Crear un nuevo conjunto para almacenar los IDs únicos
    const uniqueIdsSet = new Set([]);

    // Agregar los IDs de cada objeto en newValue al conjunto
    newValue.forEach((obj) => {
      if (obj && obj.id) {
        uniqueIdsSet.add(obj.id);
      }
    });

    // Actualizar relatedStoreIdsCart con el conjunto de IDs únicos
    const relatedStoresIds = Array.from(uniqueIdsSet);
    setRelatedStoreIdsCart(relatedStoresIds);

    // Actualizar el estado de customerValues con los IDs de las tiendas actualizados
    setCustomerValues((prevValues) => ({
      ...prevValues,
      related_stores_ids: relatedStoresIds,
    }));
  };

  // Función para encontrar tiendas coincidentes
  const findMatchingStores = (sellerStores, relatedStoreIds) => {
    // Filtra los objetos de seller_stores cuyos IDs coinciden con los IDs en related_stores_ids
    const matchingStores = sellerStores?.filter((store) => relatedStoreIds?.includes(store.id));
    return matchingStores;
  };

  // Función para mostrar mensajes
  const addMessage = () => {
    if(msgsChip.current) {

      msgsChip.current.show([
        {
          severity: "info",
          summary: "Info",
          detail: t("toast-detail.infoMsgsChipPhones"),
          sticky: true,
          closable: false,
        },
        {
          severity: "warn",
          summary: "Importante",
          detail: t("toast-detail.warnMsgsChipEmails"),
          sticky: true,
          closable: false,
        },
      ]);
    }
  };

  // Función para validar los campos necesarios
  const validateFieldsCustomerValues = (valuesCustomer) => {
    if (valuesCustomer.phones.length > 0 && valuesCustomer.emails.length > 0 && valuesCustomer.related_stores_ids.length > 0) {
      return true;
    } else {
      toastFunction(toast, "warn", t("toast-summary.allFieldsNotOk"), "", 3000);
      return false;
    }
  };

  // Función para filtrar emails y teléfonos válidos del cliente
  const filterEmailsAndPhonesCustomerValues = (customerValues) => {
    // Funciones de validación
    const isValidPhone = (phone) => /^\d+$/.test(phone);
    const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

    // Funciones para filtrar teléfonos y emails válidos
    const filterValidPhones = (phones) => phones?.filter((phone) => isValidPhone(phone.trim()));
    const filterValidEmails = (emails) => emails?.filter((email) => isValidEmail(email.trim()));

    // Filtrar teléfonos y emails válidos
    const validPhones = filterValidPhones(customerValues.phones);
    const validEmails = filterValidEmails(customerValues.emails);

    // Verificar si hay cambios en los teléfonos o correos electrónicos
    const phonesChanged = JSON.stringify(customerValues.phones) !== JSON.stringify(validPhones);
    const emailsChanged = JSON.stringify(customerValues.emails) !== JSON.stringify(validEmails);

    // Mostrar el toast solo si hay cambios
    if (phonesChanged || emailsChanged) {
      toastFunction(toast, "info", t("toast-summary.emailsAndPhonesRepeated"), "", 4000);
    }

    // Devolver valores actualizados
    return { ...customerValues, phones: validPhones, emails: validEmails };
  };

  // Función para filtrar emails y teléfonos válidos del cliente considerando documentos existentes
  const filterEmailsAndPhonesCustomerValuesRepeatOnDb = (customerValues, emailsDocuments, phonesDocuments) => {
    // Funciones de validación
    const isValidPhone = (phone) => /^\d+$/.test(phone);
    const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

    // Funciones para filtrar teléfonos y emails válidos
    const filterValidPhones = (phones) => phones?.filter((phone) => isValidPhone(phone.trim()));
    const filterValidEmails = (emails) => emails?.filter((email) => isValidEmail(email.trim()));

    // Filtrar teléfonos y emails válidos
    let validPhones = filterValidPhones(customerValues.phones);
    let validEmails = filterValidEmails(customerValues.emails);

    // Mapear por cada email en emailsDocuments y quitarlo de validEmails si coincide
    emailsDocuments.forEach((document) => {
      if (customerValues.id !== document.id) {
        document.emails.forEach((emailValue) => {
          validEmails = validEmails?.filter((email) => email !== emailValue);
        });
      }
    });

    // Mapear por cada teléfono en phonesDocuments y quitarlo de validPhones si coincide
    phonesDocuments.forEach((document) => {
      if (customerValues.id !== document.id) {
        document.phones.forEach((phoneValue) => {
          validPhones = validPhones?.filter((phone) => phone !== phoneValue);
        });
      }
    });

    // Devolver valores actualizados
    return { ...customerValues, phones: validPhones, emails: validEmails };
  };
  const foundStoreBySellerIdAndStoreId = async () => {
    const sellerCollection = collection(db, "seller_users");
    const sellerDocRef = doc(sellerCollection, relatedStoreValues.seller_id);
    const sellerDoc = await getDoc(sellerDocRef);
    if (sellerDoc.exists(sellerDoc)) {
      const sellerData = sellerDoc?.data();
      if (sellerData.seller_stores) {
        const relatedStoreIdAlreadyExistInSeller = customerValues.related_stores_ids.some(
          (store) => store.id === relatedStoreValues?.related_store_id
        );
        if (relatedStoreIdAlreadyExistInSeller) {
          toastFunction(toast, "warn", "El ID de la tienda ya existe en el seller", relatedStoreValues.seller_id, 3000);
          return null;
        } else {
          const foundedStore = sellerData?.seller_stores.find((store) => {
            return store?.id === relatedStoreValues?.related_store_id;
          });
          if (foundedStore) {
            setFoundedStoreByInsider(foundedStore);
            const foundedStoreId = foundedStore.id;
            setCustomerValues((prevValues) => ({
              ...prevValues,
              related_stores_ids: [foundedStoreId],
            }));
            return foundedStoreId;
          } else {
            console.log("No encontre la tienda");
            toastFunction(
              toast,
              "warn",
              "El vendedor no es propietario de esta tienda",
              relatedStoreValues.related_store_id,
              3000
            );
            return null;
          }
        }
      } else {
        console.log("El Seller no tiene tiendas");
        toastFunction(toast, "warn", "El vendedor no tiene tiendas", relatedStoreValues.related_store_id, 3000);
        return null;
      }
    } else {
      console.log("El documento del Seller no existe");
      toastFunction(toast, "warn", "El ID del vendedor no es correcto", relatedStoreValues.seller_id, 3000);
      return null;
    }
  };
  // Función para manejar la creación, actualización y validación de clientes
  const handleCRUDCustomer = async (e) => {
    setGlobalLoading(true);
    e.preventDefault();
    let foundedStoreId;
    let allowCreate;
    let validationFields;
    const addRelatedStoreIdByInsider = isInsider && relatedStoreValues.seller_id && relatedStoreValues.related_store_id;
    if (addRelatedStoreIdByInsider) {
      foundedStoreId = await foundStoreBySellerIdAndStoreId();
      validationFields =
        validateFieldsCustomerValues({ ...customerValues, related_stores_ids: [foundedStoreId] }) && !!foundedStoreId;
    } else {
      validationFields = validateFieldsCustomerValues(customerValues);
    }
    try {
      const customersRef = collection(db, "customers"); // Validar los campos del cliente
      if (validationFields) {
        // Consultar clientes con los mismos correos electrónicos
        const qEmails = query(customersRef, where("emails", "array-contains-any", customerValues.emails), orderBy("created_at", "asc"));

        // Consultar clientes con los mismos números de teléfono
        const qPhones = query( customersRef, where("phones", "array-contains-any", customerValues.phones), orderBy("created_at", "asc"));

        // Obtener resultados de ambas consultas de forma asíncrona
        const [emailsSnapshot, phonesSnapshot] = await Promise.all([getDocs(qEmails), getDocs(qPhones)]);

        // Obtener datos de documentos encontrados
        const emailsDocumentsData = emailsSnapshot.docs.map((doc) => doc.data());
        const phonesDocumentsData = phonesSnapshot.docs.map((doc) => doc.data());

        // Actualizar estados con los documentos encontrados
        setEmailsDocuments(emailsDocumentsData);
        setPhonesDocuments(phonesDocumentsData);

        // Validar si se permite la creación del cliente
        if (
          phonesDocumentsData.length === 0 && phonesDocumentsData.length === 0 &&emailsDocumentsData.length === 0 &&
          emailsDocumentsData.length === 0
        ) {
          allowCreate = true;
        }
        // Validar si se trata de una actualización y no se permite la creación
        if (hasEdit) {
          if (!allowCreate) {
            // Filtrar valores del cliente para evitar repetición de correos y teléfonos
            const filteredCustomerValuesWithoutRepeatedEmailsAndPhonesOnDb = filterEmailsAndPhonesCustomerValuesRepeatOnDb(
              customerValues,
              emailsDocumentsData,
              phonesDocumentsData
            );

            // Validar campos y actualizar el documento del cliente
            if (validateFieldsCustomerValues(customerValues)) {
              delete filteredCustomerValuesWithoutRepeatedEmailsAndPhonesOnDb.related_stores_ids;
              const resultUpdate = await updateDocument(
                "customers",
                customerValues.id,
                filteredCustomerValuesWithoutRepeatedEmailsAndPhonesOnDb
              );

              if (addRelatedStoreIdByInsider) {
                await addToArray("customers", customerValues.id, "related_stores_ids", foundedStoreId);
              } else {
                for (const storeId of relatedStoreIdsCart) {
                  await addToArray("customers", customerValues.id, "related_stores_ids", storeId);
                }
              }
              // Mostrar mensaje de éxito o error según el resultado de la actualización
              if (resultUpdate) {
                toastFunction(toast, "success", t("toast-summary.customerUpdateSuccess"), "", 3000);
              } else {
                toastFunction(toast, "error", t("toast-summary.customerUpdateError"), "", 3000);
              }
              closeCustomerModal();
            }
          }
        } else {
          // Proceso para la creación de un nuevo cliente
          if (allowCreate) {
            let newBillingData = {};

            // Filtrar valores del cliente para evitar repetición de correos y teléfonos
            const filteredCustomerValues = filterEmailsAndPhonesCustomerValues(customerValues);

            // Obtener nuevos correos y teléfonos
            const newEmails = filteredCustomerValues.emails;
            const newPhones = filteredCustomerValues.phones;

            // Actualizar estados con los nuevos correos y teléfonos
            setPhonesCart(newPhones);
            setEmailsCart(newEmails);

            // Actualizar estado del cliente con los nuevos valores filtrados
            setCustomerValues((prevValues) => ({ ...prevValues, ...filteredCustomerValues }));

            // Crear nueva información de facturación si no se utiliza una diferente
            if (!customerValues.has_different_billing_address) {
              newBillingData = {
                entity_zip_code: shippingAddressValues.zip_code,
                entity_city: shippingAddressValues.city,
                entity_type: "",
                entity_state: shippingAddressValues.country,
                identification_type: "",
                entity_address: shippingAddressValues.address_lines_one + " " + shippingAddressValues.address_lines_two,
                id: generateRandomId(),
                entity_email: customerValues.emails[0],
              };
            }
            // Validar campos y crear un nuevo documento de cliente
            const customersRef = collection(db, "customers");
            const newCustomerId = customerValues.id;
            const customDocRef = doc(customersRef, newCustomerId);

            try {
              // Establecer el nuevo documento del cliente
              if (validateFieldsCustomerValues(filteredCustomerValues) && !addRelatedStoreIdByInsider) {
                await setDoc(customDocRef, { ...filteredCustomerValues, billing_data: [newBillingData] });
                closeCustomerModal();
              } else if (addRelatedStoreIdByInsider && validationFields) {
                await setDoc(customDocRef, {
                  ...filteredCustomerValues,
                  related_stores_ids: [foundedStoreId],
                  billing_data: [newBillingData],
                });
                closeCustomerModal();
                toastFunction(toast, "success", t("toast-summary.customerCreateSuccess"), "", 3000);
              }
              // Cerrar modal después de la creación exitosa
            } catch (error) {
              toastFunction(toast, "error", t("toast-summary.createCustomerError"), "", 3000);
            }
          } else {
            // Mostrar modal de vinculación de clientes si no se permite la creación
            setVisibleModalVinculateCustomer(true);
          }
        }
      } else {
        toastFunction(toast, "error", t("Los datos no estan correctos"), "", 3000);
      }
    } catch (error) {
      console.error("Error CRUD:", error);
    }
    setGlobalLoading(false);
  };
  // Función para cerrar el modal de vinculación de clientes
  const closeVinculateCustomerModal = () => {
    setVisibleModalVinculateCustomer(false);
  };

  // Función para alternar el estado de la dirección de facturación
  const toggleBillingAddress = () => {
    setHasBillingAddress((prevHasBillingAddress) => !prevHasBillingAddress);
    setCustomerValues((prevCustomerValues) => ({
      ...prevCustomerValues,
      has_different_billing_address: !prevCustomerValues.has_different_billing_address,
    }));
  };


  const [isReadyToRender, setIsReadyToRender] = useState(false);

    // Función para realizar los cálculos y actualizaciones de estado
  const performCalculationsAndSetState = () => {
    let matchingStores;
    
    if (isInsider && selectedCustomerRowData.id) {
      matchingStores = selectedCustomerRowData?.related_stores_ids.map((id) => {
        return { name: id };
      });
      setOptionsToInsider(matchingStores);
      setSelectedSellerStore(matchingStores);
    } else {
      matchingStores = findMatchingStores(userData?.seller_stores, selectedCustomerRowData?.related_stores_ids);
      setSelectedSellerStore(matchingStores);
    }

    if (hasEdit) {
      
      const matchingIdentificationType = typeIdUserOptions?.find((identificationType) => {
        return identificationType.id === firstBillingData?.identification_type;
      });

      const matchingEntityType = typeEntitys?.find((typeEntity) => {
        return typeEntity.id === firstBillingData?.entity_type;
      });
  
      setIdentificationTypeBilling(matchingIdentificationType);
      setTypeEntityBilling(matchingEntityType);
    }

    // Marca que está listo para renderizar
      setIsReadyToRender(true);
  };


  useEffect(() => {
    // Llama a la función para realizar cálculos y actualizar estados
    performCalculationsAndSetState();
  }, [cities, shippingBillingCities, province]);



  useEffect(() => {
    const delay = setTimeout(() => {
      if (!messagesShown) {
        addMessage();
        setMessagesShown(true);
      }
    }, 100);
  
    return () => clearTimeout(delay);
  }, [messagesShown]);



  // console.log("Render Fuera", cities, shippingBillingCities,)
  // console.log( "VALIDACION Provincia", province,  defProvinces(zones))

  return (
    <>
        {isReadyToRender && (

     <form onSubmit={handleCRUDCustomer} style={{ marginBottom: "40px", display: "grid", gap: "20px" }}>
        <CustomMultiSelect
          optionLabel={"name"}
          options={isInsider ? optionsToInsider : userData.seller_stores}
          value={selectedSellerStore}
          onValue={setSelectedSellerStore}
          placeholder={t("input-placeholders.applicableStores")}
          updateField={updateRelatedStoresField}
          fieldNameToUpdate={"related_stores_ids"}
          required={true}
          disabled={isInsider || !userData?.seller_stores || userData?.seller_stores?.length === 0 || globalLoading}
        />
        {isInsider && (
          <>
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={relatedStoreValues.seller_id}
              placeholder={t("input-placeholders.sellerIdOwners")}
              onChange={(e) => setRelatedStoreValues({ ...relatedStoreValues, seller_id: e.target.value })}
            />
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={relatedStoreValues.related_store_id}
              placeholder={t("input-placeholders.storeIdOfSellerOwner")}
              onChange={(e) => setRelatedStoreValues({ ...relatedStoreValues, related_store_id: e.target.value })}
            />
          </>
        )}

        <CustomInputText
          floatLabel={true}
          type={"text"}
          value={customerValues.first_names}
          placeholder={t("input-placeholders.firstName")}
          onChange={(e) => handleCustomerRowChange("first_names", e.target.value)}
          disabled={globalLoading}
          required={true}
        />
        <CustomInputText
          floatLabel={true}
          type={"text"}
          value={customerValues.last_names}
          placeholder={t("input-placeholders.lastName")}
          onChange={(e) => handleCustomerRowChange("last_names", e.target.value)}
          disabled={globalLoading}
          required={true}
        />
        {/* carrito telefonos */}
        <Messages ref={msgsChip} />
        <CustomChips
          floatLabel={true}
          type="number"
          value={phonesCart.map((num) => num)}
          onChange={(e) => handleCustomerPhonesChipChange(e.target.value)}
          separator=","
          keyfilter="num"
          // disabled={!selectAttributeOrders}
          placeholder={t("input-placeholders.phones")}
          disabled={globalLoading}
        />
        {/* carrito correos */}
        <CustomChips
          floatLabel={true}
          type="email"
          value={emailsCart.map((email) => email)}
          onChange={(e) => handleCustomerEmailsChipChange(e.target.value)}
          separator=","
          // disabled={!selectAttributeOrders}
          placeholder={t("input-placeholders.emails")}
          disabled={globalLoading}
        />
        <div className="">
          <h4>Direccion de envio</h4>
          <CustomInputText
            floatLabel={true}
            type={"text"}
            value={customerValues?.shipping_address[0]?.name}
            placeholder={t("input-placeholders.addressAlias")}
            onChange={(e) => handleCustormerShippingAddressChange("name", e.target.value)}
            disabled={globalLoading}        
            required={true}
          />
          <CustomInputText
            floatLabel={true}
            type={"text"}
            value={customerValues?.shipping_address[0]?.address_lines_one}
            placeholder={t("input-placeholders.address1")}
            onChange={(e) => handleCustormerShippingAddressChange("address_lines_one", e.target.value)}
            // disabled={dissabledTagName}
            required={true}
            disabled={globalLoading}
          />
          <CustomInputText
            floatLabel={true}
            type={"text"}
            value={customerValues?.shipping_address[0]?.address_lines_two}
            placeholder={t("input-placeholders.address2")}
            onChange={(e) => handleCustormerShippingAddressChange("address_lines_two", e.target.value)}
            disabled={globalLoading}
          />
          <CustomSelect
            floatLabel={true}
            optionLabel={"name"}
            options={countries}
            valueTemplate={""}
            itemTemplate={""}
            value={customerValues?.shipping_address[0]?.country}
            placeholder={"Pais"}
            onChange={(e) => handleCustormerShippingAddressChange("country", e.target.value)}
            disabled={globalLoading}
            required={true}
          />
          <CustomSelect
            floatLabel={true}
            optionLabel={"province_name"}
            options={defProvinces(zones)}
            valueTemplate={""}
            itemTemplate={""}
            value={province}
            placeholder={"Departamento"}
            onChange={(e) => handleCustormerShippingAddressChange("state", e.target.value)}
            disabled={globalLoading}
          />
          
          {/* Select de ciudades */}
          <CustomSelect
            floatLabel={true}
            optionLabel={"city_name"}
            options={cities}
            valueTemplate={""}
            itemTemplate={""}
            value={city}
            placeholder={"Ciudad"}
            onChange={(e) => handleCustormerShippingAddressChange("city", e.target.value)}
            disabled={globalLoading}
          />

          <CustomInputText
            floatLabel={true}
            type={"text"}
            value={customerValues?.shipping_address[0]?.zip_code}
            placeholder={t("input-placeholders.zipCode")}
            onChange={(e) => handleCustormerShippingAddressChange("zip_code", e.target.value)}
            disabled={globalLoading}
          />
        </div>
        <div>
          <h5>Usar una direccion de facturacion diferente</h5>
          <CustomInputSwitch checked={hasBillingAddress} onChange={toggleBillingAddress} 
            disabled={globalLoading}
          />
        </div>
        {hasBillingAddress && (
          <div className="">
            <h4>Direccion de Facturacion</h4>
          
          <CustomSelect
            floatLabel={true}
            optionLabel={"province_name"}
            options={defProvinces(shippingBillingZones)}            
            valueTemplate={""}
            itemTemplate={""}
            value={billingProvince}
            placeholder={t("input-placeholders.entityState")}
            required={true}
            onChange={(e) => handleCustormerBillingAddressChange("entity_state", e.target.value)}
            disabled={globalLoading}
          />
          
          {/* Select de ciudades */}
          <CustomSelect
            floatLabel={true}
            optionLabel={"city_name"}
            options={shippingBillingCities}
            valueTemplate={""}
            itemTemplate={""}
            value={billingCity}
            placeholder={t("input-placeholders.entityCity")}
            onChange={(e) => handleCustormerBillingAddressChange("entity_city", e.target.value)}
            disabled={globalLoading}
            required={true}
          />

            <CustomSelect
              floatLabel={true}
              optionLabel={"name"}
              options={typeEntitys}
              valueTemplate={""}
              itemTemplate={""}
              // value={customerValues?.billing_data[0]?.entity_type}
              value={typeEntityBilling}
              placeholder={t("input-placeholders.entityType")}
              onChange={(e) => handleCustormerBillingAddressChange("entity_type", e.target.value)}
              disabled={globalLoading}
              required={true}
            />
            <CustomSelect
              floatLabel={true}
              optionLabel={"name"}
              options={typeIdUserOptions}
              valueTemplate={""}
              itemTemplate={""}
              value={identificationTypeBilling}
              placeholder={t("input-placeholders.identificationType")}
              onChange={(e) => handleCustormerBillingAddressChange("identification_type", e.target.value)}
              disabled={globalLoading}
              required={true}
            />
            <CustomInputText
              floatLabel={true}
              type={"email"}
              value={customerValues?.billing_data[0]?.entity_email}
              placeholder={t("input-placeholders.entityEmail")}
              onChange={(e) => handleCustormerBillingAddressChange("entity_email", e.target.value)}
              disabled={globalLoading}
              // disabled={dissabledTagName}
              required={true}
            />
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={customerValues?.billing_data[0]?.entity_address}
              placeholder={t("input-placeholders.entityAddress")}
              onChange={(e) => handleCustormerBillingAddressChange("entity_address", e.target.value)}
              disabled={globalLoading}
              required={true}
            />
            <CustomInputText
              floatLabel={true}
              type={"text"}
              value={customerValues.billing_data[0].entity_zip_code}
              placeholder={t("input-placeholders.entityZipCode")}
              onChange={(e) => handleCustormerBillingAddressChange("entity_zip_code", e.target.value)}
              disabled={globalLoading}
            />
          </div>
        )}
        <MainButton 
          loading={globalLoading} 
          disabled={globalLoading}
          type="submit" label={hasEdit ? t("updateCustomer") : t("createCustomer")} 
        />
          <CustomModal
            visible={visibleModalVinculateCustomer}
            setVisible={setVisibleModalVinculateCustomer}
            createMode={t("vinculateCustomerStores")}
            content={
              <VinculateStoreCustomer
                toast={toast}
                onClose={closeVinculateCustomerModal}
                sellerStores={userData.seller_stores}
                emailsDocuments={emailsDocuments}
                phonesDocuments={phonesDocuments}
                closeCustomerModal={closeCustomerModal}
                isInsider={isInsider}
                foundedStoreByInsider={foundedStoreByInsider}
              />
            }
            onClose={closeVinculateCustomerModal}
          />
      </form>
          )}

    </>
    
  );
};
CustomerForm.propTypes = {
  zonesData: PropTypes.array,
  selectedCustomerRowData: PropTypes.shape({
    id: PropTypes.string,
    first_names: PropTypes.string,
    last_names: PropTypes.string,
    related_stores_ids: PropTypes.array,
    phones: PropTypes.array,
    emails: PropTypes.array,
    has_different_billing_address: PropTypes.bool,
    created_at: PropTypes.object,
    shipping_address: PropTypes.arrayOf(
      PropTypes.shape({
        address_lines_one: PropTypes.string,
        address_lines_two: PropTypes.string,
        city: PropTypes.object,
        country: PropTypes.object,
        currrent_address: PropTypes.bool,
        id: PropTypes.string,
        name: PropTypes.string,
        state: PropTypes.string,
        zip_code: PropTypes.string,
      })
    ),
    billing_data: PropTypes.arrayOf(
      PropTypes.shape({
        entity_address: PropTypes.string,
        entity_city: PropTypes.string,
        entity_email: PropTypes.string,
        entity_state: PropTypes.string,
        entity_type: PropTypes.bool,
        id: PropTypes.string,
        identification_type: PropTypes.string,
        zip_code: PropTypes.string,
      })
    ),
  }),
  userData: PropTypes.object,
  toast: PropTypes.object,
  closeCustomerModal: PropTypes.func,
  isInsider: PropTypes.bool,
};
export default CustomerForm;
