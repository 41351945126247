import { ProgressSpinner } from "primereact/progressspinner";
import PropTypes from "prop-types";
import styles from "./DetailCheckShopifyOrder.module.css";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import VideoModal from "../VideoModal/VideoModal";
import { BiCheckCircle } from "react-icons/bi";
import toastFunction from "../../utils/toastFunction";

const DetailCheckShopifyOrder = ({
  userData,
  selectedRowStoreData,
  toast,
}) => {
  const { t } = useTranslation("Stores");
  const { VIDEO_SHOPIFY_CHECK_ORDERS__TUTORIAL } = useGlobalContext();

  const [hasProductsConnection, setHasCheckedProductsConnection] = useState(false);
  const testVerifyConnection = async (e) => {
    e.preventDefault();
    //  setHasCheckedProductsConnection(true);
  }
  useEffect(() => {
    if (userData?.seller_stores) {
      const storeToCheck = userData.seller_stores.find((store) => store.id === selectedRowStoreData.id);
      storeToCheck?.test_order_notification_status === true ? setHasCheckedProductsConnection(true): setHasCheckedProductsConnection(false)
      toastFunction(toast, "success", t("toast-summary.succefullConnection"), "", 3000);
    }
  }, [userData]);
  return (
    <form className={styles.containerInputs}
      onSubmit={testVerifyConnection}
    >
      <div className="flex justify-center">
        {hasProductsConnection ? (
          <div className="">
            <h4 className="text-green-600"> Conexion exitosa </h4>
            <div className="flex justify-center">
              <BiCheckCircle
                style={{
                  backgroundColor: "var(--main-color)",
                  borderRadius: "100%",
                  fontSize: "var(--font-size-h2)",
                }}
              />
            </div>
          </div>
        ) : (
          <div className=" ">
            <h4 className="text-green-600"> Esperando el pedido de prueba </h4>
            <div className="flex justify-center">
              <ProgressSpinner />
            </div>
          </div>
        )}
      </div>
      <div>
        <VideoModal videoUrl={VIDEO_SHOPIFY_CHECK_ORDERS__TUTORIAL} />
      </div>
      {/* <MainButton
          type={"onSubmit"}
          onClick={testVerifyConnection}
          label={t("verifyFunction")}
          autoFocus
        /> */}
    </form>
  );
};

DetailCheckShopifyOrder.propTypes = {
  selectedRowStoreData: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  index: PropTypes.number,
  toast: PropTypes.object,
};

export default DetailCheckShopifyOrder;
