import React from 'react';

export default function Privacy() {
  return (
    <div>
      <h2 style={{lineHeight:"1", marginBottom:"30px"}}>POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES</h2>
      <p>El sitio web WWW.SUPPLAII.COM, es una página propiedad de SUPPLAII, persona jurídica, domiciliada en Colombia, regida por la ley colombiana y los presentes Términos y Condiciones, los cuales el USUARIO se obliga a respetar desde el momento de ingreso al sitio. Si el USUARIO no está de acuerdo con cualquiera de los presentes Términos y Condiciones deberá abstenerse de ingresar al sitio web o hacer uso de sus servicios.</p>
      
      <h3>1. Definiciones</h3>
      <ul>
        <li><strong>Usuario:</strong> persona que ingresa al sitio web de SUPPLAII o hace uso de cualquiera de sus servicios.</li>
        <li><strong>Sitio web:</strong> Dirección URL (WWW.SUPPLAII.COM) propiedad de SUPPLAII y al cual aplican los presentes términos y condiciones.</li>
        <li><strong>Propietario:</strong> SUPPLAII, quien tiene el control sobre el contenido que se ingresa, elimina o permanece en el sitio, así como de la publicación del mismo.</li>
        <li><strong>Tratamiento de datos:</strong> Cualquier operación u operaciones (recolectar, almacenar, analizar, transmitir, eliminar, etc) que se realice con datos personales.</li>
        <li><strong>Responsable del tratamiento:</strong> Quien decide sobre el tratamiento que se le da a los datos personales de los usuarios, que para efectos de estos términos y condiciones será SUPPLAII.</li>
        <li><strong>Dato personal:</strong> Cualquier información asociada o que pueda asociarse a una o varias personas determinadas o determinables.</li>
        <li><strong>Titular:</strong> Persona cuyos datos personales sean tratados.</li>
      </ul>

      <h3>2. Política de tratamiento de datos personales</h3>
      <p>Al suministrar datos personales y aceptar su tratamiento el USUARIO autoriza a que el sitio web y SUPPLAII almacenen, transmitan, transfieran a cualquier título la información proporcionada. Así mismo acepta su uso para fines de prestar un mejor servicio, usos comerciales, publicitarios y la comunicación de ofertas del sitio web, SUPPLAII y terceros a los que les haya sido transferida la información a cualquier título.</p>
      <p>Al proporcionar sus datos personales el USUARIO acepta que estos hagan parte de la base de datos que genera el sitio web o SUPPLAII y que ésta sea incluida, junto con su información, en los activos del sitio web o SUPPLAII para las operaciones de venta, fusión, escisión o cualquier otra operación comercial.</p>

      <h3>Obligaciones del propietario</h3>
      <ul>
        <li>Medidas de protección: para garantizar la privacidad de los datos personales proporcionados por el USUARIO, el propietario se obliga a tomar las medidas de protección necesarias para evitar el acceso no autorizado a dicha información. Estas medidas se ajustarán en función del tipo de información proporcionada pero en ningún caso se asume una obligación de resultado frente a la protección de dichos datos. En caso de ingreso no autorizado, vulneración, o falla de los servicios de seguridad el propietario se compromete a solucionar el problema dentro de un tiempo razonable, mas no será responsable por los daños producidos al USUARIO o terceros por la violación de las medidas de seguridad dispuestas o el acceso abusivo al sistema informático de SUPPLAII.</li>
        <li>Control de la información: el USUARIO podrá en cualquier momento solicitar la corrección, rectificación, o actualización de la información proporcionada. Cuando la Superintendencia de Comercio encuentre que el propietario ha incumplido con la política de protección de datos acá establecida o con los mínimos señalados por la ley el USUARIO podrá solicitar la supresión de sus datos personales tanto del sitio web como de las bases de datos de SUPPLAII y terceros a los que les haya sido transferida a cualquier título.</li>
        <li>Términos de respuesta: el propietario responderá en un máximo de 10 (diez) días hábiles las peticiones sobre consulta de datos personales que sean elevadas por el USUARIO a través de los canales establecidos para ello. Cuando la petición sea sobre corrección, actualización o supresión de los datos el término de respuesta será de 15 (quince) días hábiles.</li>
        <li>Canales de atención: para solicitudes sobre actualización, rectificación, actualización o supresión de sus datos personales el USUARIO podrá dirigirse a la dirección de correo electrónico HI@SUPPLAII.COM y al teléfono +57 319 2902823.</li>
        <li>Copia de autorización: el propietario conservará copia de la autorización otorgada por el USUARIO para el tratamiento de sus datos personales y hará entrega de la misma dentro de los 10 (diez) días hábiles siguientes a la solicitud realizada por el USUARIO. Los términos comenzarán a contar desde el día hábil siguiente a la fecha de realización de la solicitud.</li>
        <li>Revelación de información: SUPPLAII podrá dar a conocer los datos personales del USUARIO cuando sea requerida por autoridades administrativas o judiciales competentes.</li>
      </ul>

      <h3>3. Propiedad intelectual</h3>
      <p>El contenido del sitio web (artículos, imágenes, vídeos, logos, diseños, entre otros) es propiedad de SUPPLAII y sólo podrán ser consultados por el USUARIO para fines personales. Cualquier descarga, reproducción, almacenamiento, transmisión, transferencia para permitir el acceso a terceros, ya sea para fines personales o comerciales se encuentra prohibido.</p>
      <p>Cuando en el sitio web se presente contenido de terceros (artículos, imágenes, opiniones, vídeos, logos diseños, entre otros) esto se hace con la autorización de los autores del contenido y su descarga, reproducción, almacenamiento, transmisión o transferencia a cualquier título por parte del USUARIO queda sujeto a las mismas restricciones del párrafo anterior.</p>
      <p>Sobre los contenidos que el USUARIO ingrese en el sitio web y que no constituyan datos personales (opiniones, comentarios, imágenes, vídeos, logos, diseños, entre otros) el USUARIO otorga a el sitio y a SUPPLAII licencia para su uso sin restricción alguna, incluyendo, pero no limitándose al almacenamiento, reproducción, transferencia a cualquier título, oneroso, gratuito, exhibición, entre otros.</p>

      <h3>4. Calidad de la información proporcionada</h3>
      <p>El contenido publicado en el sitio web por cualquier medio o formato (texto, video, imágenes, etc) cumple propósitos meramente enunciativos y no constituyen asesoría o consejo sobre cualquiera de los temas que traten. SUPPLAII no garantiza la actualidad o veracidad de la información contenida, sin perjuicio de aquella que constituya oferta comercial o parte de ésta de conformidad con la normatividad colombiana.</p>
      <p>SUPPLAII no garantiza la actualidad o veracidad de la información contenida en sitios web, archivos, aplicaciones, y demás contenido de terceros a los que el USUARIO sea redirigido desde el sitio web de su propiedad, así como del contenido aportado por otros usuarios en los espacios dispuestos para ello. El ingreso y uso de los sitios, archivos, aplicaciones y similares a los que sea redirigido el USUARIO desde WWW.SUPPLAII.COM estarán sujetos a los términos y condiciones de los mismos, sobre los que SUPPLAII no tiene responsabilidad alguna. SUPPLAII tampoco garantiza que el contenido de sitios web, archivos o aplicaciones de terceros cumpla con la legislación colombiana en materia de protección de datos o que el contenido allí presentado sea legal bajo la normatividad colombiana.</p>
      <p>SUPPLAII no se hace responsable por los daños o perjuicios que pudiera causar al USUARIO o terceros la inexactitud, error, o veracidad de la información proporcionada en el sitio web o sitios, aplicaciones, archivos, documentos y contenido de terceros a los cuales sea redirigido el USUARIO, así como fallas parciales o completas en el funcionamiento del mismo, códigos maliciosos que terceros incluyeran en el sitio o sus archivos. Lo anterior sin perjuicio de aquella información que constituya oferta comercial en WWW.SUPPLAII.COM de conformidad con la normatividad colombiana.</p>

      <h3>5. Moderación</h3>
      <ul>
        <li>Para garantizar el adecuado funcionamiento del sitio web y una experiencia segura para los usuarios, SUPPLAII podrá:</li>
        <ul>
          <li>Cambiar en cualquier momento los presentes Términos y Condiciones, así como la política de privacidad del sitio web.</li>
          <li>Negar el registro de un USUARIO.</li>
          <li>Eliminar en cualquier momento los datos personales del USUARIO de sus bases de datos.</li>
          <li>Eliminar el contenido que el USUARIO haya ingresado a la página, incluyendo pero no limitándose a comentarios, imágenes, logos, vídeos, planos, diseños, entre otros.</li>
          <li>Suspender de manera temporal o permanente la publicación del sitio web, así como cualquiera de los servicios que preste.</li>
          <li>Eliminar sin previo aviso una parte o todo el contenido agregado por el USUARIO al sitio cuando este viole los presentes términos y condiciones o la legislación colombiana.</li>
        </ul>
        <li>El USUARIO, para garantizar el adecuado funcionamiento del sitio y una experiencia segura para los demás deberá:</li>
        <ul>
          <li>Acceder sólo a las secciones del sitio para las cuales ha sido autorizado.</li>
          <li>Abstenerse de proporcionar datos personales falsos, o que induzcan a error al sitio web, SUPPLAII a otros usuarios o a terceros, o que pretendan impersonarlos.</li>
          <li>Abstenerse de realizar actividades comerciales o promocionales en los espacios en que el sitio le permita agregar contenido, a menos que se autorice expresamente.</li>
          <li>Abstenerse de descargar, reproducir, duplicar, almacenar exhibir y en general disponer del contenido pago del sitio para su exhibición o acceso por cualquier medio externo, ya sea con fines personales o comerciales.</li>
          <li>Abstenerse de proporcionar sus credenciales de ingreso (CUENTA DE GMAIL, CORREO ELECTRÓNICO, NÚMERO CELULAR, Y CONTRASEÑA) para que terceros diferentes del USUARIO accedan a los contenidos de pago del sitio.</li>
        </ul>
      </ul>

      <h3>6. Política de cookies</h3>
      <p>Las cookies son archivos que se guardan en el computador o dispositivo del USUARIO al visitar el sitio web. Estos archivos contienen información sobre las preferencias del USUARIO, datos de login en diferentes sitios web así como páginas visitadas, clicks dados e información con que se rellenaron formularios, entre otros. Las cookies ayudan al sitio a funcionar más rápidamente y prestar un mejor servicio.</p>
      <p>El USUARIO puede deshabilitar el uso de cookies para sitios específicos desde la configuración de su navegador web. Al no deshabilitarlos para el sitio web de SUPPLAII acepta su uso.</p>
      <p>El deshabilitar el uso de cookies puede causar que el sitio no funcione de manera adecuada, con poca velocidad, con errores o que presente fallas que no permitan el uso por parte del USUARIO.</p>
    </div>
  );
}
