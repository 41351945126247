// Función para obtener ciudades basadas en el province_code seleccionado
export function getCitiesByProvince(provinceCode, zones) {
  if (!provinceCode || !zones) {
    return [];
  }

  // Filtrar las zonas basadas en el province_code
  const cities = zones.filter(zone => zone?.province_code === provinceCode)
                      .map(zone => ({
                        city_code: zone.city_code,
                        city_name: zone.city_name
                      }));

  return cities.length > 0 ? cities : [];
}
