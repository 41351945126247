export function defProvinces(zones) {
    const uniqueProvincesArray = [];

    zones?.forEach(zone => {
        // Verificar si ya tenemos la provincia en el array uniqueProvincesArray
        const existingProvince = uniqueProvincesArray?.find(p => p.province_code === zone.province_code);

        // Si la provincia no existe en el array, la agregamos
        if (!existingProvince) {
        uniqueProvincesArray.push({
            province_name: zone.province_name,
            province_code: zone.province_code
        });
        }
    });

    return uniqueProvincesArray;
}