import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import styles from './DeleteButton.module.css';

export default function DeleteButton({style, auxKey, id, type, label, icon, iconPos, badge, disabled, loading, onClick}) {
  return (
    <Button
      style={style}
      key={auxKey}
      id={id}
      type={type}
      className={`clickable ${styles.DeleteButton}`}
      label={label}
      icon={icon}
      iconPos={iconPos}
      badge={badge}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    />
  )
}
