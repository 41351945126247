const sortObjectsByPosition = (objects) => {
    if (!objects || objects?.lenght === 0) {
        return []
    }
    // Usamos el método sort() con una función de comparación personalizada
    return objects?.sort((a, b) => {
        // Convertimos "position" a número usando el operador unario '+' para asegurar la comparación correcta
        return +a.position - +b.position;
    });
}
export default sortObjectsByPosition