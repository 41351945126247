import React from 'react'
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import styles from './ContinueWithGoogleBtn.module.css';

export default function ContinueWithGoogleBtn({style, auxKey, id, type, label, icon, iconPos, badge, disabled, loading, onClick}) {
  return (
    <Button
      style={style}
      key={auxKey}
      id={id}
      type={type}
      className={`clickable ${styles.ContinueWithGoogleBtn}`}
      label={label} 
      icon={icon}
      iconPos={iconPos}
      badge={badge}
      disabled={disabled} 
      loading={loading}
      onClick={onClick} 
    />
  )
}
