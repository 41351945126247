import PropTypes from "prop-types";
import styles from "../../pages/MyOrders/MyOrders.module.css";

import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import { Messages } from "primereact/messages";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect";
import CustomCalendar from "../FormComponents/CustomCalendar/CustomCalendar";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import MainButton from "../MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { ToggleButton } from "primereact/togglebutton";
import CustomChips from "../FormComponents/CustomChips/CustomChips.jsx";
import SecondaryButton from "../SecondaryButton/SecondaryButton.jsx";

const OrdersSellerFilters = ({
  attributesValues,
  attributesValuesOnBlur,
  cleanFilters,
  cleanFiltersAndConsultOrders,

  dates,
  filteredSellerStores,
  handleBlurChipAttributes,
  handleConsultOrders,
  handleFocusChipAttributes,
  handleProductSelect,
  handleStoreSelect,
  handleVariationSelect,
  hasFilterBySKU,
  msgs,
  checkRelatedSuplierIds,
  orderAttributes,
  orderStates,
  selectAttributeOrders,
  selectedOrderStates,
  selectedProductOfData,
  selectedStoreName,
  selectedVariationOfData,
  setAttributesValues,
  setAttributesValuesOnBlur,
  setDates,
  setCheckRelatedSupplierIds,
  setSelectAttributeOrder,
  setSelectedOrderStates,
  setValuesSKUS,
  setValuesSKUSOnBlur,
  setVisibleVinculateItemsBatch,
  t,
  toggleFilterBySKU,
  updatePromotionField,
  valueMultiCheckboxGuidesGenerated,
  valuesSKUS,
  valuesSKUSOnBlur,
  setValueMultiCheckboxGuidesGenerated,
  variationsData,
  productsData,
}) => {
  const { globalLoading } = useLoaderContext();
  const optionsFilterByGeneratedGuide = [
    { value: "Todas", icon: "pi pi-globe" },
    { value: "Generadas", icon: "pi pi-lock-open" },
    { value: "Sin generar", icon: "pi pi-lock" },
  ];

  return (
    <main style={{ marginBottom: "40px", display: "grid", gap: "20px",  paddingTop: "20px"}}>
      <ToggleButton checked={checkRelatedSuplierIds} onChange={(e) => setCheckRelatedSupplierIds(e.value)} onLabel="Ver solo mis pedidos" offLabel="Ver pedidos con productos mios" />
      <h5 className="mt-4" >{!checkRelatedSuplierIds  ? "Viendo solo mis pedidos" : "Viendo solo pedidos con productos mios"}</h5>
      
      <CustomSelect
        floatLabel={true}
        optionLabel={"name"}
        options={filteredSellerStores}
        value={selectedStoreName}
        placeholder={t("input-placeholders.stores")}
        onChange={(e) => handleStoreSelect(e.value)}
        disabled={checkRelatedSuplierIds}
      />
      
      <CustomCalendar
        dates={dates}
        setDates={(e) => setDates(e.target.value)}
        selectionMode="range"
        placeholder={t("input-placeholders.calendarRange")}
      />
      <CustomMultiSelect
        optionLabel={"name"}
        options={orderStates}
        value={selectedOrderStates}
        onValue={setSelectedOrderStates}
        placeholder={t("input-placeholders.states")}
        fieldNameToUpdate={"state"}
        updateField={updatePromotionField}
      />
      <Divider />
      <h4>Filtro por atributo</h4>
      <CustomSelect
        floatLabel={true}
        optionLabel={"name"}
        options={orderAttributes}
        value={selectAttributeOrders}
        placeholder={t("input-placeholders.aplicableAttribute")}
        onChange={(e) => setSelectAttributeOrder(e.value)}
      />

      <CustomChips
        floatLabel={true}
        type="number"
        value={attributesValues.map((num) => num)}
        onChange={(e) => setAttributesValues(e.value.map((str) => str))}
        onFocus={() => handleFocusChipAttributes(setAttributesValuesOnBlur, attributesValues)}
        onBlur={() => handleBlurChipAttributes(attributesValuesOnBlur, attributesValues, setAttributesValuesOnBlur)}
        separator=","
        keyfilter="num"
        disabled={!selectAttributeOrders}
        placeholder={t("input-placeholders.attributeValues")}
      />
      
      <Messages ref={msgs} />
      <Divider />
      <h4>Filtro por producto / SKU </h4>
      <div className="flex items-center gap-4">
        <CustomInputSwitch checked={hasFilterBySKU} onChange={toggleFilterBySKU} />
        <h5>Filtrar por SKU</h5>
      </div>
      {!hasFilterBySKU ? (
        <div>
          <CustomSelect
            floatLabel={true}
            optionLabel={"product_name"}
            options={productsData}
            value={selectedProductOfData}
            placeholder={t("input-placeholders.productName")}
            onChange={(e) => handleProductSelect(e.value)}
          />
          <CustomSelect
            floatLabel={true}
            optionLabel={"variation_label"}
            options={variationsData}
            value={selectedVariationOfData}
            placeholder={t("input-placeholders.selectSKU")}
            onChange={(e) => handleVariationSelect(e.value)}
            disabled={!selectedProductOfData}
          />
        </div>
      ) : (
        <CustomChips
          floatLabel={true}
          type="number"
          value={valuesSKUS}
          onChange={(e) => setValuesSKUS(e?.value?.map((str) => str))}
          onFocus={() => handleFocusChipAttributes(setValuesSKUSOnBlur, valuesSKUS)}
          onBlur={() => handleBlurChipAttributes(valuesSKUSOnBlur, valuesSKUS, setValuesSKUSOnBlur)}
          separator=","
          placeholder={t("input-placeholders.chipSKUS")}
          keyfilter="num"
      />
      )}
      <Divider />
      <h4>Ordenes con guia</h4>
      <div className="flex flex-column align-items-center gap-3">
        <MultiStateCheckbox
          value={valueMultiCheckboxGuidesGenerated}
          onChange={(e) => setValueMultiCheckboxGuidesGenerated(e.value)}
          options={optionsFilterByGeneratedGuide}
          optionValue="value"
          style={{ fontSize: "2em" }} // Ajusta el valor según tus necesidades
        />
        <span>{valueMultiCheckboxGuidesGenerated || "Todas"}</span>
      </div>

      <Button
        className={styles.secondaryButton}
        type={"button"}
        label={t("cleanFilters")}
        loading={globalLoading}
        onClick={cleanFilters}
      />

      {!checkRelatedSuplierIds && (
        <Button
          className={styles.secondaryButton}
          type={"button"}
          label={t("fixOrdersWithNoSyncedItems")}
          loading={globalLoading}
          onClick={() => setVisibleVinculateItemsBatch(true)}
        />
      )}

    <SecondaryButton
        onClick={cleanFiltersAndConsultOrders}
        type={"button"}
        label={t("cleanAndConsult")}
      />
      <MainButton
        style={{ marginTop: "20px" }}
        type={"button"}
        label={t("consultOrders")}
        loading={globalLoading}
        onClick={handleConsultOrders}
        autoFocus
      />
    </main>
  );
};
OrdersSellerFilters.propTypes = {
  t: PropTypes.func,
  checkRelatedSuplierIds: PropTypes.bool,
  setCheckRelatedSupplierIds: PropTypes.func,
  options: PropTypes.array,
  filteredSellerStores: PropTypes.array,
  selectedStoreName: PropTypes.object,
  handleStoreSelect: PropTypes.func,
  dates: PropTypes.any,
  setDates: PropTypes.func,
  orderStates: PropTypes.array,
  selectedOrderStates: PropTypes.array,
  setSelectedOrderStates: PropTypes.func,
  updatePromotionField: PropTypes.func,
  orderAttributes: PropTypes.array,
  msgs: PropTypes.object,
  selectAttributeOrder: PropTypes.func,
  selectAttributeOrders: PropTypes.string,
  setSelectAttributeOrder: PropTypes.func,
  attributesValues: PropTypes.array,
  setAttributesValues: PropTypes.func,
  handleFocusChipAttributes: PropTypes.func,
  handleBlurChipAttributes: PropTypes.func,
  attributesValuesOnBlur: PropTypes.array,
  setAttributesValuesOnBlur: PropTypes.func,
  hasFilterBySKU: PropTypes.bool,
  toggleFilterBySKU: PropTypes.func,
  productsData: PropTypes.array,
  selectedProductOfData: PropTypes.any,
  handleProductSelect: PropTypes.func,
  variationsData: PropTypes.array,
  selectedVariationOfData: PropTypes.any,
  handleVariationSelect: PropTypes.func,
  valuesSKUS: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
  setValuesSKUS: PropTypes.func,
  setValuesSKUSOnBlur: PropTypes.func,
  valuesSKUSOnBlur: PropTypes.array,
  valueMultiCheckboxGuidesGenerated: PropTypes.any,
  setValueMultiCheckboxGuidesGenerated: PropTypes.func,
  optionsFilterByGeneratedGuide: PropTypes.array,
  cleanFilters: PropTypes.func,
  setVisibleVinculateItemsBatch: PropTypes.func,
  handleConsultOrders: PropTypes.func,
  cleanFiltersAndConsultOrders: PropTypes.func,
};
export default OrdersSellerFilters;
