import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/firebase.js";

 const getZones = async () => {
    const configCol = collection(db, "config");
    const configDoc = doc(configCol, "zones");
    // Obtenemos las zonas de la configuracion
    const configSnapshot = getDoc(configDoc);
    const configData = (await configSnapshot).data();
    const zones = configData?.zones || [];
    // eslint-disable-next-line no-unused-vars
    const filteredZones = zones.map(({ ["id"]: _, ...rest }) => rest);
    return filteredZones;
  }
export default getZones;