import {useState, useEffect, useRef} from 'react';
import styles from './BannerCard.module.css';
import PropTypes from 'prop-types'
import toastFunction from '../../utils/toastFunction.js';
import VideoCardCover from '../VideoCardCover/VideoCardCover.jsx';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner.jsx';
import { COVER_BANNER, COVER_COLLECTION, DESKTOP, MOBILE, SECTION } from '../router/paths.js';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext.jsx';


const BannerCard = ({ 
  noresize,
  setNoresize,
  isCircleCard,
  borderRadius,
  shoppexStoreId,
  data,
  mediaType,
  onClick,
  isErasable,
  toast,
  setBannerValues,
  mobileHeight = '200px',
  desktopHeight = '400px',
  coverTypeMedia,
  coverType,
  groupType,
  groupId,
  disabled,
  sectionId,
}) => {
  const {defaultCoverImage} = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [mediaSrc, setMediaSrc] = useState(null)
  const [mediaAlt, setMediaAlt] = useState(data?.alt);
  const fileInputRef = useRef(null);
  const currentHeight = windowWidth > 2000 ? desktopHeight : mobileHeight;
  const minWidth = "100%";
  const [isVideo, setIsVideo ] = useState();

  const onClickFunction = ( mediaType) => {
    onClick(mediaType);
  }

  useEffect(() => {
    switch (coverTypeMedia?.code) {
      case 0:
        if (mediaType) {
          switch (mediaType) {
            case MOBILE:
              setMediaSrc(data?.media_mobile_src);
              setMediaAlt("Subir imagen (MOBILE)");
              break;
            case DESKTOP:
              setMediaSrc(data?.media_src);       
              setMediaAlt("Subir imagen (DESKTOP)");
              break;
            default:
              setMediaSrc(null);
              break;
          }
        } else {
              setMediaSrc(data.media_src);
              setMediaAlt("Subir imagen");
          }
        break;
      case 1:
        if (mediaType) {
          switch (mediaType) {
            case MOBILE:
              setMediaSrc(data?.media_mobile_src);
              setMediaAlt("Subir video (MOBILE)");
              break;
            case DESKTOP:
              setMediaSrc(data?.media_src);       
              setMediaAlt("Subir video (DESKTOP)");
              break;
            default:
              setMediaSrc(null);
              break;
          }
        } else {
              setMediaSrc(data.media_src);
              setMediaAlt("Subir video");
        }
        break;
      default:
        if (mediaType) {
          switch (mediaType) {
            case MOBILE:
              setMediaSrc(data?.media_mobile_src);
              setMediaAlt("Subir imagen / Video (MOBILE)");
              break;
            case DESKTOP:
              setMediaSrc(data?.media_src);       
              setMediaAlt("Subir imagen / Video (DESKTOP)");
              break;
            default:
              setMediaSrc(defaultCoverImage);
              setMediaAlt("Subir imagen / Video ");
              break;
          }
          break;
        } else {
            setMediaSrc(defaultCoverImage);
            setMediaAlt("Subir imagen / Video ");
        }
    }    
    if (coverTypeMedia) {
      setIsVideo(coverTypeMedia?.code === 1 ? true : false)
    }
    setWindowWidth(window.innerWidth);
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);   
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data, coverTypeMedia]);
  
  const handleVisibleFileReaderInputImg = () => {
    fileInputRef.current.click();
  };
  
  const handleInputCoverBanner = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (!file) return;
    reader.onloadend = async () => {
        if (file.type.startsWith('image/')) {
            handleImage(reader);
        } else if (file.type.startsWith('video/')) {
            handleVideo(reader);
        } else {
            toastFunction(toast, "warn", "Formato de archivo no admitido", "", 3000);
        }
    };

    reader.readAsDataURL(file);

  };

  const handleImage = async (reader) => {
    const img = new Image();
    img.src = reader.result;
    img.onload = async () => {
      setLoading(true);
      const canvas = document.createElement("canvas");
      canvas.width = img.width; // Mantener el ancho original
      canvas.height = img.height; // Mantener la altura original

      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Convertir el canvas a base64
      const base64Image = canvas.toDataURL('image/jpeg', 1); // Calidad máxima (sin compresión)
      // Subir la imagen al storage de Firebase
      const storagePath = `shoppexStores/${shoppexStoreId}/${groupType}/${groupId}/${coverType}/${mediaType}/archive`
      setDownloadUrlToBannerValuesForm(base64Image, storagePath, coverType, mediaType);
      setMediaSrc(base64Image);
      setLoading(false);
    };
  };


  const handleVideo = async (reader) => {
    setLoading(true);
    // Convertir la base64 del video a Blob
    const base64Video = reader.result;
    // Subir el video original al storage de Firebase
    const storagePath = `shoppexStores/${shoppexStoreId}/${groupType}/${groupId}/${coverType}/${mediaType}/archive`
    // Obtener la URL de descarga del video original    
    setMediaSrc(base64Video);        
    setDownloadUrlToBannerValuesForm(base64Video, storagePath, coverType, mediaType);
    setLoading(false);
  };

  const setDownloadUrlToBannerValuesForm = (base64, routePath, coverType, mediaType) => {
    switch (coverType) {
      case COVER_BANNER:
        switch (mediaType) {
          case MOBILE:
            setBannerValues((prevData) => ({
              ...prevData,
              media_mobile_src:  base64, // Tipo del contenido multimedia del slide "img" || "video"
              media_mobile_path: routePath
            }));
            break;
          case DESKTOP:
            setBannerValues((prevData) => ({
              ...prevData,
              media_src:  base64, // Tipo del contenido multimedia del slide "img" || "video"
              media_path: routePath
            }));
            break;
        }
        break;
      case COVER_COLLECTION:
      switch (mediaType) {
        case MOBILE:
          setBannerValues((prevData) => ({
            ...prevData,
            collection_banner_media:  [{
              ...data,
              media_mobile_src:  base64,
              media_mobile_path: routePath

            }]
          }));         
          break;
        case DESKTOP:
          setBannerValues((prevData) => ({
            ...prevData,
            collection_banner_media:  [{
              ...data,
              media_src:  base64,
              media_path: routePath

            }]
          }));    
          break;
      }        
      break;
      case SECTION:
      setBannerValues((prevData) => ({
          ...prevData,
            media_src:  base64,
            media_path: `shoppexStores/${shoppexStoreId}/${coverType}/${sectionId}/archive`
        }));
      setNoresize(true);
      break;
    }
  }

  return (
    <div className={`flex ${isCircleCard ? styles.circleCardContainer : styles.sliderContainer}`}>
      <picture key={data?.collection_id} 
       className={` ${isCircleCard ? styles.circle_container : "isCircleCard ?"}`}
       >
        {isErasable ? (
          <div className={styles.image_containerErasable}>
            { !isVideo? (
              <img 
              className={styles.slideImage} 
              src={mediaSrc} 
              // src={`data:image/jpeg;base64,${mediaSrc}`}
              alt={data?.alt} 
              style={{
                minWidth: minWidth,
                minHeight: currentHeight, maxHeight: currentHeight, borderRadius, }}  
              />
            ) :
            ( <VideoCardCover noresize={noresize} video_feed_url={mediaSrc}/> )}
            
            <div onClick={handleVisibleFileReaderInputImg } className='cursor-pointer ' >

              {!loading ? (<div className={`${styles.alt_text} ${mediaSrc === defaultCoverImage ? "" : styles.hidden}`}><p style={isVideo ? {color: "white" } : {color: "black"}}>{mediaAlt}</p></div>) 
                :
              (
              <span className={styles.alt_text}>
                <LoaderSpinner />
              </span>
              )}

              <div >
                <input 
                  disabled={!coverTypeMedia || loading || disabled} 
                  type="file" accept={isVideo? "video/*" : "image/*"} onChange={handleInputCoverBanner} style={{ display: "none" , cursor: "pointer"}} ref={fileInputRef} />
              </div>
            </div>

            <button type="button" disabled={loading||disabled} onClick={() => onClickFunction(mediaType)} 
              className={` ${isCircleCard ? styles.circleButtonOverlay : styles.buttonOverlay}`}
            >Eliminar</button>
          </div>
        ) : (
           <div className={styles.image_container}>
            { !isVideo? (             
              <div className={styles.image_container}>
                <img 
                  className={styles.slideImage} 
                  src={data?.media_src} 
                  alt={data.alt} 
                  style={{
                    minWidth: minWidth,
                    minHeight: currentHeight, maxHeight: currentHeight, borderRadius}}  
                />
                <div className={`${styles.hidden} ${mediaSrc ? "" : styles.hidden}`}>{mediaAlt}</div>
                <button disabled={disabled} type="button" onClick={() => onClick(data, "banner")} 
                  className={` ${isCircleCard ? styles.circleButtonOverlay : styles.buttonOverlay}`}
                >Editar</button> 
              </div>
            ) :
            ( <VideoCardCover noresize={noresize} video_feed_url={mediaSrc}/> )}
            
            <button disabled={disabled} type="button" onClick={() => onClick(data, "banner")} 
            className={` ${isCircleCard ? styles.circleButtonOverlay : styles.buttonOverlay}`}
            >Editar</button> 
          </div>
        )}
      </picture>
    </div>
  );
}

BannerCard.propTypes = {
  borderRadius: PropTypes.string,
  mobileHeight: PropTypes.string,
  desktopHeight: PropTypes.string,
  data: PropTypes.object,
  onClick: PropTypes.func,
  isErasable: PropTypes.bool,
  mediaType: PropTypes.string,
  toast: PropTypes.object,
  shoppexStoreId: PropTypes.string,
  defaultCoverImage: PropTypes.string,
  setBannerValues: PropTypes.func,
  coverTypeMedia: PropTypes.string,
  coverType: PropTypes.string,
  groupType: PropTypes.string,
  groupId: PropTypes.string, 
  disabled: PropTypes.bool,
  isCircleCard: PropTypes.bool,
  noresize: PropTypes.bool,
  sectionId: PropTypes.string,
  setNoresize: PropTypes.func,
}

export default BannerCard;
