import { InputSwitch } from "primereact/inputswitch";
import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import { generateRandomId } from "../../utils/generateRandomId";
import MainButton from "../MainButton/MainButton";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import CustomModal from "../CustomModal/CustomModal";
import ImagesVariations from "../ImagesVariations/ImagesVariations";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase.js";
import { saveObjectToLocalStorage } from "../../utils/localStorage.js";

const createVariationsFromOptions = (options, existingVariations) => {
  const allVariations = [];
  const helper = (currentVariation, remainingOptions) => {
    if (remainingOptions?.length === 0) {
      allVariations.push(currentVariation);
      return;
    }
    const [currentOption, ...nextOptions] = remainingOptions;
    for (let displayName of currentOption.display_names) {
      helper(
        [
          ...currentVariation,
          { id: displayName.option_id, name: displayName.name },
        ],
        nextOptions
      );
    }
  };

  helper([], options);

  return allVariations.map((variation) => {
    const variationIds = variation.map((v) => v.id);
    const variationLabel = variation.map((v) => v.name).join(" / ");

    const existingVariation = existingVariations?.find(
      (v) => JSON.stringify(v.options) === JSON.stringify(variationIds)
    );
    return {
      variation_id: existingVariation
        ? existingVariation.variation_id
        : generateRandomId(),
      options: variationIds,
      sku: existingVariation ? existingVariation.sku : generateRandomId(),
      variation_label: variationLabel,
      is_active: existingVariation ? existingVariation.is_active : true,
      images: existingVariation?.images || [],
      NewImages: existingVariation?.NewImages || [],
      is_default: existingVariation?.is_default
        ? existingVariation.is_active
        : false,
    };
  });
};

export default function ProductVariations({
  productValues,
  productVariations,
  productOptions,
  addArrayToProductValues,
  mode,
  isView,
  productVariationDefault,
  handleDeletedImagesFromVariations,
  globalLoading

}) {
  const [visibleImagesVariations, setVisibleImagesVariations] = useState(false);
  const [variation, setVariation] = useState([]);
  const [variationIndex, setVariationIndex] = useState([]);

  // Estructuras base
  const displayNameStructure = {
    option_id: generateRandomId(),
    name: "",
    is_active: true,
  };
  const variationStructure = {
    variation_id: generateRandomId(),
    options: [""],
    sku: generateRandomId(),
    variation_label: "",
    is_active: true,
    is_default: false,
  };
  const optionStructure = {
    option_id: generateRandomId(),
    type: "",
    display_names: [displayNameStructure],
    is_size: false,
  };
  // Estados
  const [options, setOptions] = useState([optionStructure]);
  const [variations, setVariations] = useState([variationStructure]);
  // eslint-disable-next-line no-unused-vars
  const [hasModified, setHasModified] = useState(false);
  const [imagesToDelete, setImagesToDelete] = useState([]);

  const [optionNamesOptions, setOptionNamesOptions ] = useState([]);
  const inputRefs = useRef([]);
  // Funciones
  const addNewOption = () => {
    if (mode === "EDIT") {
      // Construir el array deletedImages
      const deletedImages = productVariations
        .map((variation) => {
          return variation.images.map((image) => ({
            img_url: image.img_url,
            img_id: image.img_id,
            is_main_image: image.is_main_image,
            variation_id: variation.variation_id,
          }));
        })
        .flat(); // Convertir el array 2D en un array 1D

      setImagesToDelete((prevImages) => [...prevImages, ...deletedImages]);
      // Aquí puedes agregar más lógica si necesitas hacer algo adicional con deletedImages
    }
    setOptions((prevOptions) => [...prevOptions, optionStructure]);
  };
  const updateOptionType = (e, optionIndex) => {
    const newOptions = [...options];
    newOptions[optionIndex] = {
      ...newOptions[optionIndex],
      type: e.target.value,
    };
    setOptions(newOptions);
    setHasModified(true);
  };

  const addNewDisplayName = (optionIndex, displayNameIndex) => {
    const newOptions = [...options];
    // Verificar si newOptions[optionIndex] y displayNames existen
    if (!newOptions[optionIndex] || !newOptions[optionIndex].display_names) {
      console.error("Option or displayNames is undefined!");
      return;
    }

    const lastIndex = newOptions[optionIndex].display_names?.length - 1;

    // Si displayNameIndex no se pasa (es decir, se llama desde el botón),
    // usamos el último displayName en la lista.
    const currentDisplayNameIndex =
      displayNameIndex !== undefined ? displayNameIndex : lastIndex;
    const currentDisplayName =
      newOptions[optionIndex].display_names[currentDisplayNameIndex];

    // Verificar si el input actual no está vacío
    if (currentDisplayName && currentDisplayName.name.trim() !== "") {
      const newDisplayNames = [
        ...newOptions[optionIndex].display_names,
        displayNameStructure,
      ];
      newOptions[optionIndex].display_names = newDisplayNames;
      setOptions(newOptions);
      setTimeout(() => {
        inputRefs.current[currentDisplayNameIndex + 1]?.focus();
      }, 0);
    }
  };
  const updateOptionDisplayName = (
    optionIndex,
    displayNameIndex,
    fieldUpdate,
    valudataUptatee
  ) => {
    const newOptions = [...options];
    newOptions[optionIndex].display_names[displayNameIndex][fieldUpdate] =
      valudataUptatee;
    setOptions(newOptions);
    addArrayToProductValues("options", options);
    setHasModified(true);
  };
  const updateOptionIsSize = (optionIndex, newValue) => {
    const newOptions = [...options];
    newOptions[optionIndex].is_size = newValue;
    setOptions(newOptions);
    setHasModified(true);
  };
  const createVariations = () => {
    saveObjectToLocalStorage("beforeProductState", productValues)
    // Verificar si el array options está vacío
    if (!options || options.length === 0) {
      return;
    }
    // Filtrar los displayNames con name vacío y opciones con type vacío
    const cleanedOptions = options
      .filter((option) => option.type && option.type.trim() !== "") // Verificar que type no esté vacío
      .map((option) => ({
        ...option,
        display_names: option.display_names.filter(
          (displayName) => displayName.name && displayName.name.trim() !== ""
        ),
      }))
      .filter((option) => option.display_names?.length > 0); // Esto también eliminará las opciones que no tienen ningún displayName después de la limpieza

    // Si las opciones limpias son diferentes de las opciones originales, entonces había campos vacíos
    if (cleanedOptions.length !== options.length) {
      return;
    }
    const newVariations = createVariationsFromOptions(cleanedOptions, [
      ...variations,
    ]);
    addArrayToProductValues("variations", newVariations);
    addArrayToProductValues("options", cleanedOptions); // Usamos cleanedOptions en lugar de options
    setVariations(newVariations);
    setHasModified(false);
    handleDeletedImagesFromVariations(imagesToDelete);
  };
  const updateVariation = (variationIndex, updates) => {
    const newVariations = [...variations];
    newVariations[variationIndex] = {
      ...newVariations[variationIndex],
      ...updates,
    };
    setVariations(newVariations);

    if (productVariationDefault) {
      const variationDefaultModified = newVariations[0];
      addArrayToProductValues("variationDefault", variationDefaultModified);
    } else {
      addArrayToProductValues("variations", newVariations);
    }
    setHasModified(true);
    saveObjectToLocalStorage("beforeProductState", productValues)

  };
  const removeDisplayName = (optionIndex, displayNameIndex) => {
    const optionWithDisplayNameToRemove = options[optionIndex];
    const displayNameToRemove =
      optionWithDisplayNameToRemove.display_names[displayNameIndex];
    const optionIdToRemove = displayNameToRemove.option_id;

    // Verificar si el optionId del displayName a eliminar coincide con algún optionId en productVariations
    const isDisplayNameInProductValues = productVariations?.some((variation) =>
      variation.options.includes(optionIdToRemove)
    );
    if (isDisplayNameInProductValues) {
      // Construir el array deletedImages
      const deletedImages = productVariations
        .filter((variation) => variation.options.includes(optionIdToRemove))
        .map((variation) => {
          return variation.images.map((image) => ({
            img_url: image.img_url,
            img_id: image.img_id,
            is_main_image: image.is_main_image,
            variation_id: variation.variation_id,
          }));
        })
        .flat(); // Convertir el array 2D en un array 1D

      setImagesToDelete((prevImages) => [...prevImages, ...deletedImages]);
      // Aquí puedes agregar más lógica si necesitas hacer algo adicional con deletedImages
    }
    const newOptions = [...options];
    newOptions[optionIndex].display_names.splice(displayNameIndex, 1);

    // Si después de eliminar el displayName, la opción ya no tiene ningún displayName, eliminamos la opción.
    if (newOptions[optionIndex].display_names?.length === 0) {
      newOptions.splice(optionIndex, 1);
    }

    addArrayToProductValues("options", newOptions); // Actualizar productValues con newOptions
    setOptions(newOptions);
    setHasModified(true);
  };
  const removeOption = (optionIndex) => {
    const optionToRemove = options[optionIndex];
    // Verificar si la opción a eliminar ya está inscrita en productValues.options
    const isOptionInProductValues = productOptions?.some(
      (opt) => opt.type === optionToRemove.type
    );

    if (isOptionInProductValues) {
      // Construir el array deletedImages
      const deletedImages = productVariations
        .map((variation) => {
          return variation.images.map((image) => ({
            img_url: image.img_url,
            img_id: image.img_id,
            is_main_image: image.is_main_image,
            variation_id: variation.variation_id,
          }));
        })
        .flat(); // Convertir el array 2D en un array 1D
      setImagesToDelete(deletedImages);
      // Aquí puedes agregar la lógica para actualizar el estado o lo que necesites hacer con deletedImages
      // Por ejemplo: setDeletedImagesFromVariations(deletedImages);
    }

    const newOptions = [...options];
    newOptions.splice(optionIndex, 1);
    setOptions(newOptions);
    setHasModified(true);
  };
  const renderModal = (variation, variationIndex) => {
    setVisibleImagesVariations(true);
    setVariation(variation);
    setVariationIndex(variationIndex);
  };
  const closeImagesVariationsModal = () => {
    setVisibleImagesVariations(false);
  };

  const handleOptionName = (optionName, optionIndex) => {
    const newOptions = [...options];

      if( optionName === "Talla") {
        newOptions[optionIndex] = {
        ...newOptions[optionIndex],
        is_size: true,
        type: optionName,
      };
      }else {
        newOptions[optionIndex] = {
          ...newOptions[optionIndex],
          is_size: false,
          type: optionName,
        };
      }
    setOptions(newOptions);
    setHasModified(true);
  }


  const setVariationsAndOptions = () => {
    if (productVariationDefault) {
      setVariations([productVariationDefault]);
    } else {
      if (productVariations?.length > 0) {
        setVariations([...productVariations]);
      } else {
        setVariations([]);
      }
      if (productOptions?.length > 0) {
        setOptions([...productOptions]);
      } else {
        setOptions([optionStructure]);
      }
    }
  };


  useEffect(() => {
    const initializeOptions = async () => {
      try {
        await loadOptionNames();
        setVariationsAndOptions()

      } catch (error) {
        console.error("Error initializing options:", error);
      }
    };

    initializeOptions();
  }, []);


  const loadOptionNames = async () => {
    const configCol = collection(db, "config");
    const optionNamesDoc = doc(configCol, "productOptions");
    const optionNamesData = (await getDoc(optionNamesDoc)).data();
    const optionLabels = optionNamesData.optionNames.map((option) => option.label)
    setOptionNamesOptions(optionLabels );
  }
  
  useEffect((function loadInitialData(){
  }), [])
  
  
  return (
    <div>
      {!productVariationDefault &&
        options?.map((option, optionIndex) => (
          <OptionBox
            key={option.option_id}
            optionIndex={optionIndex}
            option={option}
            updateOptionType={updateOptionType}
            updateOptionDisplayName={updateOptionDisplayName}
            addNewDisplayName={addNewDisplayName}
            removeDisplayName={removeDisplayName}
            removeOption={removeOption}
            inputRefs={inputRefs}
            isView={isView}
            updateOptionIsSize={updateOptionIsSize}
            optionNamesOptions={optionNamesOptions}
            handleOptionName={handleOptionName}
          />
        ))}
      {!productVariationDefault && (
        <>
          <SecondaryButton
              // loading={globalLoading}
              type={"button"}
              label={"Añadir opcion"}
              onClick={addNewOption}
            />
          <MainButton
          type={"button"}
          label={"Crear variaciones"}
          // loading={globalLoading}
          onClick={createVariations}
          autoFocus
        />
        </>
      )}
      {!productVariationDefault && variations && variations?.length > 0 && (
        <VariationsBox
          variations={variations}
          updateVariation={updateVariation}
          renderModal={renderModal}
          isView={isView}
          mode={mode}
          handleDeletedImagesFromVariations={handleDeletedImagesFromVariations}
          globalLoading={globalLoading}

        />
      )}
      {productVariationDefault && (
        <VariationsBox
          variations={variations}
          updateVariation={updateVariation}
          renderModal={renderModal}
          isView={isView}
          productVariationDefault={productVariationDefault}
          mode={mode}
          handleDeletedImagesFromVariations={handleDeletedImagesFromVariations}
          globalLoading={globalLoading}

        />
      )}
        <div>
        <CustomModal
          visible={visibleImagesVariations}
          setVisible={setVisibleImagesVariations}
          createMode={`Imagenes de la variacion : ${variation.variation_label}`}
          content={
            <ImagesVariations
                updateVariation={updateVariation}
                variation={variation}
                variationIndex={variationIndex}
                mode={mode}
                handleDeletedImagesFromVariations={handleDeletedImagesFromVariations}
                onClose={closeImagesVariationsModal}
              />
          }
          onClose={closeImagesVariationsModal}
        />
      </div>
    </div>
  );
}
function OptionBox({
  optionIndex,
  option,
  updateOptionDisplayName,
  addNewDisplayName,
  removeDisplayName,
  removeOption,
  inputRefs,
  isView,updateOptionIsSize,
  optionNamesOptions,
  handleOptionName
}) {
  return (
    <div className="mb-8 mt-4">
      <label className="justify-start flex mt-2 block text-sm font-medium mb-2">
        Nombre de la Opcion
      </label>
      <div className="flex mb-2   justify-between">
        <span className="w-20 mr-1">
          <label className="text-sm font-medium ">¿Es Talla?</label>
          <InputSwitch
            checked={option?.is_size}
            onChange={(e) => {
              updateOptionIsSize(optionIndex, e.value);
            }}
            disabled={true}
          />
        </span>
        <span
          className="w-full"
        >
          <CustomSelect
            required={true}
            floatLabel={true}
            optionLabel=""        
            options={optionNamesOptions}
            valueTemplate=""
            itemTemplate=""
            value={option.type}
            placeholder="Selecciona una variacion"
            onChange={(e) => handleOptionName(e.target.value, optionIndex)}
          />

        </span>
        <button
          type="button"
          className={`bg-red-500 text-white p-2 ml-1  ${
            isView ? "opacity-50" : ""
          }`}
          onClick={() => removeOption(optionIndex)}
          disabled={isView}
        >
          Eliminar Opcion
        </button>
      </div>
      {option?.display_names?.map((displayName, displayNameIndex) => (
        <div key={displayName?.option_id} className="flex items-center">
          <span className=" flex justify-content-center w-20">
            <InputSwitch
              checked={displayName?.is_active}
              onChange={(e) => {
                updateOptionDisplayName(
                  optionIndex,
                  displayNameIndex,
                  "is_active",
                  e.value
                );
              }}
              disabled={isView}
            />
          </span>
          <input
            ref={(el) => (inputRefs.current[displayNameIndex] = el)}
            className="border p-2  w-full rounded"
            placeholder="Rojo, 33, etc"
            type="text"
            disabled={isView}
            value={displayName.name}
            onChange={(e) =>
              updateOptionDisplayName(
                optionIndex,
                displayNameIndex,
                "name",
                e.target.value
              )
            }
            onKeyDown={(e) => {
              if (
                e.key === "Tab" &&
                displayNameIndex === option.display_names?.length - 1
              ) {
                e.preventDefault(); // Prevenir el comportamiento por defecto de TAB
                addNewDisplayName(optionIndex, displayNameIndex);
              }
            }}
          />
          <button
            type="button"
            className={`bg-red-500 text-white p-2 ml-1 rounded ${
              isView ? "opacity-50" : ""
            }`}
            onClick={() => removeDisplayName(optionIndex, displayNameIndex)}
            disabled={isView}
          >
            Eliminar
          </button>
        </div>
      ))}

      <div className="flex justify-between mt-6">
        <button
          className={`bg-green-500 text-white p-2 mb-2 rounded ${
            isView ? "opacity-50" : ""
          }`}
          type="button"
          onClick={() => addNewDisplayName(optionIndex)}
          disabled={isView}
        >
          Añadir Valor
        </button>
      </div>
    </div>
  );
}
function VariationsBox({
  variations,
  updateVariation,
  renderModal,
  isView,
  mode,
  handleDeletedImagesFromVariations,
  globalLoading
}) {
  return (
    <div>
      <label className="justify-start flex mt-2 block text-xl font-semibold mb-6">
        Datos de las variantes
      </label>

      <div className="overflow-x-auto">
        <table className="min-w-full shadow-md mb-12">
          <thead className="h-16 p-4 bg-slate-100 ">
            <tr className="rounded-t-xl">
              <th className="p-2">Activo</th>
              <th className="p-2">Imagen</th>
              <th className="p-2">Titulo</th>
              <th className="p-2">SKU</th>
              <th className="p-2">¿Es variante default?</th>
              {/* <th className="p-2">ID de la variación</th>
              {!productVariationDefault && (
                <th className="p-2">IDs de las opciones</th>
              )} */}
            </tr>
          </thead>
          <tbody>
            {variations?.map((variation, variationIndex) => (
              <tr key={variation?.variation_id}>
                <td className="h-6 p-4 border">
                  <InputSwitch
                    checked={variation.is_active || false}
                    disabled={isView || globalLoading}
                    onChange={(e) => {
                      updateVariation(variationIndex, { is_active: e.value });
                    }}
                  />
                </td>
                <td className="border">
                  <button
                    className="h-full w-full relative"
                    type="button"
                    disabled={isView}
                    onClick={() =>
                      renderModal(
                        variation,
                        variationIndex,
                        mode,
                        handleDeletedImagesFromVariations
                      )
                    }
                  >
                    {(() => {
                      // Buscar la imagen principal en variations.images
                      const mainImageFromImages = variation?.images?.find(
                        (image) => image.is_main_image
                      );

                      // Buscar la imagen principal en variations.NewImages
                      const mainImageFromNewImages = variation?.NewImages?.find(
                        (image) => image.is_main_image
                      );

                      // Determinar qué imagen renderizar
                      let imageToRender;
                      if (mainImageFromImages) {
                        imageToRender = mainImageFromImages.img_url;
                      } else if (mainImageFromNewImages) {
                        // Agregar el prefijo de base64 si la imagen proviene de NewImages
                        imageToRender = `data:image/jpeg;base64,${mainImageFromNewImages.img_url}`;
                      }
                      // console.log("mainImageFromImages",mainImageFromImages, mainImageFromNewImages, imageToRender, variation?.images?.length <= 0 &&
                      //   variation?.NewImages?.length <= 0)
                      // Renderizar la imagen si existe
                      if (imageToRender) {
                        return (
                          <img
                            style={{
                              width: "80px",
                              height: "80px",
                              borderRadius: "95%",
                              objectFit: "cover",
                            }}
                            className="p-2"
                            alt="imagen principal"
                            src={imageToRender}
                          />
                        );
                      } else if (
                        (variation?.images?.length <= 0 &&
                        variation?.NewImages?.length <= 0 ) || (variation?.images?.length === 0 && !variation?.NewImages )
                      ) {
                        return renderDefaultimageToVariations(variation);
                      }
                    })()}
                  </button>
                </td>
                <td
                  className="h-6 w-full p-4 border font-thin text-md"
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {variation.variation_label}
                </td>

                <td className="h-6 p-4 border font-thin text-md">
                  <input
                    className="border p-2"
                    value={variation.sku}
                    type="text"
                    placeholder="sku"
                    onChange={(e) =>
                      updateVariation(variationIndex, { sku: e.value })
                    }
                    disabled={true}
                  />
                </td>
                <td className="h-6 p-4 border ">
                  <InputSwitch
                    checked={variation.is_default || false}
                    onChange={(e) => {
                      updateVariation(variationIndex, { is_default: e.value });
                    }}
                    disabled={isView|| globalLoading}
                    
                  />
                </td>
                {/* <td className="h-6 p-4 border font-thin text-md">
                  <label className="justify-start flex mt-2 block text-sm font-medium mb-2">
                    id de la variacion
                  </label>
                  <span className="text-black font-thin text-md">
                    {variation.variation_id}
                  </span>
                </td>
                {!productVariationDefault && (
                  <td className="h-6 p-4 border">
                    <label className="justify-start flex mt-2 block text-sm font-medium mb-2">
                      ids de las opciones
                    </label>
                    {variation?.options?.map((option, i) => (
                      <p key={i} style={{ fontSize: "0.7rem", color: "red" }}>
                        {option}
                      </p>
                    ))}
                  </td>
                )} */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
function renderDefaultimageToVariations(variation) {
  return (
    <div>
      {variation?.variation_label === "DEFAULT" &&
      variation?.images?.length === 0 &&
      variation?.NewImages?.length === 0 ? (
        <div className=" ">
          <img
            className="opacity-60 p-2"
            alt="imagen"
            src="https://cdn-icons-png.flaticon.com/128/8191/8191585.png?ga=GA1.1.395521382.1694465211"
          />
        </div>
      ) : (
        <div className=" ">
          <img
            className="opacity-60 p-2"
            alt="imagen"
            src="https://cdn-icons-png.flaticon.com/128/8191/8191585.png?ga=GA1.1.395521382.1694465211"
          />
        </div>
      )}
    </div>
  );
}




ProductVariations.propTypes = {
  productVariations: PropTypes.arrayOf(
    PropTypes.shape({
      variation_id: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
      sku: PropTypes.string,
      variation_label: PropTypes.string,
      is_active: PropTypes.bool,
      is_default: PropTypes.bool,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          img_url: PropTypes.string,
          Img_Id: PropTypes.string,
          is_main_image: PropTypes.bool,
        })
      ),
    })
  ),
  productOptions: PropTypes.arrayOf(
    PropTypes.shape({
      option_id: PropTypes.string,
      type: PropTypes.string,
      display_names: PropTypes.arrayOf(
        PropTypes.shape({
          option_id: PropTypes.string,
          name: PropTypes.string,
          is_active: PropTypes.bool,
        })
      ),
    })
  ),
  addArrayToProductValues: PropTypes.func,
  mode: PropTypes.string,
  isView: PropTypes.string,
  productVariationDefault: PropTypes.object,
  handleDeletedImagesFromVariations: PropTypes.func,
};

OptionBox.propTypes = {
  optionIndex: PropTypes.number,
  option: PropTypes.shape({
    option_id: PropTypes.string,
    type: PropTypes.string,
    is_size: PropTypes.bool,
    display_names: PropTypes.arrayOf(
      PropTypes.shape({
        option_id: PropTypes.string,
        name: PropTypes.string,
        is_active: PropTypes.bool,
      })
    ),
  }),
  updateOptionType: PropTypes.func,
  updateOptionDisplayName: PropTypes.func,
  addNewDisplayName: PropTypes.func,
  removeDisplayName: PropTypes.func,
  removeOption: PropTypes.func,
  inputRefs: PropTypes.object,
  isView: PropTypes.string,
  updateOptionIsSize : PropTypes.func,
  handleOptionName:  PropTypes.func,
  optionNamesOptions: PropTypes.array,
 
};

VariationsBox.propTypes = {
  variations: PropTypes.arrayOf(
    PropTypes.shape({
      variation_id: PropTypes.string,
      options: PropTypes.arrayOf(PropTypes.string),
      sku: PropTypes.string,
      variation_label: PropTypes.string,
      is_active: PropTypes.bool,
      is_default: PropTypes.bool,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          img_url: PropTypes.string,
          Img_Id: PropTypes.string,
          is_main_image: PropTypes.bool,
        })
      ),
    })
  ),
  updateVariation: PropTypes.func,
  renderModal: PropTypes.func,
  isView: PropTypes.bool,
  productVariationDefault: PropTypes.object,
  mode: PropTypes.string,
  handleDeletedImagesFromVariations: PropTypes.func,
};