import { collection, doc } from "firebase/firestore";
import { db } from "../firebase/firebase";

const generateRandomId = () => {
  const tempDocRefTAX = doc(collection(db, "tempCollection"));
  const newRandomId = tempDocRefTAX.id;
  return newRandomId;
};

const extractKeys = (productsArray, keys) => {
  return productsArray.map((product) => {
    const extracted = {};
    keys.forEach((key) => {
      if (product.hasOwnProperty(key)) {
        extracted[key] = product[key];
      }
    });
    return extracted;
  });
};
export { generateRandomId, extractKeys };
