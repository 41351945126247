import React, { useState } from 'react'
import styles from './Footer.module.css';
// import CustomDialog from '../../OverlayComponents/CustomDialog/CustomDialog';
// import TyC from '../../../Legal/TyC';
// import Privacity from '../../../Legal/Privacity';
import COL_FLAG from '../../../assets/FLAGS/FLAG-07.svg';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import CustomModal from '../../CustomModal/CustomModal';
import TyC from '../../../Legal/TyC';
import Privacy from '../../../Legal/Privacy';


export default function Footer() {

    const {isDesktop} = useGlobalContext();
    const [showPrivacity, setShowPrivacity] = useState(false)
    const [showTyC, setShowTyC] = useState(false)


    function handleLinkClick(event) {
        event.preventDefault();
        const targetId = event.currentTarget.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);
        
        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth',
          });
        }
    }

  return (
    <div className={styles.footer}>
        <div className={styles.footer_section}>
            <h4 className={styles.title}>Enlaces Rápidos</h4>
            <div className={styles.link}>
                <a href="#hero_sect" onClick={handleLinkClick}>Inicio</a>
            </div>
            <div className={styles.link}>
                <a href="#pros" onClick={handleLinkClick}>Beneficios</a>
            </div>
            <div className={styles.link}>
                <a href="#reviews_sect" onClick={handleLinkClick}>Testimonios</a>
            </div>
            <div className={styles.link}>
                <a href="#calc" onClick={handleLinkClick}>Calculadora</a>
            </div>
            <div className={styles.link}>
                <a href="mailto:contacto@supplaii.com">contacto@supplaii.com</a>
            </div>
        </div>

        <div className={styles.footer_section}>
            <h4 className={styles.title}>Derechos de Autor y Política de Privacidad</h4>
            <div className={styles.link}>
                <a id='politica_privacidad' onClick={()=>setShowPrivacity(true)}>Política de Privacidad</a>
            </div>
            <div className={styles.link}>
                <a id='terminos_condiciones' onClick={()=>setShowTyC(true)}>Términos y condiciones</a>
            </div>
        </div>

        <div className={styles.footer_section}>
            <span>
                © 2024 Supplaii. Todos los derechos reservados.
                <img style={{width:"20px"}} src={COL_FLAG} alt="Mybroo Colombia" />
            </span>
        </div>

        {/* <CustomDialog style={{width:isDesktop?"60%":"100%"}} header={"Política de Privacidad"} visible={showPrivacity} onHide={()=>setShowPrivacity(false)}><Privacity /></CustomDialog>
        <CustomDialog style={{width:isDesktop?"60%":"100%"}} header={"Terminos y condiciones"} visible={showTyC} onHide={()=>setShowTyC(false)}><TyC /></CustomDialog> */}
                <CustomModal
                        visible={showPrivacity}
                        setVisible={setShowPrivacity}
                        content={<Privacy/>}
                        onClose={() => setShowPrivacity(false)}
                    />
                <CustomModal
                        visible={showTyC}
                        setVisible={setShowTyC}
                        content={<TyC/>}
                        onClose={() => setShowTyC(false)}
                    />
    </div>
  )
}
