import React from 'react';

export default function TyC() {
  return (
    <div>
      <h1>Términos y Condiciones de Uso de la Plataforma “Supplaii”</h1>

      <h2>INFORMACIÓN:</h2>
      <p>Sociedad constituida conforme a las leyes colombianas, con domicilio en la ciudad de Bogotá D.C., quien en adelante se denominará Supplaii.</p>

      <h2>DEFINICIONES:</h2>
      <ul>
        <li><strong>USUARIO COMERCIALIZADOR:</strong> Toda persona natural o jurídica, que use la PLATAFORMA como medio para consultar y cotizar productos, así como para obtener el transporte de los que solicite, a instancias de sus clientes dentro del territorio colombiano.</li>
        <li><strong>USUARIOS PROVEEDORES:</strong> Toda persona natural o jurídica que ofrezca a la venta sus productos usando la infraestructura tecnológica de Supplaii.</li>
        <li><strong>PLATAFORMA:</strong> Aplicativo web y móvil administrado por Supplaii, que permite a EL USUARIO COMERCIALIZADOR consultar, ordenar y ofertar en su propio nombre, por su propia cuenta y riesgo los productos que autónomamente determine; es decir, a prestar una plataforma para que se conecten la oferta con la demanda.</li>
      </ul>

      <h2>CONSIDERACIONES:</h2>
      <ol>
        <li>Que EL USUARIO COMERCIALIZADOR se encuentra interesado en acceder al aplicativo Supplaii, para la consulta y cotización de productos, así como para obtener el transporte de los que solicite, a instancias de sus clientes dentro del territorio colombiano.</li>
        <li>Que ambas partes se reconocen la capacidad legal necesaria para suscribir el presente contrato.</li>
        <li>Que las partes consienten en que quien suscribe el presente negocio jurídico en su representación legal, cuenta con los poderes y facultades necesarios para obligarse en nombre de las personas jurídicas o naturales que tendrán a su cargo la ejecución y cumplimiento de las obligaciones emanadas en virtud del presente contrato.</li>
        <li>Que, en consecuencia, las partes suscribientes del presente negocio jurídico han decidido de común acuerdo, establecer las condiciones que regirán la relación contractual que surge entre ellas, en los precisos términos y alcances señalados en las siguientes cláusulas.</li>
      </ol>

      <h2>CLÁUSULAS:</h2>
      <h3>PRIMERA. OBJETO:</h3>
      <p>Supplaii se obliga con EL USUARIO COMERCIALIZADOR a desarrollar todas las labores que estén dentro de su alcance, para permitirle el acceso al aplicativo Supplaii (en adelante también el aplicativo), a efectos de la consulta, cotización y demanda de productos allí ofertados por los USUARIOS PROVEEDORES, y para el transporte de los mismos de acuerdo con las órdenes de envío generadas por EL USUARIO COMERCIALIZADOR, a cambio de una comisión añadida al precio total del producto. Supplaii realiza los mejores esfuerzos para mantener en operación LA PLATAFORMA, pero en ningún caso garantiza disponibilidad y continuidad permanente de LA PLATAFORMA.</p>

      <h3>SEGUNDA. DEFINICIONES. Para efectos del presente negocio jurídico:</h3>
      <p>Las palabras técnicas o científicas que no se encuentren definidas expresamente en este documento, tendrán los significados que les correspondan según la ley, la técnica o ciencia respectiva. Los títulos de las cláusulas se incluyen con fines de referencia y de conveniencia, pero de ninguna manera limitan, definen, o describen el alcance y la intención del presente Contrato. Las demás palabras se entenderán en su sentido natural y obvio, según el uso general de las mismas.</p>

      <h3>TERCERA. DECLARACIONES. EL USUARIO COMERCIALIZADOR declara libremente:</h3>
      <p>Que ha leído de manera cuidadosa y suficiente el presente documento, de manera previa a la creación de su perfil en el aplicativo. Que comprende y consiente en todas y cada una de las condiciones señaladas en el mismo, para efectos de las actividades relacionadas con su participación en el aplicativo Supplaii. Que con la suscripción del presente Contrato y el cumplimiento de las obligaciones que surjan del mismo, no se contravienen las disposiciones de alguna ley o regulación aplicable, ni de los estatutos de EL USUARIO COMERCIALIZADOR, ni de algún contrato o instrumento que le obligue. Que, si EL USUARIO COMERCIALIZADOR incumple por medio de la suscripción, ejecución y liquidación del presente Contrato obligaciones o regulaciones, deberá asumir las consecuencias e indemnizaciones derivadas de dicho incumplimiento, sin que por ello se encuentre facultado para evadir el cumplimiento de las obligaciones de este Contrato, o para formular reclamaciones o solicitudes a Supplaii para atender, cubrir o excusar el cumplimiento de sus otras obligaciones.</p>

      <h3>CUARTA. OBLIGACIONES DE Supplaii. Sin perjuicio de las demás obligaciones establecidas a lo largo del presente Contrato y sus Anexos, son obligaciones de Supplaii las siguientes:</h3>
      <p>Permitir a EL USUARIO COMERCIALIZADOR la creación de un perfil en el aplicativo Supplaii de forma gratuita, diligenciando los datos requeridos por Supplaii. Podrá EL USUARIO COMERCIALIZADOR, además de la información obligatoria y facultativa requerida al momento de creación de la cuenta, suministrar voluntariamente datos adicionales relacionados con su individualización. Permitir a EL USUARIO COMERCIALIZADOR la consulta de información relacionada con los productos ofertados por los USUARIOS PROVEEDORES en el aplicativo, incluyendo imágenes, precios, otras especificaciones y condiciones relevantes. Permitir a EL USUARIO COMERCIALIZADOR el envío de preguntas a los USUARIOS PROVEEDORES en relación con los productos por ellos ofertados en el aplicativo. Desarrollar las labores tendientes a que EL USUARIO COMERCIALIZADOR reciba la información con la que los USUARIOS PROVEEDORES respondan las preguntas por él formuladas, respecto a los productos ofertados por aquellos. Permitir al USUARIO COMERCIALIZADOR generar órdenes de envío de los productos ofertados en el aplicativo, para lo cual aquél deberá señalar de manera completa las especificaciones de cantidad, calidad, forma de pago, margen de utilidad de EL USUARIO COMERCIALIZADOR, lugar de recepción de los productos, entre otros que Supplaii solicite. Realizar todas las gestiones pertinentes para el contacto con la transportadora encargada de entregar los productos ordenados por EL USUARIO COMERCIALIZADOR en las cantidades señaladas en las órdenes de envío generadas por aquél. Transferir a EL USUARIO COMERCIALIZADOR la suma correspondiente al margen de utilidad por él señalado para cada uno de los productos que solicita con método de pago Contra entrega o Link de pago. Acatar las demás obligaciones derivadas de la naturaleza propia del servicio contratado.</p>

      <h3>QUINTA. OBLIGACIONES DEL USUARIO COMERCIALIZADOR:</h3>
      <p>EL USUARIO COMERCIALIZADOR se obliga expresamente a desarrollar las siguientes actividades: Consignar información veraz, clara, precisa y verificable al momento de crear su perfil en el aplicativo Supplaii, haciéndose responsable por cualquier inexactitud o imprecisión. Hacer uso adecuado del aplicativo Supplaii, de acuerdo con su criterio comercial autónomo e informado en la consulta de la información allí consignada, abstenerse de publicar información no relacionada con los productos ofertados por los USUARIOS PROVEEDORES y de utilizar dicha información para fines no autorizados expresamente. Indicar en las órdenes de envío, de forma responsable y veraz, en los términos más precisos posibles el margen de utilidad de EL USUARIO COMERCIALIZADOR para cada uno de los productos y el lugar donde estos serán recibidos.</p>

      <h3>SEXTA. CUENTA DEL USUARIO COMERCIALIZADOR:</h3>
      <p>El uso de la cuenta es personal e intransferible, por lo cual EL USUARIO COMERCIALIZADOR no está facultado para ceder los datos de validación para el acceso a la PLATAFORMA ni el uso de su cuenta a ningún tipo de terceros. El incumplimiento de lo anterior acarreará la suspensión y bloqueo definitivo de la cuenta. En caso de olvido de los datos de validación o de usurpación de éstos, es obligación EL USUARIO COMERCIALIZADOR informarlo a Supplaii por medio de la opción “Soporte” al interior de la aplicación.</p>

      <h3>SÉPTIMA. MARGEN DE UTILIDAD DE EL USUARIO COMERCIALIZADOR:</h3>
      <p>Cada producto ofertado en el aplicativo Supplaii tendrá señalado un precio total, al que los usuarios comercializadores deben añadir su margen de utilidad. La suma que habrá de recibir EL USUARIO COMERCIALIZADOR, será aquella señalada por él en el aplicativo como su margen de utilidad, al momento en que genere la respectiva orden de envío con método de pago Contra entrega o Link de pago. En consecuencia, Supplaii no reconocerá a EL USUARIO COMERCIALIZADOR suma alguna adicional, por concepto de cualquier ajuste o evento anterior, concomitante o posterior verificado en el aplicativo.</p>
    
      <h3>OCTAVA. FORMA DE PAGO DEL PRECIO DE LOS PRODUCTOS:</h3>
      <p>El precio total de los productos sumado al margen de utilidad determinado en la cláusula anterior, podrá ser pagado a Supplaii en cualquiera de las siguientes tres formas: Será pagado por EL USUARIO COMERCIALIZADOR a Supplaii al momento de generar la respectiva orden de envío, mediante transferencia electrónica, descontando su margen de utilidad. Será pagado por el cliente de EL USUARIO COMERCIALIZADOR a Supplaii a través del transportador que esta disponga, contra entrega de los productos. Será pagado electrónicamente por el cliente de EL USUARIO COMERCIALIZADOR a Supplaii, previamente a la generación de la respectiva orden de envío, mediante un link de pago que EL USUARIO COMERCIALIZADOR debe enviarle.</p>

      <h3>NOVENA. TÉRMINO DE VIGENCIA:</h3>
      <p>El presente contrato estará vigente desde la fecha en que EL USUARIO COMERCIALIZADOR cree un perfil (cuenta) en el aplicativo Supplaii.</p>

      <h3>DÉCIMA. CLÁUSULA PENAL:</h3>
      <p>En caso en el que EL USUARIO COMERCIALIZADOR incumpla alguna de las obligaciones que por medio de este contrato asume, faculta a Supplaii para darlo por terminado sin ninguna clase de requerimientos, así como para exigir cien mil pesos colombianos ($100.000) a título de pena, sin perjuicio de la correspondiente acción de indemnización de perjuicios.</p>

      <h3>DÉCIMA PRIMERA. INDEPENDENCIA DE LAS PARTES Y SUS TRABAJADORES Y EXCLUSION DE RELACIÓN LABORAL:</h3>
      <p>Las partes del presente contrato ejecutarán las obligaciones establecidas en el mismo, con total autonomía técnica, administrativa y operativa, por lo que no existirá relación laboral o de subordinación de ningún tipo entre ellas, ni entre cualquier parte contratante y cualquiera de los empleados, subcontratistas o dependientes de la otra.</p>

      <h3>DÉCIMA SEGUNDA. AUSENCIA DE ENCARGO:</h3>
      <p>Las Partes asimismo, reconocen y declaran que el presente Contrato no constituye encargo de ningún tipo ni entraña compromiso alguno de promover o desarrollar los negocios o prestar los servicios de cualquiera de ellas, en la medida en que la labor de Supplaii se limita a permitir que EL USUARIO COMERCIALIZADOR consulte, ordene y oferte en su propio nombre, por su propia cuenta y riesgo los productos que autónomamente determine; es decir, a prestar una plataforma para que se conecten la oferta con la demanda.</p>

      <h3>DÉCIMA TERCERA. DERECHO DE RETRACTO:</h3>
      <p>Supplaii no comercializa productos directamente a EL USUARIO COMERCIALIZADOR. Supplaii solo actúa como un portal de contacto entre EL USUARIO COMERCIALIZADOR y proveedores de bienes y servicios. Todos los productos que se reflejan y son exhibidos en la PLATAFORMA son comercializados por LOS USUARIOS PROVEEDORES.</p>

      <h3>DÉCIMA CUARTA. RESPONSABILIDAD:</h3>
      <p>Las consecuencias de índole penal y civil que puedan sobrevenir del ejercicio del oficio o la profesión indispensables para la ejecución del objeto de este contrato, serán atendidas de conformidad con la normatividad legal vigente.</p>

      <h3>DÉCIMA QUINTA. CONFIDENCIALIDAD:</h3>
      <p>La información, que le haya sido confiada a alguna de las partes por la otra, o que conozca, del personal vinculado a ella o de cualquier otra entidad o programa vinculado a la actividad ejecutada en desarrollo del objeto contratado y de cuyo uso indebido pueden generarse consecuencias comerciales - técnicas o de cualquier carácter para los mismos, será confidencial; por tanto, ambas partes, por el solo hecho de la creación del perfil de EL USUARIO COMERCIALIZADOR, se comprometen a no revelar, difundir, comentar, analizar, evaluar, copiar o realizar un uso diferente del previsto en este acuerdo, ni utilizarán dicha información para el ejercicio de su propia actividad, ni la duplicarán o compartirán con terceras personas, salvo autorización previa y escrita del propietario de la información, so pena de incumplir el presente contrato e independientemente de la decisión frente a su vinculación.</p>

      <h3>DÉCIMA SEXTA. SOLUCIÓN DE CONTROVERSIAS:</h3>
      <p>Las partes solucionarán directamente las diferencias que se presenten entre ellas con ocasión de la negociación, celebración, perfeccionamiento, desarrollo, ejecución, interpretación, efectos, terminación y liquidación del presente contrato. No obstante, si transcurrieren cuarenta y cinco (45) días comunes sin que las mismas llegaren a algún acuerdo, las partes acudirán a los mecanismos de solución de controversias extrajudiciales establecidos en la Ley 446 de 1998 y las demás disposiciones que las modifiquen, adicionen o sustituyan, tales como la transacción, conciliación y amigable composición, para solucionar las diferencias surgidas en la ejecución del presente contrato.</p>

      <h3>DÉCIMA SEPTIMA. CESIÓN:</h3>
      <p>EL USUARIO COMERCIALIZADOR no podrá ceder el presente contrato ni hacerse sustituir total o parcialmente en el cumplimiento de sus obligaciones salvo previa, expresa y escrita autorización de Supplaii.</p>

      <h3>DÉCIMA OCTAVA. CAUSALES DE TERMINACIÓN:</h3>
      <p>El presente contrato se dará por terminado por Supplaii sin requerimiento alguno, sin perjuicio de las demás causales establecidas en la ley, en los siguientes eventos: Por incumplimiento de las obligaciones a cargo de EL USUARIO COMERCIALIZADOR que afecte de manera directa la ejecución del contrato, acarreando retrasos o paralización del servicio contratado. Cesión parcial o total de la posición contractual, derechos u obligaciones derivadas de este negocio jurídico no autorizada por Supplaii.</p>

      <h3>DÉCIMA NOVENA. FUERZA MAYOR:</h3>
      <p>Se aplicará la fuerza mayor en los precisos términos de lo establecido en el Código Civil colombiano.</p>

      <h3>VIGÉSIMA. RENUNCIAS:</h3>
      <p>Ninguna omisión, demora o inacción de cualquiera de las Partes en el ejercicio de cualquier derecho, facultad o recurso bajo este Contrato, podrá ser considerada como una renuncia a la misma, a menos que así se indique expresamente por escrito.</p>

      <h3>VIGÉSIMA PRIMERA. DIVISIBILIDAD Y NULIDAD PARCIAL:</h3>
      <p>En el evento en que se determine que alguna disposición de este Contrato es nula, no válida o inexigible (en todo o en parte), dicha disposición o la parte correspondiente se considerará, en esa medida, que no forma parte de este Contrato y, en consecuencia, el resto de las disposiciones contractuales mantendrán toda su vigencia.</p>

      <h3>VIGÉSIMA SEGUNDA. JURISDICCIÓN Y LEY APLICABLE:</h3>
      <p>La Ley aplicable a todo asunto relacionado con el presente negocio jurídico será la Ley Colombiana y el idioma del contrato y el de las comunicaciones entre las partes, será el castellano.</p>

      <h3>VIGÉSIMA TERCERA. MANEJO DE DATOS:</h3>
      <p>Los datos proporcionados por EL USUARIO COMERCIALIZADOR a Supplaii, sólo podrán ser utilizados para efectos de la identificación y relacionamiento contractual a que se refieren las cláusulas del presente negocio, no podrán ser divulgados ni utilizados para efectos diferentes y en su manejo serán aplicables las normas colombianas pertinentes.</p>

      <h3>VIGÉSIMA CUARTA. ANEXOS:</h3>
      <p>Se considera documento integrante del presente acuerdo, el registro electrónico de los datos de EL USUARIO COMERCIALIZADOR para la creación de su perfil en el aplicativo.</p>

      <h3>VIGÉSIMA QUINTA. ACUERDO INTEGRAL Y REFORMAS:</h3>
      <p>El presente contrato y sus anexos constituyen el acuerdo integral que vincula a las partes en relación con el objeto del mismo. En consecuencia, este contrato deroga expresamente todos los acuerdos anteriores verbales o escritos que tengan relación con el mismo objeto.</p>

      <h3>VIGÉSIMA SEXTA. PERFECCIONAMIENTO:</h3>
      <p>El presente contrato se entenderá perfeccionado con la creación de un perfil en el aplicativo Supplaii por parte de EL USUARIO COMERCIALIZADOR.</p>

    </div>
  );
}
