import PropTypes from "prop-types";
import style from "./DetailShippingAddressForm.module.css";
import {  useState } from "react";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import DeleteButton from "../DeleteButton/DeleteButton";
import MainButton from "../MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";

import { generateRandomId } from "../../utils/generateRandomId.JS";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import useMatchProvinceAndCity from "../../hooks/useMatchProvinceAndCity";
import { defProvinces } from "../../utils/defProvinces";
import { getCitiesByProvince } from "../../utils/getCitiesByProvince";

const DetailShippingAddressForm = ({
  selectedShippingAddress,
  userData,
  labelDelete,
  onClose,
  toast,
  isInsider,
  isMaster
}) => {
  const { t } = useTranslation("Home");
  const { addToArray, removeFromArray } = useFirebaseCRUD();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { countries } = useGlobalContext();
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const timestamp = new Date();
  const hasEdit = !!Object?.keys(selectedShippingAddress)?.length;
  const collectionName = isMaster || isInsider ? "master_user" : "seller_users"; 

  const [shippingAddressValues, setshippingAddressValues] = useState({
    id: selectedShippingAddress.id || generateRandomId(),
    created_at: selectedShippingAddress.created_at || timestamp,
    address_label: selectedShippingAddress?.address_label || "",
    address_line_1: selectedShippingAddress?.address_line_1 || "",
    address_line_2: selectedShippingAddress?.address_line_2 || "",
    city: selectedShippingAddress?.city || "",
    country: selectedShippingAddress?.country || "",
    country_code: selectedShippingAddress?.country_code || "",
    phone_number: selectedShippingAddress?.phone_number || "",
    zip_code: selectedShippingAddress?.zip_code || "",
  });

  const [country, setCountry] = useState(selectedShippingAddress?.country|| null);
  const [province, setProvince] = useState(selectedShippingAddress?.province||null);
  const [ city, setCity ] = useState(selectedShippingAddress?.city|| null );
  const { zones} = useMatchProvinceAndCity(province);


 
  
  const handleInputChange = (field, value) => {
    setshippingAddressValues((prevValues) => {
      // Guardar los valores previos antes de la actualización
      const previousValues = { ...prevValues };
      // Actualizar el campo específico con el nuevo valor
      previousValues[field] = value;
      return previousValues;
    });
  };
  const handleInputSelectChange = (fieldName, e) => {
    // Guardar los valores previos antes de la actualización
    // console.log("validacion", fieldName, e);
    if (fieldName==="country") {
      setCountry(e);
      setshippingAddressValues((prevValues) => ({
        ...prevValues,
        country: e,
        country_code: e.code
      }));
    }else if (fieldName === "departament") {
      setProvince(e);
      setshippingAddressValues((prevValues) => ({
        ...prevValues,
        province: e,
      }));
    }else if (fieldName==="city") {
      setCity(e);
      setshippingAddressValues((prevValues) => ({
        ...prevValues,
        city: e,
      }));
    }
  };
  const handleCRUDShippingAddress = async (e) => {
    e.preventDefault();
 
    setGlobalLoading(true);
    try {
      if (hasEdit) {
        await removeFromArray(collectionName, userData.uid, "shipping_address", selectedShippingAddress);
      }
      await addToArray(collectionName, userData.uid, "shipping_address", shippingAddressValues);
      toast?.current?.show({
        severity: "success",
        summary: `${t("toast-summary.shippingAddressSucces1")} ${hasEdit ? t("updated") : t("added")} ${t("toast-summary.shippingAddressSucces2")}`,
        detail: "",
      });
    } catch (error) {
      const errorMessage = error?.data?.message || t("toast-summary.errorMessageShippingAddress");
      toast?.current?.show({
        severity: "error",
        summary: errorMessage,
        detail: "",
      });
    } finally {
      setGlobalLoading(false);
      onClose(false);
    }
  };
  const handleDeleteShippingAddress = async () => {
    setGlobalLoading(true);
    const result = await removeFromArray(
      collectionName,
      userData.uid,
      "shipping_address",
      selectedShippingAddress
    );
    if (result) {
      toast?.current?.show({
        severity: "success",
        summary: t("toast-summary.deleteShippingAddresSucess"),
        detail: "",
      });
    } else {
      if (result.data.success) {
        toast?.current?.show({
          severity: "error",
          summary: t("toast-summary.deleteShippingAddresError"),
          detail: "",
        });
      }
    }
    setGlobalLoading(false);
    onClose(false);
  };
  const openConfirmDialog = () => {
    setVisibleConfirmDialog(true);
  };


  return (
    <form onSubmit={handleCRUDShippingAddress}>
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={shippingAddressValues.address_label}
        placeholder={t("input-placeholders.addressLabel")}
        onChange={(e) => handleInputChange("address_label", e.target.value)}
        required={true}
      />
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={countries}
          valueTemplate={""}
          itemTemplate={""}
          value={country}
          placeholder={t("input-placeholders.country")}
          onChange={(e) => handleInputSelectChange("country", e.target.value)}
          required={true}
        />

        {/* Select de departamento */}

       <CustomSelect
          floatLabel={true}
          optionLabel={"province_name"}
          options={defProvinces(zones)}
          valueTemplate={""}
          itemTemplate={""}
          value={province}
          placeholder={t("input-placeholders.province")}
          onChange={(e) => handleInputSelectChange("departament", e.target.value)}

        disabled={globalLoading }
        />
        {/* Select de ciudades */}
          <CustomSelect
            floatLabel={true}
            optionLabel={"city_name"}
            options={getCitiesByProvince(province?.province_code, zones)}
            valueTemplate={""}
            itemTemplate={""}
            value={city}
            placeholder={t("input-placeholders.city")}
            onChange={(e) => handleInputSelectChange("city", e.target.value)}

            disabled={globalLoading  || !province}

          />
      {/* <CustomInputText
        floatLabel={true}
        type={"text"}
        value={shippingAddressValues.city}
        placeholder={t("input-placeholders.city")}
        onChange={(e) => handleInputChange("city", e.target.value)}
        required={true}
      /> */}
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={shippingAddressValues.address_line_1}
        placeholder={t("input-placeholders.addressLine1")}
        onChange={(e) => handleInputChange("address_line_1", e.target.value)}
        required={true}
      />
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={shippingAddressValues.address_line_2}
        placeholder={t("input-placeholders.addressLine2")}
        onChange={(e) => handleInputChange("address_line_2", e.target.value)}
        required={true}
      />
      <CustomInputText
        className="p-2 m-0 w-full"
        name="phone_number"
        floatLabel={true}
        value={shippingAddressValues.phone_number}
        placeholder={t("input-placeholders.phoneNumber")}
        required={true}
        type={"number"}
        onChange={(e) => handleInputChange("phone_number", e.target.value)}
      />
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={shippingAddressValues.zip_code}
        placeholder={t("input-placeholders.zipCode")}
        onChange={(e) => handleInputChange("zip_code", e.target.value)}
      />
      <div className={style.containerBtns}>
        {hasEdit && (
          <DeleteButton
            type={"button"}
            label={labelDelete}
            loading={globalLoading}
            onClick={() => openConfirmDialog(true)}
          />
        )}
        <MainButton
          type={"onSubmit"}
          label={hasEdit ? t("labelEditShippingAddress") : t("labelCreateShippingAddress")}
          loading={globalLoading}
          autoFocus
        />
      </div>
      <div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteBankAccount.header")}
          message={t("confirmDialog-deleteBankAccount.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteShippingAddress}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteBankAccount.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteBankAccount.rejectLabel")}
        />
      </div>
    </form>
  );
};

DetailShippingAddressForm.propTypes = {
  selectedShippingAddress: PropTypes.object,
  toast: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  index: PropTypes.number,
  isInsider: PropTypes.bool,
  isMaster: PropTypes.bool
};

export default DetailShippingAddressForm;
