import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx"
import { useState, useEffect } from "react";
import toastFunction from "../../utils/toastFunction.js";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber.jsx";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import {generateRandomId} from "../../utils/generateRandomId.js";
import MainButton from "../MainButton/MainButton.jsx";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/firebase.js";


export default function RequestWithdrawalForm({userData, walletUser, toast, onClose}) {
    const { t } = useTranslation("Wallet");
    const { globalLoading, setGlobalLoading } = useLoaderContext();
    const [selectedBankAccount, setSelectedBankAccount] = useState(null);
    const initialWithdrawalRequest = {
        id: generateRandomId(), // id de la solicitud
        bank_account_id: "", // id del banco del seller donde desea recibir el desembolso
        related_business_id: userData.business_id,
        related_business_name: userData.business_name,
        request_withdrawal_available: false, // Indicador de si se retirara todo lo disponible
        related_wallet_id: null, // Idnetificador del wallet a la que se vinculara la transaccion
        related_transaction_id: "",  // Identificador de la transaccion de retiro asociada por conecpto OUT
        is_processed: false,
        amount: 0, // monto a retirar
        state: 0, // estado codificado de la solicitud 0. "pending", 1. "paid" 2. "rejected"  
    }
    const [withdrawalRequestValues, setWithdrawalRequestValues] = useState(initialWithdrawalRequest);
    // console.log("Valores de la solicitud de retiro", withdrawalRequestValues)
    
    const handleToggleSwitchRequestWithdrawalAvailable = (e) => {
    // Actualiza el estado utilizando el anterior valor de product_is_active
    setWithdrawalRequestValues((prevValues) => ({
            ...prevValues,
            request_withdrawal_available: e.value,
        }));
        if(e.value) {
            setWithdrawalRequestValues((prevValues) => ({
                ...prevValues, 
                amount: walletUser.balance_available,
            }));
        }
    };

    const handleBankAccountSelect = (bankAccount) => {
        setSelectedBankAccount(bankAccount);
        setWithdrawalRequestValues((prevValues) => ({
            ...prevValues,
            bank_account_id: bankAccount.id,
        }));
    };
    
    const createWithdrawalRequest = async (e) => {
        setGlobalLoading(true);
        e.preventDefault();
        if(withdrawalRequestValues.amount === 0 ) {
            toastFunction(toast, "warn", "El monto de la solicitud es insuficiente ", "", 5000);
            setGlobalLoading(false);
            return
        }
        const body = {withdrawalRequestValues}
        const createWithdralRequestFunc = httpsCallable(functions, "createWithdrawalRequest");
        let responseWalletTransaction;
        try {
            responseWalletTransaction = await createWithdralRequestFunc({
                ...body
            })
            if (responseWalletTransaction.data.success) {
                toastFunction(toast, "success", "Solicitud de retiro exitosa", "Espera a que sea aprobada", 5000);
                onClose();
            } else {
                toastFunction(toast, "error", responseWalletTransaction.data.message, responseWalletTransaction.data.error, 7000);
            }
        } catch (error) {
            console.error("Error llamando la función:", error);
        }
       
        // console.log("responseWalletTransaction", responseWalletTransaction);
        setGlobalLoading(false);
    };

    useEffect(() => {
        setGlobalLoading(true);
        if(userData?.bank_accounts.length === 0) {
            toastFunction(toast, "info", "Porfavor Agrega cuentas bancarias para poder solcitar un retiro", "", 3000)
        }
        setGlobalLoading(false);
    },[userData]);

    return (
        <form onSubmit={createWithdrawalRequest}>
          <CustomSelect
            required={true}
            floatLabel={true}
            optionLabel={"name_user_account"}
            options={userData?.bank_accounts}
            valueTemplate={""}
            itemTemplate={""}
            value={selectedBankAccount}
            placeholder={t("input-placeholders.bankAccount")}
            onChange={(e) => handleBankAccountSelect(e.target.value)}
            disabled={globalLoading}
          />
          <CustomInputNumber
            required={true}
            floatLabel={true}
            value={withdrawalRequestValues?.amount}
            min={0}
            prefix={"$ "}
            max={walletUser.balance_available}
            placeholder={t("input-placeholders.balanceAvailable")}
            onChange={(e) => setWithdrawalRequestValues((prevValues) => ({
                ...prevValues,
                amount: e.value,
            }))}
            disabled={!selectedBankAccount || globalLoading}
          />
          <span className="flex justify-center items-center gap-4 mt-4 mb-4">
            <CustomInputSwitch
                checked={withdrawalRequestValues.request_withdrawal_available}
                name={"show_product_to_other_sellers"}
                onChange={(e) => handleToggleSwitchRequestWithdrawalAvailable(e)}
                disabled={!selectedBankAccount || globalLoading || walletUser?.balance_available === 0}
            /> Retirar todo lo disponible
          </span>
          <MainButton
            style={{ marginTop: "20px" }}
            type={"submit"}
            label={t("createWithdrawalRequest")}
            loading={globalLoading}
            disabled={globalLoading}
            autoFocus
        />
        </form>
    )
}


RequestWithdrawalForm.propTypes = {
    userData: PropTypes.object, // Datos del usuario
    walletUser: PropTypes.object, // Datos de la billetera del usuario
    toast: PropTypes.object, // Función para mostrar mensajes de notificación
    onClose: PropTypes.func,
};